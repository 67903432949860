/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { useParams } from "react-router";

import { makeStyles } from "@mui/styles";

import ErrorState from "Components/DataTable/ErrorState";

import { ISmartDocData, getSmartDocDataForDocument } from "Adapters/documentAdapter";

type SmartDocMatch = {
  packageId: string;
  docId: string;
};

const useStyles = makeStyles(() => ({
  content: {
    height: "100vh",
    overflow: "auto",
    position: "absolute",
    width: "100%",
  },
}));

/**
 * This component will take smart doc data and render a table of the all of the fields and values for the smart doc
 * @constructor
 */
export default function SmartDocData(): ReactElement | null {
  const classes = useStyles();
  const [smartDocData, setSmartDocData] = useState<ISmartDocData>();
  const [loading, setLoading] = useState<boolean>(true);
  const match = useParams();
  const { packageId, docId } = match as SmartDocMatch;
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const retrieveData = async () => {
      try {
        const data = await getSmartDocDataForDocument(packageId, docId);
        setSmartDocData(data);
      } catch (err) {
        setShowError(true);
      }
      setLoading(false);
    };
    if (loading) {
      retrieveData();
    }
  }, [docId, loading, packageId]);

  function handleRetry() {
    setLoading(true);
  }

  if (loading) return null;
  let children;

  if (smartDocData != null) {
    children = (
      <>
        <h1>SMART Doc: {smartDocData.title}</h1>
        <h3>Document Data</h3>
        <table className="smart-doc-table">
          <thead>
            <tr>
              <th align="left" className="no-border">
                Field
              </th>
              <th align="left" className="no-border">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {smartDocData.rows.map((row, index) => (
              <tr className="border-top" key={index}>
                <td className="no-border">{row.field}</td>
                <td className="no-border">{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  } else if (showError) {
    children = (
      <div
        css={css`
          align-items: center;
          display: flex;
          height: 100%;
        `}
      >
        <ErrorState message="Error loading smart doc data" onRetry={handleRetry} />
      </div>
    );
  } else {
    children = (
      <>
        <h1>SMART Doc</h1>
        <h3>No smart doc was found for this package</h3>
      </>
    );
  }

  return <div className={classes.content}>{children}</div>;
}
