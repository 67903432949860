/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import AppBar from "Features/Navigation/AppBar";
import Footer from "Features/Navigation/Footer";

interface IErrorPageProps {
  children: React.ReactNode | React.ReactNode[];
  title: string;
}

export default function ErrorPage({ children, title }: IErrorPageProps): ReactElement {
  return (
    <>
      <AppBar />
      <main
        css={css`
          background-color: #ebebeb;
          display: flex;
          flex-direction: column;
          height: 100vh;
        `}
      >
        <Toolbar />
        <Container maxWidth="sm">
          <Paper
            css={css`
              align-items: center;
              flex: 1;
              justify-content: center;
              margin-top: 60px;
              padding: 40px;
            `}
          >
            <Typography
              css={css`
                text-align: center;
              `}
              variant="h1"
            >
              {title}
            </Typography>
            <div
              css={css`
                margin-top: 24px;
              `}
            >
              {children}
            </div>
          </Paper>
        </Container>
      </main>
      <Footer />
    </>
  );
}
