import axios from "axios";
import querystring from "querystring";

import { SortOrderType } from "Common/Enums";
import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import { IResultPages } from "Types/EVaultAppTypes";

import { IVaultViewQueueSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../../Constants";
import { IQueueSearchApiParams } from "./QueueSlice";

export interface IQueuePagedData {
  links: IResultPages;
  results: IVaultViewQueueSearchResultData[];
}

export function getQueueByUrl(url: string): Promise<IQueuePagedData> {
  return axios.get(url).then((response) => response.data);
}

interface IQueueSeachPayload {
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
  sortField?: string;
  sortOrderType?: SortOrderType;
}

function getQueueQueryString(params: IQueueSearchApiParams) {
  const payload: IQueueSeachPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop),
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchTerm: params.searchTerm,
  };

  // Sort
  if (params.sortField) {
    payload.sortField = params.sortField;
    payload.sortOrderType = params.sortOrderType;
  }

  return "?" + querystring.stringify(payload as any);
}

export function getQueueUrl(params: IQueueSearchApiParams): string {
  return `/api/v1/mers/queue${getQueueQueryString(params)}`;
}
