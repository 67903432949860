/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ReverseRegisterationIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        d="M17.2,16.5L12.7,12L9.4,8.7v0L6.6,5.9L3.4,2.8L2,4.2l4.1,4v12.4h3v-6h3.6l0.5,0.8l2.2,5.2h3.2l2,2.1
				l1.4-1.4L17.2,16.5z M9.1,11.6v-0.4l0.4,0.4H9.1z"
      />
      <path
        d="M12.3,7.6c1,0,1.6,0.1,2.1,0.2C14.8,8,15,8.2,15.2,8.5c0.1,0.3,0.2,0.8,0.2,1.5c0,0.7-0.1,1.2-0.2,1.5
				c0,0.1-0.1,0.2-0.2,0.2l2.5,2.5c0.9-0.8,1.4-2.3,1.4-4.3c0-1.2-0.1-2.2-0.4-2.9c-0.3-0.8-0.8-1.4-1.5-1.8c-0.8-0.4-1.9-0.6-3.3-0.6
				H7.9l3,3H12.3z"
      />
    </SvgIcon>
  );
}
