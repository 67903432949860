/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ValidIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M20 12l-1.9-2.1.3-2.8-2.8-.6L14.2 4l-2.6 1.1L9 4 7.5 6.4l-2.7.7.2 2.8L3.2 12 5 14.1l-.3 2.8 2.8.6L9 20l2.6-1.1 2.6 1.1 1.4-2.4 2.8-.6-.3-2.8L20 12m-9.9 3.8l-3.1-3 1.1-1.1 2 2 5-5 1.1 1.1-6.1 6z" />
    </SvgIcon>
  );
}
