import React, { useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import Loading from "Components/Loading";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { AppDispatch, RootState } from "../../../App/Store";
import { viewBatchAction } from "../Mers/MersTransaction/MersTransactionSlice";
import { MersTransactionStatus, MersTransactionType } from "../VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  batch: state.mersTransactions.batches[0],
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

interface ITransactionResultsModalPopupProps {
  onClose: (type: MersTransactionType) => void;
}

type Props = TypesFromRedux & ITransactionResultsModalPopupProps;

export function TransactionResultsModalPopup(props: Props) {
  const closable = true;
  const loading = false;
  const dispatch = useDispatch() as AppDispatch;
  const { id: batchId, type } = props.batch || {};
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (batchId) {
      dispatch(viewBatchAction({ batchId }));
      setOpen(true);
    }
  }, [dispatch, batchId]);

  if (!props.batch) return null;

  function getStatusObjectByMersTransactionTypeAndStatus(
    type: MersTransactionType,
    status: MersTransactionStatus
  ): JSX.Element | null {
    let result: JSX.Element | null;
    switch (type) {
      case MersTransactionType.Delivery:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">eNote delivery failed</span>;
            break;

          case MersTransactionStatus.DeliveryNotApproved:
            result = <span className="title-fail">eNote delivery not approved</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">eNote delivery in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">eNote delivery complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">eNote delivery unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Registration:
      case MersTransactionType.ReverseRegistration:
      case MersTransactionType.ChangeStatus:
      case MersTransactionType.ChangeData:
      case MersTransactionType.SecuredParty:
      case MersTransactionType.DelegateeForTransfer:
      case MersTransactionType.RemoveSubservicer:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">In progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Transfer:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Transfer failed</span>;
            break;

          case MersTransactionStatus.TransferNotApproved:
            result = <span className="title-fail">Transfer not approved</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Transfer in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Transfer complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Transfer unknown</span>;
            break;
        }
        break;

      case MersTransactionType.DeliveryConfirmation:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Cancel pending delivery failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Delivery cancel in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Delivery cancelled</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Delivery unknown</span>;
            break;
        }
        break;

      case MersTransactionType.TransferConfirmation:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Cancel pending transfer failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Transfer cancel in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Transfer cancelled</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Transfer unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Unknown:
      default:
        result = <span className="title-fail">[Unknown]</span>;
        break;
    }

    return result;
  }

  const groupedResults = _.groupBy(props.batch.requests, "loanNumber");

  const items: JSX.Element[] = [];

  _.each(groupedResults, (resultStack, loanNumber) => {
    const itemNumber = items.length + 1;
    let parentStatus: JSX.Element | null = null;
    const parentErrors: JSX.Element[] = [];
    const parentWarnings: JSX.Element[] = [];
    const children: JSX.Element[] = [];

    _.each(resultStack, (request) => {
      _.each(request.transactions, (result) => {
        if (1 === resultStack.length || MersTransactionType.Transfer !== result.type) {
          parentStatus = getStatusObjectByMersTransactionTypeAndStatus(result.type, result.status);

          _.each(result.errors, (error, index) => {
            parentErrors.push(<li key={"merstransaction-item-" + itemNumber + "- error - " + index}>{error}</li>);
          });
          _.each(result.warnings, (warning, index) => {
            parentWarnings.push(<li key={"merstransaction-item-" + itemNumber + "-warning-" + index}>{warning}</li>);
          });
        } else {
          // We have a 'Transfer' that is grouped with another Mers transaction -- The transfer is a child
          const childNumber = children.length + 1;
          const childStatus = getStatusObjectByMersTransactionTypeAndStatus(result.type, result.status);
          const childErrors: JSX.Element[] = [];
          const childWarnings: JSX.Element[] = [];

          _.each(result.errors, (error, index) => {
            childErrors.push(<li key={"merstransaction-item-" + itemNumber + "- error - " + index}>{error}</li>);
          });
          _.each(result.warnings, (warning, index) => {
            childWarnings.push(<li key={"merstransaction-item-" + itemNumber + "-warning-" + index}>{warning}</li>);
          });

          children.push(
            <div
              key={"merstransaction-item-" + itemNumber + "-child-" + childNumber}
              className="modalpopup-merstransactionresults-dialog-content-item"
            >
              <div className="modalpopup-merstransactionresults-dialog-content-item-title">
                <span>Loan # / MIN: {loanNumber}, </span>
                {childStatus}
              </div>
              <div>
                {0 < childErrors.length && <span className="italicized">Error(s): </span>}
                <ul>{childErrors}</ul>
                {0 < childWarnings.length && <span className="italicized">Warnings(s): </span>}
                <ul>{childWarnings}</ul>
              </div>
            </div>
          );
        }
      });
    });

    items.push(
      <div key={"merstransaction-item-" + itemNumber} className="modalpopup-merstransactionresults-dialog-content-item">
        <div className="modalpopup-merstransactionresults-dialog-content-item-title">
          <span className="bolded">Loan # / MIN: </span>
          <span>{loanNumber}, </span>
          {parentStatus}
        </div>
        <div className="modalpopup-merstransactionresults-dialog-content-item-content">
          {0 < parentErrors.length && <span className="italicized">Error(s): </span>}
          <ul>{parentErrors}</ul>
          {0 < parentWarnings.length && <span className="italicized">Warnings(s): </span>}
          <ul>{parentWarnings}</ul>
          {children}
        </div>
      </div>
    );
  });

  function handleClose() {
    props.onClose(type);
    setOpen(false);
  }

  // Content can be error or items
  // let content;
  // if (error) {
  //   content = getErrorMessage(error);
  // } else {
  //   content = items;
  // }
  const modalContent = (
    <div>
      {loading && <Loading />}
      {items}
    </div>
  );

  return (
    <ModalPopupAlert
      confirmationButtonText="OK"
      disabled={!closable}
      onClose={handleClose}
      overlayCloseClick={closable}
      title={getTitleFromTransactionType(type)}
      content={modalContent}
      open={open}
      size="sm"
    />
  );
}

/**
 * Get the title text for the component via the transaction's type
 * @param type The type of the transaction
 * @returns the title from the type
 */
function getTitleFromTransactionType(type: MersTransactionType): string {
  let result: string;
  switch (type) {
    case MersTransactionType.Registration:
    case MersTransactionType.LastResultRegistration:
      result = "Registration results";
      break;
    case MersTransactionType.ReverseRegistration:
    case MersTransactionType.LastResultReverseRegistration:
      result = "Reverse registration results";
      break;
    case MersTransactionType.Delivery:
    case MersTransactionType.LastResultDelivery:
      result = "Delivery results";
      break;
    case MersTransactionType.Transfer:
    case MersTransactionType.TransferConfirmation:
    case MersTransactionType.LastResultTransfer:
      result = "Transfer results";
      break;

    case MersTransactionType.CancelTransfer:
      result = "Cancel pending transfer results";
      break;
    case MersTransactionType.CancelDelivery:
      result = "Cancel pending delivery results";
      break;

    case MersTransactionType.ChangeStatus:
    case MersTransactionType.ChangeData:
      result = "Update results";
      break;

    case MersTransactionType.DelegateeForTransfer:
      result = "Delegatee for transfers results";
      break;

    case MersTransactionType.SecuredParty:
      result = "Secured party results";
      break;

    case MersTransactionType.RemoveSubservicer:
      result = "Subservicer results";
      break;

    case MersTransactionType.Unknown:
    default:
      result = "[Unknown MERS transaction type]";
      break;
  }

  return result;
}

export default connector(TransactionResultsModalPopup);
