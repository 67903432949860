/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function RemovePartyIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M12.67,2.06a10,10,0,1,0,10,10A10,10,0,0,0,12.67,2.06Zm-8,10a8,8,0,0,1,8-8,7.87,7.87,0,0,1,4,1.12l-1.5,1.5a2.67,2.67,0,0,0-2.57-1.62c-1.63,0-3,1.08-3,3.59a3.41,3.41,0,0,0,1.05,2.5l-1.5,1.5a10.29,10.29,0,0,0-1.15.4,2.3,2.3,0,0,0-1.43,2.08v.1l-.89.89A8,8,0,0,1,4.67,12.06Zm8,8A8,8,0,0,1,7,17.72L18.32,6.41a8,8,0,0,1-5.65,13.65Z" />
      <path d="M17.22,13.05a14.91,14.91,0,0,0-2.07-.64L10.5,17.06h8.17V15.13A2.33,2.33,0,0,0,17.22,13.05Z" />
    </SvgIcon>
  );
}
