import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import { IHttpErrorState } from "Types/EVaultAppTypes";

import { getClientDefinedDataFields } from "./clientDefinedDataFieldsAdapters";

export interface IClientDefinedDataFieldsState {
  initialized: boolean;
  data: IClientDefinedDataField[];
  isLoading: boolean;
  error?: IHttpErrorState;
}

export interface IClientDefinedDataField {
  id: string;
  enabled: boolean;
  name: string;
}

const initialClientDefinedDataFields: IClientDefinedDataFieldsState = {
  data: [],
  initialized: false,
  isLoading: false,
};

export const fetchDataFields = createAsyncThunk<
  IClientDefinedDataField[],
  undefined,
  {
    rejectValue: IHttpErrorState;
  }
>("client-defined-data-fields/fetch", async (_, thunkApi) => {
  try {
    return await getClientDefinedDataFields();
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const clientDefinedDataFieldsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchDataFields.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.data = [];
    });
    builder.addCase(fetchDataFields.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchDataFields.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: initialClientDefinedDataFields,
  name: "clientDefinedDataFields",
  reducers: {},
});

export default clientDefinedDataFieldsSlice.reducer;
