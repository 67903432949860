import React, { ReactElement } from "react";

import { Route, Routes } from "react-router-dom";

import AllLoans from "Features/Vault/AllLoans";
import ArchivedLoans from "Features/Vault/ArchivedLoans";
import BatchOperations from "Features/Vault/BatchOperations";
import ClosedLoans from "Features/Vault/ClosedLoans";
import InactiveLoans from "Features/Vault/InactiveLoans";
import Incoming from "Features/Vault/Incoming";
import MinReconciliation from "Features/Vault/MinReconciliation";
import Queue from "Features/Vault/Queue";

export function Vault(): ReactElement {
  return (
    <Routes>
      <Route path="/app/vault/all" element={<AllLoans />} />
      <Route path="/app/vault/closed" element={<ClosedLoans />} />
      <Route path="/app/vault/archived" element={<ArchivedLoans />} />
      <Route path="/app/vault/inactive" element={<InactiveLoans />} />
      <Route path="/app/vault/incoming" element={<Incoming />} />
      <Route path="/app/vault/queue" element={<Queue />} />
      <Route path="/app/vault/batch-operations" element={<BatchOperations />} />
      <Route path="/app/vault/min-reconciliation" element={<MinReconciliation />} />
    </Routes>
  );
}

export default Vault;
