/*
 We will be organizing the store in a nonsql structure way
//The idea is to normalize like a collections(Table in SQL) base database or store in redux
//Each mini App Example AdminApp or DocReviewApp has its own set of entities/document/interfaces
*/
// Add all the collection types here for TS reference
import { SortOrderType } from "Common/Enums";

import { IVaultClosedSearchBarParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import { IVaultQueueSearchBarParams } from "Features/Vault/Mers/Queue/QueueSlice";
import {
  IMersRecipientDetails,
  IVaultViewClosedLoansSearchResultData,
  IVaultViewManualDeliveriesSearchResultData,
  IVaultViewManualTransfersSearchResultData,
  IVaultViewQueueSearchResultTableData,
} from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../Features/Vault/Constants";

export interface IMersRecipientStoreState {
  error?: IHttpErrorState;
  isLoading: boolean;
  recipients: IMersRecipientDetails[];
}

// ##STOP...ADD ANY GENERIC CONSTANT TO BOTTOM OR UNDER A CATEGORY

export interface IResultPages {
  next?: string;
  offset: number;
  previous?: string;
  self?: string;
}

export interface ISortByParams {
  sortField?: string;
  sortOrderType?: SortOrderType;
}

export interface IHttpErrorState {
  errors?: string[];
  name?: string;
  message?: string;
  status?: number;
}

export interface IClosedLoans {
  initialized: boolean;
  isLoading: boolean;
  links?: IResultPages;
  resourceUrl?: string;
  searchResult: IVaultViewClosedLoansSearchResultData[];
  searchBarParams: IVaultClosedSearchBarParams;
  sortByParams: ISortByParams;
  error?: IHttpErrorState;
}

export interface IManualTransfers {
  searchResult: IVaultViewManualTransfersSearchResultData[];
  searchBarParams: IVaultSearchBarParams;
  isLoading: boolean;
  error?: IHttpErrorState;
  [key: string]: any;
}

export interface IManualDeliveries {
  searchResult: IVaultViewManualDeliveriesSearchResultData[];
  searchBarParams: IVaultSearchBarParams;
  isLoading: boolean;
  error?: IHttpErrorState;
  [key: string]: any;
}

export interface IQueue {
  initialized: boolean;
  isLoading: boolean;
  links?: IResultPages;
  searchBarParams: IVaultQueueSearchBarParams;
  searchResult: IVaultViewQueueSearchResultTableData[];
  sortField?: string;
  sortOrderType?: SortOrderType;
  error?: IHttpErrorState;
  [key: string]: any;
}

export interface IVaultSearchBarParams {
  data: IVaultsSearchParams;
}

export interface IVaultsSearchParams {
  start?: Date;
  stop?: Date;
  searchKey?: QueryStringSearchKey;
  searchTerm?: string;
  [key: string]: any;
}

export type WorkflowType = "InkClose" | "MERS" | "eClose" | "eDisclose" | "eSign";

// Generic Types
export interface IActionGenericPayload {
  value?: any;
  [key: string]: any;
}

export interface ISearchBarHTMLElementsValues {
  apiParamValue: string;
  description: string;
}

export enum HTMLElementTypes {
  DROPDOWN,
  CALENDAR,
  INPUT,
  DROPDOWNCHECKLIST,
  DIVIDERBLOCK,
}
