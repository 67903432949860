import axios from "axios";

import { store } from "App/Store";

import { getErrorMessage } from "Common/Utilities";
import { errorEnqueued } from "Common/error";

import * as errorActions from "Features/Errors/Errors";
import { IMersOriginator, IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

/**
 * Save Mers recipients
 * @param data MERS recipient details
 * @returns a Promise for the request
 */
export function updateMERSRecipients(data: IMersRecipientDetails[]): Promise<() => void> {
  return axios.put("/api/mers/recipients", data).then((response) => response.data);
}

/**
 * Retrieve the list of user created MERS recipients
 * "address book"
 * @returns a Promise for the request
 */
export function getMERSRecipients(): Promise<IMersRecipientDetails[]> {
  return axios
    .get("/api/mers/recipients")
    .then((response) => response.data)
    .catch((err) => {
      store.dispatch(errorActions.enqueue(errorEnqueued(getErrorMessage(err, "Error loading MERS recipients."))));
    });
}

/**
 * delete single recipient by id
 * @param recipient The recipient to be deleted
 * @returns a Promise for the request
 */
export function deleteMERSRecipient(recipient: IMersRecipientDetails): Promise<() => any> {
  return axios
    .delete(`/api/mers/recipients/${recipient.orgId}`, {
      headers: { accept: "text" },
    })
    .then((response) => response.data);
}

/**
 * Get the current user's MERS organizations
 * @returns A promise for the request
 */
export function getCurrentUserMERSOrgs(): Promise<IMersOriginator[]> {
  return axios.get("/api/mers/originators").then((response) => response.data);
}
