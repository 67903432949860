import React, { ReactElement, useState } from "react";

import { ConnectedProps, connect } from "react-redux";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { makeStyles } from "@mui/styles";

import { RootState } from "App/Store";

import { VaultIcon } from "Components/Icons";

import { getProfile } from "Features/Profile/ProfileSlice";
import { getAuthorizedVaultMenuOptions } from "Features/Vault/navigationService";

import VaultCollapsedMenu from "./VaultCollapsedMenu";
import VaultExpandedMenu from "./VaultExpandedMenu";

interface IVaultMenuProps {
  isContainerExpanded: boolean;
}

const mapStateToProps = (state: RootState) => ({
  profile: getProfile(state),
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IVaultMenuProps;

const useStyles = makeStyles({
  iconStyle: {
    minWidth: 40,
  },
  list: {
    padding: 0,
  },
});

export function VaultMenu(props: Props): ReactElement {
  const { isContainerExpanded, profile } = props;
  const classes = useStyles();
  const [collapsedMenuOpen, setCollapsedMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const id = "left-nav-vault";
  const vaultNavigation = getAuthorizedVaultMenuOptions(profile);

  function handleMouseEnter(event: any) {
    setAnchorEl(event.currentTarget);
    if (!isContainerExpanded) {
      setCollapsedMenuOpen(true);
    }
  }

  function handleMenuClose() {
    setCollapsedMenuOpen(false);
  }

  return (
    <List className={classes.list}>
      <ListItem id={id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMenuClose} selected={!isContainerExpanded}>
        <ListItemIcon className={classes.iconStyle}>
          <VaultIcon />
        </ListItemIcon>
        <ListItemText primary="Vault" />
        {!isContainerExpanded && (
          <VaultCollapsedMenu
            anchorEl={anchorEl}
            open={collapsedMenuOpen}
            onClose={handleMenuClose}
            subItems={vaultNavigation}
          />
        )}
      </ListItem>
      {isContainerExpanded && <VaultExpandedMenu subItems={vaultNavigation} />}
    </List>
  );
}

export default connector(VaultMenu);
