import axios, { AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";

import { invalidateAntiforgery } from "Features/Profile/ProfileSlice";

axiosRetry(axios, {
  retries: 2,
  retryDelay: () => 1000,
});
let dispatch: any;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initializeDispatch(storeDispatch: any): void {
  dispatch = storeDispatch;
}

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // Add the request verification token from the hidden input form to prevent XSRF attacks
    if (config.headers) {
      config.headers.RequestVerificationToken = (
        document.querySelector("input[name=__RequestVerificationToken]") as HTMLInputElement
      ).value;
    }
    return config;
  },
  (error: any) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data && "Invalid AntiforgeryToken" === error.response.data.message) {
      console.error("Redirect: /Error/InvalidToken", error);
      console.dir(error);
      if (dispatch) {
        dispatch(invalidateAntiforgery());
      }
    } else if (error.response && (401 === error.response.status || 403 === error.response.status)) {
      if (error.response.config.url !== "/api/profile") {
        console.error("Redirect: /Error/NotSignedIn:", error);
        console.dir(error);
        window.location.replace("/app/signed-out");
      }
    }
    return Promise.reject(error);
  }
);
