import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { PackageAction, PackageTransferStatus } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import { profileSelector } from "Features/Profile/ProfileSlice";
import { addTab } from "Features/Tabs/Actions";
import {
  IMersTransferPackage,
  IVaultViewClosedLoansSearchResultData,
  IVaultViewTransferableClosedLoansSearchResultData,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";

import { canTransfer } from "../mersService";
import { resetLoadingState, setLoadingState } from "./MersTransferSlice";
import { updateTransferQueue } from "./mersTransferService";

export function openTransferTabForPackage(packageId: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsTransfer = canTransfer(profile.roles);
    if (rightsTransfer) {
      const closedLoans = getTransferableLoans(state.closedLoans.searchResult);
      const loan = _.find(closedLoans, { packageId });
      if (loan) {
        loan.selected = true;
      }

      dispatch(setLoadingState(false));
      dispatch(updateTransferQueue(closedLoans));
      dispatch(goToTransferTab());
    }
  };
}

export function openTransferTabAction(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsTransfer = canTransfer(profile.roles);
    if (rightsTransfer) {
      dispatch(resetLoadingState());
      dispatch(goToTransferTab());
    }
  };
}

export function getTransferableLoans(closedLoans: IVaultViewClosedLoansSearchResultData[]): IMersTransferPackage[] {
  const transferableLoans = _.filter(closedLoans, (loan: IVaultViewClosedLoansSearchResultData) => {
    return (
      loan.action === PackageAction.Transfer ||
      loan.mersData.mersTransferStatus === PackageTransferStatus.TransferFailed
    );
  });

  const loanData = transferableLoans.map((loan: IVaultViewClosedLoansSearchResultData) => {
    return {
      ...loan,
      effectiveDate: new Date(),
      id: loan.packageId,
      mersInitiators: loan.mersData.mersInitiators,
      selected: false,
    };
  });
  return _.sortBy(loanData, "loanNumber");
}

export function getTransferableLoansForTransferable(
  transferableLoans: IVaultViewTransferableClosedLoansSearchResultData[]
): IMersTransferPackage[] {
  const loanData = transferableLoans.map((loan: IVaultViewTransferableClosedLoansSearchResultData) => {
    return {
      ...loan,
      effectiveDate: new Date(),
      id: loan.packageId,
      mersInitiators: loan.mersInitiators,
      selected: false,
    };
  });
  return _.sortBy(loanData, "loanNumber");
}

function goToTransferTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-ClosedLoans-";
    const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.TransferMERS, {});
    const tabData = {
      closeable: true,
      key,
      loanType: constants.CLOSED_LOANS,
      title: "Transfer",
      type: VaultViewClosedLoansTabTypes.TransferMERS,
    };
    dispatch(addTab(tabData));
  };
}
