import React, { useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import EllipsesIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";

import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import dateTimeField from "Common/Fields/DateTimeField";
import { createTabKey } from "Common/Utilities";

import { DocumentIcon } from "Components/Icons";
import { BottomBar } from "Components/Notifications/BottomBar";
import ScrollContainer from "Components/ScrollContainer";

import { fetchIncomingDeliveries } from "Features/Vault/Incoming/Deliveries/IncomingDeliveriesSlice";

import { nonce } from "../../../../App/App";
import ErrorOverlay from "../../../../Components/DataGridCustomComponents/ErrorOverlay";
import GridCellTooltip from "../../../../Components/DataGridCustomComponents/GridCellTooltip";
import LoadingOverlay from "../../../../Components/DataGridCustomComponents/LoadingOverlay";
import ENoteElement from "../../ENoteElement";
import AuditLogMenuItem from "../../MenuItems/AuditLogMenuItem";
import MersIdentificationNumber from "../../Mers/MersIdentificationNumber";
import MersManualDeliveryConfirmationSelector from "../../Mers/MersManualDeliveryConfirmationSelector";
import TableRowContextMenu from "../../TableRowContextMenu";
import {
  DeliveryRecipientStatus,
  IVaultViewManualDeliveriesSearchResultData,
  IVaultViewManualDeliveriesSearchResultExtendedData,
  ManualDeliveryStatus,
  VaultViewIncomingTabTypes,
} from "../../VaultInterfaces";
import { canUserDownload } from "../../documentService";
import { openTabForDocList } from "../../documentService";
import { datagridColumns } from "../../shared";
import { DeliveryConfirmationModal } from "./DeliveryConfirmationModal";
import * as incomingDeliveriesService from "./incomingDeliveriesService";

const mapStateToProps = (state: RootState) => ({
  canDownload: canUserDownload(state),
  deliveries: incomingDeliveriesService.getIncomingDeliveries(state).searchResult,
  error: state.incomingDeliveries.error,
  incomingDeliveryProps: incomingDeliveriesService.getIncomingDeliveries(state),
  isLoading: state.incomingDeliveries.isLoading,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export interface IIncomingDeliveriesTabProps {
  onViewDocListCallback: (payload?: any) => void;
}

type Props = IIncomingDeliveriesTabProps & PropsFromRedux;

function IncomingDeliveriesTab(props: Props) {
  const keyRoot = "VaultTab-Incoming-";
  const dispatch = useDispatch() as AppDispatch;
  const { deliveries, error, isLoading: stateLoaded } = props;
  const [data, setData] = useState<IVaultViewManualDeliveriesSearchResultExtendedData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeliveryConfirmation, setShowDeliveryConfirmation] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchIncomingDeliveries());
    setIsLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (deliveries) {
      const mappedData: IVaultViewManualDeliveriesSearchResultExtendedData[] = deliveries.map((item) => {
        return {
          ...item,
          action: DeliveryRecipientStatus.None,
          initiatedSortable: dateTimeField.sortable(item.createDate),
        };
      });
      setData(mappedData);
    }
  }, [deliveries]);

  useEffect(() => {
    setIsLoading(stateLoaded);
  }, [stateLoaded]);

  async function showDocList(response: IVaultViewManualDeliveriesSearchResultData) {
    const data = {
      loanNumber: response.loanNumber,
      min: response.min,
      packageId: response.packageId,
      packageType: "Closing",
    };
    const key: string = createTabKey(keyRoot, VaultViewIncomingTabTypes.DocumentList, data);
    const payload = {
      key,
      loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
      packageType: "Closing",
      rowData: response,
      type: VaultViewIncomingTabTypes.DocumentList,
    };
    await dispatch(openTabForDocList(payload));
  }

  const incomingDeliveryColumns: GridColDef[] = [
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row}
          loanType={constants.INCOMING_DELIVERIES_TRANSFERS}
          tabKeyRoot={VaultViewIncomingTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
      sortable: true,
    },
    {
      ...datagridColumns.created,
      field: "createDate",
      headerName: "INITIATED",
      valueGetter: (params: GridValueGetterParams) => {
        return dateTimeField.renderForDisplay(params.row.createDate);
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "requestorOrgId",
      headerName: "REQUESTOR",
      renderCell: GridCellTooltip,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.requestorOrgId;
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "eNoteElement",
      headerName: "ENOTE",
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        return (
          <ENoteElement itemId={response.documentId} packageId={response.packageId} canDownload={props.canDownload} />
        );
      },
      sortable: false,
    },
    {
      editable: false,
      field: "status",
      flex: 1,
      headerName: "STATUS",
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        return response.manualDeliveryStatus === ManualDeliveryStatus.Acceptance
          ? "Incoming delivery"
          : "Pending approval";
      },
      sortable: false,
    },
    {
      ...datagridColumns.loanNumber,
      field: "confirmation",
      headerName: "CONFIRMATION",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        let actions: DeliveryRecipientStatus[];
        if (response.manualDeliveryStatus === ManualDeliveryStatus.Acceptance) {
          actions = [DeliveryRecipientStatus.None, DeliveryRecipientStatus.Accept, DeliveryRecipientStatus.Reject];
        } else {
          actions = [DeliveryRecipientStatus.None, DeliveryRecipientStatus.Approve, DeliveryRecipientStatus.Disapprove];
        }
        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersManualDeliveryConfirmationSelector
              parentDelivery={response.transferId}
              manualDeliveryStatus={response.manualDeliveryStatus}
              currentAction={response.action}
              actions={actions}
              onConfirmationStatusChanged={handleChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        const isManualDeliveryNotAccepted = response.manualDeliveryStatus !== ManualDeliveryStatus.Acceptance;

        return (
          <TableRowContextMenu
            actions={[
              {
                disabled: !isManualDeliveryNotAccepted,
                icon: <DocumentIcon />,
                onClick: () => {
                  showDocList(response);
                },
                title: "Docs",
              },
              <AuditLogMenuItem key="auditlog" data={response} disabled={!isManualDeliveryNotAccepted} />,
            ]}
          />
        );
      },
      renderHeader: function renderContextMenuHeader() {
        return <EllipsesIcon />;
      },
    },
  ];

  const handleChange = (
    action: DeliveryRecipientStatus,
    manualDeliveryStatus: ManualDeliveryStatus,
    delivery: string
  ) => {
    const updatedData = _.map(data, (resultDelivery: IVaultViewManualDeliveriesSearchResultExtendedData) => {
      if (resultDelivery.transferId !== delivery || resultDelivery.manualDeliveryStatus !== manualDeliveryStatus) {
        return resultDelivery;
      }
      const updatedDelivery = _.clone(resultDelivery);
      updatedDelivery.action = action;
      return updatedDelivery;
    });
    setData(updatedData);
  };

  function handleSubmitClick() {
    setShowDeliveryConfirmation(true);
  }

  function hideConfirmation() {
    setShowDeliveryConfirmation(false);
  }

  function passesValidation(): boolean {
    return _.some(data, function (item) {
      return item.action !== DeliveryRecipientStatus.None;
    });
  }

  return (
    <>
      <ScrollContainer>
        <DataGrid
          columnBuffer={0}
          columns={incomingDeliveryColumns}
          components={{
            ErrorOverlay: ErrorOverlay,
            LoadingOverlay: LoadingOverlay,
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          error={error}
          loading={isLoading}
          localeText={{
            noRowsLabel: "No results found",
          }}
          nonce={nonce}
          pagination
          rows={data}
          rowsPerPageOptions={[100]}
          rowCount={data.length}
          scrollbarSize={17}
          sortingOrder={["desc", "asc"]}
        />
      </ScrollContainer>

      <BottomBar
        contents="Choose how to confirm these incoming deliveries."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation(),
            onClick: handleSubmitClick,
            text: "SUBMIT",
          },
        ]}
      />
      <DeliveryConfirmationModal
        data={data}
        showDeliveryConfirmation={showDeliveryConfirmation}
        onHideConfirmation={hideConfirmation}
      />
    </>
  );
}

export default connector(IncomingDeliveriesTab);
