/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UndoIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M12,4C9.8,4,7.8,4.9,6.4,6.4L4,4v6h0.3h2.1H10L7.8,7.8C8.9,6.7,10.3,6,12,6c3.3,0,6,2.7,6,6s-2.7,6-6,6  c-1.3,0-2.5-0.4-3.5-1.1l-1.4,1.4C8.5,19.4,10.2,20,12,20c4.4,0,8-3.6,8-8C20,7.6,16.4,4,12,4z" />
    </SvgIcon>
  );
}
