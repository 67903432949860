import axios from "axios";
import { formatISO, parse, startOfDay } from "date-fns";

import {
  IMersConfirmationResponse,
  IMersManualTransferTransaction,
  IMersSecuredPartyTransaction,
  IMersTransactionResponse,
  IMersTransferTransaction,
  IVaultViewManualTransfersSearchResultData,
  MersConfirmationOption,
  MersConfirmationStatus,
} from "Features/Vault/VaultInterfaces";

/**
 * Get the Manual Transfer search results
 * @returns A promise for the request
 */
export function getManualTransfers(): Promise<IVaultViewManualTransfersSearchResultData[]> {
  return axios.get("/api/v1/mers/transfers/incoming").then((response) => response.data);
}

/**
 * Perform the action for the secured party
 * @param transaction Contains the min and the action
 * @returns the Promise for the request
 */
export function securedPartyAction(transaction: IMersSecuredPartyTransaction): Promise<any> {
  return axios
    .post(`/api/v1/mers/queue/${transaction.id}/secured-party/action`, transaction)
    .then((response) => response.data);
}

export function transferMERSPackage(data: IMersTransferTransaction): Promise<IMersTransactionResponse[]> {
  let isoDate = formatISO(new Date());

  if (data.effectiveDate != null) {
    let effectiveDate: Date;

    if (typeof data.effectiveDate === "string") {
      effectiveDate = parse(data.effectiveDate, "MM/dd/yyyy", new Date());
    } else {
      effectiveDate = data.effectiveDate as Date;
    }
    isoDate = formatISO(startOfDay(effectiveDate));
  }
  return axios
    .post(`/api/v1/packages/${data.packageId}/mers/transfers`, {
      effectiveDate: isoDate,
      newController: data.transferControlRecipient,
      newDelegatee: data.transferServicerRecipient,
      newDelegateeForTransfers: data.transferDelegateeForTransfersRecipient,
      newLocation: data.transferLocationRecipient,
      newSecuredParty: data.transferSecuredPartyRecipient,
      newSecuredPartyDelegatee: data.transferSecuredPartyDelegateeRecipient,
      newSubServicer: data.transferSubServicerRecipient,
      skipDeliveryFor: data.skipDeliveryFor,
      transferType: data.transferType,
    })
    .then((response) => response.data);
}

export function manualConfirmationMERSTransfer(
  data: IMersManualTransferTransaction
): Promise<IMersConfirmationResponse[]> {
  let status: string;
  switch (data.confirmationStatus) {
    case MersConfirmationStatus.Accepted:
      status = MersConfirmationOption.Accepted;
      break;
    case MersConfirmationStatus.Pending:
      status = MersConfirmationOption.Pending;
      break;
    case MersConfirmationStatus.Rejected:
      status = MersConfirmationOption.Rejected;
      break;
    default:
      status = MersConfirmationOption.Other;
      break;
  }
  const payload = {
    status,
  };
  return axios
    .post(`/api/v1/packages/${data.packageId}/mers/transfers/incoming/${data.transferId}`, payload)
    .then((response) => response.data);
}

export function cancelPendingTransferMERSPackage(packageId: string): Promise<IMersTransactionResponse[]> {
  return axios
    .post(`/api/v1/packages/${packageId}/mers/transfers/cancel`, null, {
      headers: { Accept: "text" },
    })
    .then((response) => response.data);
}
