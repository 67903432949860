import React, { useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";
import ScrollContainer from "Components/ScrollContainer";

import * as actions from "Features/Tabs/Actions";
import * as tabActions from "Features/Tabs/Tabs";
import { ILoanTabDetails } from "Features/Tabs/Tabs";

import TabStore from "../TabStore";
import { VaultViewIncomingTabTypes } from "../VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  tabs: state.tabs[constants.INCOMING_DELIVERIES_TRANSFERS],
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

const keyRoot = "VaultTab-Incoming-";

const DeliveriesTab = {
  key: createTabKey(keyRoot, VaultViewIncomingTabTypes.IncomingDeliveries),
  loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
  title: "Deliveries (In)",
  type: VaultViewIncomingTabTypes.IncomingDeliveries,
};
const TransfersTab = {
  key: createTabKey(keyRoot, VaultViewIncomingTabTypes.IncomingTransfers),
  loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
  title: "Transfers (In)",
  type: VaultViewIncomingTabTypes.IncomingTransfers,
};

function VaultViewIncomingDeliveries(props: TypesFromRedux) {
  const { dispatch, tabs } = props;

  // Add Search Results as first tab on original mount and make initial query
  useEffect(() => {
    const initialMount = !tabs.byId[DeliveriesTab.key] || !tabs.byId[TransfersTab.key];
    if (initialMount) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(TransfersTab));
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(DeliveriesTab));
    }
  }, [dispatch, tabs]);

  /**
   * Click handler for switching tabs
   * @param tab the tab being clicked
   * @returns {void}
   */
  function handleTabClick(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.goToTab({
        key: tab.key,
        loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
      })
    );
  }

  /**
   * Callback event for when a tab is closed
   * @param tab the tab to close
   * @returns {void}
   */
  function handleTabClosed(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.removeTab({
        key: tab.key,
        loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
      })
    );
  }

  return (
    <ScrollContainer>
      <TabPanel
        currentTab={tabs.currentTab}
        onTabClick={handleTabClick}
        onTabClosed={handleTabClosed}
        tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
      >
        <TabStore tab={tabs.byId[tabs.currentTab]} />
      </TabPanel>
    </ScrollContainer>
  );
}

export default connector(VaultViewIncomingDeliveries);
