/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";

import Box from "@mui/material/Box";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";

import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import {
  IMersOriginator,
  IMersRecipientDetails,
  IMersRegisterTransaction,
  MersRecipientStatusType,
  RecipientAccessors,
} from "../VaultInterfaces";

interface IMersRecipientSelectorProps {
  mersRecipients: IMersRecipientDetails[];
  currentRecipient?: IMersRecipientDetails;
  type?: RecipientAccessors;
  labelText?: string;
  id?: string;
  isDisabled?: boolean;
  originatorOrgId?: string;
  parentLoan?: IMersRegisterTransaction;
  placeholderText?: string;
  hidePlaceholder?: boolean;
  requireRecipients?: boolean; // if no recipients set red border
  onMersRecipientIdSelectionChanged: (recipient: IMersRecipientDetails, loan?: any, type?: RecipientAccessors) => void;
}

const mapStateToProps = (state: RootState) => ({
  mersOriginators: state.mersOriginators.originators,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export type MersRecipientSelectorProps = IMersRecipientSelectorProps & TypesFromRedux;

export function MersRecipientSelector(props: MersRecipientSelectorProps): ReactElement {
  function handleChangeClick(event: any) {
    if (props.originatorOrgId) {
      props.onMersRecipientIdSelectionChanged(
        orderedRecipients[parseInt(event.target.value)],
        props.originatorOrgId,
        props.type
      );
    } else if (props.parentLoan || props.type !== undefined) {
      props.onMersRecipientIdSelectionChanged(
        orderedRecipients[parseInt(event.target.value)],
        props.parentLoan,
        props.type
      );
    } else {
      props.onMersRecipientIdSelectionChanged(props.mersRecipients[parseInt(event.target.value)]);
    }
  }
  let originator: IMersOriginator | undefined;
  if (props.parentLoan) {
    originator = props.mersOriginators.find((originator) => originator.mersOrgId === props.parentLoan?.originatorOrgId);
  } else if (props.originatorOrgId) {
    originator = props.mersOriginators.find((originator) => originator.mersOrgId === props.originatorOrgId);
  }
  const filtered = props.mersRecipients.filter(
    (recipient) => originator && recipient.mersOriginatorId === originator.id
  );

  const orderedRecipients: IMersRecipientDetails[] = _.sortBy(filtered, [
    (recipient: IMersRecipientDetails) => MersRecipientStatusType.Default !== recipient.status,
  ]);

  const placeholder = (
    <TableMenuItem key="none" value={EMPTY_MERS_RECIPIENT_DETAILS.id}>
      {props.placeholderText || "none"}
    </TableMenuItem>
  );

  const recipientSelectStylings: string[] = ["width-full"];
  let recipientOptions: JSX.Element[] = [];

  if (!props.hidePlaceholder) {
    if (orderedRecipients[0] && orderedRecipients[0].id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
      orderedRecipients.unshift(EMPTY_MERS_RECIPIENT_DETAILS);
    }
  }

  if (0 !== orderedRecipients.length) {
    recipientOptions = orderedRecipients.map((mersRecipient: IMersRecipientDetails, iterator: number) => {
      const defaultIndicator = MersRecipientStatusType.Default !== mersRecipient.status ? null : "★ ";
      const hasName = mersRecipient.name.length;
      return (
        <TableMenuItem key={mersRecipient.id} value={iterator}>
          {defaultIndicator}
          {hasName ? `${mersRecipient.name} (${mersRecipient.orgId})` : mersRecipient.orgId}
        </TableMenuItem>
      );
    });
  } else {
    if (props.requireRecipients) {
      recipientSelectStylings.push("indicator-border-thick");
      recipientSelectStylings.push("color-text-mid");
    }
    recipientOptions.push(placeholder);
  }
  let currentIndex = EMPTY_MERS_RECIPIENT_DETAILS.id;

  _.each(orderedRecipients, (recipient: IMersRecipientDetails, iteratee: number) => {
    if (props.currentRecipient && recipient.id === props.currentRecipient.id) {
      currentIndex = iteratee;
    }
  });

  const title = (
    <div className="vertical-align-baseline">
      <span className="wrap-none padding-right-large font-xsmall">{props.labelText}</span>
    </div>
  );

  return (
    <Box sx={{ lineHeight: "normal", width: "100%" }}>
      {props.labelText && title}
      <div>
        <TableSelect
          className={recipientSelectStylings.join(" ")}
          displayEmpty={true}
          value={currentIndex.toString()}
          disabled={props.isDisabled}
          id={props.id}
          onChange={handleChangeClick}
          size="small"
          variant="standard"
        >
          {recipientOptions}
        </TableSelect>
      </div>
    </Box>
  );
}

export default connector(MersRecipientSelector);
