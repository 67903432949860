import * as React from "react";

import { Box, Button, Link, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled("div")(() => ({
  bottom: "30px",
  left: "50%",
  maxWidth: "1200px",
  position: "absolute",
  transform: "translateX(-50%)",
  width: "100%",
  zIndex: "99999999999999999",
}));

const ResponsiveBox = styled("div")(({ theme }: any) => ({
  display: "flex",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export default function CookieConsentAlert() {
  const [show, setShow] = React.useState(false);
  const COOKIE_CONSENT_KEY = "evault-cpp";

  React.useEffect(() => {
    const cookiePrivacyPolicy = window.localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!cookiePrivacyPolicy) {
      setShow(true);
    } else {
      const cookieDate = new Date(cookiePrivacyPolicy);
      const todayDate = new Date();
      if (cookieDate.getTime() < todayDate.getTime()) {
        setShow(true);
      }
    }
  }, [COOKIE_CONSENT_KEY]);
  const onAccept = () => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    window.localStorage.setItem(COOKIE_CONSENT_KEY, date.toISOString());
    setShow(false);
  };
  if (!show) {
    return null;
  }
  return (
    <Container>
      <Paper elevation={8}>
        <ResponsiveBox>
          <Box sx={{ display: "flex", flex: "3", flexDirection: "column", padding: "15px" }}>
            <Typography variant="h6">Cookie Privacy Policy</Typography>
            <Typography variant="body1">
              This website uses cookies and similar technologies to manage your sessions, manage content, and improve
              your website experience. To learn more about these technologies, your options, and about other categories
              of personal information we collect through this website and how we may use it, please see our{" "}
              <Link href="https://www.firstam.com/privacy-policy/#type-of-information">
                <strong>privacy policy</strong>
              </Link>
              . <strong>This is effective for your use of this website for the next 30 days.</strong>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flex: "1", flexDirection: "column", justifyContent: "center", padding: "15px" }}>
            <Button onClick={() => onAccept()} variant="contained" color="primary">
              I Understand
            </Button>
          </Box>
        </ResponsiveBox>
      </Paper>
    </Container>
  );
}
