import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "../../../Common/EVaultAppConstants";
import { createTabKey } from "../../../Common/Utilities";
import { addTab } from "../../Tabs/Actions";
import { VaultViewMinReconciliationTabTypes } from "../VaultInterfaces";
import { IMinReconciliationReport } from "./MinReconciliationReportInterfaces";
import {
  IMinReconciliationReportsSearchApiParams,
  fetchMinReconciliationReports,
  updateSearchBarParams,
} from "./MinReconciliationSlice";
import { getMinReconciliationReportsUrl } from "./minReconciliationAdapter";

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const data = state.minReconciliation.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    await dispatch(fetchMinReconciliationReportsAction());
  };
}

export function fetchMinReconciliationReportsAction(
  resourceUrl?: string
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    if (resourceUrl) {
      dispatch(fetchMinReconciliationReports(resourceUrl));
    } else {
      const searchCriteria = state.minReconciliation.searchBarParams.data;
      const sortByParams = state.minReconciliation.sortByParams;
      const params: IMinReconciliationReportsSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        ...sortByParams,
      };

      const url = getMinReconciliationReportsUrl(params);
      dispatch(fetchMinReconciliationReports(url));
    }
  };
}

/**
 * Dispatches the open tab call to open reconciliation report status details
 */
export function goToMinReconciliationReportDetailsTab(
  report: IMinReconciliationReport
): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-MinReconciliationReportOperations-";
    const key: string = createTabKey(keyRoot, VaultViewMinReconciliationTabTypes.MinReconciliationStatusDetails, {
      reportId: report.id,
    });
    const tabData = {
      closeable: true,
      key,
      loanType: constants.MIN_RECONCILIATION,
      report: report,
      reportId: report.id,
      title: "Report Details",
      type: VaultViewMinReconciliationTabTypes.MinReconciliationStatusDetails,
    };
    return dispatch(addTab(tabData));
  };
}

/**
 * Dispatches the open tab call to open reconciliation report status details
 */
export function goToMinReconciliationClientMersOrgsTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-MinReconciliationReportOperations-";
    const key: string = createTabKey(keyRoot, VaultViewMinReconciliationTabTypes.MinReconciliationClientMersOrgs);
    const tabData = {
      closeable: true,
      key,
      loanType: constants.MIN_RECONCILIATION,
      title: "MERS Orgs",
      type: VaultViewMinReconciliationTabTypes.MinReconciliationClientMersOrgs,
    };
    return dispatch(addTab(tabData));
  };
}
