import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { apiError } from "Common/error";

import { IHttpErrorState, IResultPages, ISortByParams } from "Types/EVaultAppTypes";
import * as types from "Types/EVaultAppTypes";

import { getMersPackagesByUrl } from "Adapters/Mers/mersPackageAdapter";

import { IVaultClosedSearchBarParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import {
  IMersOriginator,
  IMersRecipientDetails,
  IMersTransferPackage,
  IMersUpdatePackage,
  MersChangeStatusTypes,
  MersModificationTypes,
  MersNoteUpdateFunctions,
} from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../../Constants";
import { getUpdatableLoans } from "./mersUpdateTabService";

const initialUpdates: IMersUpdatePackage[] = [];

export interface updateSearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortByParams?: ISortByParams;
  start: Date;
  stop: Date;
}

export interface IMersUpdateSearchApiParams extends updateSearchParams {
  limit: number;
  offset: number;
  sortByParams: ISortByParams;
}

const initialUpdateApiCallParams: updateSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialUpdateSearchBarParams: IVaultClosedSearchBarParams = {
  data: initialUpdateApiCallParams,
};

export interface IMersUpdateSliceState {
  error?: IHttpErrorState;
  initialized: boolean;
  isLoading: boolean;
  links?: IResultPages;
  resourceUrl?: string;
  searchBarParams: IVaultClosedSearchBarParams;
  sortByParams: ISortByParams;
  updateSet: MersNoteUpdateFunctions;
  updates: IMersUpdatePackage[];
}

const initialState: IMersUpdateSliceState = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialUpdateSearchBarParams,
  sortByParams: initialSortByParams(),
  updateSet: MersNoteUpdateFunctions.ChangeStatus,
  updates: initialUpdates,
};

export interface IMersUpdateFetch {
  url: string;
  mersOrgs: IMersOriginator[];
  state: RootState;
}

export enum UpdateRecipientAccessors {
  rightsholderRecipient = "rightsholderRecipient",
}

export const fetchPackages = createAsyncThunk<
  IMersPackageResponse,
  IMersUpdateFetch,
  {
    rejectValue: IHttpErrorState;
  }
>("mersUpdates/fetch", async (payload: IMersUpdateFetch, thunkApi) => {
  try {
    const response = await getMersPackagesByUrl(payload.url);
    const updatableLoans = getUpdatableLoans(response.results);
    const results = updatableLoans.map((loan) => ({
      ...loan,
      selected: false,
    }));
    return {
      ...response,
      results,
    };
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

interface IMersUpdateRecipientUpdate {
  packageId: string;
  recipient: IMersRecipientDetails;
  type: UpdateRecipientAccessors;
}

interface IMersUpdateGroup {
  group: MersNoteUpdateFunctions;
  packages: IMersUpdatePackage[];
}

type updateTypes = "changeStatus";

interface IMersUpdateChangeType {
  packageId: string;
  property: updateTypes;
  value: keyof MersChangeStatusTypes;
}

export interface IMersPackageResponse {
  links: types.IResultPages;
  results: IMersUpdatePackage[];
}

export const mersUpdateSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.updates = [];
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.updates = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState,
  name: "mersUpdates",
  reducers: {
    addMersUpdate: (state, action: PayloadAction<IMersUpdatePackage>) => {
      const transfer = action.payload;
      setCalculatedStates(transfer, state.updateSet);
      state.updates.push(transfer);
    },
    removeMersUpdate: (state, action: PayloadAction<IMersTransferPackage>) => {
      state.updates = _.filter(state.updates, (transfer) => transfer.packageId !== action.payload.packageId);
    },
    resetMersUpdates: (state) => {
      state.updates = [];
    },
    resetSearch: (state) => {
      state.searchBarParams = initialState.searchBarParams;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      if (!action.payload) {
        state.initialized = true;
      }
    },
    updateChangeType: (state, action: PayloadAction<IMersUpdateChangeType>) => {
      const transfer = state.updates.find((transfer) => transfer.packageId === action.payload.packageId);
      if (transfer) {
        transfer[action.payload.property] = action.payload.value;
        setCalculatedStates(transfer, state.updateSet);
      }
    },
    updateGroup: (state, action: PayloadAction<IMersUpdateGroup>) => {
      const updateSet = action.payload.group;
      state.updateSet = updateSet;
      state.updates = action.payload.packages.map((transfer) => {
        setCalculatedStates(transfer, updateSet);
        return transfer;
      });
    },
    updateMersUpdates: (state, action: PayloadAction<IMersUpdatePackage[]>) => {
      state.updates = action.payload.map((transfer) => {
        setCalculatedStates(transfer, state.updateSet);
        return transfer;
      });
    },
    updateRecipient: (state, action: PayloadAction<IMersUpdateRecipientUpdate>) => {
      const transfer = state.updates.find((transfer) => transfer.packageId === action.payload.packageId);
      if (transfer) {
        transfer[action.payload.type] = action.payload.recipient || EMPTY_MERS_RECIPIENT_DETAILS;
        setCalculatedStates(transfer, state.updateSet);
      }
    },
    updateSearchBarParams: (state, action: PayloadAction<updateSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

function setCalculatedStates(loan: IMersUpdatePackage, updateSet: MersNoteUpdateFunctions) {
  if (!loan.selected) {
    loan.isInvalidState = false;
    return;
  }
  switch (updateSet) {
    case MersNoteUpdateFunctions.ChangeStatus:
      if (loan.changeStatus === "" || loan.changeStatus == null) {
        loan.isInvalidState = true;
      } else {
        loan.isInvalidState = false;
      }
      break;
    case MersNoteUpdateFunctions.Modification:
      if (loan.changeData == null || loan.changeData === MersModificationTypes.None.toString()) {
        loan.isInvalidState = true;
      } else {
        loan.isInvalidState = false;
      }
      break;
    case MersNoteUpdateFunctions.UpdateRightsholder:
      if (
        loan.rightsholder === "" ||
        loan.rightsholder == null ||
        loan.rightsholderRecipient == null ||
        loan.rightsholderRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
      ) {
        loan.isInvalidState = true;
      } else {
        loan.isInvalidState = false;
      }
      break;
    default:
      loan.isInvalidState = false;
      break;
  }
}

export const {
  resetMersUpdates,
  resetSearch,
  setLoadingState,
  updateChangeType,
  updateGroup,
  updateMersUpdates,
  updateRecipient,
  updateSearchBarParams,
  updateSortBy,
} = mersUpdateSlice.actions;

export default mersUpdateSlice.reducer;
