import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

import createRootReducer from "./rootReducer";

/**
 * Redux Store Configuration
 */
const initialState = {};

const inDevelopment = process.env.NODE_ENV === "development";

export type RootState = ReturnType<typeof store.getState>;

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    inDevelopment ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
  preloadedState: initialState,
  reducer: createRootReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
