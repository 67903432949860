/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import AlertIcon from "@mui/icons-material/Warning";

import { StatusRibbonValues } from "Features/Vault/VaultInterfaces";

import { UnknownIcon, ValidIcon } from "../Icons";

interface IStatusRibbonProps {
  status: StatusRibbonValues;
  isLoading: boolean;
  validText?: string;
  invalidText?: string;
  errorText?: string;
  className?: string;
}

export default function StatusRibbon(props: IStatusRibbonProps): ReactElement {
  let title = "";
  let icon: JSX.Element = <span />;
  let color;
  if (props.status === StatusRibbonValues.valid) {
    color = "#73942C";
  } else if (props.status === StatusRibbonValues.invalid) {
    color = "#CB2B27";
  } else {
    color = "#E5922F";
  }

  const ribbonStyles = css`
    align-items: center;
    color: ${color};
    display: flex;
    font-family: "Roboto Italic", "Roboto", "Arial";
    font-size: 15px;
    margin-top: 16px;
    padding-left: 8px;

    > span {
      margin-left: 8px;
    }
  `;

  if (!props.isLoading) {
    switch (props.status) {
      case StatusRibbonValues.invalid:
        icon = <AlertIcon />;
        title = props.invalidText || "Invalid";
        break;
      case StatusRibbonValues.valid:
        icon = <ValidIcon />;
        title = props.validText || "Valid";
        break;
      default:
        icon = <UnknownIcon />;
        title = props.errorText || "Error...";
    }
  }

  return (
    <div css={ribbonStyles}>
      {icon}
      <span>{title}</span>
    </div>
  );
}
