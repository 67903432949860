/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface IBottomBarProps {
  buttons: {
    closable: boolean;
    disabled: boolean;
    text: string;
    onClick: () => void;
    onOptionClick?: (option: ISplitButtonOption) => void;
    options?: ISplitButtonOption[];
  }[];
  contents: JSX.Element | string;
  onClose?: () => void;
}

export interface ISplitButtonOption {
  value: string;
  display: string;
}

export const BottomBar = (props: IBottomBarProps): ReactElement => {
  const [buttonGroupMenuAnchorEl, setButtonGroupMenuAnchorEl] = useState<null | HTMLElement>(null);

  // Button Group Menu
  const handleButtonMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setButtonGroupMenuAnchorEl(event.currentTarget);
  };
  const handleButtonMenuClose = () => {
    setButtonGroupMenuAnchorEl(null);
  };

  const buttons: JSX.Element[] = [];
  _.each(props.buttons, (button) => {
    if (button.options != null && button.options.length > 0) {
      buttons.push(
        <React.Fragment>
          <ButtonGroup disabled={button.disabled}>
            <Button color="secondary" variant="contained" size="large" onClick={button.onClick}>
              {button.text}
            </Button>
            <Button
              css={css`
                margin-left: 0px;
                display: flex;
              `}
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleButtonMenuClick}
            >
              <MoreVertIcon />
            </Button>
          </ButtonGroup>
          <Menu
            anchorEl={buttonGroupMenuAnchorEl}
            anchorOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
            keepMounted
            open={Boolean(buttonGroupMenuAnchorEl)}
            onClose={handleButtonMenuClose}
          >
            <div>
              {button.options.map((option) => (
                <MenuItem
                  key={option.value}
                  onClick={() => {
                    button.onOptionClick?.(option);
                    handleButtonMenuClose();
                  }}
                >
                  {option.display}
                </MenuItem>
              ))}
            </div>
          </Menu>
        </React.Fragment>
      );
    } else {
      buttons.push(
        <Button
          color="secondary"
          variant="contained"
          size="large"
          disabled={button.disabled}
          key={_.uniqueId("notification-bottombar-")}
          onClick={button.onClick}
        >
          {button.text}
        </Button>
      );
    }
  });

  return (
    <Grid
      css={css`
        background-color: rgba(20, 20, 20, 0.93);
        bottom: 0;
        color: #fff;
        left: 0;
        padding-left: 36px;
        padding-right: 36px;
        right: 0;
        text-align: left;
        z-index: 1100;
      `}
    >
      <Grid
        css={css`
          padding: 10px 0px;
        `}
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>{props.contents}</Grid>
        <Grid>{buttons}</Grid>
      </Grid>
    </Grid>
  );
};
