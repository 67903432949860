/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ExternalLinkIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M18 18H6V6h6V4H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-6h-2v6zM14 4v2h2.6l-6 6 1.4 1.4 6-6V10h2V4h-6z" />
    </SvgIcon>
  );
}
