import { differenceInMilliseconds } from "date-fns";

let fallbackTimeout: string | undefined;

export function getTimeout(): number | null {
  const cookieName = "eVault.ActiveSession";
  const period: string | undefined = readCookieByName(cookieName);
  if (period) {
    fallbackTimeout = period;
    const time = new Date(decodeURIComponent(period));
    return differenceInMilliseconds(time, new Date());
  } else if (fallbackTimeout) {
    // Used when the front end check happens but the cookie is gone
    const time = new Date(decodeURIComponent(fallbackTimeout));
    return differenceInMilliseconds(time, new Date());
  }
  return null;
}

export function isExpired(): boolean {
  const timeout = getTimeout();
  return !!(timeout && timeout < 0);
}

/**
 * Returns the value of the specified cookie or undefined
 * @param name The name of the cookie
 * @returns The cookie value by name
 */
function readCookieByName(name: string): string | undefined {
  const cookie = "; " + document.cookie;
  const parts = cookie.split("; " + name + "=");

  let value: string | undefined;
  if (parts.length === 2) {
    const last = parts.pop();
    if (last) {
      value = last.split(";").shift();
    }
  }

  return value;
}
