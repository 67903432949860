/**
 * The DocumentListContextMenu component is responsible for handling all menu options for all of the items in the
 * document list. This includes print/download functionality.
 */
import React, { ReactElement, useState } from "react";

import { ConnectedProps, connect } from "react-redux";

import EllipsesIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import { GridSelectionModel } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import { IVaultLoanSearchResult, IVaultViewAllLoansDocumentDetailData } from "../VaultInterfaces";
import * as documentService from "../documentService";
import DocumentListDownloadMenu from "./DocumentListDownloadMenu";
import DocumentListPrintMenu from "./DocumentListPrintMenu";

const mapStateToProps = (state: RootState) => ({
  canDownload: documentService.canUserDownload(state),
});

interface IListContextMenuProps {
  auditLogData: IVaultLoanSearchResult;
  checkedRows: GridSelectionModel;
  documents: IVaultViewAllLoansDocumentDetailData[];
  onPrint(options: DocumentOptions): void;
  onDownload(options: DocumentOptions): void;
  workflowType?: string;
}

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IListContextMenuProps & TypesFromRedux;

export enum DocumentOptions {
  All,
  Selected,
  InkSign,
  eSign,
}

export function DocumentListContextMenu(props: Props): ReactElement {
  const { auditLogData, canDownload, checkedRows, documents, onDownload, onPrint, workflowType } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  function handleMenuOpen(event: any): any {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handlePrintOption(option: DocumentOptions) {
    setAnchorEl(null);
    onPrint(option);
  }

  function handleDownloadOption(option: DocumentOptions) {
    setAnchorEl(null);
    onDownload(option);
  }

  const auditLogDisabled = workflowType === "MERS";
  return (
    <>
      <IconButton size="small" onClick={handleMenuOpen}>
        <EllipsesIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <DocumentListPrintMenu
          canDownload={canDownload}
          checkedRows={checkedRows}
          documentListData={documents}
          onPrint={handlePrintOption}
          workflowType={workflowType}
        />
        <DocumentListDownloadMenu
          canDownload={canDownload}
          checkedRows={checkedRows}
          documentListData={documents}
          onDownload={handleDownloadOption}
          workflowType={workflowType}
        />
        {canDownload && documents.length && (
          <span>
            <Divider light sx={{ my: 1 }} />
            <AuditLogMenuItem data={auditLogData} disabled={auditLogDisabled} dense />
          </span>
        )}
      </Menu>
    </>
  );
}

export default connector(DocumentListContextMenu);
