/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, SyntheticEvent } from "react";

import { css } from "@emotion/react";

import MuiButton from "@mui/material/Button";

import { IVaultSearchBarParams } from "Types/EVaultAppTypes";

import CustomHTMLElement from "./CustomHTMLElement";

interface ISearchProps {
  displayResetButton: boolean;
  onCriteriaChange: (criteria: any) => void;
  onReset: () => void;
  onSubmit: () => void;
  searchCriterias: any[];
  searchParams: IVaultSearchBarParams;
}

export function FilterBar(props: ISearchProps): ReactElement {
  const { displayResetButton, onCriteriaChange, onReset, onSubmit, searchParams } = props;

  /**
   * Event - Occurs when the search criteria changes
   * @param event The input event change event used to trigger criteria change
   * @returns {void}
   */
  function handleCriteriaChange(event: any): void {
    const target = event.target;
    let value;
    if (event.date) {
      value = event.date;
    } else {
      value = target.type === "checkbox" ? target.checked : target.value;
    }
    const name = target.name || "";
    const params = {
      ...searchParams.data,
      [name]: value,
    };
    onCriteriaChange(params);
  }

  /**
   * Event - Occurs when the search form submits (Search button is clicked)
   * @param ev The form submit event
   * @returns {void}
   */
  function onFormSubmit(ev: SyntheticEvent) {
    ev.preventDefault();
    onSubmit();
  }

  /**
   * Render the SearchBar component
   * @returns The rendered SearchBar component
   */
  if (!props.searchParams) {
    return <div>Loading...</div>;
  }

  // Creates Search Bar form based on search criteria
  const htmlElements = props.searchCriterias.map((criteria, sectionIndex) => {
    const { ...selectedParams } = searchParams.data;
    return (
      <CustomHTMLElement
        key={`${criteria.name}-${sectionIndex}`}
        label={criteria.label}
        options={criteria.options}
        name={criteria.name}
        index={sectionIndex}
        htmlType={criteria.type}
        searchParams={selectedParams}
        eventCallback={handleCriteriaChange}
      />
    );
  });

  const resetButton: JSX.Element = (
    <MuiButton
      className="search-criteria-reset"
      css={css`
        margin-top: 8px;
        margin-left: 20px;
      `}
      onClick={onReset}
    >
      reset filters
    </MuiButton>
  );

  const dropdownBody: JSX.Element = (
    <form autoComplete="off" onSubmit={(event: SyntheticEvent) => onFormSubmit(event)}>
      <div
        css={css`
          align-items: center;
          display: flex;
          margin-left: 8px;
        `}
      >
        {htmlElements}
        <MuiButton
          color="secondary"
          css={css`
            margin-top: 8px;
            margin-left: 8px;
          `}
          className="submit-search"
          type="submit"
          variant="contained"
        >
          SEARCH
        </MuiButton>
        {displayResetButton ? resetButton : ""}
      </div>
    </form>
  );

  return (
    <div
      css={css`
        padding: 8px;
      `}
    >
      {dropdownBody}
    </div>
  );
}

export default FilterBar;
