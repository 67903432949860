export enum PackageAction {
  Unknown,
  Register,
  Transfer,
}

export enum PackageRegistrationStatus {
  Unknown,
  Registered,
  RegistrationFailed,
  RegistrationPending,
  Unregistered,
}

export enum PackageDeliveryStatus {
  Unknown,
  Delivered,
  DeliveryFailed,
  DeliveryPending,
  NotDelivered,
}

export enum PackageTransferStatus {
  Unknown,
  NotTransferred,
  TransferFailed,
  TransferPending,
  Transferred,
}

export enum SortOrderType {
  ASCENDING = "Ascending",
  DESCENDING = "Descending",
}
