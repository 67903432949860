import React, { ReactElement } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import {
  initialAllLoansSearchBarParams,
  resetSearch,
  selectSearchBarParams,
  updateSearchBarParams,
} from "./AllLoansSlice";
import { executeSearch } from "./allLoansService";

const mapStateToProps = (state: RootState) => ({
  searchBarParams: selectSearchBarParams(state),
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function AllLoansFilterBar(props: TypesFromRedux): ReactElement {
  const { searchBarParams, dispatch } = props;

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialAllLoansSearchBarParams, searchBarParams);

  return (
    <FilterBar
      displayResetButton={displayResetButton}
      onCriteriaChange={handleCriteriaChange}
      onReset={handleReset}
      onSubmit={handleSubmit}
      searchCriterias={constants.SEARCH_BAR_CRITERIA.all}
      searchParams={searchBarParams}
    />
  );
}

export default connector(AllLoansFilterBar);
