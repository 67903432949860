import { SortOrderType } from "Common/Enums";

import { ISortByParams } from "Types/EVaultAppTypes";

export function initialSortByParams(sortField = "created"): ISortByParams {
  return {
    sortField: sortField,
    sortOrderType: SortOrderType.DESCENDING,
  };
}

export enum QueryStringSearchKey {
  ClientDefinedDataField = "clientDefinedDataField",
  ReconciliationReportId = "reportId",
  BatchId = "batchId",
  LoanNumber = "loanNumber",
  MIN = "mortgageIdentificationNumber",
  Empty = "",
}
