import _ from "lodash";

import { IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

const VALIDATIONERROR_MERSORG = "Recipient's MERS Org ID must contain 7-digits.";
const VALIDATIONERROR_MERSORG_UNIQUE =
  "Ensure that each recipient has a unique, 7-digit MERS Org ID, and then try again.";
const VALIDATIONERROR_NAME_REQUIRED = "Recipient's MERS Org Name is required.";
const VALIDATIONERROR_CONTACTEMAIL_INVALID = "Recipient's Contact Email is not valid.";

/**
 * Validates the MersOrgDetails
 * @param mersOrgDetails MersOrgDetails data to validate
 * @returns void
 */
export function validateMersOrg(mersOrgDetails: IMersRecipientDetails[]): string[] {
  const validationMessages: string[] = [];

  for (let i = 0; i < mersOrgDetails.length - 1; ++i) {
    // Id must be 7-digits
    if (!isMersOrgIdValid(mersOrgDetails[i].orgId)) {
      validationMessages[validationMessages.length] = VALIDATIONERROR_MERSORG;
    }

    // Name is required
    if (!isMersOrgNameValid(mersOrgDetails[i].name)) {
      validationMessages[validationMessages.length] = VALIDATIONERROR_NAME_REQUIRED;
    }

    // Contact Email is not valid
    if (!isMersOrgContactEmailValid(mersOrgDetails[i].contactEmail)) {
      validationMessages[validationMessages.length] = VALIDATIONERROR_CONTACTEMAIL_INVALID;
    }
  }

  // Check for unique OrgId
  mersOrgDetails = _.filter(mersOrgDetails, "orgId");
  const uniqueResults = _.uniqWith(mersOrgDetails, (org1, org2) => {
    return org1.orgId === org2.orgId && org1.mersOriginatorId === org2.mersOriginatorId;
  });
  if (mersOrgDetails.length !== uniqueResults.length) {
    validationMessages[validationMessages.length] = VALIDATIONERROR_MERSORG_UNIQUE;
  }

  return validationMessages;
}

/**
 * Validates the MersOrgDetails's Id
 * @param value MersOrgDetail's Id to validate
 * @returns Flag to indicate if field is valid
 */
export function isMersOrgIdValid(value: string): boolean {
  return 7 === value.length && !isNaN(parseInt(value, 10));
}

/**
 * Validates the MersOrgDetails's Name
 * @param value MersOrgDetail's Name to validate
 * @returns Flag to indicate if field is valid
 */
export function isMersOrgNameValid(value: string): boolean {
  return value.length > 0;
}

/**
 * Validates the MersOrgDetails's Contact Email
 * @param value MersOrgDetail's Contact Email to validate
 * @returns Flag to indicate if field is valid
 */
export function isMersOrgContactEmailValid(value: string): boolean {
  if (value.length === 0) {
    return true;
  }
  // eslint-disable-next-line no-useless-escape
  const regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  return regEmail.test(value);
}
