import React, { ReactElement } from "react";

import MuiMenuItem from "@mui/material/MenuItem";
import { MenuItemProps } from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    fontSize: ".8rem",
  },
});

function TableMenuItem(props: MenuItemProps): ReactElement {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <>
      <MuiMenuItem
        classes={{
          root: classes.root,
        }}
        {...rest}
      >
        {children}
      </MuiMenuItem>
    </>
  );
}

export default TableMenuItem;
