import React from "react";

import { ModalPopupAlert } from "../../../Components/Notifications/ModalPopupAlert";
import localization from "../localization";

interface IRemoveDelegateeForTransfersModalProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

export function RemoveDelegateeForTransfersModal(props: IRemoveDelegateeForTransfersModalProps) {
  return (
    <ModalPopupAlert
      title={localization.removeDelegateeForTransfersModal.title}
      content={localization.removeDelegateeForTransfersModal.content}
      confirmationButtonText={localization.remove}
      onConfirm={props.onConfirm}
      showDeclineLink={true}
      size="sm"
      onClose={props.onClose}
      open={props.open}
    />
  );
}
