/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <circle fill="#f0f0f0" cx="12" cy="12" r="12" />
      <path
        fill="#bebebe"
        d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 1c6.1 0 11 4.9 11 11 0 2.6-.9 5-2.4 6.9-1.3-1.2-6.3-2.5-6.3-2.5s-.3-.7-.4-1c1.3-.8 2.3-2.4 2.3-4.3 0-3.7-1.9-5.3-4.2-5.3-2.3 0-4.2 1.6-4.2 5.3 0 1.9.9 3.5 2.3 4.3-.1.2-.4 1-.4 1s-4.9 1.3-6.2 2.5C1.9 17 1 14.6 1 12 1 5.9 5.9 1 12 1z"
      />
    </SvgIcon>
  );
}
