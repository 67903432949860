import axios from "axios";
import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { GridSelectionModel } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import * as constants from "../../Common/EVaultAppConstants";
import { profileSelector } from "../Profile/ProfileSlice";
import { addTab } from "../Tabs/Actions";
import * as tabActions from "../Tabs/Tabs";
import * as allLoansService from "./AllLoans/allLoansService";
import { fetchDocuments } from "./Documents/DocumentsSlice";
import { IVaultLoanSearchResult, Roles, VaultDocumentModes } from "./VaultInterfaces";
import { DocListPayload } from "./shared";

export function canUserDownload(state: RootState): boolean {
  if (!state.profile) return false;
  const validDownloadRoles: Roles[] = [
    "evault.admin",
    "evault.mers.registrar",
    "evault.mers.transferor",
    "evault.mers.admin",
    "evault.reader",
  ];
  const roles = profileSelector(state).roles;
  return _.some(roles, (role) => validDownloadRoles.indexOf(role) > -1);
}

export function preSelectedMode(state: RootState): VaultDocumentModes {
  const profile = profileSelector(state);
  const preSelectedMode = profile.settings.application.vaultDocumentsPreSelected;
  if (!preSelectedMode) return "None";
  return profile.settings.application.vaultDocumentsPreSelected;
}

export function getAuditLogLink(item: IVaultLoanSearchResult): string {
  let auditFilename = item.loanNumber;
  if (item.primarySignerLastName) {
    auditFilename += "_" + item.primarySignerLastName;
  }
  return `/api/packages/${item.packageId}/documents/audit-log/print?filename=${auditFilename}`;
}

export interface DocumentDownloadProperties {
  documentList: GridSelectionModel;
  loanNumber: string;
  packageId: string;
  partyName: string;
}

export function openDocumentDownload(props: DocumentDownloadProperties): void {
  const url = getDocumentPrintUrl(props);
  window.open(`${url}&zip=true`);
}

export function openDocumentPrint(props: DocumentDownloadProperties): void {
  const url = getDocumentPrintUrl(props);
  window.open(url, "PrintWindow");
}

export function getDocumentPrintUrl(properties: DocumentDownloadProperties): string {
  const packageId = formatString(properties.packageId);
  const loanNumber = formatString(properties.loanNumber);
  const partyName = formatString(properties.partyName);
  const docList = properties.documentList.join(",");
  return `/api/packages/${packageId}/documents/print?loanNumber=${loanNumber}&partyName=${partyName}&documentIds=${docList}`;
}

function formatString(text: string): string {
  if (!text) return "";
  return text.replace(/,/g, "").replace(/\s/g, "");
}

export interface ITabDoclistPayload {
  key: string;
  displayActivityPanel?: boolean;
  loanType: string;
  packageId?: string;
  packageType?: string;
  type: string;
  rowData: any;
}
export function openTabForDocList(payload: ITabDoclistPayload): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    const addPayload = {
      closeable: true,
      icon: constants.DOC_LIST_TAB.icon,
      isLoading: true,
      key: payload.key,
      loanType: payload.loanType,
      title: "Doc List",
      type: payload.type,
    };
    dispatch(addTab(addPayload));
    const docListData = await getDocListPayload(payload.rowData);
    await dispatch(fetchDocuments(payload.rowData.packageId));
    const updatePayload = {
      ...docListData,
      key: payload.key,
      loanType: payload.loanType,
      packageType: docListData.packageType || payload.packageType,
      title: "Doc List",
      type: payload.type,
    };

    // If there is a package id and we are going to show activity, we want to update the document list in a timeout
    // to account for rendering issues with the table. The table needs to know how much space is available when it is rendered.
    if (payload.packageId && payload.displayActivityPanel) {
      dispatch(allLoansService.onShowActivity(payload.packageId));
      setTimeout(() => {
        dispatch(tabActions.updateTab(updatePayload));
      }, 0);
    } else {
      dispatch(tabActions.updateTab(updatePayload));
    }
  };
}

export async function getDocListPayload(rowData: any): Promise<any> {
  let name = rowData.primarySignerLastName || "";
  const nameParts = [rowData.primarySignerFirstName, rowData.primarySignerMiddleName, rowData.primarySignerSuffix];
  if ("" !== rowData.primarySignerFirstName || "" !== rowData.primarySignerMiddleName) {
    name += ", " + nameParts.join(" ");
  }

  return retrieveDocListPayload(
    rowData.packageId,
    rowData.loanNumber,
    rowData.min,
    rowData.packageType,
    rowData.alternatePackageType,
    name,
    rowData.workflowType
  );
}

export async function getDocuments(packageId: string, pdfOnly = false, joinSmartDocWithPdf = true): Promise<any> {
  const url = `/api/packages/${packageId}/documents?pdfOnly=${pdfOnly}&joinSmartDocWithPdf=${joinSmartDocWithPdf}`;
  return await axios.get(url);
}

export function retrieveDocListPayload(
  packageId: string,
  loanNumber: string,
  min: string,
  packageType: string,
  alternatePackageType: string,
  partyName: string,
  workflowType: string
): DocListPayload {
  return {
    alternatePackageType,
    loanNumber,
    min,
    packageId,
    packageType,
    partyName,
    workflowType,
  };
}
