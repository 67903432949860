import React, { ReactElement } from "react";

import _ from "lodash";

import Box from "@mui/material/Box";

import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import Tooltip from "../../../Components/Tooltip";
import { MersConfirmationStatus } from "../VaultInterfaces";

interface IMersManualTransferConfirmationSelectorProps {
  actions: MersConfirmationStatus[];
  currentAction?: MersConfirmationStatus;
  packageId: string | null;
  parentTransfer: string;
  onConfirmationStatusChanged: (action: MersConfirmationStatus, transfer: string) => void;
}

export default class MersManualTransferConfirmationSelector extends React.Component<IMersManualTransferConfirmationSelectorProps> {
  private actions: MersConfirmationStatus[];
  public constructor(props: IMersManualTransferConfirmationSelectorProps) {
    super(props);
    this.actions = this.props.actions;
  }

  private handleChangeClick = (event: any) => {
    this.props.onConfirmationStatusChanged(this.props.actions[parseInt(event.target.value)], this.props.parentTransfer);
  };

  public render(): ReactElement {
    const recipientSelectStylings: string[] = ["width-full"];
    const options = this.actions.map((action: string, iterator: number) => {
      return (
        <TableMenuItem key={iterator} value={iterator}>
          {action}
        </TableMenuItem>
      );
    });
    let currentIndex = -1;

    _.each(this.actions, (action: string, iteratee: number) => {
      if (this.props.currentAction && action === this.props.currentAction) {
        currentIndex = iteratee;
      }
    });
    const noteTipContent = this.props.packageId ? "" : "Pending Delivery";

    return (
      <Box sx={{ lineHeight: "normal", width: "100%" }}>
        <Tooltip title={noteTipContent}>
          <div>
            <TableSelect
              disabled={!this.props.packageId}
              value={currentIndex.toString()}
              onChange={this.handleChangeClick}
              className={recipientSelectStylings.join(" ")}
              size="small"
              variant="standard"
            >
              {options}
            </TableSelect>
          </div>
        </Tooltip>
      </Box>
    );
  }
}
