import axios from "axios";

import { Roles, VaultDocumentModes } from "Features/Vault/VaultInterfaces";

export interface ISettings {
  application: {
    activityPanelEnabled: boolean;
    clientDefinedDataFieldsEnabled: boolean;
    displayAltPackage: boolean;
    exportLimit: number;
    vaultDocumentsPreSelected: VaultDocumentModes;
  };
  mers: {
    dataPointRegistrationsEnabled: boolean;
    delegateeForTransferEnabled: boolean;
    independentDeliveryEnabled: boolean;
    minReconciliationEnabled: boolean;
    securedPartyEnabled: boolean;
    securedPartyDelegateeEnabled: boolean;
    subservicerEnabled: boolean;
    transferWithoutDeliveryEnabled: boolean;
  };
}

export interface IAdminProfileViewModel {
  displayName: string;
  roles: Roles[];
  settings: ISettings;
}

/**
 * Gets the profile for the currently logged in user
 * @returns A promise for the request
 */
export async function getUserProfile(): Promise<IAdminProfileViewModel> {
  const response = await axios.get("/api/profile");
  return response.data;
}

export async function logUserOut(): Promise<void> {
  await axios.post("/api/logout");
}

export async function sessionTimedOut(): Promise<void> {
  window.location.href = "/app/signed-out";
  await axios.post("/api/logout");
}

export async function keepAlive(): Promise<void> {
  await axios.post("/api/keepalive");
}
