/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ActivityIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M17.7,12,17,8H14.24l-2.07,7.71L10.11,4h-3L5.71,12H1v2H7.39L8.61,7,10.9,20h2.2l2.4-9L16,14h7V12Z"
      />
    </SvgIcon>
  );
}
