import React, { ReactElement, forwardRef } from "react";

import { NavLink as BaseNavLink } from "react-router-dom";

import { MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";

import { ICollapsibleSideMenuListSubItem } from "./CollapsibleSideMenuInterfaces";

interface IVaultCollapsedMenu {
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  subItems: ICollapsibleSideMenuListSubItem[];
}

const NavLink = forwardRef(({ ...props }, ref) => {
  return (
    <BaseNavLink
      // @ts-ignore
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [
          // @ts-ignore
          props.className,
          isActive ? "Mui-selected" : null,
        ]
          .filter(Boolean)
          .join(" ")
      }
    />
  );
});

NavLink.displayName = "NavLink";

export default function VaultCollapsedMenu(props: IVaultCollapsedMenu): ReactElement {
  const { anchorEl, open, onClose, subItems } = props;

  const id = "left-nav-vault";
  return (
    <Menu anchorEl={anchorEl} key={"sidemenu-" + id} onClose={onClose} open={open}>
      {subItems.map((action) => (
        // @ts-ignore
        <MenuItem
          // @ts-ignore
          component={NavLink}
          key={action.title}
          onClick={onClose}
          to={action.to}
        >
          {action.title}
        </MenuItem>
      ))}
    </Menu>
  );
}
