export enum TablePanelColumnDataType {
  checkboxMultiple,
  checkboxSingle,
  editable,
  number,
  raw,
  text,
  renderBody,
  dateTime,
  date,
}

export interface ITablePanelColumn {
  accessor: string;
  bodyTextAlignLeft?: boolean;
  className?: string;
  dataType: TablePanelColumnDataType;
  editable?: boolean;
  header: string | JSX.Element;
  headerTextAlignLeft?: boolean;
  hidden?: boolean;
  renderBody?: (item: any) => JSX.Element | string | null;
  shrinkToFit?: boolean;
  sortable?: boolean;
  validator?: (newValue: string) => boolean;
  orderBy?: { accessors: string[]; orderings: TablePanelColumnOrder[] };
  sortKey?: string;
}

export enum TablePanelColumnOrder {
  ASC = "ASC",
  INHERITED = "INHERITED",
  DESC = "DESC",
  REVERSE_INHERITED = "REVERSE_INHERITED",
}

export interface ITablePanelRowStyling {
  accessor: string;
  validator: (data: any) => boolean;
  classList: string[];
}
