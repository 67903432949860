import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { GridSortItem } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import { SortOrderType } from "Common/Enums";

import { ISortByParams } from "Types/EVaultAppTypes";

import { IQueueSearchApiParams, fetchQueue, updateSearchBarParams, updateSortBy } from "./QueueSlice";
import { getQueueUrl } from "./queueAdapter";

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const data = state.queue.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    await dispatch(fetchQueueAction());
  };
}

export function updateSortByAction({ field, sort }: GridSortItem): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    // Return if missing necessary arguments
    if (!field || !sort) return;
    const state: RootState = getState();
    const { sortField: prevSortField, sortOrderType: prevSortOrderType } = state.queue.sortByParams;
    const newSortOrderType = sort === "asc" ? SortOrderType.ASCENDING : SortOrderType.DESCENDING;

    // If no sort property changes, exit without fetching new data
    if (prevSortField === field && prevSortOrderType === newSortOrderType) return;

    const newSortOrderParams: ISortByParams = {
      sortField: field,
      sortOrderType: newSortOrderType,
    };

    dispatch(updateSortBy(newSortOrderParams));
    dispatch(fetchQueueAction());
  };
}

export function fetchQueueAction(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    if (resourceUrl) {
      dispatch(fetchQueue(resourceUrl));
    } else {
      const searchCriteria = state.queue.searchBarParams.data;
      const sortByParams = state.queue.sortByParams;
      const params: IQueueSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        ...sortByParams,
      };

      const url = getQueueUrl(params);
      dispatch(fetchQueue(url));
    }
  };
}
