/* eslint-disable quote-props */
import { stepConnectorClasses } from "@mui/material";
import { createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    contrastThreshold: 3,
    error: {
      main: "#CB2B27",
    },
    primary: {
      main: "#013A6F",
    },
    secondary: {
      main: "#518221",
    },
  },
  typography: {
    h1: {
      fontFamily: "'Saira Light','Saira Regular',Saira,sans-serif",
      fontSize: "1.4rem",
    },
  },
});

theme = createTheme(theme, {
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:disabled": {
            background: theme.palette.grey[400],
            color: theme.palette.grey[600],
          },
          letterSpacing: 1,
        },
        sizeLarge: {
          minWidth: 180,
        },
        sizeMedium: {
          minWidth: 150,
        },
        sizeSmall: {
          minWidth: 100,
        },
        textSecondary: {
          "&$disabled": {
            backgroundColor: "transparent",
            color: theme.palette.grey[600],
          },
          "&:focus": {
            color: theme.palette.secondary.main,
          },
          "&:hover": {
            backgroundColor: theme.palette.action.hover,
          },
          backgroundColor: "transparent",
          color: theme.palette.secondary.main,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          fontFamily: "Saira Regular, Saira, sans-serif",
          fontSize: 13,
        },
        root: {
          "& .Mui-error": {
            backgroundColor: "rgb(126,10,15, 0.1)",
            color: "#750f0f",
          },
          "& .MuiDataGrid-cell": {
            "&:focus, &:focus-within": {
              boxShadow: "none",
              outline: "none",
            },
            boxShadow: "none",
          },
          "& .MuiDataGrid-cell.MuiDataGrid-cell--editing": {
            "&:focus, &:focus-within": {
              boxShadow: "none",
              outline: "none",
            },
            boxShadow: "none",
          },
          "& .MuiDataGrid-colCell": {
            "&:focus": {
              outline: "none",
            },
            fontFamily: "'Saira'",
            fontSize: 13,
            letterSpacing: 0.65,
          },
          "& .MuiDataGrid-columnHeader": {
            "&:focus,  &:focus-within": {
              outline: "none",
            },
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-row": {
            "&:hover": {
              "& .table-panel-row--context-menu": {
                visibility: "visible",
              },
              backgroundColor: "#00000003",
            },
          },
          "& .MuiDataGrid-row--editing": {
            "& .MuiDataGrid-cell": {
              backgroundColor: "#7DC9F030",
            },
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "#00000010",
          },
          "& .MuiDataGrid-row.MuiDataGrid-row-error": {
            backgroundColor: "rgb(126,10,15, 0.1)",
          },
          "& .MuiDataGrid-window": {
            outline: "1px solid #F0F0F0",
          },
          "& .table-panel-row--context-menu": {
            visibility: "hidden",
          },
          "& .table-panel-row--context-menu.active": {
            visibility: "visible",
          },
          border: 0,
          fontSize: 13,
          width: "100%",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        spacing: {
          padding: theme.spacing(2.5, 3, 2),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.grey[700],
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "#00000095",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              borderColor: theme.palette.primary.main,
            },
          },
          [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
              borderColor: theme.palette.primary.main,
            },
          },
          [`& .${stepConnectorClasses.line}`]: {
            borderRadius: 1,
            borderTopWidth: 1,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: "top",
      },
      styleOverrides: {
        arrow: {
          color: theme.palette.common.black,
        },
        tooltip: {
          backgroundColor: theme.palette.common.black,
          fontSize: ".8rem",
        },
      },
    },
  },
});

export default theme;
