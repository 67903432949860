import React, { ReactElement, useState } from "react";

import Link from "@mui/material/Link";

import * as constants from "Common/EVaultAppConstants";
import dateTimeField from "Common/Fields/DateTimeField";
import { getOrgDisplay } from "Common/Utilities";

import ErrorState from "Components/DataTable/ErrorState";
import DetailRow from "Components/DetailRow";

import { retrieveLastMersTransactionData } from "Adapters/Mers/mersAdapter";

import * as closedLoan from "Features/Vault/ClosedLoans/ClosedLoan";

import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import {
  IMersOriginator,
  IMersTransactionData,
  IVaultViewClosedLoansSearchResultData,
  MersInitiatorTypes,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";

interface IRegistrationDetailsModal {
  data: IVaultViewClosedLoansSearchResultData;
  keyRoot: string;
  mersOriginators: IMersOriginator[];
  myMersPermissions: MersInitiatorTypes;
  onClose: () => void;
  onShowReverseRegistrationModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

export default function RegistrationDetailsModal(props: IRegistrationDetailsModal): ReactElement {
  const { data, keyRoot, myMersPermissions, onClose } = props;
  const [details, setDetails] = React.useState<IMersTransactionData | null>(null);
  const isPendingTransfer = closedLoan.isPendingTransfer(data);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { packageId } = data;

  React.useEffect(() => {
    function retrieveDetails() {
      setShowError(false);
      retrieveLastMersTransactionData(packageId, "Registration")
        .then((response) => {
          setDetails(response);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setShowError(true);
          console.error("Last result retrieval error", error);
          console.log(error);
        });
    }
    if (isLoading) {
      retrieveDetails();
    }
  }, [isLoading, packageId]);

  function handleRetry() {
    setIsLoading(true);
  }

  const showReverseRegisterLink =
    myMersPermissions === MersInitiatorTypes.Controller ||
    myMersPermissions === MersInitiatorTypes.Servicer ||
    myMersPermissions === MersInitiatorTypes.ControllerServicer;

  let reverseRegisterPromptAndLink: null | JSX.Element = null;

  function handleShowReverseRegistrationModal() {
    props.onShowReverseRegistrationModal(data);
    onClose();
  }

  if (
    showReverseRegisterLink &&
    !isPendingTransfer &&
    data.mersData.mersInitiators.originalRegistrant &&
    data.originatorOrgId === data.mersData.mersInitiators.originalRegistrant.orgId
  ) {
    reverseRegisterPromptAndLink = (
      <p className="margin-top-xxsmall">
        <span>To reverse the registration, </span>
        <Link className="pseudolink__body" onClick={handleShowReverseRegistrationModal} role="button">
          click here
        </Link>
        .
      </p>
    );
  }

  let initiatedDate = "Unknown";
  let initiator = "Unknown";
  if (details != null && details.initiatedDate != null) {
    // TODO: When the backend starts returning better data, this tmp variable can be removed
    const tmpInitiatedDate = dateTimeField.renderForDisplay(details.initiatedDate);
    if (tmpInitiatedDate) {
      initiatedDate = tmpInitiatedDate;
      if (details.initiator) {
        initiator = getOrgDisplay(details.initiator);
      }
    }
  }

  let content = (
    <div>
      <DetailRow label="Date" value={initiatedDate} />
      <DetailRow label="Loan #" value={data.loanNumber || "Unknown"} />
      <DetailRow
        label="MIN"
        value={
          <MersIdentificationNumber
            className="pseudolink__body"
            keyRoot={keyRoot}
            loan={data}
            loanType={constants.CLOSED_LOANS}
            tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
            value={data.min}
          />
        }
      />
      <DetailRow label="Registrant" value={initiator} />
      {reverseRegisterPromptAndLink}
    </div>
  );

  if (showError) {
    content = <ErrorState message="An error occurred while loading registration details." onRetry={handleRetry} />;
  }
  return content;
}
