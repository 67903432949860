/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { upperCase } from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import CalendarIcon from "@mui/icons-material/Event";
import { Checkbox } from "@mui/material";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import TableDatePicker from "Components/Table/TableDatePicker";

import { profileSelector } from "Features/Profile/ProfileSlice";
import {
  IMersRecipientDetails,
  IMersRegisterTransaction,
  IMersTransferPackage,
  RecipientAccessors,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";
import { datagridColumns } from "Features/Vault/shared";

import localization from "../../localization";
import MersIdentificationNumber from "../MersIdentificationNumber";
import MersRecipientSelector from "../MersRecipientSelector";
import { updateIncludeDelivery, updateRecipient, updateTransferDate } from "./MersTransferSlice";
import { fetchTransfers, updateTransferQueue } from "./mersTransferService";

const CustomCalendarIcon = () => <CalendarIcon fontSize="small" sx={{ pb: 0.2 }} />;

const mapStateToProps = (state: RootState) => ({
  error: state.mersTransfers.error,
  initialized: state.mersTransfers.initialized,
  isLoading: state.mersTransfers.isLoading,
  mersRecipients: state.mersRecipients.recipients,
  queueList: state.mersTransfers.transfers,
  settings: profileSelector(state).settings,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersTransferTable(props: TypesFromRedux): ReactElement {
  const keyRoot = "VaultTab-ClosedLoans-";
  const { error, initialized, isLoading, mersRecipients, queueList, settings } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [selected, setSelected] = useState<GridSelectionModel>([]);

  const permissions = {
    delegateeForTransferEnabled: settings.mers.delegateeForTransferEnabled,
    securedPartyDelegateeEnabled: settings.mers.securedPartyDelegateeEnabled,
    securedPartyEnabled: settings.mers.securedPartyEnabled,
    subservicerEnabled: settings.mers.subservicerEnabled,
    transferWithoutDeliveryEnabled: settings.mers.transferWithoutDeliveryEnabled,
  };
  console.log("rendering transfer table");

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchTransfers());
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    setSelected(queueList.filter((loan) => loan.selected).map((loan) => loan.id));
  }, [queueList]);

  const columns: GridColDef[] = [
    {
      ...datagridColumns.loanNumber,
      sortable: false,
    },
    {
      ...datagridColumns.min,
      renderCell(value: GridCellParams) {
        return (
          <MersIdentificationNumber
            clickable={true}
            keyRoot={keyRoot}
            loan={value.row}
            loanType={constants.CLOSED_LOANS}
            tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
            value={value.row.min}
          />
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.controlRecipient,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.controlRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.controlRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.locationRecipient,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.locationRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.locationRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.servicingAgentRecipient,
      editable: true,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.servicingAgentRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.servicingAgentRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.subServicerRecipient,
      editable: true,
      hide: !permissions.subservicerEnabled,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.subservicerRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.subservicerRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.securedPartyRecipient,
      editable: true,
      hide: !permissions.securedPartyEnabled,
      minWidth: 100,
      renderEditCell(params: GridRenderEditCellParams) {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.securedPartyRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              type={RecipientAccessors.securedPartyRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.securedPartyDelegateeRecipient,
      editable: true,
      hide: !permissions.securedPartyDelegateeEnabled || !permissions.securedPartyEnabled,
      minWidth: 100,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.securedPartyDelegateeRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.securedPartyDelegateeRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.delegateeForTransferRecipient,
      editable: true,
      field: "delegateeForTransfersRecipient",
      headerName: upperCase(localization.mers.rightsHolder.delegateeForTransfers),
      hide: !permissions.delegateeForTransferEnabled,
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.delegateeForTransfersRecipient.name) {
          return (
            <Tooltip title={params.row.delegateeForTransfersRecipient.orgId}>
              <span>{params.row.delegateeForTransfersRecipient.name}</span>
            </Tooltip>
          );
        }
        return params.row.delegateeForTransfersRecipient.orgId;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.delegateeForTransfersRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.delegateeForTransfersRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.effectiveDate,
      editable: true,
      renderEditCell(params: GridRenderEditCellParams) {
        const { id, api, field } = params;

        const effectiveDateChange = (queue: IMersTransferPackage) => (date: any) => {
          if (date != null) {
            api.setEditCellValue({ field, id, value: date });
            dispatch(
              updateTransferDate({
                effectiveDate: date,
                packageId: queue.packageId,
              })
            );
          }
        };
        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <TableDatePicker
              components={{
                OpenPickerIcon: CustomCalendarIcon,
              }}
              onChange={effectiveDateChange(params.row)}
              value={params.row.effectiveDate}
            />
          </Grid>
        );
      },
    },
    {
      editable: true,
      field: "includeDelivery",
      headerName: "INCLUDE DELIVERY",
      hide: !permissions.transferWithoutDeliveryEnabled,
      minWidth: 100,
      renderCell(params: GridCellParams) {
        return (
          <Grid container item alignItems="center" justifyContent="center">
            <Checkbox
              checked={params.row.includeDelivery}
              color="default"
              disabled
              size="small"
              value={params.row.includeDelivery}
            />
          </Grid>
        );
      },
      renderEditCell(params: GridRenderEditCellParams) {
        const { id, api, field } = params;

        function includeDeliveryChange(loan: IMersTransferPackage) {
          api.setEditCellValue({ field, id, value: !loan.includeDelivery });
          dispatch(
            updateIncludeDelivery({
              includeDelivery: !loan.includeDelivery,
              packageId: loan.packageId,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center">
            <Checkbox
              checked={params.row.includeDelivery}
              color="default"
              onClick={() => {
                includeDeliveryChange(params.row);
              }}
              size="small"
              value={params.row.includeDelivery}
            />
          </Grid>
        );
      },
      sortable: false,
    },
  ];

  function getRowClassName(params: any): string {
    if (params.row.isInvalidState) {
      return "MuiDataGrid-row-error";
    }
    return "";
  }

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
    const transfers = queueList.map((queue) => {
      return {
        ...queue,
        selected: selected.includes(queue.id),
      };
    });
    dispatch(updateTransferQueue(transfers));
  };

  return (
    <DataGrid
      css={css`
        min-height: ${error ? "200px" : "0px"};
      `}
      checkboxSelection
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
      }}
      componentsProps={{
        errorOverlay: { error },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      editMode="row"
      error={error}
      getRowClassName={getRowClassName}
      hideFooter
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selected}
      rows={queueList}
      scrollbarSize={17}
    />
  );
}

export default connector(MersTransferTable);
