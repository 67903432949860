/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import UploadCloudIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";

const MEGABYTE_SIZE = 1048576;
const FILE_SIZE_LIMIT: number = MEGABYTE_SIZE * 15;

interface IUploadButtonProps {
  onError: (message: string) => void;
  onUpload: (file: any) => void;
}

export default function UploadButton(props: IUploadButtonProps): ReactElement {
  const [value, setValue] = React.useState<string>("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  function handleUploadClick() {
    setValue("");
    inputRef.current?.click();
  }

  function handleUploadChange(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    let file;
    if (event.target != null && target.files != null) {
      file = target.files[0];
    }
    if (!file) {
      // User cancelled the upload
      return;
    }
    if (FILE_SIZE_LIMIT > file.size) {
      props.onUpload(file);
    } else {
      props.onError(`${file.name} exceeds the 15 MB limit and cannot be attached.`);
    }
  }

  return (
    <>
      <Button
        css={css`
          border: 2px dashed #323232;
          color: #555555;
          padding: 32px 32px;
        `}
        onClick={handleUploadClick}
      >
        <UploadCloudIcon />
        <span
          css={css`
            margin-left: 8px;
          `}
        >
          Click to upload
        </span>
      </Button>
      <input
        className="hidden"
        aria-label="upload-button-input"
        id="upload-button-input"
        name="newDocument"
        onChange={handleUploadChange}
        ref={inputRef}
        type="file"
        value={value}
      />
    </>
  );
}
