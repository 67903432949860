import { DATETIME_SHORT_WITH_OFFSET, DATETIME_SORTABLE, format } from "Common/dateUtilities";

import { ITablePanelColumn } from "Components/Panels/TablePanel/TablePanelInterfaces";

import { IFieldInterface } from "./index";

interface IDateTimeField extends IFieldInterface {
  renderForDisplay: (value: Date | string | undefined) => string;
  renderForTable: (value: Date | string | undefined, column?: ITablePanelColumn) => string;
  sortable: (value: Date | string | undefined) => string;
}

const dateTimeField: IDateTimeField = {
  renderForDisplay: renderValue,
  renderForTable: renderValue,
  sortable,
};

function sortable(value: string | Date | undefined): string {
  return format(value, DATETIME_SORTABLE);
}

// TODO: Will need to remove defaultValue in the future. Code outside of this service should be responsible for the default.
function renderValue(value: string | Date | undefined): string {
  if (value == null) return "";
  return format(value, DATETIME_SHORT_WITH_OFFSET);
}

export default dateTimeField;
