/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DataTableIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M16,4h-1H9H8H2v4v3v1v3v1v4h6h1h6h1h6v-4v-1v-3v-1V8V4H16z M15,8v3H9V8H15z M15,12v3H9v-3H15z M3,8h5v3H3V8z	M3,12h5v3H3V12z M3,19v-3h5v3H3z M9,19v-3h6v3H9z M21,19h-5v-3h5V19z M21,15h-5v-3h5V15z M16,11V8h5v3H16z" />
    </SvgIcon>
  );
}
