/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { AppDispatch, RootState } from "App/Store";

import { BottomBar, ISplitButtonOption } from "Components/Notifications/BottomBar";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import ScrollContainer from "Components/ScrollContainer";

import { profileSelector } from "Features/Profile/ProfileSlice";
import RegistrationFilterBar from "Features/Vault/ClosedLoans/RegistrationFilterBar";
import MersRegisterUpload from "Features/Vault/Mers/MersRegister/MersRegisterUpload";
import { VaultMersTransactionResultsModalPopup } from "Features/Vault/ModalPopups/VaultMersTransactionResultsModalPopup";
import { IMersBatchTransactionRequest, MersTransactionType, RegistrationType } from "Features/Vault/VaultInterfaces";

import { getRegistrationQueue } from "./MersRegisterSlice";
import MersRegisterTable from "./MersRegisterTable";
import { executeSearch, getRegistrationTransactions } from "./mersRegistrationService";

const mapStateToProps = (state: RootState) => ({
  isLoading: state.mersRegistrations.isLoading,
  queueList: getRegistrationQueue(state) ?? [],
  settings: profileSelector(state).settings,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersRegister(props: TypesFromRedux): ReactElement {
  const { isLoading, settings, queueList } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [mersRegistrationResultsOpen, setMersRegistrationResultsOpen] = useState<boolean>(false);
  const [registerMessage, setRegisterMessage] = useState<string>("");
  const [registerTransaction, setRegisterTransaction] = useState<IMersBatchTransactionRequest>({
    packages: [],
    type: MersTransactionType.Unknown,
  });
  const [registrationType, setRegistrationType] = useState<RegistrationType>(RegistrationType.Presentation);
  const [showRegisterConfirmation, setShowRegisterConformation] = useState<boolean>(false);

  const permissions = {
    dataPointRegistrationsEnabled: settings.mers.dataPointRegistrationsEnabled,
    delegateeForTransferEnabled: settings.mers.delegateeForTransferEnabled,
    securedPartyDelegateeEnabled: settings.mers.securedPartyDelegateeEnabled,
    securedPartyEnabled: settings.mers.securedPartyEnabled,
  };

  function handleRegisterLoans(): void {
    setShowRegisterConformation(false);
    setMersRegistrationResultsOpen(true);
    const packagesToRegister = queueList.filter((loan) => loan.selected);
    setRegisterTransaction({
      packages: getRegistrationTransactions(packagesToRegister, registrationType),
      type: MersTransactionType.Registration,
    });
  }

  function handleRegisterOptionClick(option: ISplitButtonOption) {
    const isPresentation = option.value === "Presentation";
    const message = isPresentation
      ? "This will initiate a presentation registration of the selected eNotes."
      : "This will initiate a data point registration of the selected eNotes.";
    setRegisterMessage(message);
    setRegistrationType(isPresentation ? RegistrationType.Presentation : RegistrationType.DataPoint);
    setShowRegisterConformation(true);
  }

  function handleRegisterClick() {
    const message = "This will initiate registration of the selected eNotes.";

    setRegisterMessage(message);
    setRegistrationType(RegistrationType.Presentation);
    setShowRegisterConformation(true);
  }

  function handleMersTransactionRequestResponseClosed() {
    setMersRegistrationResultsOpen(false);
    setShowRegisterConformation(false);
    dispatch(executeSearch());
  }

  const registrationResultsModal = (
    <VaultMersTransactionResultsModalPopup
      onClosed={handleMersTransactionRequestResponseClosed}
      request={registerTransaction}
      open={mersRegistrationResultsOpen}
    />
  );

  const registrationOptions: ISplitButtonOption[] = [];
  if (permissions.dataPointRegistrationsEnabled) {
    registrationOptions.push({
      display: "Presentation",
      value: "Presentation",
    });
    registrationOptions.push({ display: "Data point", value: "DataPoint" });
  }

  const loansSelected = _.filter(queueList, "selected").length;
  return (
    <>
      <ModalPopupAlert
        title="Register?"
        content={registerMessage}
        confirmationButtonText="REGISTER"
        onConfirm={handleRegisterLoans}
        showDeclineLink={true}
        onClose={() => {
          setShowRegisterConformation(false);
        }}
        open={showRegisterConfirmation}
        size="sm"
      />
      <ScrollContainer>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            padding-right: 8px;
          `}
        >
          <RegistrationFilterBar />
          <MersRegisterUpload />
        </div>
        <MersRegisterTable />
      </ScrollContainer>

      <BottomBar
        contents="Add loans(s), and then click register."
        buttons={[
          {
            closable: false,
            disabled: !loansSelected || isLoading,
            onClick: handleRegisterClick,
            onOptionClick: handleRegisterOptionClick,
            options: registrationOptions,
            text: "REGISTER",
          },
        ]}
      />
      {registrationResultsModal}
    </>
  );
}

export default connector(MersRegister);
