import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import { notificationEnqueued } from "Common/error";

import * as types from "Types/EVaultAppTypes";

import * as errorActions from "Features/Errors/Errors";

import * as tabActions from "./Tabs";

// Tab Actions
export const addTab = (payload: types.IActionGenericPayload): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const state = getState();
    const loanTabs: any = (state.tabs as any)[payload.loanType as any];
    const tabsTotal = loanTabs.allIds.length;
    const attemptToCreateNewTab = !loanTabs.byId[payload.key];
    const tabLimitReached = tabsTotal >= 5;
    if (tabLimitReached && attemptToCreateNewTab) {
      dispatch(
        errorActions.enqueue(
          notificationEnqueued("The maximum number of tabs is 5. Close one or more to open a new one.", {
            autoHide: true,
            autoHideDuration: 5000,
            variant: "warning",
          })
        )
      );
      return;
    }
    dispatch(tabActions.addTab(payload as any));
  };
};
