import React, { ReactElement, useEffect, useState } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";

import { clientDefinedDataFieldsSelector, profileSelector } from "Features/Profile/ProfileSlice";
import * as actions from "Features/Tabs/Actions";
import { ILoanTabDetails, ITabPayload } from "Features/Tabs/Tabs";
import * as tabActions from "Features/Tabs/Tabs";
import { fetchRecipients } from "Features/Vault/Mers/MersRecipients/MersRecipientSlice";

import { fetchDataFields } from "../ClientDefinedDataFieldsSlice";
import { resetDeliveries } from "../Mers/MersDelivery/MersDeliverySlice";
import { fetchOriginators } from "../Mers/MersOriginatorSlice";
import { resetRegistrations } from "../Mers/MersRegister/MersRegisterSlice";
import { resetMersTransfers } from "../Mers/MersTransfer/MersTransferSlice";
import { resetMersUpdates } from "../Mers/MersUpdate/MersUpdateSlice";
import { canRegister, canTransfer } from "../Mers/mersService";
import TransactionResultsModalPopup from "../ModalPopups/TransactionResultsModalPopup";
import { VaultMersTransactionResultsModalPopup } from "../ModalPopups/VaultMersTransactionResultsModalPopup";
import TabStore from "../TabStore";
import {
  IMersBatchTransactionRequest,
  IMersReverseRegistrationTransaction,
  IMersTransactionResponse,
  IVaultViewClosedLoansSearchResultData,
  MersModificationTypes,
  MersTransactionType,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";
import { fetchClosedLoans } from "./closedLoansService";

let mersTransactionRequest: IMersBatchTransactionRequest;

const mapStateToProps = (state: RootState) => {
  const profile = profileSelector(state);
  return {
    clientDefinedDataFieldsEnabled: clientDefinedDataFieldsSelector(state),
    closedLoanProps: state.closedLoans,
    independentDelivery: profileSelector(state).settings.mers.independentDeliveryEnabled ?? false,
    rightsRegister: canRegister(profile.roles),
    rightsTransfer: canTransfer(profile.roles),
    tabs: state.tabs[constants.CLOSED_LOANS],
  };
};

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function VaultViewClosedLoans(props: TypesFromRedux): ReactElement {
  const keyRoot = "VaultTab-ClosedLoans-";
  const { clientDefinedDataFieldsEnabled, dispatch, independentDelivery, tabs } = props;

  const SearchResultsTab: ITabPayload = {
    independentDelivery,
    key: createTabKey(keyRoot, VaultViewClosedLoansTabTypes.SearchResults),
    loanType: constants.CLOSED_LOANS,
    openLastResultModal,
    showDeliveryCancelConfirmModal,
    showReverseModificationConfirmModal,
    showReverseRegistrationConfirmModal,
    showTransferCancelConfirmModal,
    title: "Closed",
    type: VaultViewClosedLoansTabTypes.SearchResults,
  };

  const [mersResultsModalOpen, setMersResultsModalOpen] = useState<boolean>(false);
  const [reverseRegistrationConfirmModalOpen, setReverseRegistrationConfirmModalOpen] = useState<boolean>(false);
  const [cancelTransferConfirmModalOpen, setCancelTransferConfirmModalOpen] = useState<boolean>(false);
  const [cancelDeliveryConfirmModalOpen, setCancelDeliveryConfirmModalOpen] = useState<boolean>(false);
  const [reverseModificationModalOpen, setReverseModificationModalOpen] = useState<boolean>(false);
  const [transactionAwaitingConfirmation, setTransactionAwaitingConfirmation] = useState<
    IVaultViewClosedLoansSearchResultData | undefined
  >(undefined);

  // Add Search Results as first tab on original mount and make initial queries for MERS Orgs
  useEffect(() => {
    if (!tabs.byId[SearchResultsTab.key]) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(SearchResultsTab));
    } else {
      dispatch(tabActions.updateTab(SearchResultsTab));
    }
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchRecipients());
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchOriginators());
    if (clientDefinedDataFieldsEnabled) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchDataFields());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientDefinedDataFieldsEnabled]);

  /**
   * Click handler for switching tabs
   * @param tab the tab being clicked
   * @returns {void}
   */
  function onTabClick(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.goToTab({
        key: tab.key,
        loanType: constants.CLOSED_LOANS,
      })
    );
  }

  /**
   * Callback event for when a tab is closed
   * @param tab the tab to close
   * @returns {void}
   */
  function handleTabClosed(tab: ILoanTabDetails): void {
    switch (tab.type) {
      case VaultViewClosedLoansTabTypes.RegisterMERS:
        dispatch(resetRegistrations());
        break;
      case VaultViewClosedLoansTabTypes.TransferMERS:
        dispatch(resetMersTransfers());
        break;
      case VaultViewClosedLoansTabTypes.DeliverMERS:
        dispatch(resetDeliveries());
        break;
      case VaultViewClosedLoansTabTypes.UpdateMERS:
        dispatch(resetMersUpdates());
        break;
      default:
        break;
    }

    dispatch(
      tabActions.removeTab({
        key: tab.key,
        loanType: constants.CLOSED_LOANS,
      })
    );
  }

  /**
   * Find and close a tab based off min number
   * @param min The min number for the loan
   * @returns {void}
   */
  function closeTabByMin(min: string) {
    tabs.allIds.forEach((key) => {
      if (tabs.byId[key].min === min) {
        handleTabClosed(tabs.byId[key]);
      }
    });
  }

  function handleMersTransactionRequestResponseClosed(type: MersTransactionType): void {
    // Refresh the search results
    function refreshResults() {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchClosedLoans());
    }

    // Additional handle based on type
    switch (type) {
      case MersTransactionType.Registration:
        dispatch(resetRegistrations());
        setMersResultsModalOpen(false);
        refreshResults();
        break;

      case MersTransactionType.Transfer:
        dispatch(resetMersTransfers());
        setMersResultsModalOpen(false);
        refreshResults();
        break;

      case MersTransactionType.Delivery:
        dispatch(resetDeliveries());
        setMersResultsModalOpen(false);
        refreshResults();
        break;

      case MersTransactionType.ChangeData:
      case MersTransactionType.ChangeStatus:
        dispatch(resetMersUpdates());
        setMersResultsModalOpen(false);
        refreshResults();
        break;

      default:
        setMersResultsModalOpen(false);
        refreshResults();
        break;
    }
  }

  function cancelDelivery(response: IVaultViewClosedLoansSearchResultData): void {
    mersTransactionRequest = {
      packages: [{ packageId: response.packageId }],
      successCallback: (result: IMersTransactionResponse) => {
        if (result.transactionResults[0].status.toLowerCase() === "success") {
          closeTabByMin(response.min);
        }
      },
      type: MersTransactionType.CancelDelivery,
    };
    setMersResultsModalOpen(true);
  }

  /**
   * Update MERS Reverse Request state based on note status
   * @returns {void}
   */
  function reverseModification(response: IVaultViewClosedLoansSearchResultData) {
    mersTransactionRequest = {
      packages: [
        {
          min: response.min,
          modificationType: MersModificationTypes.Reversal,
          packageId: response.packageId,
        },
      ],
      type: MersTransactionType.ChangeData,
    };
    setMersResultsModalOpen(true);
  }

  /**
   * after confirmation passed, execute transfer cancellation
   * @param response search result row clicked
   * @returns {void}
   */
  function cancelTransfer(response: IVaultViewClosedLoansSearchResultData): void {
    mersTransactionRequest = {
      packages: [{ packageId: response.packageId }],
      successCallback: (result: IMersTransactionResponse) => {
        if (result.transactionResults[0].status.toLowerCase() === "success") {
          closeTabByMin(response.min);
        }
      },
      type: MersTransactionType.CancelTransfer,
    };

    setMersResultsModalOpen(true);
  }

  /**
   * Initiate a reverse registration on the loan.
   * @param response The loan for which we are going to reverse register
   * @returns {void}
   */
  function reverseRegister(response: IVaultViewClosedLoansSearchResultData): void {
    const packages: IMersReverseRegistrationTransaction[] = [{ packageId: response.packageId }];
    mersTransactionRequest = {
      packages,
      successCallback: (result: IMersTransactionResponse) => {
        if (result.transactionResults[0].status.toLowerCase() === "success") {
          closeTabByMin(response.min);
        }
      },
      type: MersTransactionType.ReverseRegistration,
    };

    setMersResultsModalOpen(true);
  }

  /**
   * Show the reverse registration modal asking if they are sure they want to reverse register.
   * @param response The loan for which we are showing the confirmation modal
   * @returns {void}
   */
  function showReverseRegistrationConfirmModal(response: IVaultViewClosedLoansSearchResultData) {
    setMersResultsModalOpen(false);
    setReverseRegistrationConfirmModalOpen(true);
    setTransactionAwaitingConfirmation(response);
  }

  /**
   * Part of transfer cancellation process. confirmation after cancel click
   * @param response search result row clicked
   * @returns {void}
   */
  function showTransferCancelConfirmModal(response: IVaultViewClosedLoansSearchResultData) {
    setMersResultsModalOpen(false);
    setCancelTransferConfirmModalOpen(true);
    setTransactionAwaitingConfirmation(response);
  }

  /**
   * Part of delivery cancellation process. confirmation after cancel click
   * @param response search result row clicked
   * @returns {void}
   */
  function showDeliveryCancelConfirmModal(response: IVaultViewClosedLoansSearchResultData) {
    setMersResultsModalOpen(false);
    setCancelDeliveryConfirmModalOpen(true);
    setTransactionAwaitingConfirmation(response);
  }

  /**
   * Handles displaying state for reversing modifications
   * @param response search result row clicked
   * @returns {void}
   */
  function showReverseModificationConfirmModal(response: IVaultViewClosedLoansSearchResultData) {
    setMersResultsModalOpen(false);
    setReverseModificationModalOpen(true);
    setTransactionAwaitingConfirmation(response);
  }

  /**
   * Show the last result in a modal. (latest error)
   * @param response The loan for which we are opening the last result
   * @param type The type of the transaction we are requesting
   * @returns {void}
   */
  function openLastResultModal(response: IVaultViewClosedLoansSearchResultData, type: MersTransactionType): void {
    mersTransactionRequest = {
      packages: [{ packageId: response.packageId }],
      type,
    };

    setMersResultsModalOpen(true);
  }

  const reverseRegistrationConfirmModal = (
    <ModalPopupAlert
      title="Initiate Reverse Registration"
      content="This cannot be cancelled. Are you sure you want to initiate the reverse registration?"
      confirmationButtonText="REVERSE"
      onConfirm={() => {
        setReverseRegistrationConfirmModalOpen(false);

        if (transactionAwaitingConfirmation) {
          reverseRegister(transactionAwaitingConfirmation);
        }
      }}
      showDeclineLink={true}
      onClose={() => setReverseRegistrationConfirmModalOpen(false)}
      open={reverseRegistrationConfirmModalOpen}
      size="sm"
    />
  );

  const cancelTransferConfirmModal = (
    <ModalPopupAlert
      title="Cancel Transfer"
      content="This cannot be reversed. Are you sure you want to cancel the transfer in progress?"
      confirmationButtonText="CONTINUE"
      onConfirm={() => {
        setCancelTransferConfirmModalOpen(false);

        if (transactionAwaitingConfirmation) {
          cancelTransfer(transactionAwaitingConfirmation);
        }
      }}
      showDeclineLink={true}
      size="sm"
      onClose={() => setCancelTransferConfirmModalOpen(false)}
      open={cancelTransferConfirmModalOpen}
    />
  );

  const cancelDeliveryConfirmModal = (
    <ModalPopupAlert
      title="Cancel Delivery"
      content="This cannot be reversed. Are you sure you want to cancel the delivery in progress?"
      confirmationButtonText="CONTINUE"
      onConfirm={() => {
        setCancelDeliveryConfirmModalOpen(false);

        if (transactionAwaitingConfirmation) {
          cancelDelivery(transactionAwaitingConfirmation);
        }
      }}
      showDeclineLink={true}
      size="sm"
      onClose={() => setCancelDeliveryConfirmModalOpen(false)}
      open={cancelDeliveryConfirmModalOpen}
    />
  );

  const noteStatusReversalConfirmationModal = (
    <ModalPopupAlert
      title="Confirm reversal"
      content="	This action will reverse the modification of this eNote and cannot be
					cancelled. Are you sure you want to perform this reverse action?"
      confirmationButtonText="CONTINUE"
      onConfirm={() => {
        setReverseModificationModalOpen(false);
        if (transactionAwaitingConfirmation) {
          reverseModification(transactionAwaitingConfirmation);
        }
      }}
      showDeclineLink={true}
      onClose={() => setReverseModificationModalOpen(false)}
      open={reverseModificationModalOpen}
      size="sm"
    />
  );

  return (
    <>
      {reverseRegistrationConfirmModal}
      {cancelTransferConfirmModal}
      {cancelDeliveryConfirmModal}
      {noteStatusReversalConfirmationModal}
      <TransactionResultsModalPopup onClose={handleMersTransactionRequestResponseClosed} />
      <VaultMersTransactionResultsModalPopup
        onClosed={handleMersTransactionRequestResponseClosed}
        request={mersTransactionRequest}
        open={mersResultsModalOpen}
      />
      <TabPanel
        currentTab={tabs.currentTab}
        onTabClosed={handleTabClosed}
        onTabClick={onTabClick}
        tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
      >
        <TabStore tab={tabs.byId[tabs.currentTab]} />
      </TabPanel>
    </>
  );
}

export default connector(VaultViewClosedLoans);
