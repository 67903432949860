import React, { ReactElement } from "react";

import CompleteIcon from "@mui/icons-material/CheckCircle";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { PackageRegistrationStatus } from "Common/Enums";
import { getRegistrationStatusDisplay } from "Common/Utilities";

import { InProgressIcon, UnknownIcon } from "Components/Icons";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { IMersOriginator, IVaultViewClosedLoansSearchResultData, MersInitiatorTypes } from "../VaultInterfaces";
import RegistrationDetailsModal from "./RegistrationDetailsModal";

interface IRegistrationDetails {
  mersOriginators: IMersOriginator[];
  data: IVaultViewClosedLoansSearchResultData;
  keyRoot: string;
  myMERsPositions: MersInitiatorTypes;
  onShowReverseRegistrationModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

export default function RegistrationDetails(props: IRegistrationDetails): ReactElement | null {
  const { data } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  let icon;
  const noteTip = getRegistrationStatusDisplay(data.mersData.mersRegistrationStatus);

  switch (data.mersData.mersRegistrationStatus) {
    case PackageRegistrationStatus.Registered:
      icon = <CompleteIcon fontSize="small" />;
      break;
    case PackageRegistrationStatus.RegistrationPending:
      icon = <InProgressIcon />;
      break;
    case PackageRegistrationStatus.Unregistered:
      break;
    case PackageRegistrationStatus.RegistrationFailed:
      icon = <AlertIcon color="error" />;
      break;
    default:
      icon = <UnknownIcon />;
      break;
  }

  if (!icon) {
    return null;
  }

  return (
    <>
      <Tooltip title={noteTip}>
        <Grid container item justifyContent="center" alignItems="center">
          <IconButton className="success-icon-button" size="small" onClick={handleOpen}>
            {icon}
          </IconButton>
        </Grid>
      </Tooltip>
      <ModalPopupAlert
        confirmationButtonText="CLOSE"
        onClose={handleClose}
        open={open}
        size="xs"
        title="Registration details"
      >
        <RegistrationDetailsModal
          mersOriginators={props.mersOriginators}
          data={props.data}
          keyRoot={props.keyRoot}
          myMersPermissions={props.myMERsPositions}
          onClose={handleClose}
          onShowReverseRegistrationModal={props.onShowReverseRegistrationModal}
        />
      </ModalPopupAlert>
    </>
  );
}
