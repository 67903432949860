/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */

/* eslint-disable quote-props */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import clsx from "clsx";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/WarningRounded";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

import { nonce } from "App/App";

import { getMersOrgDisplayName } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";

import { uploadPermissions } from "Features/Vault/ModalPopups/CsvFileUpload";

import { datagridColumns } from "../../../shared";
import { IMersRegisterPackageImport } from "./Content";

interface SearchResults {
  loanIdsNotFound: string[];
  nonRegistrableLoanIds: string[];
  registers: IMersRegisterPackageImport[];
}

interface ResultsProps {
  error?: string | undefined;
  isLoading: boolean;
  permissions: uploadPermissions;
  results: SearchResults | undefined;
}

function Results({ isLoading = false, error, permissions, results }: ResultsProps): ReactElement {
  const classes = useStyles();
  const { securedPartyEnabled, securedPartyDelegateeEnabled, delegateeForTransferEnabled } = permissions;

  const columns: GridColDef[] = [
    datagridColumns.loanNumber,
    {
      field: "min",
      flex: 1,
      headerName: "MIN",
      sortable: false,
    },
    {
      ...datagridColumns.securedPartyRecipient,
      cellClassName: (params: GridCellParams) => clsx({ warning: params.row.securedPartyModificationWarning }),
      hide: !securedPartyEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        const recipientName = getMersOrgDisplayName(params.row.securedPartyRecipient) || <i>none</i>;
        if (params.row.securedPartyModificationWarning) {
          return (
            <Tooltip
              title="Secured Party has been modified from what was originally given in your CSV.
							The org id passed was either not found or affiliated with a recipient from a different originator."
            >
              <Grid container item alignItems="center">
                {recipientName}
              </Grid>
            </Tooltip>
          );
        }
        return recipientName;
      },
    },
    {
      ...datagridColumns.securedPartyDelegateeRecipient,
      cellClassName: (params: GridCellParams) => clsx({ warning: params.row.securedPartyDelegateeModificationWarning }),
      hide: !securedPartyDelegateeEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        const recipientName = getMersOrgDisplayName(params.row.securedPartyDelegateeRecipient) || <i>none</i>;
        if (params.row.securedPartyDelegateeModificationWarning) {
          return (
            <Tooltip
              title="Secured Party Delegatee has been modified from what was originally given in your CSV.
							The org id passed was either not found or affiliated with a recipient from a different originator."
            >
              <Grid container item alignItems="center">
                {recipientName}
              </Grid>
            </Tooltip>
          );
        }
        return recipientName;
      },
    },
    {
      ...datagridColumns.delegateeForTransferRecipient,
      cellClassName: (params: GridCellParams) => clsx({ warning: params.row.securedPartyModificationWarning }),
      hide: !delegateeForTransferEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        const recipientName = getMersOrgDisplayName(params.row.delegateeForTransferRecipient) || <i>none</i>;
        if (params.row.securedPartyModificationWarning) {
          return (
            <Tooltip
              title="Delegatee for Transfers has been modified from what was originally given in your CSV.
							The org id passed was either not found or affiliated with a recipient from a different originator."
            >
              <Grid container item alignItems="center">
                {recipientName}
              </Grid>
            </Tooltip>
          );
        }
        return recipientName;
      },
    },
  ];

  const loanIdsNotFound = Boolean(results?.loanIdsNotFound.length);
  const nonRegistrableLoanIds = Boolean(results?.nonRegistrableLoanIds.length);
  return (
    <Box sx={{ mb: 1, mt: 3 }}>
      <Box sx={{ my: 2 }}>
        {loanIdsNotFound && (
          <Accordion
            sx={{
              ":hover": {
                background: (theme) => `${theme.palette.warning.light}30`,
              },
              background: (theme) => `${theme.palette.warning.light}10`,
              boxShadow: "none",
              fontSize: 12,
              transition: (theme) => `${theme.transitions.duration.standard}`,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="unable-to-load-loan-ids">
              <Grid container item xs={12} alignItems="center">
                <WarningIcon
                  sx={{
                    color: (theme) => theme.palette.warning.light,
                    mr: 1.5,
                  }}
                />
                <Typography variant="body2">Loan ID/MIN(s) not found associated with your organization:</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ul
                css={css`
                  margin: 0;
                  padding-left: 60px;
                `}
              >
                {results?.loanIdsNotFound.map((id) => (
                  <li key={id}>{id}</li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
        {nonRegistrableLoanIds && (
          <Accordion
            sx={{
              ":hover": {
                background: (theme) => `${theme.palette.warning.light}30`,
              },
              background: (theme) => `${theme.palette.warning.light}10`,
              boxShadow: "none",
              fontSize: 12,
              transition: (theme) => `${theme.transitions.duration.standard}`,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="non-registrable-loan-ids">
              <Grid container item xs={12} alignItems="center">
                <WarningIcon
                  sx={{
                    color: (theme) => theme.palette.warning.light,
                    mr: 1.5,
                  }}
                />
                <Typography variant="body2">Non-registrable Loan ID(s):</Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <ul
                css={css`
                  margin: 0px;
                  padding-left: 60px;
                `}
              >
                {results?.nonRegistrableLoanIds.map((id) => (
                  <li key={id}>{id}</li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
      <DataGrid
        autoHeight
        classes={{
          columnHeader: classes.columnHeader,
          main: classes.main,
          root: classes.root,
        }}
        columnBuffer={0}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlay,
        }}
        componentsProps={{
          errorOverlay: { error },
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        error={error}
        hideFooter
        loading={isLoading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        rows={results?.registers ?? []}
        scrollbarSize={0}
      />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  columnHeader: {
    background: "#f5f5f5",
  },
  main: {
    border: "1px solid #F0F0F0",
  },
  root: {
    "& .warning": {
      background: "#ff980030",
    },
  },
}));

export default React.memo(Results);
