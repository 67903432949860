import React, { ReactElement } from "react";

import MuiTooltip from "@mui/material/Tooltip";
import { TooltipProps } from "@mui/material/Tooltip/Tooltip";
import { makeStyles } from "@mui/styles";

interface ITooltipProps extends TooltipProps {
  children: ReactElement<any, any>;
  title: string;
}

const useStylesBootstrap = makeStyles((theme: any) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: ".8rem",
  },
}));

export default function Tooltip(props: ITooltipProps): ReactElement {
  const classes = useStylesBootstrap();
  const { children, ...rest } = props;

  return (
    <MuiTooltip arrow classes={classes} placement="top" {...rest}>
      {props.children}
    </MuiTooltip>
  );
}
