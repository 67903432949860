/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";

import { RootState } from "App/Store";

import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import { IMersOriginator } from "../VaultInterfaces";

interface IMersRecipientSelectorProps {
  onChange: (value: number) => void;
  value: string;
}

const mapStateToProps = (state: RootState) => ({
  mersOriginators: state.mersOriginators.originators,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

export type MersRecipientSelectorProps = IMersRecipientSelectorProps & TypesFromRedux;

export function MersOriginatorSelector(props: MersRecipientSelectorProps): ReactElement {
  function handleChangeClick(event: any) {
    props.onChange(parseInt(event.target.value));
  }

  const recipientSelectStylings: string[] = ["width-full"];
  const recipientOptions: JSX.Element[] = props.mersOriginators.map((mersOriginator: IMersOriginator) => {
    return (
      <TableMenuItem key={mersOriginator.id} value={mersOriginator.id.toString()}>
        {mersOriginator.name}
      </TableMenuItem>
    );
  });

  return (
    <TableSelect
      className={recipientSelectStylings.join(" ")}
      displayEmpty={true}
      value={props.value}
      onChange={handleChangeClick}
      size="small"
      variant="standard"
    >
      {recipientOptions}
    </TableSelect>
  );
}

export default connector(MersOriginatorSelector);
