import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT, EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { PackageRegistrationStatus } from "Common/Enums";

import {
  IMersInitiators,
  IMersOriginator,
  IMersRecipientDetails,
  IVaultMersPackageRecord,
  MersInitiatorTypes,
} from "../VaultInterfaces";

export function getMersOrgs(state: RootState): IMersOriginator[] {
  return state.mersOriginators.originators;
}

/**
 * Get the MersOrg by Id
 * @param state State containing MersOrg data
 * @param Id MersOrg's Id to retrieve
 * @returns MersOrg
 */
function getMersOrgById(state: RootState, Id: string): IMersOriginator {
  const originators = getMersOrgs(state);
  return originators.find((item) => item.mersOrgId === Id) as IMersOriginator;
}

/**
 * Get the MersOrg for view
 * @param state State containing MersOrg data
 * @param Id MersOrg's Id to retrieve
 * @returns MersOrg for view
 */
export function getMersOrgForView(state: RootState, Id: string): string {
  const originator = getMersOrgById(state, Id);
  let name = "";
  if (originator) {
    if (originator.name) {
      name = `${originator.mersOrgId} (${originator.name})`;
    } else {
      name = originator.mersOrgId;
    }
  }
  return name;
}

export function getMersRecipients(state: RootState): IMersRecipientDetails[] {
  return state.mersRecipients.recipients;
}

export function getIsAuthoritativeCopy(mersOrgs: IMersOriginator[], loan: IVaultMersPackageRecord): boolean {
  const mersData = loan.mersData;
  const mersRegistrationStatus = mersData.mersRegistrationStatus;
  const controllerOrgId = mersData.mersInitiators.controller?.orgId;
  const locationOrgId = mersData.mersInitiators.location?.orgId;
  const myOrgId = loan.originatorOrgId;

  return (
    mersRegistrationStatus !== PackageRegistrationStatus.RegistrationFailed &&
    ((myOrgId === controllerOrgId && locationOrgId === EMPTY_MERS_RECIPIENT.orgId) || myOrgId === locationOrgId)
  );
}

export function getMERsInitiatorPositions(
  mersOrgs: IMersOriginator[],
  loan: IVaultMersPackageRecord
): MersInitiatorTypes {
  const initiators = loan.mersData.mersInitiators;
  return getMERsInitiatorPositionsForInitiators(mersOrgs, loan.originatorOrgId, initiators);
}

export function getMERsInitiatorPositionsForInitiators(
  mersOrgs: IMersOriginator[],
  originatorOrgId: string,
  initiators: IMersInitiators
): MersInitiatorTypes {
  if (!initiators) {
    return MersInitiatorTypes.None;
  }
  const myOrgId = originatorOrgId;

  if (!initiators.controller) {
    initiators.controller = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegatee) {
    initiators.delegatee = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegateeForTransfer) {
    initiators.delegateeForTransfer = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.location) {
    initiators.location = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  // controller only
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.Controller;
  }

  // controller + servicer
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.ControllerServicer;
  }

  // controller + DforT
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.ControllerDelegateeForTransfer;
  }

  // location only
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId &&
    initiators.location.orgId === myOrgId
  ) {
    return MersInitiatorTypes.Location;
  }

  // servicer
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.Servicer;
  }

  // servicer + DforT
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.ServicerDelegateeForTransfer;
  }

  // DforT
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.DelegateeForTransfer;
  }

  // none
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId &&
    initiators.location.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.None;
  }

  // All
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.All;
  }

  return MersInitiatorTypes.None;
}

export function initializeMersPositions(mersOrgs: IMersOriginator[], loan: IVaultMersPackageRecord): void {
  const initiators = loan.mersData.mersInitiators;
  if (!initiators) {
    return;
  }

  if (!initiators.controller) {
    initiators.controller = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegatee) {
    initiators.delegatee = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegateeForTransfer) {
    initiators.delegateeForTransfer = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.location) {
    initiators.location = EMPTY_MERS_RECIPIENT_DETAILS;
  }
}
