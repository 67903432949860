import React, { ReactElement } from "react";

import _ from "lodash";
import { useDispatch } from "react-redux";

import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import { Grid, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColDef,
  GridEditRowsModel,
  GridRenderCellParams,
  GridRowId,
} from "@mui/x-data-grid";

import { nonce } from "App/App";

import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";

import {
  IMersRecipientDetails,
  MersRecipientAcceptStatusType,
  MersRecipientStatusType,
} from "Features/Vault/VaultInterfaces";

import { AppDispatch } from "../../../../App/Store";
import MersOriginatorSelector from "../MersOriginatorSelector";
import MersOrgDetailsAutoAcceptSelector from "./MersOrgDetailsAutoAcceptSelector";
import MersRecipientDeleteButton from "./MersRecipientDeleteButton";
import { deleteMersRecipient } from "./mersOrgService";
import { isMersOrgContactEmailValid, isMersOrgIdValid, isMersOrgNameValid } from "./mersOrgValidation";

interface MersRecipientsTableProps {
  isLoading: boolean;
  onRecipientsChange: (recipients: IMersRecipientDetails[]) => void;
  recipients: IMersRecipientDetails[];
}

export function MersRecipientsTable(props: MersRecipientsTableProps): ReactElement {
  const dispatch = useDispatch() as AppDispatch;
  const { isLoading, onRecipientsChange, recipients } = props;

  const [editRowsModel, setEditRowsModel] = React.useState<GridEditRowsModel>({});

  // Column validation
  const handleEditRowsModelChange = React.useCallback((newModel: GridEditRowsModel) => {
    const updatedModel = { ...newModel };
    Object.keys(updatedModel).forEach((id) => {
      if (updatedModel[id].contactEmail) {
        const isValid = isMersOrgContactEmailValid(updatedModel[id].contactEmail.value as string);
        updatedModel[id].contactEmail = {
          ...updatedModel[id].contactEmail,
          error: !isValid,
        };
      }
      if (updatedModel[id].orgId) {
        const isValid = isMersOrgIdValid(updatedModel[id].orgId.value as string);
        updatedModel[id].orgId = {
          ...updatedModel[id].orgId,
          error: !isValid,
        };
      }
      if (updatedModel[id].name) {
        const isValid = isMersOrgNameValid(updatedModel[id].name.value as string);
        updatedModel[id].name = { ...updatedModel[id].name, error: !isValid };
      }
    });
    setEditRowsModel(updatedModel);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "originator",
      flex: 1,
      headerName: "ORIGINATOR",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersOriginatorSelector
              onChange={handleOriginatorChange(params.row.id)}
              value={params.row.mersOriginatorId ? params.row.mersOriginatorId.toString() : ""}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      editable: false,
      field: "orgId",
      flex: 1,
      headerName: "MERS ORG ID",
      sortable: false,
    },
    {
      editable: true,
      field: "name",
      flex: 1,
      headerName: "INSTITUTION",
      sortable: false,
    },
    {
      editable: true,
      field: "contactName",
      flex: 1,
      headerName: "CONTACT NAME",
      sortable: false,
    },
    {
      editable: true,
      field: "contactPhone",
      flex: 1,
      headerName: "PHONE",
      sortable: false,
    },
    {
      editable: true,
      field: "contactEmail",
      flex: 1,
      headerName: "EMAIL",
      sortable: false,
    },
    {
      editable: true,
      field: "deliveryAutoAcceptStatus",
      flex: 1,
      headerName: "DELIVERY  (In)",
      renderCell: (params: GridRenderCellParams) => {
        return getAutoAcceptDropDown(params.row.id, params.row.deliveryAutoAcceptStatus, true);
      },
      sortable: false,
    },
    {
      editable: true,
      field: "transferAutoAcceptStatus",
      flex: 1,
      headerName: "TRANSFERS  (In)",
      renderCell: (params: GridRenderCellParams) => {
        return getAutoAcceptDropDown(params.row.id, params.row.transferAutoAcceptStatus, false);
      },
      sortable: false,
    },
    {
      align: "right",
      field: "deleteButton",
      minWidth: 56,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientDeleteButton mersOrgRecipient={params.row} onDelete={handleDeleteOrgClick} />
          </Grid>
        );
      },
      renderHeader: function renderDeleteHeader() {
        return (
          <Tooltip title="Delete">
            <Grid container item justifyContent="center" alignItems="center">
              <DeleteIcon />
            </Grid>
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      align: "right",
      field: "defaultButton",
      minWidth: 56,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.status === MersRecipientStatusType.Default) {
          return (
            <Tooltip title="Default">
              <Grid container item justifyContent="center" alignItems="center">
                <input type="checkbox" defaultChecked={true} checked={true} readOnly />
              </Grid>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Set to default">
            <Grid container item justifyContent="center" alignItems="center">
              <input
                type="checkbox"
                defaultChecked={false}
                checked={false}
                onChange={() => {
                  handleDefaultChangeClick(params.row.orgId, params.row.mersOriginatorId);
                }}
                className="point-click"
              />
            </Grid>
          </Tooltip>
        );
      },
      renderHeader: function renderDeleteHeader() {
        return (
          <Tooltip title="Default">
            <Grid container item justifyContent="center" alignItems="center">
              <StarIcon />
            </Grid>
          </Tooltip>
        );
      },
      sortable: false,
    },
  ];

  function handleDefaultChangeClick(mersOrgId: string, mersOriginatorId: number) {
    const updatedRecipients = recipients.map((recipient) => {
      if (recipient.mersOriginatorId !== mersOriginatorId) return recipient;
      return {
        ...recipient,
        status: mersOrgId === recipient.orgId ? MersRecipientStatusType.Default : MersRecipientStatusType.Active,
      };
    });
    onRecipientsChange(updatedRecipients);
  }

  function handleDeleteOrgClick(recipient: IMersRecipientDetails) {
    dispatch(deleteMersRecipient(recipient));
  }

  const handleOriginatorChange = (id: GridRowId) => (value: number) => {
    const updatedData: IMersRecipientDetails[] = _.map(recipients, (org: IMersRecipientDetails) => {
      if (org.id !== id) {
        return org;
      }
      return {
        ...org,
        mersOriginatorId: value,
      };
    });
    onRecipientsChange(updatedData);
  };

  function getAutoAcceptDropDown(
    id: GridRowId,
    autoAcceptStatusValue: MersRecipientAcceptStatusType,
    isDelivery: boolean
  ) {
    return (
      <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
        <MersOrgDetailsAutoAcceptSelector
          currentOption={autoAcceptStatusValue}
          isDelivery={isDelivery}
          onSelectionChange={handleChange}
          rowId={id}
        />
      </Grid>
    );
  }

  function handleChange(option: MersRecipientAcceptStatusType, id: GridRowId, isDelivery: boolean) {
    const updatedData: IMersRecipientDetails[] = _.map(recipients, (org: IMersRecipientDetails) => {
      if (org.id !== id) {
        return org;
      }
      if (isDelivery) {
        return {
          ...org,
          deliveryAutoAcceptStatus: option,
        };
      } else {
        return {
          ...org,
          transferAutoAcceptStatus: option,
        };
      }
    });
    onRecipientsChange(updatedData);
  }

  function handleCellEditCommit(params: GridCellEditCommitParams) {
    const { field, id, value } = params;
    const updatedData: IMersRecipientDetails[] = _.map(recipients, (org: IMersRecipientDetails) => {
      if (org.id !== id) {
        return org;
      }
      return {
        ...org,
        [field]: value,
      };
    });
    onRecipientsChange(updatedData);
  }

  return (
    <DataGrid
      autoHeight
      columnBuffer={0}
      columns={columns}
      components={{
        LoadingOverlay: LoadingOverlay,
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      hideFooter
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onCellEditCommit={handleCellEditCommit}
      rows={recipients}
      scrollbarSize={0}
      editRowsModel={editRowsModel}
      onEditRowsModelChange={handleEditRowsModelChange}
    />
  );
}

export default MersRecipientsTable;
