import React, { ReactElement } from "react";

import BackIcon from "@mui/icons-material/ArrowBack";
import RetryIcon from "@mui/icons-material/Replay";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { IHttpErrorState } from "../../Types/EVaultAppTypes";

interface ErrorOverlayProps {
  error?: IHttpErrorState;
  onGoBack?: () => void;
  onRetry?: () => void;
}

const ErrorOverlay = ({ error, onGoBack, onRetry }: ErrorOverlayProps): ReactElement => {
  const errorMessage = "An error occurred while loading search results.";
  const header = `Error${error && error.status ? " - " + error.status : ""}`;
  return (
    <Grid
      container
      sx={{
        background: (theme) => `${theme.palette.error.main}20`,
        color: (theme) => theme.palette.error.main,
        height: "100%",
        minHeight: 200,
      }}
    >
      <Grid container item xs={12} direction="column" alignItems="center" justifyContent="center" rowSpacing={0}>
        <Box sx={{ fontSize: 14, mb: 2 }}>
          <Typography
            sx={{
              color: (theme) => theme.palette.error.main,
              textAlign: "center",
            }}
            variant="h3"
          >
            {header}
          </Typography>
          {errorMessage}
        </Box>
        <Box>
          {onRetry && (
            <Button variant="outlined" onClick={onRetry} startIcon={<RetryIcon />} size="small" sx={{ mx: 1 }}>
              Retry
            </Button>
          )}
          {onGoBack && (
            <Button variant="outlined" onClick={onGoBack} startIcon={<BackIcon />} size="small" sx={{ mx: 1 }}>
              Go Back
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ErrorOverlay;
