import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "../../../Common/EVaultAppConstants";
import { createTabKey } from "../../../Common/Utilities";
import { addTab } from "../../Tabs/Actions";
import { VaultViewBatchOperationsTabTypes } from "../VaultInterfaces";
import { IMersBatch } from "./BatchOperationsInterfaces";
import { IBatchOperationsSearchApiParams, fetchBatchOperations, updateSearchBarParams } from "./BatchOperationsSlice";
import { getBatchOperationsUrl } from "./batchOperationsAdapter";

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const data = state.batchOperations.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    await dispatch(fetchBatchOperationsAction());
  };
}

export function fetchBatchOperationsAction(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    if (resourceUrl) {
      dispatch(fetchBatchOperations(resourceUrl));
    } else {
      const searchCriteria = state.batchOperations.searchBarParams.data;
      const sortByParams = state.batchOperations.sortByParams;
      const params: IBatchOperationsSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        ...sortByParams,
      };

      const url = getBatchOperationsUrl(params);
      dispatch(fetchBatchOperations(url));
    }
  };
}

/**
 * Dispatches the open tab call to open batch status details
 */
export function goToBatchOperationsDetailsTab(batch: IMersBatch): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-BatchOperations-";
    const key: string = createTabKey(keyRoot, VaultViewBatchOperationsTabTypes.BatchOperationsStatusDetails, {
      batchId: batch.id,
    });
    const tabData = {
      batch,
      batchId: batch.id,
      closeable: true,
      key,
      loanType: constants.BATCH_OPERATIONS,
      title: "Batch Details",
      type: VaultViewBatchOperationsTabTypes.BatchOperationsStatusDetails,
    };
    return dispatch(addTab(tabData));
  };
}
