/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip } from "@mui/material";

import { ILoanTabDetails } from "Features/Tabs/Tabs";

interface ITabbedPanelHeaderProps {
  closeable?: boolean;
  icon?: JSX.Element;
  id: string;
  noteTip?: string;
  selected?: boolean;
  onTabClick: (tab: ILoanTabDetails) => void;
  onTabClosed?: (tab: ILoanTabDetails) => void;
  tab: ILoanTabDetails;
  title: string;
}

const tabStyle = (selected?: boolean) => css`
  background-color: #e8e8e8;
  height: 36px;
  position: relative;
  width: 200px;
  ${selected &&
  `
		background-color: #FFFFFF;
		color: unset;
		height: 37px;
  `}
  ${!selected &&
  `
  	color: #A0A0A0;
  	cursor: pointer;
		&:hover {
			background-color: #f8f8f8
		}
  `}
`;

const iconStyle = css`
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
  user-select: none;
`;

export default function TabbedPanelTab(props: ITabbedPanelHeaderProps): ReactElement {
  const { closeable, icon, id, noteTip, onTabClick, onTabClosed, selected, tab, title } = props;
  const activeClass = selected ? "tabbed-panel-tab active" : "tabbed-panel-tab";
  const closeButton = (
    <IconButton onClick={handleCloseClick} size="small">
      <CloseIcon
        css={css`
          font-size: 14px;
        `}
      />
    </IconButton>
  );

  function handleCloseClick(event: any) {
    event.stopPropagation();
    if (onTabClosed) {
      onTabClosed(tab);
    }
    return false;
  }

  function handleClick() {
    if (onTabClick) {
      onTabClick(tab);
    }
  }

  return (
    <div css={tabStyle(selected)} key={id} className={activeClass} onClick={handleClick}>
      {selected && (
        <div
          css={css`
            background-color: #7dc9f0;
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
          `}
        />
      )}
      <Tooltip title={noteTip || ""} placement="top-start">
        <div
          className="tabbed-panel-tab-shape"
          css={css`
            align-items: center;
            border-right: solid 1px #afafaf;
            display: flex;
            height: 100%;
            padding: 0 8px;
            width: 100%;
          `}
        >
          <div
            className="tabbed-panel-tab-content"
            css={css`
              align-items: center;
              color: unset;
              display: flex;
              font-size: 15px;
              height: 100%;
              justify-content: space-between;
              user-select: none;
              width: 100%;
            `}
          >
            <div css={iconStyle}>{icon}</div>
            <div
              className="tabbed-panel-tab-content-title noselect"
              css={css`
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                user-select: none;
                white-space: nowrap;
              `}
            >
              {title}
            </div>
            <div
              css={css`
                flex: 1;
              `}
            />
            {(closeable || tab.closeable) && closeButton}
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
