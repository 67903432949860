import React, { ReactElement } from "react";

import _ from "lodash";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import {
  getErrorMessage,
  getMersTransactionStatusFromString,
  getMersTransactionTypeFromString,
} from "Common/Utilities";

import Loading from "Components/Loading";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { getMERSPackageLastResult } from "Adapters/Mers/mersAdapter";
import { cancelPendingDeliveryMERSPackage, deliverMERSPackage } from "Adapters/Mers/mersDeliveryAdapter";
import {
  modificationReversal,
  modifyMERSPackage,
  noteStatusUpdateMERSPackage,
  registerMERSPackage,
  removeSecuredParty,
  reverseRegisterMERSPackage,
  rightsholderUpdateMERSPackage,
} from "Adapters/Mers/mersRegistrationAdapter";
import {
  cancelPendingTransferMERSPackage,
  securedPartyAction,
  transferMERSPackage,
} from "Adapters/Mers/mersTransferAdapter";

import {
  IMersBatchTransactionRequest,
  IMersDeliveryTransaction,
  IMersRecipientDetails,
  IMersRegisterTransaction,
  IMersReverseRegistrationTransaction,
  IMersSecuredPartyTransaction,
  IMersTransaction,
  IMersTransactionResponse,
  IMersTransferTransaction,
  IMersUpdateChangeDataTransaction,
  IMersUpdateChangeStatusTransaction,
  MersModificationTypes,
  MersTransactionStatus,
  MersTransactionType,
  QueueAction,
} from "../VaultInterfaces";

interface IVaultMersTransactionResultsPopupProps {
  onClosed: (type: MersTransactionType) => void;
  open: boolean;
  request: IMersBatchTransactionRequest;
}

interface IContentItem {
  errors: string[];
  loanNumber: string;
  status: MersTransactionStatus;
  type: MersTransactionType;
  warnings: string[];
}

interface IVaultMersTransactionResultsPopupState {
  error: Error | null;
  closable: boolean;
  contentItems: IContentItem[];
  loading: boolean;
}

export class VaultMersTransactionResultsModalPopup extends React.Component<
  IVaultMersTransactionResultsPopupProps,
  IVaultMersTransactionResultsPopupState
> {
  private pendingRequestCount = 0;

  constructor(props: IVaultMersTransactionResultsPopupProps) {
    super(props);

    this.state = {
      closable: false,
      contentItems: [],
      error: null,
      loading: true,
    };

    // One time binding
    this.onCloseClick = this.onCloseClick.bind(this);
    this.getStatusObjectByMersTransactionTypeAndStatus = this.getStatusObjectByMersTransactionTypeAndStatus.bind(this);
    this.handleMersTransactionResponse = this.handleMersTransactionResponse.bind(this);
    this.finishProcessingTransaction = this.finishProcessingTransaction.bind(this);
    this.getOrgId = this.getOrgId.bind(this);
  }

  public componentDidUpdate(prevProps: IVaultMersTransactionResultsPopupProps): void {
    if (this.props.open && prevProps.request !== this.props.request) {
      this.setState({
        closable: false,
        contentItems: [],
        error: null,
        loading: true,
      });
      this.pendingRequestCount = this.props.request.packages.length;
      this.executeRequest();
    }
  }

  private onCloseClick() {
    if (this.props.onClosed) {
      this.props.onClosed(this.props.request.type);
    }
  }

  /**
   * Get the title text for the component via the transaction's type
   * @param type The type of the transaction
   * @returns the title from the type
   */
  private getTitleFromTransactionType(type: MersTransactionType): string {
    let result: string;
    switch (type) {
      case MersTransactionType.Registration:
      case MersTransactionType.LastResultRegistration:
        result = "Registration results";
        break;
      case MersTransactionType.ReverseRegistration:
      case MersTransactionType.LastResultReverseRegistration:
        result = "Reverse registration results";
        break;
      case MersTransactionType.Delivery:
      case MersTransactionType.LastResultDelivery:
        result = "Delivery results";
        break;
      case MersTransactionType.Transfer:
      case MersTransactionType.TransferConfirmation:
      case MersTransactionType.LastResultTransfer:
        result = "Transfer results";
        break;

      case MersTransactionType.CancelTransfer:
        result = "Cancel pending transfer results";
        break;
      case MersTransactionType.CancelDelivery:
        result = "Cancel pending delivery results";
        break;

      case MersTransactionType.ChangeStatus:
      case MersTransactionType.ChangeData:
        result = "Update results";
        break;

      case MersTransactionType.DelegateeForTransfer:
        result = "Delegatee for transfers results";
        break;

      case MersTransactionType.SecuredParty:
        result = "Secured party results";
        break;

      case MersTransactionType.Unknown:
      default:
        result = "[Unknown MERS transaction type]";
        break;
    }

    return result;
  }

  private getOrgId(recipient?: IMersRecipientDetails): string | undefined {
    if (recipient && recipient.id && recipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
      return recipient.orgId;
    }
    return undefined;
  }

  /**
   * Make the Mers transaction request
   * @returns {void}
   */
  private executeRequest() {
    switch (this.props.request.type) {
      case MersTransactionType.Registration:
        _.each(this.props.request.packages as IMersRegisterTransaction[], (request: IMersRegisterTransaction) => {
          registerMERSPackage(request)
            .then((response) => {
              this.handleMersTransactionResponse(response, request.loanNumber);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Registration error", error);
              console.dir(error);
            });
        });
        break;

      case MersTransactionType.ReverseRegistration:
        _.each(
          this.props.request.packages as IMersReverseRegistrationTransaction[],
          (mersPackage: IMersReverseRegistrationTransaction) => {
            reverseRegisterMERSPackage(mersPackage)
              .then((response: IMersTransactionResponse) => {
                if (this.props.request && this.props.request.successCallback) {
                  this.props.request.successCallback(response);
                }
                this.handleMersTransactionResponse(response);
              })
              .catch((error) => {
                this.finishProcessingTransaction(error);
                console.error("Reverse registration error", error);
                console.dir(error);
              });
          }
        );
        break;

      case MersTransactionType.LastResultRegistration:
      case MersTransactionType.LastResultReverseRegistration:
      case MersTransactionType.LastResultTransfer:
      case MersTransactionType.LastResultDelivery:
        _.each(this.props.request.packages as IMersTransaction[], (mersPackage: IMersTransaction) => {
          getMERSPackageLastResult(mersPackage.packageId)
            .then((response) => {
              this.handleMersTransactionResponse(response);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Last result retrieval error", error);
              console.dir(error);
            });
        });
        break;

      case MersTransactionType.CancelTransfer:
        _.each(this.props.request.packages as IMersTransaction[], (mersPackage: IMersTransaction) => {
          cancelPendingTransferMERSPackage(mersPackage.packageId)
            .then((response) => {
              this.handleMersTransactionResponse(response);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Cancel transfer error", error);
              console.dir(error);
            });
        });
        break;

      case MersTransactionType.CancelDelivery:
        _.each(this.props.request.packages as IMersTransaction[], (mersPackage: IMersTransaction) => {
          cancelPendingDeliveryMERSPackage(mersPackage.packageId).then(
            (response) => {
              this.handleMersTransactionResponse(response);
            },
            (error) => {
              this.finishProcessingTransaction(error);
              console.error("Cancel delivery error", error);
              console.dir(error);
            }
          );
        });
        break;

      case MersTransactionType.Transfer:
        _.each(this.props.request.packages as IMersTransferTransaction[], (mersPackage: IMersTransferTransaction) => {
          // don't send the none dropdown option object to the server
          if (
            mersPackage.transferControlRecipient &&
            mersPackage.transferControlRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferControlRecipient = undefined;
          }

          if (
            mersPackage.transferLocationRecipient &&
            mersPackage.transferLocationRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferLocationRecipient = undefined;
          }

          if (
            mersPackage.transferServicerRecipient &&
            mersPackage.transferServicerRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferServicerRecipient = undefined;
          }

          if (
            mersPackage.transferSubServicerRecipient &&
            mersPackage.transferSubServicerRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferSubServicerRecipient = undefined;
          }

          if (
            mersPackage.transferSecuredPartyRecipient &&
            mersPackage.transferSecuredPartyRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferSecuredPartyRecipient = undefined;
          }

          if (
            mersPackage.transferSecuredPartyDelegateeRecipient &&
            mersPackage.transferSecuredPartyDelegateeRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferSecuredPartyDelegateeRecipient = undefined;
          }

          if (
            mersPackage.transferDelegateeForTransfersRecipient &&
            mersPackage.transferDelegateeForTransfersRecipient.id === EMPTY_MERS_RECIPIENT_DETAILS.id
          ) {
            mersPackage.transferDelegateeForTransfersRecipient = undefined;
          }

          if (mersPackage.skipDeliveryFor && mersPackage.skipDeliveryFor.length === 0) {
            mersPackage.skipDeliveryFor = undefined;
          }

          transferMERSPackage(mersPackage)
            .then((response) => {
              this.handleMersTransactionResponse(response);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Transfer error", error);
              console.dir(error);
            });
        });
        break;
      case MersTransactionType.Delivery:
        _.each(this.props.request.packages as IMersDeliveryTransaction[], (mersPackage: IMersDeliveryTransaction) => {
          deliverMERSPackage(mersPackage)
            .then((response) => {
              this.handleMersTransactionResponse(response);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Delivery Error", error);
              console.dir(error);
            });
        });
        break;

      case MersTransactionType.ChangeStatus:
        _.each(
          this.props.request.packages as IMersUpdateChangeStatusTransaction[],
          (mersPackage: IMersUpdateChangeStatusTransaction) => {
            noteStatusUpdateMERSPackage(mersPackage)
              .then((response) => {
                this.handleMersTransactionResponse(response);
              })
              .catch((error) => {
                this.finishProcessingTransaction(error);
                console.error("Change status error", error);
                console.dir(error);
              });
          }
        );
        break;

      case MersTransactionType.ChangeData:
        _.each(
          this.props.request.packages as IMersUpdateChangeDataTransaction[],
          (mersPackage: IMersUpdateChangeDataTransaction) => {
            if (mersPackage.rightsholder) {
              rightsholderUpdateMERSPackage(mersPackage)
                .then((response) => {
                  this.handleMersTransactionResponse(response);
                })
                .catch((error) => {
                  this.finishProcessingTransaction(error);
                  console.error("Transfer error", error);
                  console.dir(error);
                });
            } else {
              if (
                mersPackage.modificationType === MersModificationTypes.PaperReversal ||
                mersPackage.modificationType === MersModificationTypes.ElectronicReversal ||
                mersPackage.modificationType === MersModificationTypes.Reversal
              ) {
                modificationReversal(mersPackage.packageId, mersPackage.min)
                  .then((response) => {
                    this.handleMersTransactionResponse(response);
                  })
                  .catch((error) => {
                    this.finishProcessingTransaction(error);
                    console.error("Change status error", error);
                    console.dir(error);
                  });
              } else if (mersPackage.modificationType) {
                modifyMERSPackage(mersPackage.packageId, mersPackage.min, mersPackage.modificationType)
                  .then((response) => {
                    this.handleMersTransactionResponse(response);
                  })
                  .catch((error) => {
                    this.finishProcessingTransaction(error);
                    console.error("Transfer error", error);
                    console.dir(error);
                  });
              }
            }
          }
        );
        break;
      case MersTransactionType.SecuredParty:
        _.each(
          this.props.request.packages as IMersSecuredPartyTransaction[],
          (mersPackage: IMersSecuredPartyTransaction) => {
            if (mersPackage.queueAction === QueueAction.Remove) {
              removeSecuredParty(mersPackage)
                .then((response) => {
                  this.handleMersTransactionResponse(response);
                })
                .catch((error) => {
                  this.finishProcessingTransaction(error);
                  console.error("Remove secured party error", error);
                  console.dir(error);
                });
            } else {
              securedPartyAction(mersPackage)
                .then((response) => {
                  this.handleMersTransactionResponse(response);
                })
                .catch((error) => {
                  this.finishProcessingTransaction(error);
                  console.error("Secured party action error", error);
                  console.dir(error);
                });
            }
          }
        );
        break;
      default:
        console.error("MersTransactionRequests> Unknown request made");
        break;
    }
  }

  private getStatusObjectByMersTransactionTypeAndStatus(
    type: MersTransactionType,
    status: MersTransactionStatus
  ): JSX.Element | null {
    let result: JSX.Element | null;
    switch (type) {
      case MersTransactionType.Delivery:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">eNote delivery failed</span>;
            break;

          case MersTransactionStatus.DeliveryNotApproved:
            result = <span className="title-fail">eNote delivery not approved</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">eNote delivery in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">eNote delivery complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">eNote delivery unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Registration:
      case MersTransactionType.ReverseRegistration:
      case MersTransactionType.ChangeStatus:
      case MersTransactionType.ChangeData:
      case MersTransactionType.SecuredParty:
      case MersTransactionType.DelegateeForTransfer:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">In progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Transfer:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Transfer failed</span>;
            break;

          case MersTransactionStatus.TransferNotApproved:
            result = <span className="title-fail">Transfer not approved</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Transfer in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Transfer complete</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Transfer unknown</span>;
            break;
        }
        break;

      case MersTransactionType.DeliveryConfirmation:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Cancel pending delivery failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Delivery cancel in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Delivery cancelled</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Delivery unknown</span>;
            break;
        }
        break;

      case MersTransactionType.TransferConfirmation:
        switch (status) {
          case MersTransactionStatus.Failed:
          case MersTransactionStatus.Rejected:
          case MersTransactionStatus.Timeout:
            result = <span className="title-fail">Cancel pending transfer failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Initiated:
            result = <span className="title-success">Transfer cancel in progress</span>;
            break;

          case MersTransactionStatus.Success:
            result = <span className="title-success">Transfer cancelled</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Transfer unknown</span>;
            break;
        }
        break;

      case MersTransactionType.Unknown:
      default:
        result = <span className="title-fail">[Unknown]</span>;
        break;
    }

    return result;
  }

  /**
   * Handle the response result from the Mers transaction request
   * @param responses The mers transaction responses
   * @param loanNumber The loan number
   * @returns {void}
   */
  private handleMersTransactionResponse(
    responses: IMersTransactionResponse[] | IMersTransactionResponse,
    loanNumber = ""
  ) {
    let results;

    if (Array.isArray(responses)) {
      results = responses;
    } else {
      results = [responses];
    }

    _.each(results, (response) => {
      const responseLoanNumber = loanNumber || response.loanNumber || "[Unknown]";

      // TODO errors need to be fixed from the backend and handled in one way here - RW
      if (null != response.error && 0 !== response.error.length) {
        // An error occurred

        let standardizedError: string;
        try {
          const errorObject = JSON.parse(response.error) as { error: string };
          standardizedError = errorObject.error;
        } catch (e) {
          console.log("Error response was not a valid JSON. Using response as literal.");
          standardizedError = response.error;
        }

        const newContentItem: IContentItem = {
          errors: [standardizedError],
          loanNumber: responseLoanNumber,
          status: MersTransactionStatus.Failed,
          type: MersTransactionType.Unknown,
          warnings: [],
        };

        this.setState({
          contentItems: _.uniq(this.state.contentItems.concat(newContentItem)),
        });
      } else if (null != response.errors && 0 !== response.errors.length) {
        // An error occurred

        const newContentItem: IContentItem = {
          errors: response.errors,
          loanNumber: responseLoanNumber,
          status: MersTransactionStatus.Failed,
          type: getMersTransactionTypeFromString(response.type),
          warnings: [],
        };

        this.setState({
          contentItems: _.uniq(this.state.contentItems.concat(newContentItem)),
        });
      }

      if (response.type === "Transfer" && response.status === "TransferNotApproved") {
        const newContentItem: IContentItem = {
          errors: ["Transfer Not Approved"],
          loanNumber: responseLoanNumber,
          status: MersTransactionStatus.TransferNotApproved,
          type: MersTransactionType.Transfer,
          warnings: [],
        };

        this.setState({
          contentItems: _.uniq(this.state.contentItems.concat(newContentItem)),
        });
      }

      _.each(response.transactionResults, (result) => {
        const errors: string[] = [];
        const warnings: string[] = [];

        const resultLoanNumber = loanNumber || result.loanNumber || "[Unknown]";

        _.each(result.statusDetails, (detail) => {
          switch (detail.type.toLowerCase()) {
            case "error":
              errors.push(detail.description ? detail.description : detail.name);
              break;

            case "warning":
              warnings.push(detail.description ? detail.description : detail.name);
              break;

            default:
              break;
          }
        });

        const newContentItem: IContentItem = {
          errors,
          loanNumber: resultLoanNumber,
          status: getMersTransactionStatusFromString(result.status),
          type: getMersTransactionTypeFromString(result.type),
          warnings,
        };

        this.setState({
          contentItems: _.uniq(this.state.contentItems.concat(newContentItem)),
        });
      });

      this.finishProcessingTransaction();
    });
  }

  /**
   * A Mers request's processing has been completed
   * @param error Error (Optional)
   * @returns {void}
   */
  private finishProcessingTransaction(error: Error | null = null) {
    if (0 === --this.pendingRequestCount) {
      this.setState({
        closable: true,
        error: error,
        loading: false,
      });
    }
  }

  public render(): ReactElement {
    const { open } = this.props;
    const groupedResults = _.groupBy(this.state.contentItems, "loanNumber");

    const items: JSX.Element[] = [];

    _.each(groupedResults, (resultStack, loanNumber) => {
      const itemNumber = items.length + 1;
      let parentStatus: JSX.Element | null = null;
      const parentErrors: JSX.Element[] = [];
      const parentWarnings: JSX.Element[] = [];
      const children: JSX.Element[] = [];

      _.each(resultStack, (result) => {
        if (1 === resultStack.length || MersTransactionType.Transfer !== result.type) {
          parentStatus = this.getStatusObjectByMersTransactionTypeAndStatus(result.type, result.status);

          _.each(result.errors, (error, index) => {
            parentErrors.push(<li key={"merstransaction-item-" + itemNumber + "- error - " + index}>{error}</li>);
          });
          _.each(result.warnings, (warning, index) => {
            parentWarnings.push(<li key={"merstransaction-item-" + itemNumber + "-warning-" + index}>{warning}</li>);
          });
        } else {
          // We have a 'Transfer' that is grouped with another Mers transaction -- The transfer is a child
          const childNumber = children.length + 1;
          const childStatus = this.getStatusObjectByMersTransactionTypeAndStatus(result.type, result.status);
          const childErrors: JSX.Element[] = [];
          const childWarnings: JSX.Element[] = [];

          _.each(result.errors, (error, index) => {
            childErrors.push(<li key={"merstransaction-item-" + itemNumber + "- error - " + index}>{error}</li>);
          });
          _.each(result.warnings, (warning, index) => {
            childWarnings.push(<li key={"merstransaction-item-" + itemNumber + "-warning-" + index}>{warning}</li>);
          });

          children.push(
            <div
              key={"merstransaction-item-" + itemNumber + "-child-" + childNumber}
              className="modalpopup-merstransactionresults-dialog-content-item"
            >
              <div className="modalpopup-merstransactionresults-dialog-content-item-title">
                <span>Loan # / MIN: {loanNumber}, </span>
                {childStatus}
              </div>
              <div>
                {0 < childErrors.length && <span className="italicized">Error(s): </span>}
                <ul>{childErrors}</ul>
                {0 < childWarnings.length && <span className="italicized">Warnings(s): </span>}
                <ul>{childWarnings}</ul>
              </div>
            </div>
          );
        }
      });

      items.push(
        <div
          key={"merstransaction-item-" + itemNumber}
          className="modalpopup-merstransactionresults-dialog-content-item"
        >
          <div className="modalpopup-merstransactionresults-dialog-content-item-title">
            <span className="bolded">Loan # / MIN: </span>
            <span>{loanNumber}, </span>
            {parentStatus}
          </div>
          <div className="modalpopup-merstransactionresults-dialog-content-item-content">
            {0 < parentErrors.length && <span className="italicized">Error(s): </span>}
            <ul>{parentErrors}</ul>
            {0 < parentWarnings.length && <span className="italicized">Warnings(s): </span>}
            <ul>{parentWarnings}</ul>
            {children}
          </div>
        </div>
      );
    });

    // Content can be error or items
    let content;
    if (this.state.error) {
      content = getErrorMessage(this.state.error);
    } else {
      content = items;
    }
    content = (
      <div>
        {this.state.loading && <Loading />}
        {content}
      </div>
    );

    return (
      <ModalPopupAlert
        confirmationButtonText="OK"
        disabled={!this.state.closable}
        onClose={this.onCloseClick}
        overlayCloseClick={this.state.closable}
        title={this.props.request ? this.getTitleFromTransactionType(this.props.request.type) : ""}
        content={content}
        open={open}
        size="sm"
      />
    );
  }
}
