/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect } from "react";

import { css } from "@emotion/react";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { Toolbar } from "@mui/material";

import { initializeDispatch } from "Common/apiManager";

import LoadingPage from "Components/LoadingPage";

import InvalidAntiforgeryToken from "Pages/Security/InvalidAntiforgeryToken";
import PageTimeout from "Pages/Security/PageTimeout";
import Vault from "Pages/Vault";

import { fetchChannels } from "Features/Channels/ChannelSlice";
import AppBar from "Features/Navigation/AppBar";
import SideMenu from "Features/Navigation/CollapsibleSideMenu/CollapsibleSideMenu";
import Footer from "Features/Navigation/Footer";
import { fetchProfile, profileStateSelector } from "Features/Profile/ProfileSlice";
import { getAuthorizedVaultMenuOptions } from "Features/Vault/navigationService";

import { AppDispatch, RootState } from "./Store";

const mapStateToProps = (state: RootState) => ({
  channels: state.channels,
  profile: profileStateSelector(state),
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux;

export function Main(props: Props): ReactElement {
  const { channels, profile } = props;
  const dispatch = useDispatch() as AppDispatch;

  useEffect(() => {
    initializeDispatch(dispatch);
    dispatch(fetchProfile());
    dispatch(fetchChannels());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      const validVaultPaths = getAuthorizedVaultMenuOptions(profile);
      if (validVaultPaths.length === 0 && !profile.isLoading) {
        window.location.href = "/app/unauthorized";
      }
      if (!profile.authorized) {
        window.location.href = "/login";
      }
    }
  }, [dispatch, profile]);

  if (!profile || profile.isLoading || getAuthorizedVaultMenuOptions(profile).length === 0 || !channels.initialized) {
    return <LoadingPage />;
  }

  return (
    <>
      <AppBar profile={profile.profile} />
      <div
        css={css`
          display: flex;
          height: calc(100vh - 30px);
          width: 100%;
        `}
      >
        <nav>
          <SideMenu />
        </nav>
        <main
          css={css`
            bottom: 94px;
            flex-grow: 1;
            overflow-wrap: break-word;
          `}
          id="admin-view-content"
        >
          <Toolbar />
          <div
            className="flex-container"
            css={css`
              height: calc(100vh - 94px);
            `}
          >
            <Vault />
          </div>
        </main>
      </div>
      <Footer />
      <PageTimeout />
      <InvalidAntiforgeryToken />
    </>
  );
}

export default connector(Main);
