import * as React from "react";

import Ellipses from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

interface MersOrgMainContextMenuProps {
  onAddMersOrg: () => void;
}

/**
 * Creates the context menu that will be used for the global mers org context menu
 */
function MersOrgMainContextMenu(props: MersOrgMainContextMenuProps): JSX.Element {
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  /**
   * Sets the menu to open.
   */
  function handleMenuOpen(event: any) {
    setMenuOpen(true);
    setAnchorEl(event.currentTarget);
  }

  /**
   * Sets the menu to closed.
   */
  function handleMenuClose() {
    setMenuOpen(false);
  }

  function handleAddMersOrg() {
    handleMenuClose();
    props.onAddMersOrg();
  }

  const menuContents: React.ReactElement[] = [
    <MenuItem key="add-mers-org" onClick={handleAddMersOrg} aria-label="Add mers org">
      Add Mers Org
    </MenuItem>,
  ];

  return (
    <>
      <IconButton size="small" aria-label="Menu button" onClick={handleMenuOpen}>
        <Ellipses />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuList>{menuContents}</MenuList>
      </Menu>
    </>
  );
}

export default MersOrgMainContextMenu;
