import React, { ReactElement } from "react";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  logo: {
    height: 30,
  },
}));

export default function Logo(): ReactElement {
  const classes = useStyles();

  return (
    <svg className={classes.logo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116.66 29.84">
      <g>
        <path
          d="M11.03,25.07c1.11,0,1.9-0.11,2.36-0.33c0.46-0.22,0.69-0.64,0.69-1.26c0-0.49-0.3-0.92-0.9-1.28
		c-0.6-0.37-1.51-0.78-2.73-1.24c-0.95-0.35-1.81-0.72-2.58-1.1S6.44,19.03,5.9,18.5c-0.54-0.53-0.96-1.16-1.26-1.89
		c-0.3-0.73-0.45-1.61-0.45-2.65c0-2.01,0.75-3.59,2.24-4.76s3.54-1.75,6.15-1.75c1.3,0,2.55,0.11,3.74,0.35
		c1.19,0.23,2.14,0.48,2.85,0.75l-1.06,4.72c-0.71-0.24-1.47-0.46-2.3-0.65c-0.83-0.19-1.76-0.28-2.79-0.28
		c-1.9,0-2.85,0.53-2.85,1.59c0,0.24,0.04,0.46,0.12,0.65c0.08,0.19,0.24,0.37,0.49,0.55c0.24,0.18,0.58,0.37,1,0.57
		c0.42,0.21,0.96,0.43,1.61,0.68c1.33,0.49,2.43,0.98,3.3,1.46c0.87,0.48,1.55,1,2.06,1.55c0.5,0.56,0.85,1.17,1.06,1.85
		c0.2,0.68,0.31,1.46,0.31,2.36c0,2.11-0.79,3.71-2.38,4.79C16.13,29.46,13.89,30,10.99,30c-1.9,0-3.48-0.16-4.74-0.49
		c-1.26-0.33-2.14-0.6-2.62-0.81l1.02-4.93c1.03,0.41,2.09,0.73,3.17,0.96C8.89,24.96,9.97,25.07,11.03,25.07z"
          fill="white"
        />
      </g>
      <path
        d="M94.44,14.02l4.26-6h6.27l-7.43,10.41c1.7,1.96,3.22,3.9,4.54,5.83c1.32,1.93,2.38,3.65,3.18,5.17h-6.5
	c-0.22-0.41-0.49-0.92-0.82-1.53c-0.33-0.61-0.69-1.25-1.1-1.91c-0.41-0.67-0.83-1.23-1.27-1.69c-0.44-0.46-0.88-0.88-1.31-1.26
	c-0.41,0.35-0.83,0.74-1.25,1.17c-0.42,0.42-0.83,0.96-1.21,1.59c-0.38,0.64-0.76,1.27-1.12,1.89c-0.37,0.62-0.69,1.21-0.98,1.75
	h-6.17c0.34-0.67,0.79-1.47,1.35-2.4c0.56-0.93,1.17-1.89,1.84-2.88c0.67-0.99,1.39-1.95,2.16-2.88c0.78-0.93,1.54-1.81,2.29-2.63
	c-1.33-1.89-2.61-3.71-3.84-5.45c-1.23-1.74-2.44-3.47-3.64-5.18h6.55L94.44,14.02z"
        fill="white"
      />
      <g>
        <path
          d="M49.32,22.39c0,1.14,0.11,2.16,0.35,3.07c0.23,0.91,0.66,1.68,1.3,2.32s1.51,1.13,2.6,1.49
		c1.1,0.35,2.53,0.54,4.29,0.57l0.85-5.01c-0.73-0.05-1.32-0.18-1.75-0.37c-0.43-0.19-0.77-0.43-1-0.73
		c-0.23-0.3-0.39-0.66-0.47-1.1c-0.07-0.4-0.11-0.86-0.11-1.35V7.45c0-1.14-0.11-2.16-0.35-3.07c-0.23-0.91-0.66-1.68-1.3-2.32
		c-0.64-0.64-1.51-1.13-2.6-1.49c-1.1-0.35-2.53-0.54-4.29-0.57h-0.63c0,0,3.14,2.33,3.11,8.41L49.32,22.39z"
          fill="white"
        />
      </g>
      <path
        d="M61.17,18.89c0-1.9,0.29-3.56,0.88-4.99c0.58-1.43,1.35-2.61,2.3-3.56s2.04-1.67,3.28-2.16
	c1.23-0.49,2.5-0.73,3.81-0.73c3.04,0,5.44,0.93,7.2,2.79c1.76,1.86,2.64,4.59,2.64,8.2c0,0.35-0.01,0.74-0.04,1.16
	c-0.03,0.42-0.06,0.79-0.08,1.12H67.39c0.14,1.25,0.72,2.24,1.75,2.97c1.03,0.73,2.41,1.1,4.15,1.1c1.11,0,2.2-0.1,3.28-0.31
	c1.07-0.2,1.95-0.45,2.62-0.75l0.81,4.92c-0.33,0.16-0.76,0.33-1.3,0.49c-0.54,0.16-1.15,0.3-1.81,0.43
	c-0.66,0.12-1.38,0.22-2.14,0.31C74,29.96,73.24,30,72.48,30c-1.93,0-3.6-0.29-5.03-0.85c-1.42-0.57-2.6-1.35-3.54-2.34
	c-0.94-0.99-1.63-2.16-2.08-3.52C61.39,21.93,61.17,20.46,61.17,18.89z M75.41,16.57c-0.03-0.52-0.12-1.02-0.27-1.51
	c-0.15-0.49-0.38-0.92-0.69-1.3c-0.31-0.38-0.71-0.69-1.18-0.94c-0.47-0.24-1.06-0.37-1.77-0.37c-0.68,0-1.26,0.12-1.75,0.35
	c-0.49,0.23-0.9,0.54-1.22,0.91c-0.33,0.38-0.58,0.82-0.75,1.32c-0.18,0.5-0.31,1.01-0.39,1.53H75.41z"
        fill="white"
      />
      <g>
        <path
          d="M43.87,14.08c-0.52-1.38-1.23-2.56-2.16-3.54c-0.92-0.98-2.03-1.74-3.32-2.28c-1.29-0.54-2.71-0.81-4.25-0.81
		c-1.52,0-2.92,0.27-4.21,0.81c-1.29,0.54-2.4,1.3-3.34,2.28c-0.94,0.98-1.67,2.16-2.2,3.54c-0.53,1.39-0.79,2.92-0.79,4.6
		c0,1.68,0.26,3.22,0.77,4.62s1.23,2.59,2.16,3.58c0.92,0.99,2.03,1.76,3.32,2.3c1.29,0.54,2.72,0.81,4.29,0.81
		c1.6,0,3.04-0.27,4.33-0.81c1.29-0.54,2.39-1.31,3.32-2.3c0.92-0.99,1.63-2.18,2.12-3.58s0.73-2.94,0.73-4.62
		C44.64,17,44.39,15.47,43.87,14.08z M37.34,23.14c-0.75,1.1-1.81,1.65-3.19,1.65c-1.38,0-2.46-0.55-3.22-1.65
		c-0.76-1.1-1.14-2.58-1.14-4.46c0-1.87,0.38-3.34,1.14-4.41c0.76-1.07,1.83-1.61,3.22-1.61c1.38,0,2.45,0.54,3.19,1.61
		c0.75,1.07,1.12,2.54,1.12,4.41C38.46,20.56,38.08,22.04,37.34,23.14z"
          fill="white"
        />
      </g>
      <g>
        <path
          d="M107.95,11.13c0.04-2.46,2-4.35,4.46-4.31c2.38,0.04,4.28,2.03,4.24,4.44c-0.04,2.36-2.01,4.29-4.36,4.26
		C109.85,15.49,107.92,13.54,107.95,11.13z M112.29,14.63c1.89,0.01,3.47-1.54,3.48-3.41c0.01-1.93-1.53-3.51-3.43-3.52
		c-1.91-0.01-3.5,1.54-3.51,3.44C108.82,13.06,110.38,14.63,112.29,14.63z"
          fill="white"
        />
        <path
          d="M113.75,10.46c-0.07,0-0.14-0.01-0.21,0c-0.22,0.03-0.35-0.04-0.45-0.26c-0.28-0.58-1.24-0.58-1.62-0.01
		c-0.34,0.52-0.33,1.4,0.04,1.9c0.25,0.35,0.61,0.41,1,0.35c0.36-0.06,0.57-0.28,0.65-0.63c0.04-0.16,0.11-0.21,0.27-0.21
		c0.24,0.01,0.49,0.01,0.73,0c0.19-0.01,0.23,0.07,0.2,0.25c-0.16,0.91-0.83,1.53-1.75,1.61c-1.08,0.09-1.94-0.38-2.34-1.28
		c-0.43-0.97-0.22-2.16,0.49-2.85c0.88-0.84,2.46-0.76,3.25,0.15c0.19,0.21,0.29,0.47,0.34,0.75c0.03,0.16-0.01,0.23-0.18,0.23
		C114.03,10.45,113.89,10.46,113.75,10.46z"
          fill="white"
        />
      </g>
    </svg>
  );
}
