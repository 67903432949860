import axios from "axios";

import {
  IMersRegisterTransaction,
  IMersReverseRegistrationTransaction,
  IMersSecuredPartyTransaction,
  IMersTransactionResponse,
  IMersUpdateChangeDataTransaction,
  IMersUpdateChangeStatusTransaction,
  MersModificationTypes,
} from "Features/Vault/VaultInterfaces";

/**
 * Remove Secured Party
 * @param transaction Contains the min
 * @returns the Promise for the request
 */
export function removeSecuredParty(transaction: IMersSecuredPartyTransaction): Promise<IMersTransactionResponse[]> {
  return axios
    .delete(`/api/packages/${transaction.packageId}/mers/registrations/secured-party?min=${transaction.min}`, {
      data: transaction,
    })
    .then((response) => response.data);
}

/**
 * Remove Delegatee for Transfers
 * @param packageId packageId
 * @param min min
 * @returns the Promise for the request
 */
export async function removeDelegateeForTransfer(packageId: string, min: string): Promise<any> {
  return await axios
    .delete(`/api/v1/packages/${packageId}/mers/registrations/delegatee-for-transfer?min=${min}`)
    .then((response) => response.data);
}

/**
 * Remove Subservicer
 * @param packageId packageId
 * @param min min
 * @returns the Promise for the request
 */
export async function removeSubservicer(packageId: string, min: string): Promise<any> {
  return await axios
    .delete(`/api/v1/packages/${packageId}/mers/registrations/subservicer?min=${min}`)
    .then((response) => response.data);
}

export function registerMERSPackage(data: IMersRegisterTransaction): Promise<IMersTransactionResponse[]> {
  return axios.post(`/api/v2/packages/${data.packageId}/mers/registrations`, data).then((response) => response.data);
}

export function reverseRegisterMERSPackage(
  data: IMersReverseRegistrationTransaction
): Promise<IMersTransactionResponse> {
  return axios.delete(`/api/v1/packages/${data.packageId}/mers/registrations`).then((response) => response.data);
}

export function modifyMERSPackage(
  packageId: string,
  min: string,
  type: MersModificationTypes
): Promise<IMersTransactionResponse[]> {
  const payload = {
    type,
  };
  return axios
    .post(`/api/packages/${packageId}/mers/registrations/modification?min=${min}`, payload)
    .then((response) => response.data);
}

export function modificationReversal(packageId: string, min: string): Promise<IMersTransactionResponse[]> {
  return axios
    .delete(`/api/packages/${packageId}/mers/registrations/modification-agreements?min=${min}`)
    .then((response) => response.data);
}

export function noteStatusUpdateMERSPackage(
  updatePackage: IMersUpdateChangeStatusTransaction
): Promise<IMersTransactionResponse[]> {
  return axios
    .patch(`/api/packages/${updatePackage.packageId}/mers/registrations/note?min=${updatePackage.min}`, {
      noteStatus: updatePackage.noteStatus,
    })
    .then((response) => response.data);
}

export function rightsholderUpdateMERSPackage(
  updatePackage: IMersUpdateChangeDataTransaction
): Promise<IMersTransactionResponse[]> {
  let propertyKey = "";
  switch (updatePackage.rightsholder.toLowerCase()) {
    case "locationrecipient":
      propertyKey = "location";
      break;
    case "servicingagentrecipient":
      propertyKey = "Delegatee";
      break;
    case "delegateefortransferrecipient":
      propertyKey = "DelegateeForTransfers";
      break;
    case "securedpartyrecipient":
      propertyKey = "SecuredParty";
      break;
    case "securedpartydelegateerecipient":
      propertyKey = "SecuredPartyDelegatee";
      break;
    case "subservicerrecipient":
      propertyKey = "SubServicer";
  }
  const update = {
    [propertyKey]: updatePackage.mersOrgId,
  };
  return axios
    .patch(`/api/packages/${updatePackage.packageId}/mers/registrations/rightsholder?min=${updatePackage.min}`, update)
    .then((response) => response.data);
}
