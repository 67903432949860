import * as React from "react";

import MinReconciliationMersOrgTable from "./MinReconciliationMersOrgTable";

/**
 * Wraps the table component. Ideally all components and functionality to be exposed at a directory level should be exposed in index files.
 */
function MinReconciliationMersOrgs(): JSX.Element {
  return <MinReconciliationMersOrgTable />;
}

export default MinReconciliationMersOrgs;
