import React, { ReactElement, useState } from "react";

import _ from "lodash";

import Link from "@mui/material/Link";

import Tooltip from "Components/Tooltip";

import { IDeliveryDocument, IMersDeliveryPackage } from "Features/Vault/VaultInterfaces";

import DeliveryDocumentSelectionModal from "../../ModalPopups/DeliveryDocumentSelectionModal";

interface IMersDeliveryDocumentsProps {
  deliveryPackage: IMersDeliveryPackage;
  className?: string;
  onDeliveryPackageSelectionModalSubmit: (loan: IMersDeliveryPackage, documents: IDeliveryDocument[]) => void;
}

export default function MersDeliveryDocuments(props: IMersDeliveryDocumentsProps): ReactElement {
  const { className, deliveryPackage, onDeliveryPackageSelectionModalSubmit } = props;
  const [deliveryDocumentSelectionModalOpen, setDeliveryDocumentSelectionModalOpen] = useState<boolean>(false);
  let value = "None";
  const selectedDocuments = _.filter(props.deliveryPackage?.documents, (item) => item.selected);
  if (selectedDocuments.length > 0) {
    value = selectedDocuments[0].name;
  }
  if (selectedDocuments.length > 1) {
    value += ", +" + (selectedDocuments.length - 1);
  }
  const documentsElement = <span>{value}</span>;

  function handleClose() {
    setDeliveryDocumentSelectionModalOpen(false);
  }

  function handleOpen() {
    setDeliveryDocumentSelectionModalOpen(true);
  }

  const classes = ["pseudolink"];
  if (className) {
    classes.push(className);
  }
  return (
    <>
      <Tooltip title="Configure Documents">
        <Link className={classes.join(" ")} onClick={handleOpen} role="button">
          {documentsElement}
        </Link>
      </Tooltip>
      {deliveryPackage.documents.length > 0 && (
        <DeliveryDocumentSelectionModal
          deliveryPackage={deliveryPackage}
          open={deliveryDocumentSelectionModalOpen}
          onClose={handleClose}
          onSubmit={onDeliveryPackageSelectionModalSubmit}
        />
      )}
    </>
  );
}
