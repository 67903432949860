import React, { ReactElement } from "react";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { DocumentIcon } from "Components/Icons";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import { IVaultLoanSearchResult } from "../VaultInterfaces";

interface IDocListContextMenuProps {
  disabled: boolean;
  loanNumber: string;
  min: string;
  onViewDocList: (payload?: any) => void;
  packageId: string;
  packageType: string;
  primarySignerLastName: string;
}

export default function DocListContextMenu(props: IDocListContextMenuProps): ReactElement {
  const { disabled, loanNumber, min, onViewDocList, packageId, packageType, primarySignerLastName } = props;

  function showDocList(): void {
    const data = {
      loanNumber,
      min,
      packageId,
      packageType,
    };
    onViewDocList(data);
  }

  const auditlogData: IVaultLoanSearchResult = {
    id: packageId,
    loanNumber,
    packageId,
    primarySignerLastName,
  };

  return (
    <>
      <MenuItem disabled={disabled} onClick={showDocList}>
        <ListItemIcon>
          <DocumentIcon />
        </ListItemIcon>
        <ListItemText>Docs</ListItemText>
      </MenuItem>

      <AuditLogMenuItem data={auditlogData} />
    </>
  );
}
