/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AuditLogIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M5,16h4v-1H5V16z M5,8v2h4V8H5z M10,12H5v1h5V12z M3,18V6h10v7.6c0.6-0.3,1.3-0.6,2-0.6V4H1v16h11.4c-0.6-0.5-1-1.2-1.2-2H3 z M21.6,12l-5.1,5.2l-2.1-2.1L13,16.5l3.5,3.5l6.5-6.6L21.6,12z" />
    </SvgIcon>
  );
}
