/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ViewIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M16.63,15l-.69,0-.62-.62a7.15,7.15,0,1,0-.71.7l.62.62,0,.68,4.69,4.7,1.42-1.41ZM10,14.84A4.94,4.94,0,0,1,6.5,13.37,5,5,0,1,1,10,14.84Z" />
      <polygon points="13 9 11 9 11 7 9 7 9 9 7 9 7 11 9 11 9 13 11 13 11 11 13 11 13 9" />
    </SvgIcon>
  );
}
