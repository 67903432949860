import React, { ReactElement, useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";
import ScrollContainer from "Components/ScrollContainer";

import * as actions from "Features/Tabs/Actions";
import { ILoanTabDetails } from "Features/Tabs/Tabs";
import * as tabActions from "Features/Tabs/Tabs";

import { fetchOriginators } from "../Mers/MersOriginatorSlice";
import TabStore from "../TabStore";
import { VaultViewInactiveLoansTabTypes } from "../VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  tabs: state.tabs[constants.INACTIVE_LOANS],
});

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const keyRoot = "VaultTab-InactiveLoans-";

const SearchResultsTab = {
  key: createTabKey(keyRoot, VaultViewInactiveLoansTabTypes.SearchResults),
  loanType: constants.INACTIVE_LOANS,
  title: "Inactive",
  type: VaultViewInactiveLoansTabTypes.SearchResults,
};

export function VaultViewInactiveLoans(props: PropsFromRedux): ReactElement {
  const { dispatch, tabs } = props;

  // Add Search Results as first tab on original mount and make initial query
  useEffect(() => {
    if (!tabs.byId[SearchResultsTab.key]) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(SearchResultsTab));
    }
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchOriginators());
  }, [dispatch, tabs]);

  /**
   * Click handler for switching tabs
   * @param tab the tab being clicked
   * @returns {void}
   */
  function onTabClick(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.goToTab({
        key: tab.key,
        loanType: constants.INACTIVE_LOANS,
      })
    );
  }

  function handleTabClosed(tab: ILoanTabDetails) {
    dispatch(
      tabActions.removeTab({
        key: tab.key,
        loanType: constants.INACTIVE_LOANS,
      })
    );
  }

  return (
    <ScrollContainer>
      <TabPanel
        currentTab={tabs.currentTab}
        onTabClick={onTabClick}
        onTabClosed={handleTabClosed}
        tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
      >
        <TabStore tab={tabs.byId[tabs.currentTab]} />
      </TabPanel>
    </ScrollContainer>
  );
}

export default connector(VaultViewInactiveLoans);
