/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { AppDispatch, RootState } from "App/Store";

import { BottomBar } from "Components/Notifications/BottomBar";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import ScrollContainer from "Components/ScrollContainer";

import TransferFilterBar from "Features/Vault/ClosedLoans/TransferFilterBar";
import { VaultMersTransactionResultsModalPopup } from "Features/Vault/ModalPopups/VaultMersTransactionResultsModalPopup";
import {
  IMersBatchTransactionRequest,
  IMersRecipientDetails,
  IMersTransferPackage,
  MersTransactionType,
} from "Features/Vault/VaultInterfaces";

import MersTransferTable from "./MersTransferTable";
import { executeSearch, getMERsTransferType } from "./mersTransferService";

const mapStateToProps = (state: RootState) => ({
  isLoading: state.mersTransfers.isLoading,
  mersRecipients: state.mersRecipients.recipients,
  queueList: state.mersTransfers.transfers,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersTransfer(props: TypesFromRedux): ReactElement {
  const { isLoading, mersRecipients, queueList } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [alertNoMersOrg, setAlertNoMersOrg] = useState<boolean>(false);
  const [mersTransferResultsOpen, setMersTransferResultsOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<IMersBatchTransactionRequest>({
    packages: [],
    type: MersTransactionType.Unknown,
  });
  const [showTransferConfirmation, setShowTransferConfirmation] = useState<boolean>(false);

  function handleTransferClick(): void {
    const loansToTransfer = _.filter(queueList, "selected");
    if (loansToTransfer.length && mersRecipients.length && !showTransferConfirmation) {
      setShowTransferConfirmation(true);
    } else if (!mersRecipients.length && !alertNoMersOrg) {
      setAlertNoMersOrg(true);
    }
  }

  function transferLoans(): void {
    setShowTransferConfirmation(false);

    const request: IMersBatchTransactionRequest = {
      packages: [],
      type: MersTransactionType.Transfer,
    };
    const loansToTransfer = _.filter(queueList, "selected");

    _.each(loansToTransfer, (loan: IMersTransferPackage) => {
      const skipDeliveryFor: IMersRecipientDetails[] = [];
      if (!loan.includeDelivery) {
        if (loan.controlRecipient) {
          skipDeliveryFor.push(loan.controlRecipient);
        }
        if (loan.locationRecipient) {
          skipDeliveryFor.push(loan.locationRecipient);
        }
        if (loan.servicingAgentRecipient) {
          skipDeliveryFor.push(loan.servicingAgentRecipient);
        }
      }
      request.packages.push({
        effectiveDate: loan.effectiveDate,
        packageId: loan.packageId,
        skipDeliveryFor,
        transferControlRecipient: loan.controlRecipient,
        transferDelegateeForTransfersRecipient: loan.delegateeForTransfersRecipient,
        transferLocationRecipient: loan.locationRecipient,
        transferSecuredPartyDelegateeRecipient: loan.securedPartyDelegateeRecipient,
        transferSecuredPartyRecipient: loan.securedPartyRecipient,
        transferServicerRecipient: loan.servicingAgentRecipient,
        transferSubServicerRecipient: loan.subServicerRecipient,
        transferType: getMERsTransferType(loan),
      });
    });
    setRequest(request);
    setMersTransferResultsOpen(true);
  }

  function passesValidation() {
    const loansToTransfer = _.filter(queueList, "selected");

    let allLoansAreValid = true;

    _.each(loansToTransfer, (loan: IMersTransferPackage) => {
      if (loan.isInvalidState) {
        allLoansAreValid = false;
      }
    });

    return loansToTransfer.length > 0 && allLoansAreValid;
  }

  const transferConfirmationModal = (
    <ModalPopupAlert
      title="Initiate transfer"
      content="This cannot be cancelled. Are you sure you want to initiate the specified transfer(s)?"
      confirmationButtonText="CONTINUE"
      onConfirm={() => {
        transferLoans();
      }}
      showDeclineLink={true}
      onClose={() => {
        setShowTransferConfirmation(false);
      }}
      open={showTransferConfirmation}
      size="sm"
    />
  );

  const modalNoMERSOrgAlert = (
    <ModalPopupAlert
      title="No MERS Org"
      content="You must select a MERS Org to transfer to before you can transfer control."
      onClose={() => {
        setAlertNoMersOrg(false);
      }}
      open={alertNoMersOrg}
      size="sm"
    />
  );

  const handleCloseTransferRequestResults = () => {
    setMersTransferResultsOpen(false);
    setShowTransferConfirmation(false);
    dispatch(executeSearch());
  };

  const transferResultsModal = (
    <VaultMersTransactionResultsModalPopup
      onClosed={handleCloseTransferRequestResults}
      request={request}
      open={mersTransferResultsOpen}
    />
  );

  return (
    <>
      {modalNoMERSOrgAlert}
      {transferConfirmationModal}
      {transferResultsModal}
      <ScrollContainer>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            padding-right: 8px;
          `}
        >
          <TransferFilterBar />
        </div>
        <MersTransferTable />
      </ScrollContainer>
      <BottomBar
        contents="Add loan(s), select recipient(s), and then click transfer."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation() || isLoading,
            onClick: handleTransferClick,
            text: "TRANSFER",
          },
        ]}
      />
    </>
  );
}

export default connector(MersTransfer);
