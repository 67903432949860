import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import { baseSearchCriteria } from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import { HTMLElementTypes } from "Types/EVaultAppTypes";

import {
  initialRegistrationSearchBarParams,
  resetSearch,
  updateSearchBarParams,
} from "../Mers/MersRegister/MersRegisterSlice";
import { executeSearch } from "../Mers/MersRegister/mersRegistrationService";

const registrationFilters = [
  ...baseSearchCriteria,
  {
    label: "search",
    name: "searchKey",
    options: [
      {
        apiParamValue: "loanNumber",
        description: "Loan Number",
      },
      {
        apiParamValue: "mortgageIdentificationNumber",
        description: "MIN",
      },
      {
        apiParamValue: "namedSigner1LastName",
        description: "Signer Last Name",
      },
    ],
    type: HTMLElementTypes.DROPDOWN,
  },
];

const mapStateToProps = (state: RootState) => ({
  channels: state.channels,
  searchBarParams: state.mersRegistrations.searchBarParams,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function RegistrationFilterBar(props: TypesFromRedux): ReactElement {
  const { channels, searchBarParams, dispatch } = props;

  const [searchCriteria, setSearchCriteria] = useState<any[]>(registrationFilters);

  useEffect(() => {
    if (channels.channels.length > 1) {
      const filters = registrationFilters.slice(0);
      filters.push({
        name: "divider",
        type: HTMLElementTypes.DIVIDERBLOCK,
      });
      filters.push({
        label: "Channels",
        name: "channelIds",
        options: channels.channels.map((channel) => {
          return {
            apiParamValue: channel.id.toString(),
            description: channel.name,
          };
        }),
        type: HTMLElementTypes.DROPDOWNCHECKLIST,
      });
      setSearchCriteria(filters);
    }
  }, [channels.isLoading, channels.channels]);

  function handleReset() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialRegistrationSearchBarParams, searchBarParams);

  return (
    <FilterBar
      displayResetButton={displayResetButton}
      onCriteriaChange={handleCriteriaChange}
      onReset={handleReset}
      onSubmit={handleSubmit}
      searchCriterias={searchCriteria}
      searchParams={searchBarParams}
    />
  );
}

export default connector(RegistrationFilterBar);
