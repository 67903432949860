import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import { getMERSPackageSearchUrl } from "Adapters/Mers/mersPackageAdapter";

import { IMersUpdatePackage } from "Features/Vault/VaultInterfaces";

import { IMersUpdateSearchApiParams, fetchPackages, updateMersUpdates, updateSearchBarParams } from "./MersUpdateSlice";

export const updateUpdateQueue = (packages: IMersUpdatePackage[]): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    dispatch(updateMersUpdates(packages));
  };
};

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.mersUpdates.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchUpdates());
  };
}

export function fetchUpdates(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mersOrgs = state.mersOriginators;
    let fetchUrl = resourceUrl as string;
    if (!resourceUrl) {
      const searchCriteria = state.mersUpdates.searchBarParams.data;
      const sortByParams = state.mersUpdates.sortByParams;
      const params: IMersUpdateSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      fetchUrl = getMERSPackageSearchUrl(params);
    }
    const request = {
      mersOrgs: mersOrgs.originators,
      state,
      url: fetchUrl,
    };
    dispatch(fetchPackages(request));
  };
}
