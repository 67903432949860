import React, { useEffect } from "react";

import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "Components/DataGridCustomComponents/Pagination";
import { DocumentIcon } from "Components/Icons";

import { getArchivedLoansExportUrl } from "Adapters/Mers/mersPackageAdapter";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import AuthoritativeCopy from "../Mers/AuthoritativeCopy";
import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import { getMersOrgs } from "../Mers/mersOrgService";
import TableContextMenu from "../TableContextMenu";
import TableRowContextMenu from "../TableRowContextMenu";
import { IVaultViewClosedLoansSearchResultData, VaultViewArchivedLoansTabTypes } from "../VaultInterfaces";
import { openTabForDocList } from "../documentService";
import { datagridColumns } from "../shared";
import { IVaultViewArchivedLoansSearchResultData } from "./Types";
import { executeSearch, fetchArchivedLoansAction, updateSortByAction } from "./archivedLoansService";

interface ISearchResultsTabProps {
  keyRoot: string;
}

const mapStateToProps = (state: RootState) => ({
  archivedLoanProps: state.archivedLoans,
  error: state.archivedLoans.error,
  initialized: state.archivedLoans.initialized,
  isLoading: state.archivedLoans.isLoading,
  links: state.archivedLoans.links,
  mersOrgs: getMersOrgs(state),
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux & ISearchResultsTabProps;

function ArchivedSearchResults(props: Props) {
  const { archivedLoanProps, error, initialized, isLoading, links, mersOrgs, keyRoot } = props;
  const dispatch = useDispatch() as AppDispatch;
  const searchResults = useSelector((state: RootState) =>
    state.archivedLoans.searchResult.map((loan) => ({
      ...loan,
      id: loan.packageId,
    }))
  );

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchArchivedLoansAction());
    }
  }, [dispatch, initialized]);

  function showDocList(response: IVaultViewArchivedLoansSearchResultData) {
    const key: string = createTabKey(keyRoot, VaultViewArchivedLoansTabTypes.DocumentList, response);
    const payload = {
      key,
      loanType: constants.ARCHIVED_LOANS,
      rowData: response,
      type: VaultViewArchivedLoansTabTypes.DocumentList,
    };
    dispatch(openTabForDocList(payload));
  }
  const columns: GridColDef[] = [
    datagridColumns.loanNumber,
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewClosedLoansSearchResultData}
          loanType={constants.ARCHIVED_LOANS}
          tabKeyRoot={VaultViewArchivedLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
    },
    {
      ...datagridColumns.acIndicator,
      renderCell: (params: GridRenderCellParams) => (
        <Grid container item justifyContent="center" alignItems="center">
          <AuthoritativeCopy mersOrgs={mersOrgs} loan={params.row as IVaultViewArchivedLoansSearchResultData} />
        </Grid>
      ),
    },
    datagridColumns.primaryBorrowerName,
    datagridColumns.closingDate,
    datagridColumns.controlTransferCompleted,
    datagridColumns.controller,
    datagridColumns.location,
    datagridColumns.servicer,
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => (
        <TableRowContextMenu
          actions={[
            {
              disabled: false,
              icon: <DocumentIcon />,
              onClick: () => {
                showDocList(params.row as IVaultViewArchivedLoansSearchResultData);
              },
              title: "Docs",
            },
            <AuditLogMenuItem key="auditlog" data={params.row as IVaultViewArchivedLoansSearchResultData} />,
          ]}
        />
      ),
      renderHeader: function renderContextMenuHeader() {
        return (
          <TableContextMenu
            getExportUrl={getArchivedLoansExportUrl}
            searchData={archivedLoanProps.searchBarParams.data}
          />
        );
      },
    },
  ];

  function onSortChange(model: GridSortModel) {
    if (model[0]) {
      dispatch(updateSortByAction(model[0]));
    }
  }

  function handleNextPage() {
    dispatch(fetchArchivedLoansAction(links?.next));
  }

  function handlePreviousPage() {
    dispatch(fetchArchivedLoansAction(links?.previous));
  }

  function handleRetry() {
    dispatch(executeSearch());
  }

  return (
    <DataGrid
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
        Pagination: Pagination,
      }}
      componentsProps={{
        errorOverlay: { error, onRetry: handleRetry },
        pagination: { handleNextPage, handlePreviousPage, isLoading, links },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      error={error}
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSortModelChange={onSortChange}
      pagination
      paginationMode="server"
      rows={searchResults}
      rowsPerPageOptions={[100]}
      scrollbarSize={17}
      sortingMode="server"
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default connector(ArchivedSearchResults);
