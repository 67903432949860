import axios from "axios";
import querystring from "querystring";

import { SortOrderType } from "Common/Enums";
import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import * as types from "Types/EVaultAppTypes";

import { IVaultViewInactiveLoansSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../Constants";

export interface IMersInactivePackagePageResponse {
  links: types.IResultPages;
  results: IVaultViewInactiveLoansSearchResultData[];
}

/**
 * Get the Closed Loans MERS packages
 * @param params search criteria values
 * @returns A promise for the request
 */
export function getInactiveMERSPackages(params: types.IVaultsSearchParams): Promise<IMersInactivePackagePageResponse> {
  return axios.get(getInactiveMersPackagesUrl(params)).then((response) => response.data);
}

/**
 * Get the Closed Loans MERS packages
 * @param url for the request
 * @returns A promise for the request
 */
export function getInactiveMERSPackagesByUrl(url: string): Promise<IMersInactivePackagePageResponse> {
  return axios.get(url).then((response) => response.data);
}

interface IInactiveSeachPayload {
  searchKey: QueryStringSearchKey | undefined;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
  sortField?: string;
  sortOrderType?: SortOrderType;
}

export function getInactiveMersPackagesUrl(params: types.IVaultsSearchParams): string {
  const payload: IInactiveSeachPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop),
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
  };

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  return "/api/v1/packages/inactive?" + querystring.stringify(payload as any);
}
