/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

export interface ITabbedPanelTabProps {
  children?: JSX.Element;
}

export default function TabbedPanelPane(props: ITabbedPanelTabProps): ReactElement {
  return (
    <div
      className="tabbed-panel-tab-content"
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
    >
      {props.children}
    </div>
  );
}
