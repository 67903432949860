import React, { ReactElement } from "react";

interface IDetailListProps {
  label: string;
  values: string[];
}

export default function DetailList(props: IDetailListProps): ReactElement {
  return (
    <p>
      <span className="detail-row-label">{props.label}:</span>
      <ul className="detail-row-list">
        {props.values.map((value: string) => {
          return <li key={value}>{value}</li>;
        })}
      </ul>
    </p>
  );
}
