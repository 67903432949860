import React, { ReactElement, useState } from "react";

import clsx from "clsx";

import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { Theme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Toolbar from "@mui/material/Toolbar";
import { makeStyles } from "@mui/styles";

import Tooltip from "Components/Tooltip";

import Controller from "./CollapsibleSideMenuController";
import VaultInformation from "./VaultInformation";
import VaultMenu from "./VaultMenu";

const COLLAPSE_WIDTH = 54;
const EXPAND_WIDTH = 210;

const useStyles = makeStyles((theme: Theme) => ({
  bottomPanel: {
    paddingBottom: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    overflowY: "auto",
    width: EXPAND_WIDTH,
  },
  contentMini: {
    width: COLLAPSE_WIDTH,
  },
  contentWrapper: {
    borderRight: "1px solid #BEBEBE",
    height: "calc(100% - 78px)",
    width: EXPAND_WIDTH,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: COLLAPSE_WIDTH,
  },
  drawerOpen: {
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: EXPAND_WIDTH,
  },
  grow: {
    flex: 1,
  },
  iconStyle: {
    minWidth: 40,
  },
  links: {
    fontSize: 14,
  },
}));

export default function CollapsibleSideMenu(): ReactElement {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles();

  function handleToggleExpand() {
    setIsExpanded(!isExpanded);
  }

  return (
    <Drawer
      anchor="left"
      className={clsx(classes.drawer, {
        [classes.drawerClose]: !isExpanded,
        [classes.drawerOpen]: isExpanded,
      })}
      classes={{
        paper: clsx({
          [classes.drawerClose]: !isExpanded,
          [classes.drawerOpen]: isExpanded,
        }),
      }}
      variant="permanent"
    >
      <Toolbar />
      <div
        id="collapsible-side-menu"
        className={clsx(classes.contentWrapper, {
          [classes.contentMini]: !isExpanded,
        })}
      >
        <div className={classes.content}>
          <div id={isExpanded ? "collapsible-side-menu-container" : "collapsible-side-menu-side-bar"}>
            <VaultMenu isContainerExpanded={isExpanded} />
          </div>
          <div className={classes.grow} />
          <List className={classes.bottomPanel}>
            <ListItem disablePadding>
              <ListItemButton component={Link} href="https://www.informx.com/?appId=releasenotes" target="_blank">
                <ListItemIcon className={classes.iconStyle}>
                  <Tooltip title="Release Notes">
                    <NewReleasesIcon />
                  </Tooltip>
                </ListItemIcon>
                Release Notes
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} href="https://blog.docutech.com/" target="_blank">
                <Tooltip title="Blog">
                  <ListItemIcon className={classes.iconStyle}>
                    <RssFeedIcon />
                  </ListItemIcon>
                </Tooltip>
                Blog
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton component={Link} href="/evault_user_guide.pdf" target="_blank">
                <Tooltip title="User Guide">
                  <ListItemIcon className={classes.iconStyle}>
                    <FollowTheSignsIcon />
                  </ListItemIcon>
                </Tooltip>
                User Guide
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <VaultInformation />
            </ListItem>
          </List>
          <Controller isExpanded={isExpanded} onToggleExpand={handleToggleExpand} />
        </div>
      </div>
    </Drawer>
  );
}
