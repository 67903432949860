/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DocumentIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M14,17H7v-2h7 M17,13H7v-2h10 M17,9H7V7h10 M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5  C21,3.9,20.1,3,19,3z" />
    </SvgIcon>
  );
}
