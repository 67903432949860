import * as React from "react";

import { ConnectedProps, connect } from "react-redux";

import CloudDownload from "@mui/icons-material/CloudDownload";
import { Link } from "@mui/material";

import { RootState } from "../../../App/Store";

const mapStateToProps = (state: RootState) => ({
  clientDefinedDataFields: state.clientDefinedDataFields.data,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;
type Props = TypesFromRedux;

const TemplateDownload = ({ clientDefinedDataFields }: Props) => {
  const handleDownload = () => {
    const enabledFields = clientDefinedDataFields.filter((field) => field.enabled);
    let content = "MIN";
    if (enabledFields.length > 0) {
      content += "," + enabledFields.map((field) => field.name).join(",") + "\r\n";
      content += "1234567890," + enabledFields.map(() => "").join(",") + "\r\n";
    }

    const a = document.createElement("a");
    a.href = "data:text/plain;charset=utf-8," + encodeURIComponent(content); // ! encodeURIComponent
    a.download = "client_defined_field_update_template.csv";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <Link
      onClick={handleDownload}
      color="primary"
      className="pseudolink__body"
      download
      sx={{ alignItems: "center", display: "flex" }}
    >
      <CloudDownload sx={{ mr: 0.5 }} />
      <span>Download Template</span>
    </Link>
  );
};

export default connector(TemplateDownload);
