import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { SortOrderType } from "Common/Enums";
import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState } from "Types/EVaultAppTypes";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import {
  IVaultViewQueueSearchResultData,
  IVaultViewQueueSearchResultTableData,
  MersConfirmationStatus,
  MersTransferStatus,
} from "Features/Vault/VaultInterfaces";

import dateField from "../../../../Common/Fields/DateField";
import { getMersOrgDisplayName } from "../../../../Common/Utilities";
import { QueryStringSearchKey, initialSortByParams } from "../../Constants";
import { requestTypeTooltips, requestTypes } from "../../shared";
import { IQueuePagedData, getQueueByUrl } from "./queueAdapter";

const initialQueueSearchResult: IVaultViewQueueSearchResultTableData[] = [];

export interface queueSearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortField?: string;
  sortOrderType?: SortOrderType;
  start: Date;
  stop: Date;
}

export interface IQueueSearchApiParams extends queueSearchParams {
  limit: number;
  offset: number;
}

const initialQueueApiCallParams: queueSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.MIN,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export interface IVaultQueueSearchBarParams {
  data: queueSearchParams;
}

export const initialQueueSearchBarParams: IVaultQueueSearchBarParams = {
  data: initialQueueApiCallParams,
};

const queue: types.IQueue = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialQueueSearchBarParams,
  searchResult: initialQueueSearchResult,
  sortByParams: initialSortByParams("initiated"),
};

export const fetchQueue = createAsyncThunk<
  IQueuePagedData,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("queue/fetch", async (url: string, thunkApi) => {
  try {
    return await getQueueByUrl(url);
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

interface UpdateAction {
  action: MersConfirmationStatus;
  transferId: string;
}

export const queueSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchQueue.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchQueue.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = populateSearchResults(payload.results);
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchQueue.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  initialState: queue,
  name: "queue",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = queue.searchBarParams;
    },
    updateAction: (state, action: PayloadAction<UpdateAction>) => {
      const searchItem = state.searchResult.find((s) => s.transferId === action.payload.transferId);
      if (searchItem != null) {
        searchItem.action = action.payload.action;
      }
    },
    updateSearchBarParams: (state, action: PayloadAction<queueSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<types.ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

function populateSearchResults(responses: IVaultViewQueueSearchResultData[]): IVaultViewQueueSearchResultTableData[] {
  return responses.map((response: IVaultViewQueueSearchResultData) => {
    const request = requestTypes[parseInt(response.request)];
    const requestTypeTooltip = requestTypeTooltips[parseInt(response.request)];

    const effectiveDate = dateField.renderForTable(response.effectiveDate);

    let actions: MersConfirmationStatus[];
    let action: MersConfirmationStatus;

    switch (response.status) {
      case MersTransferStatus.Pending:
        actions = [MersConfirmationStatus.None, MersConfirmationStatus.Approve, MersConfirmationStatus.Deny];
        action = MersConfirmationStatus.None;
        break;
      default:
        actions = [MersConfirmationStatus.None, MersConfirmationStatus.Pending];
        action = MersConfirmationStatus.None;
        break;
    }

    const data: IVaultViewQueueSearchResultTableData = {
      action,
      actions,
      controller: getMersOrgDisplayName(response.controllerOrg),
      effective: response.effectiveDate === "0001-01-01T00:00:00+00:00" ? "" : effectiveDate,
      id: response.id,
      initiated: response.initiatedDate,
      location: getMersOrgDisplayName(response.locationOrg),
      min: response.min,
      packageId: response.packageId,
      request,
      requestTypeTooltip,
      sender: getMersOrgDisplayName(response.sender),
      servicer: getMersOrgDisplayName(response.delegateeOrg),
      smartDocId: response.smartDocId,
      status: response.status,
      transferId: response.transferId,
    };

    return data;
  });
}

export const { resetSearch, updateAction, updateSearchBarParams, updateSortBy } = queueSlice.actions;

export default queueSlice.reducer;
