import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { store } from "App/Store";

import { getErrorMessage } from "Common/Utilities";
import { apiError, errorEnqueued } from "Common/error";

import { IHttpErrorState } from "Types/EVaultAppTypes";

import { getCurrentUserMERSOrgs } from "Adapters/Mers/mersOrgAdapter";

import * as errorActions from "Features/Errors/Errors";

import { IMersOriginator } from "../VaultInterfaces";

export interface IMersOriginatorStoreState {
  error?: IHttpErrorState;
  isLoading: boolean;
  originators: IMersOriginator[];
}

const initialOriginators: IMersOriginatorStoreState = {
  error: undefined,
  isLoading: false,
  originators: [],
};

export const fetchOriginators = createAsyncThunk<
  IMersOriginator[],
  undefined,
  {
    rejectValue: IHttpErrorState;
  }
>("mersOriginators/fetch", async (_, thunkApi) => {
  try {
    return await getCurrentUserMERSOrgs();
  } catch (err) {
    store.dispatch(errorActions.enqueue(errorEnqueued(getErrorMessage(err, "Error loading MERS originators."))));
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const mersOriginatorsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchOriginators.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });
    builder.addCase(fetchOriginators.fulfilled, (state, action) => {
      state.originators = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchOriginators.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState: initialOriginators,
  name: "mersOriginators",
  reducers: {},
});

export default mersOriginatorsSlice.reducer;
