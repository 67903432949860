/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

interface IScrollContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

function ScrollContainer(props: IScrollContainerProps): ReactElement {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        overflow: auto;
      `}
    >
      {props.children}
    </div>
  );
}

export default ScrollContainer;
