/*
 * This component is responsible for displaying batch errors
 */
import * as React from "react";

interface IFluentValidationError {
	errorMessage: string;
	propertyName: string;
}
export interface IFluentValidationException {
	isValid: boolean;
	errors: IFluentValidationError[];
}

interface IBatchBatchUploadModalProps {
	errors: string[] | IFluentValidationException[];
}

export default function BatchErrorResults(props: IBatchBatchUploadModalProps) {
	const { errors } = props;
	if (!errors) return null;

	const compiledListOfErrors: string[] = [];
	errors.forEach((currentValue) => {
		if (typeof currentValue === "string") {
			compiledListOfErrors.push(currentValue);
		} else {
			currentValue.errors.forEach((error) => {
				compiledListOfErrors.push(error.errorMessage);
			});
		}
	});

	return (
		<>
			<span className="italicized">Error(s)</span>
			<ul>
				{compiledListOfErrors.map((e, index) => {
					return <li key={index}>{e}</li>;
				})}
			</ul>
		</>
	);
}
