/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import { AppDispatch, RootState } from "App/Store";

import { BottomBar } from "Components/Notifications/BottomBar";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import ScrollContainer from "Components/ScrollContainer";

import { VaultMersTransactionResultsModalPopup } from "Features/Vault/ModalPopups/VaultMersTransactionResultsModalPopup";
import {
  IMersBatchTransactionRequest,
  IMersUpdateChangeDataTransaction,
  IMersUpdateChangeStatusTransaction,
  MersNoteUpdateFunctions,
  MersTransactionType,
} from "Features/Vault/VaultInterfaces";

import MersUpdateFilterBar from "./MersUpdateFilterBar";
import { updateGroup } from "./MersUpdateSlice";
import UpdateModificationTable from "./UpdateModificationTable";
import UpdateRightsHolderTable from "./UpdateRightsHolderTable";
import UpdateStatusTable from "./UpdateStatusTable";
import { executeSearch } from "./mersUpdateService";

const mapStateToProps = (state: RootState) => ({
  queueList: state.mersUpdates.updates.map((loan) => ({
    ...loan,
    id: loan.packageId,
  })),
  updateSet: state.mersUpdates.updateSet,
});

const connector = connect(mapStateToProps);
export type IMersUpdateProps = ConnectedProps<typeof connector>;

export function MersUpdate(props: IMersUpdateProps): ReactElement {
  const dispatch = useDispatch() as AppDispatch;
  const { queueList, updateSet } = props;

  const [eNoteTransactionType, seteNoteTransactionType] = useState<MersNoteUpdateFunctions>(updateSet);
  const [mersUpdateResultsModalOpen, setMersUpdateResultsModalOpen] = useState<boolean>(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState<boolean>(false);
  const [transaction, setTransaction] = useState<IMersBatchTransactionRequest>({
    packages: [],
    type: MersTransactionType.Unknown,
  });

  function handleTableViewChange(event: any, tableView: string | null) {
    if (!tableView) return;
    if (tableView === MersNoteUpdateFunctions.UpdateRightsholder) {
      seteNoteTransactionType(MersNoteUpdateFunctions.UpdateRightsholder);
      dispatch(
        updateGroup({
          group: MersNoteUpdateFunctions.UpdateRightsholder,
          packages: queueList,
        })
      );
    } else if (tableView === MersNoteUpdateFunctions.ChangeStatus) {
      seteNoteTransactionType(MersNoteUpdateFunctions.ChangeStatus);
      dispatch(
        updateGroup({
          group: MersNoteUpdateFunctions.ChangeStatus,
          packages: queueList,
        })
      );
    } else if (tableView === MersNoteUpdateFunctions.Modification) {
      seteNoteTransactionType(MersNoteUpdateFunctions.ChangeData);
      dispatch(
        updateGroup({
          group: MersNoteUpdateFunctions.Modification,
          packages: queueList,
        })
      );
    }
  }

  function handleUpdateClick(): void {
    setShowUpdateConfirmation(true);
  }

  function updateLoans(): void {
    let transactionType = MersTransactionType.ChangeData;
    if (eNoteTransactionType === MersNoteUpdateFunctions.ChangeStatus) {
      transactionType = MersTransactionType.ChangeStatus;
    }

    const request: IMersBatchTransactionRequest = {
      packages: [],
      type: transactionType,
    };
    const loansToTransfer = _.filter(queueList, "selected");

    _.each(loansToTransfer, (loan: any) => {
      if (eNoteTransactionType === MersNoteUpdateFunctions.ChangeStatus) {
        request.packages.push({
          min: loan.min,
          noteStatus: loan.changeStatus,
          packageId: loan.packageId,
        } as IMersUpdateChangeStatusTransaction);
      } else {
        if (updateSet === MersNoteUpdateFunctions.Modification) {
          request.packages.push({
            min: loan.min,
            modificationType: parseInt(loan.changeData || ""),
            packageId: loan.packageId,
          } as IMersUpdateChangeDataTransaction);
        } else {
          request.packages.push({
            mersOrgId: loan.rightsholderRecipient ? loan.rightsholderRecipient.orgId : "",
            min: loan.min,
            packageId: loan.packageId,
            rightsholder: loan.rightsholder,
          });
        }
      }
    });

    setMersUpdateResultsModalOpen(true);
    setShowUpdateConfirmation(false);
    setTransaction(request);
  }

  function passesValidation(): boolean {
    const loansToTransfer = _.filter(queueList, "selected");
    return Boolean(loansToTransfer.length) && !_.some(loansToTransfer, "isInvalidState");
  }

  const closeUpdateRequestResults = () => {
    setMersUpdateResultsModalOpen(false);
    setShowUpdateConfirmation(false);
    dispatch(executeSearch());
  };

  const renderUpdateTable = () => {
    switch (updateSet) {
      case MersNoteUpdateFunctions.ChangeStatus:
        return <UpdateStatusTable queueList={queueList} updateSet={updateSet} />;
      case MersNoteUpdateFunctions.UpdateRightsholder:
        return <UpdateRightsHolderTable queueList={queueList} updateSet={updateSet} />;
      case MersNoteUpdateFunctions.Modification:
        return <UpdateModificationTable queueList={queueList} updateSet={updateSet} />;
    }
  };

  const updateResultsModal = (
    <VaultMersTransactionResultsModalPopup
      onClosed={closeUpdateRequestResults}
      request={transaction}
      open={mersUpdateResultsModalOpen}
    />
  );
  const modalUpdateConfirmation = (
    <ModalPopupAlert
      title="Confirm update"
      content="This action cannot be cancelled. Are you sure you want to make these updates?"
      confirmationButtonText="CONTINUE"
      onConfirm={() => {
        updateLoans();
      }}
      showDeclineLink={true}
      onClose={() => {
        setShowUpdateConfirmation(false);
      }}
      open={showUpdateConfirmation}
      size="sm"
    />
  );

  return (
    <>
      <ScrollContainer>
        <MersUpdateFilterBar />
        <ToggleButtonGroup
          color="primary"
          css={css`
            margin: 8px;
          `}
          value={updateSet}
          exclusive
          onChange={handleTableViewChange}
        >
          <ToggleButton value={MersNoteUpdateFunctions.ChangeStatus}>Status</ToggleButton>
          <ToggleButton value={MersNoteUpdateFunctions.UpdateRightsholder}>Rights Holder</ToggleButton>
          <ToggleButton value={MersNoteUpdateFunctions.Modification}>Modification</ToggleButton>
        </ToggleButtonGroup>
        {renderUpdateTable()}
      </ScrollContainer>
      <BottomBar
        contents="Add loan(s), select update(s), and then click update."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation(),
            onClick: handleUpdateClick,
            text: "UPDATE",
          },
        ]}
      />
      {modalUpdateConfirmation}
      {updateResultsModal}
    </>
  );
}

export default connector(MersUpdate);
