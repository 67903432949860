import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { PackageAction, PackageTransferStatus } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import { profileSelector } from "Features/Profile/ProfileSlice";
import { addTab } from "Features/Tabs/Actions";
import {
  IMersUpdatePackage,
  IVaultViewClosedLoansSearchResultData,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";

import { canTransfer } from "../mersService";
import { setLoadingState } from "./MersUpdateSlice";
import { updateUpdateQueue } from "./mersUpdateService";

export const getUpdatableLoans = (loans: IVaultViewClosedLoansSearchResultData[]): IMersUpdatePackage[] => {
  const updatableLoans = _.filter(loans, (loan: IVaultViewClosedLoansSearchResultData) => {
    return (
      loan.action === PackageAction.Transfer ||
      loan.mersData.mersTransferStatus === PackageTransferStatus.TransferFailed
    );
  });
  return updatableLoans.map((loan) => {
    return {
      ...loan,
      selected: false,
    };
  });
};

export function openUpdateTabForPackage(packageId: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsRegister = canTransfer(profile.roles);
    if (rightsRegister) {
      const closedLoans = getUpdatableLoans(state.closedLoans.searchResult);
      const loan = _.find(closedLoans, { packageId });
      if (loan) {
        loan.selected = true;
      }
      dispatch(setLoadingState(false));
      dispatch(updateUpdateQueue(closedLoans));
      dispatch(goToUpdateTab());
    }
  };
}

export function openUpdateTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsRegister = canTransfer(profile.roles);
    if (rightsRegister) {
      const closedLoans = getUpdatableLoans(state.closedLoans.searchResult);
      dispatch(setLoadingState(false));
      dispatch(updateUpdateQueue(closedLoans));
      dispatch(goToUpdateTab());
    }
  };
}

function goToUpdateTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-ClosedLoans-";
    const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.UpdateMERS, {});
    const tabData = {
      closeable: true,
      key,
      loanType: constants.CLOSED_LOANS,
      title: "Update",
      type: VaultViewClosedLoansTabTypes.UpdateMERS,
    };
    dispatch(addTab(tabData));
  };
}
