import * as React from "react";

import { formatInTimeZone } from "date-fns-tz";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getDisplayForMersRegistrationStatus,
  getMersNoteStatusText,
  getTransactionRightsForRegistrationDisplay,
} from "../../Common/Utilities";
import DetailList from "../../Components/DetailList";
import DetailRow from "../../Components/DetailRow";
import { IRegistrationHistory } from "./VaultInterfaces";

interface ITransferDetailsModal {
  onClose: () => void;
  open: boolean;
  details: IRegistrationHistory;
}

export default function TransactionDetailsModal(props: ITransferDetailsModal) {
  const { details, onClose, open } = props;

  if (!details) return null;
  const rights: string[] = getTransactionRightsForRegistrationDisplay(details);
  const validFrom = formatInTimeZone(new Date(details.validFrom), "America/Boise", "M/d/yyyy h:mm a zzz");
  let validTo = formatInTimeZone(new Date(details.validTo), "America/Boise", "M/d/yyyy h:mm a zzz");
  if (new Date(details.validTo) > new Date()) {
    validTo = "Current";
  }
  const noteStatus = getMersNoteStatusText(details.noteStatus);
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Registration Details</DialogTitle>
        <DialogContent>
          <DetailRow label="Valid From" value={validFrom} />
          <DetailRow label="Valid To" value={validTo} />
          <DetailRow
            label="Registration Status"
            value={getDisplayForMersRegistrationStatus(details.registrationStatus)}
          />
          <DetailRow label="Note Status" value={noteStatus} />
          {details.noteAssumptionDate && (
            <DetailRow
              label="Note Assumption Date"
              value={formatInTimeZone(new Date(details.noteAssumptionDate), "America/Boise", "M/d/yyyy h:mm a zzz")}
            />
          )}
          {details.noteModificationDate && (
            <DetailRow
              label="Note Assumption Date"
              value={(new Date(details.noteModificationDate), "America/Boise", "M/d/yyyy h:mm a zzz")}
            />
          )}

          {rights.length === 0 && <DetailRow label="Rights" value="Unknown" />}
          {rights.length > 0 && <DetailList label="Rights" values={rights} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" size="large" onClick={onClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
