import React, { useEffect, useState } from "react";

import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";

import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey, getMersNoteStatusViewText } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "Components/DataGridCustomComponents/Pagination";
import { DocumentIcon, UndoIcon } from "Components/Icons";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { getInactiveLoansExportUrl } from "Adapters/Mers/mersPackageAdapter";

import { fetchAllLoans } from "Features/Vault/AllLoans/allLoansService";
import {
  executeSearch,
  fetchInactiveLoans,
  updateSortByAction,
} from "Features/Vault/InactiveLoans/inactiveLoansService";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import { VaultMersTransactionResultsModalPopup } from "../ModalPopups/VaultMersTransactionResultsModalPopup";
import TableContextMenu from "../TableContextMenu";
import TableRowContextMenu from "../TableRowContextMenu";
import {
  IVaultViewClosedLoansSearchResultData,
  IVaultViewInactiveLoansSearchResultData,
  MersTransactionType,
  VaultViewInactiveLoansTabTypes,
} from "../VaultInterfaces";
import { openTabForDocList } from "../documentService";
import { datagridColumns } from "../shared";

interface ISearchResultsTabProps {
  keyRoot: string;
}

const mapStateToProps = (state: RootState) => ({
  error: state.inactiveLoans.error,
  inactiveLoanProps: state.inactiveLoans,
  initialized: state.inactiveLoans.initialized,
  isLoading: state.inactiveLoans.isLoading,
  links: state.inactiveLoans.links,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ISearchResultsTabProps;

function InactiveSearchResults(props: Props) {
  const { error, inactiveLoanProps, initialized, isLoading, links, keyRoot } = props;
  const searchResults = useSelector((state: RootState) =>
    state.inactiveLoans.searchResult.map((loan) => ({
      ...loan,
      id: loan.packageId,
    }))
  );

  const [noteStatusReversalConfirmationModalOpen, setNoteStatusReversalConfirmationModalOpen] = useState(false);
  const [noteStatusResultsModalOpen, setNoteStatusResultsModalOpen] = useState(false);
  const [transaction, setTransaction] = useState({
    min: "",
    noteStatus: "",
    packageId: "",
  });
  const [mersReverseRequest, setMersReverseRequest] = useState({
    packages: [],
    type: MersTransactionType.Unknown,
  });
  const dispatch = useDispatch() as AppDispatch;

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchInactiveLoans());
    }
  }, [dispatch, initialized]);

  /**
   * Callback to open Confirmation Modal and update transaction state
   * @param update MERs package to update
   * @returns {void}
   */
  function handleNoteStatusReversalRequest(update: IVaultViewInactiveLoansSearchResultData) {
    setNoteStatusReversalConfirmationModalOpen(true);
    setTransaction(update);
  }

  const columns: GridColDef[] = [
    datagridColumns.loanNumber,
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewClosedLoansSearchResultData}
          loanType={constants.INACTIVE_LOANS}
          tabKeyRoot={VaultViewInactiveLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
    },
    datagridColumns.primaryBorrowerName,
    datagridColumns.closingDate,
    datagridColumns.controller,
    datagridColumns.servicer,
    datagridColumns.noteStatus,
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => (
        <TableRowContextMenu
          actions={[
            {
              disabled: false,
              icon: <DocumentIcon />,
              onClick: () => {
                showDocList(params.row as IVaultViewInactiveLoansSearchResultData);
              },
              title: "Docs",
            },
            <AuditLogMenuItem key="auditlog" data={params.row as IVaultViewInactiveLoansSearchResultData} />,
            {
              icon: <UndoIcon />,
              onClick: () => {
                handleNoteStatusReversalRequest(params.row as IVaultViewInactiveLoansSearchResultData);
              },
              title: getMersNoteStatusViewText(true, params.row.noteStatus.toLowerCase()),
            },
          ]}
        />
      ),
      renderHeader: function renderContextMenuHeader() {
        return (
          <TableContextMenu
            getExportUrl={getInactiveLoansExportUrl}
            searchData={inactiveLoanProps.searchBarParams.data}
          />
        );
      },
    },
  ];

  async function showDocList(response: IVaultViewInactiveLoansSearchResultData) {
    const key: string = createTabKey(keyRoot, VaultViewInactiveLoansTabTypes.DocumentList, response);
    const payload = {
      key,
      loanType: constants.INACTIVE_LOANS,
      rowData: response,
      type: VaultViewInactiveLoansTabTypes.DocumentList,
    };
    dispatch(openTabForDocList(payload));
  }

  /**
   * Click handler for closing Reversal Modal and reseting assocaited state
   * @returns {void}
   */
  function closeReversalRequestResultsPopup() {
    setNoteStatusResultsModalOpen(false);
    setTransaction({
      min: "",
      noteStatus: "",
      packageId: "",
    });
    setMersReverseRequest({
      packages: [],
      type: MersTransactionType.Unknown,
    });
  }

  /**
   * Click handler for closing Confirmation Modal and triggering Reverse Modal
   * @returns {void}
   */
  function onReverseNoteStatusConfirmModal() {
    setNoteStatusReversalConfirmationModalOpen(false);
    reverseNoteStatus();
  }

  /**
   * Update MERS Reverse Request state based on note status
   * @returns {void}
   */
  function reverseNoteStatus() {
    if (transaction) {
      const request: any = {
        packages: [],
        type: MersTransactionType.Unknown,
      };

      switch (transaction.noteStatus.toLowerCase()) {
        case "chargedoff":
        case "convertedtopaper":
        case "paidoff":
        case "paperreplacement":
        case "transferredtoproprietaryregistry":
          request.packages.push({
            min: transaction.min,
            noteStatus: transaction.noteStatus + "Reversal",
            packageId: transaction.packageId,
          });
          request.type = MersTransactionType.ChangeStatus;
          break;
      }

      setMersReverseRequest(request);
      setNoteStatusResultsModalOpen(true);
    }
  }

  const noteStatusReversalConfirmationModal = (
    <ModalPopupAlert
      title="Confirm reversal"
      content="This action will reactivate this eNote and cannot be cancelled. Are you sure you want to perform this reverse action?"
      confirmationButtonText="CONTINUE"
      onConfirm={onReverseNoteStatusConfirmModal}
      showDeclineLink={true}
      onClose={() => setNoteStatusReversalConfirmationModalOpen(false)}
      open={noteStatusReversalConfirmationModalOpen}
      size="sm"
    />
  );

  const noteStatusReversalModal = (
    <VaultMersTransactionResultsModalPopup
      onClosed={closeReversalRequestResultsPopup}
      request={mersReverseRequest}
      open={noteStatusResultsModalOpen}
    />
  );

  function handleNextPage() {
    dispatch(fetchAllLoans(links?.next));
  }

  function handlePreviousPage() {
    dispatch(fetchAllLoans(links?.previous));
  }

  function onSortChange(model: GridSortModel) {
    if (model[0]) {
      dispatch(updateSortByAction(model[0]));
    }
  }

  function handleRetry() {
    dispatch(executeSearch());
  }

  return (
    <>
      <DataGrid
        columnBuffer={0}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlay,
          LoadingOverlay: LoadingOverlay,
          Pagination: Pagination,
        }}
        componentsProps={{
          errorOverlay: { error, onRetry: handleRetry },
          pagination: { handleNextPage, handlePreviousPage, isLoading, links },
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        error={error}
        loading={isLoading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        onSortModelChange={onSortChange}
        pagination
        paginationMode="server"
        rows={searchResults}
        rowsPerPageOptions={[100]}
        scrollbarSize={17}
        sortingMode="server"
        sortingOrder={["desc", "asc"]}
      />
      {noteStatusReversalConfirmationModal}
      {noteStatusReversalModal}
    </>
  );
}

export default connector(InactiveSearchResults);
