/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import { DataGrid, GridCellParams, GridColDef, GridRenderEditCellParams, GridSelectionModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";

import { profileSelector } from "Features/Profile/ProfileSlice";
import {
  IMersRecipientDetails,
  IMersRegisterPackage,
  IMersRegisterTransaction,
  RecipientAccessors,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";
import { datagridColumns } from "Features/Vault/shared";

import MersIdentificationNumber from "../MersIdentificationNumber";
import MersRecipientSelector from "../MersRecipientSelector";
import { RegistrationRecipientAccessors, getRegistrationQueue, updateRecipient } from "./MersRegisterSlice";
import { fetchRegistrations, updateRegistrationQueue } from "./mersRegistrationService";

const mapStateToProps = (state: RootState) => ({
  error: state.mersRegistrations.error,
  initialized: state.mersRegistrations.initialized,
  isLoading: state.mersRegistrations.isLoading,
  mersRecipients: state.mersRecipients.recipients,
  queueList: getRegistrationQueue(state) ?? [],
  settings: profileSelector(state).settings,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersRegisterTable(props: TypesFromRedux): ReactElement {
  const keyRoot = "VaultTab-ClosedLoans-";
  const { error, initialized, isLoading, queueList, settings } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [selected, setSelected] = useState<GridSelectionModel>([]);

  const permissions = {
    dataPointRegistrationsEnabled: settings.mers.dataPointRegistrationsEnabled,
    delegateeForTransferEnabled: settings.mers.delegateeForTransferEnabled,
    securedPartyDelegateeEnabled: settings.mers.securedPartyDelegateeEnabled,
    securedPartyEnabled: settings.mers.securedPartyEnabled,
  };

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchRegistrations());
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    setSelected(queueList.filter((loan) => loan.selected).map((loan) => loan.id));
  }, [queueList]);

  const columns: GridColDef[] = [
    {
      ...datagridColumns.loanNumber,
      sortable: false,
    },
    {
      ...datagridColumns.min,
      renderCell: (params: GridCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row.loanNumber}
          loanType={constants.CLOSED_LOANS}
          tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
      sortable: false,
    },
    datagridColumns.primaryBorrowerName,
    datagridColumns.closingDate,
    {
      ...datagridColumns.securedPartyRecipient,
      editable: true,
      hide: !permissions.securedPartyEnabled,
      minWidth: 200,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersRegisterPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RegistrationRecipientAccessors.securedPartyRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.securedPartyRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.securedPartyDelegateeRecipient,
      editable: true,
      hide: !permissions.securedPartyDelegateeEnabled || !permissions.securedPartyEnabled,
      minWidth: 200,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersRegisterPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RegistrationRecipientAccessors.securedPartyDelegateeRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.securedPartyDelegateeRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
    {
      ...datagridColumns.delegateeForTransferRecipient,
      editable: true,
      hide: !permissions.delegateeForTransferEnabled,
      minWidth: 200,
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersRegisterPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RegistrationRecipientAccessors.delegateeForTransferRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.delegateeForTransferRecipient}
              parentLoan={params.row as IMersRegisterTransaction}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
    },
  ];

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
    const updatedRegistrations = queueList.map((queue) => {
      return {
        ...queue,
        selected: selected.includes(queue.id),
      };
    });
    dispatch(updateRegistrationQueue(updatedRegistrations));
  };

  return (
    <DataGrid
      css={css`
        min-height: ${error ? "200px" : "0px"};
      `}
      checkboxSelection
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
      }}
      componentsProps={{
        errorOverlay: { error },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      editMode="row"
      error={error}
      hideFooter
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selected}
      rows={queueList}
      scrollbarSize={17}
    />
  );
}

export default connector(MersRegisterTable);
