import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { store } from "App/Store";

import { getErrorMessage } from "Common/Utilities";
import { apiError, errorEnqueued } from "Common/error";

import { IHttpErrorState, IMersRecipientStoreState } from "Types/EVaultAppTypes";

import { getMERSRecipients } from "Adapters/Mers/mersOrgAdapter";

import * as errorActions from "Features/Errors/Errors";
import { IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

const initialOriginators: IMersRecipientStoreState = {
  error: undefined,
  isLoading: false,
  recipients: [],
};

export const fetchRecipients = createAsyncThunk<
  IMersRecipientDetails[],
  undefined,
  {
    rejectValue: IHttpErrorState;
  }
>("mersRecipients/fetch", async (_, thunkApi) => {
  try {
    return await getMERSRecipients();
  } catch (err) {
    store.dispatch(errorActions.enqueue(errorEnqueued(getErrorMessage(err, "Error loading MERS originators."))));
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const mersRecipientSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchRecipients.pending, (state) => {
      state.error = undefined;
      state.isLoading = true;
    });
    builder.addCase(fetchRecipients.fulfilled, (state, action) => {
      state.recipients = action.payload as IMersRecipientDetails[];
      state.isLoading = false;
    });
    builder.addCase(fetchRecipients.rejected, (state, action) => {
      state.error = action.payload as IHttpErrorState;
      state.isLoading = false;
    });
  },
  initialState: initialOriginators,
  name: "mersRecipients",
  reducers: {},
});

export default mersRecipientSlice.reducer;
