import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState } from "Types/EVaultAppTypes";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";
import { IMinReconciliationReport, IMinReconciliationReportState } from "./MinReconciliationReportInterfaces";
import { IMinReconciliationReportsPagedData, getMinReconciliationReportsByUrl } from "./minReconciliationAdapter";

const initialMinReconciliationReportsSearchResult: IMinReconciliationReport[] = [];

export interface minReconciliationReportSearchParams {
  searchKey: QueryStringSearchKey;
  searchTerm?: string;
  start?: Date;
  stop?: Date;
}

export interface IMinReconciliationReportsSearchApiParams extends minReconciliationReportSearchParams {
  limit: number;
  offset: number;
}

const initialMinReconciliationReportsApiCallParams: minReconciliationReportSearchParams = {
  searchKey: QueryStringSearchKey.ReconciliationReportId,
  searchTerm: "",
  stop: new Date(),
};

export interface IVaultMinReconciliationReportSearchBarParams {
  data: minReconciliationReportSearchParams;
}

export const initialMinReconciliationReportSearchBarParams: IVaultMinReconciliationReportSearchBarParams = {
  data: initialMinReconciliationReportsApiCallParams,
};

const minReconciliationReport: IMinReconciliationReportState = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialMinReconciliationReportSearchBarParams,
  searchResult: initialMinReconciliationReportsSearchResult,
  sortByParams: initialSortByParams("initiated"),
};

export const fetchMinReconciliationReports = createAsyncThunk<
  IMinReconciliationReportsPagedData,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("minReconciliation/fetch", async (url: string, thunkApi) => {
  try {
    return await getMinReconciliationReportsByUrl(url);
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const minReconciliationReportSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchMinReconciliationReports.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchMinReconciliationReports.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchMinReconciliationReports.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  initialState: minReconciliationReport,
  name: "minReconciliation",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = minReconciliationReport.searchBarParams;
    },
    updateSearchBarParams: (state, action: PayloadAction<minReconciliationReportSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<types.ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateSearchBarParams, updateSortBy } = minReconciliationReportSlice.actions;

export default minReconciliationReportSlice.reducer;
