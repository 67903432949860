import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import NoteIcon from "@mui/icons-material/Book";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import IncompleteIcon from "@mui/icons-material/RadioButtonUnchecked";
import NotApplicableIcon from "@mui/icons-material/RemoveCircle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import { getFullDocumentPackageTypeDescription } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import { ExternalLinkIcon, InkSignedDocumentIcon, PartialIcon, PenIcon, UnknownIcon } from "Components/Icons";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import ScrollContainer from "Components/ScrollContainer";
import Tooltip from "Components/Tooltip";

import { WorkflowType } from "Types/EVaultAppTypes";

import { profileSelector } from "Features/Profile/ProfileSlice";
import * as tabActions from "Features/Tabs/Tabs";
import { ITabIdentifier } from "Features/Tabs/Tabs";

import { fetchDocuments } from "../Documents/DocumentsSlice";
import { IVaultViewAllLoansDocumentDetailData } from "../VaultInterfaces";
import { DocumentDownloadProperties, getDocumentPrintUrl } from "../documentService";
import * as documentService from "../documentService";
import { datagridColumns } from "../shared";
import DocumentContextMenu from "./DocumentContextMenu";
import DocumentListContextMenu, { DocumentOptions } from "./ListContextMenu";
import documentListService from "./documentListService";

export interface IDocumentListProps {
  alternatePackageType: string;
  isLoading?: boolean;
  loanNumber: string;
  packageId: string;
  packageType: string;
  partyName: string;
  tabIdentifier: ITabIdentifier;
  workflowType?: WorkflowType;
}

export enum DocumentAction {
  Print,
  Download,
}

const mapStateToProps = (state: RootState, props: IDocumentListProps) => {
  const packageDocuments = state.documents.documentsByPackageId[props.packageId];
  let documents: IVaultViewAllLoansDocumentDetailData[] = [];
  if (packageDocuments) {
    documents = packageDocuments.documents;
  }
  return {
    canDownload: documentService.canUserDownload(state),
    displayAltPackage: profileSelector(state).settings.application.displayAltPackage,
    documents: documents,
    error: packageDocuments && packageDocuments.error,
    preSelectedMode: documentService.preSelectedMode(state),
  };
};

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux & IDocumentListProps;

export function DocumentList(props: Props): ReactElement | null {
  const {
    alternatePackageType,
    canDownload,
    displayAltPackage,
    documents,
    error,
    isLoading,
    loanNumber,
    packageId,
    packageType,
    partyName,
    preSelectedMode,
    workflowType,
  } = props;

  const dispatch = useDispatch() as AppDispatch;
  const [alertNoDocumentsSelected, setAlertNoDocumentsSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<GridSelectionModel>([]);

  useEffect(() => {
    let initiallySelectedRows: GridSelectionModel;
    switch (preSelectedMode) {
      case "AllDocs":
        initiallySelectedRows = _.map(documents, (item) => item.id);
        break;
      case "eSignDocs":
        initiallySelectedRows = _.filter(
          documents,
          (item: IVaultViewAllLoansDocumentDetailData) => !item.isInkSign
        ).map((item) => item.id);
        break;
      case "InkSignDocs":
        initiallySelectedRows = _.filter(documents, (item: IVaultViewAllLoansDocumentDetailData) => item.isInkSign).map(
          (item) => item.id
        );
        break;
      default:
        initiallySelectedRows = [];
        break;
    }
    setSelected(initiallySelectedRows);
  }, [documents, preSelectedMode]);

  function renderColumns(): GridColDef[] {
    const auditLogDisabled = workflowType === "MERS";
    const auditLogLabel = "Audit Log";
    const oneOrMoreDocuments = Boolean(documents?.length);
    const fullPackageTypeDescription = getFullDocumentPackageTypeDescription(
      displayAltPackage,
      packageType,
      alternatePackageType
    );

    const renderAuditLogLink = () => {
      if (canDownload) {
        return (
          <>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ alignSelf: "center", height: 20, mx: 1 }} />
            <span>
              {oneOrMoreDocuments ? (
                <Link
                  sx={{
                    alignItems: "center",
                    color: (theme) => (auditLogDisabled ? theme.palette.grey[500] : "initial"),
                    cursor: auditLogDisabled ? "default" : "pointer",
                    display: "flex",
                  }}
                  underline={auditLogDisabled ? "none" : "hover"}
                  href={
                    auditLogDisabled
                      ? undefined
                      : documentService.getAuditLogLink({
                          id: packageId,
                          loanNumber: loanNumber,
                          packageId: packageId,
                        })
                  }
                  target="_blank"
                  rel="noopener"
                >
                  {auditLogLabel}
                  <ExternalLinkIcon sx={{ fontSize: 14, ml: 0.5 }} />
                </Link>
              ) : (
                auditLogLabel
              )}
            </span>
          </>
        );
      }
    };

    return [
      {
        field: "display",
        flex: 1,
        minWidth: 500,
        renderCell: (params: GridRenderCellParams) => (
          <Grid container item alignItems="center" justifyContent="space-between">
            {canDownload ? (
              <a href={params.row.printLink} target="_blank" onClick={handleAnchorClick}>
                {params.row.name}
              </a>
            ) : (
              <span>{params.row.name}</span>
            )}
            {params.row.hasAssociatedSMARTDoc && canDownload && (
              <Tooltip title="eNote">
                <NoteIcon />
              </Tooltip>
            )}
            {params.row.isInkSign && <InkSignedDocumentIcon fontSize="large" sx={{ pl: 1.5 }} />}
          </Grid>
        ),
        renderHeader: function renderDisplayHeader() {
          return isLoading ? (
            <Grid container item alignItems="center">
              Loading documents...
            </Grid>
          ) : (
            <Grid container item alignItems="center">
              {`LOAN #: ${loanNumber}${fullPackageTypeDescription && `, ${fullPackageTypeDescription} Package`}`}
              {renderAuditLogLink()}
            </Grid>
          );
        },
        sortable: false,
      },
      {
        align: "right",
        field: "signStatus",
        headerAlign: "center",
        minWidth: 100,
        renderCell: (params: GridRenderCellParams) => getSignStatus(params.row as IVaultViewAllLoansDocumentDetailData),
        renderHeader: function renderSignStatusHeader() {
          return (
            <Tooltip title="eSign Status">
              <Grid container item alignItems="center" justifyContent="center">
                <PenIcon sx={{ color: (theme) => theme.palette.grey[700] }} />
              </Grid>
            </Tooltip>
          );
        },
        sortable: false,
      },
      {
        ...datagridColumns.contextMenu,
        renderCell: (params: GridRenderCellParams) => (
          <DocumentContextMenu
            showSmartDoc={params.row.hasAssociatedSMARTDoc && canDownload}
            packageId={packageId}
            smartDocId={params.row.id}
            onDownload={(id) => handleDocumentActionById(id, DocumentAction.Download)}
            onPrint={(id) => handleDocumentActionById(id, DocumentAction.Print)}
          />
        ),
        renderHeader: function renderContextMenuHeader() {
          return (
            <DocumentListContextMenu
              auditLogData={{
                id: packageId,
                loanNumber,
                packageId,
              }}
              checkedRows={selected}
              documents={documents}
              onDownload={(option) => handleDocumentActionByOption(option, DocumentAction.Download)}
              onPrint={(option) => handleDocumentActionByOption(option, DocumentAction.Print)}
              workflowType={workflowType}
            />
          );
        },
      },
    ];
  }

  function handleAnchorClick(event: any): void {
    event.stopPropagation();
  }

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
  };

  function handleDocumentActionByOption(option: DocumentOptions, action: DocumentAction) {
    const documentList: GridSelectionModel = documentListService.getDocumentIdsForOption(documents, option, selected);
    const documentProperties = {
      documentList,
      loanNumber,
      packageId,
      partyName,
    };
    if (action === DocumentAction.Print) {
      documentService.openDocumentPrint(documentProperties);
    } else if (action === DocumentAction.Download) {
      documentService.openDocumentDownload(documentProperties);
    }
  }

  function handleDocumentActionById(documentId: number, action: DocumentAction) {
    const documentList: number[] = [documentId];
    const documentProperties = {
      documentList,
      loanNumber,
      packageId,
      partyName,
    };
    if (action === DocumentAction.Print) {
      documentService.openDocumentPrint(documentProperties);
    } else if (action === DocumentAction.Download) {
      documentService.openDocumentDownload(documentProperties);
    }
  }

  const noDocsSelectedErrorModal = (
    <ModalPopupAlert
      title="Select docs"
      content="To print or download, you must select at least one doc."
      onClose={() => {
        setAlertNoDocumentsSelected(false);
      }}
      open={alertNoDocumentsSelected}
      size="sm"
    />
  );

  const rows = _.map(documents, (item) => {
    const isMersWorkflow = workflowType === "MERS";
    const properties: DocumentDownloadProperties = {
      documentList: [item.id],
      loanNumber,
      packageId,
      partyName,
    };
    let printLink = getDocumentPrintUrl(properties);
    if (isMersWorkflow && item.hasAssociatedSMARTDoc) {
      printLink = `/api/packages/${packageId}/documents/${item.id}/smart-doc-content`;
    }

    return {
      ...item,
      checkDisabled: item.mediaType !== "PDF",
      printLink,
    } as IVaultViewAllLoansDocumentDetailData;
  });

  function getSignStatus(result: IVaultViewAllLoansDocumentDetailData) {
    let signStatus;
    switch (result.signatureStatus.toLowerCase()) {
      case "fullysigned":
        signStatus = (
          <Tooltip title="Complete">
            <CompleteIcon fontSize="small" />
          </Tooltip>
        );
        break;

      case "notsignable":
        signStatus = (
          <Tooltip title="Not eSignable">
            <NotApplicableIcon fontSize="small" />
          </Tooltip>
        );
        break;

      case "partiallysigned":
        signStatus = (
          <Tooltip title="Partial">
            <PartialIcon fontSize="small" />
          </Tooltip>
        );
        break;

      case "unsigned":
        signStatus = (
          <Tooltip title="None complete">
            <IncompleteIcon fontSize="small" />
          </Tooltip>
        );
        break;

      default:
        signStatus = (
          <Tooltip title={result.signatureStatus.toLowerCase()}>
            <UnknownIcon />
          </Tooltip>
        );
        break;
    }
    return (
      <Grid container item alignItems="center" justifyContent="center">
        {signStatus}
      </Grid>
    );
  }

  function handleRetry() {
    dispatch(fetchDocuments(packageId));
  }

  function handleGoBack() {
    dispatch(tabActions.removeTab(props.tabIdentifier));
  }

  return (
    <ScrollContainer>
      {noDocsSelectedErrorModal}
      <DataGrid
        autoHeight
        checkboxSelection
        columnBuffer={0}
        columns={renderColumns()}
        components={{
          ErrorOverlay: ErrorOverlay,
          LoadingOverlay: LoadingOverlay,
        }}
        componentsProps={{
          errorOverlay: {
            error: "An error occurred while loading the doc list.",
            onGoBack: handleGoBack,
            onRetry: handleRetry,
          },
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        error={error}
        hideFooter
        loading={isLoading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selected}
        rows={rows}
        scrollbarSize={0}
      />
    </ScrollContainer>
  );
}

export default connector(DocumentList);
