import * as types from "Types/EVaultAppTypes";

/**
 * Format the search result's links
 * @param links Search Result's links
 * @returns Searh Result's links formatted
 */
export function formatSearchResultLinks(links: types.IResultPages): types.IResultPages {
  if (!links) {
    return links;
  }

  if (links.self) {
    links.self = formatSearchResultLink(links.self);
  }
  if (links.next) {
    links.next = formatSearchResultLink(links.next);
  }
  if (links.previous) {
    links.previous = formatSearchResultLink(links.previous);
  }
  return links;
}

/**
 * Format the search result's link
 * @param urlString Search Result's link in string representation
 * @returns Searh Result's link formatted
 */
function formatSearchResultLink(urlString: string): string {
  if (!urlString) {
    return urlString;
  }

  // NOTE: Domain/Port are stripped to avoid CORS error
  // NOTE: s'api' is removed via ProxyKit and must be added back for future API calls
  const url = new URL(urlString);
  let formattedUrl = `${url.pathname}${url.search}`;
  if (url.pathname.indexOf("/api") < -1) {
    formattedUrl = "/api" + formattedUrl;
  }
  return formattedUrl;
}
