/**
 * The documentListService is responsible for returning the documents available to be printed/downloaded
 * given the current option. If no options are available for the given option, it will return that option
 * should be disabled.
 */
import { GridSelectionModel } from "@mui/x-data-grid";

import { IVaultViewAllLoansDocumentDetailData } from "../VaultInterfaces";
import { DocumentOptions } from "./ListContextMenu";

/**
 * Returns a new array with the document ids that match the given print/download option and checked rows
 * @param documentListData The document list used to get the document ids matching the criteria
 * @param option The selected option for what documents should be printed/downloaded
 * @param checkedRows The rows selected (only applicable) when returning items that are selected.
 * @returns An array of document ids that correspond to the criteria
 */
export function getDocumentIdsForOption(
  documentListData: IVaultViewAllLoansDocumentDetailData[],
  option: DocumentOptions,
  checkedRows: GridSelectionModel
): GridSelectionModel {
  let documentList: GridSelectionModel = [];
  if (option === DocumentOptions.All) {
    documentList = documentListData.map((document) => document.id);
  } else if (option === DocumentOptions.Selected) {
    documentList = checkedRows;
  } else if (option === DocumentOptions.InkSign) {
    documentList = documentListData.filter((item) => item.isInkSign).map((item) => item.id);
  } else if (option === DocumentOptions.eSign) {
    documentList = documentListData.filter((item) => !item.isInkSign).map((item) => item.id);
  }
  return documentList;
}

/**
 * Returns whether the selected option should be disabled given the document list, print option and checked rows
 * @param documentListData The document list used to determine whether any documents would be printed
 * @param option The selected option for what documents should be printed/downloaded
 * @param checkedRows The rows selected (only applicable) when using the Selected option
 * @returns For whether the desired option is available
 */
export function shouldDisableOption(
  documentListData: IVaultViewAllLoansDocumentDetailData[],
  option: DocumentOptions,
  checkedRows: GridSelectionModel = []
): boolean {
  const documentList = getDocumentIdsForOption(documentListData, option, checkedRows);
  return documentList.length === 0;
}

export default {
  getDocumentIdsForOption,
  shouldDisableOption,
};
