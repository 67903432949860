/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import { ConnectedProps, connect } from "react-redux";

import * as constants from "Common/EVaultAppConstants";

import { ILoanTabDetails } from "Features/Tabs/Tabs";
import * as tabActions from "Features/Tabs/Tabs";

import TabbedPanelHeader from "./TabbedPanelHeader";

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

export interface ITabbedPanelProps {
  children?: any;
  currentTab: string;
  onTabClick: (tab: ILoanTabDetails) => void;
  onTabClosed?: (tab: ILoanTabDetails) => void;
  tabs: ILoanTabDetails[];
  tabGroup?: string;
}

type Props = TypesFromRedux & ITabbedPanelProps;

export function TabbedPanel(props: Props): ReactElement | null {
  const { currentTab, dispatch, tabs } = props;

  if (tabs.length === 0) return null;

  function handleTabClosed() {
    dispatch(
      tabActions.removeTab({
        key: props.currentTab,
        loanType: constants.ALL_LOANS,
      })
    );
  }

  return (
    <div
      className="tabbed-panel"
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      `}
    >
      <TabbedPanelHeader
        currentTab={currentTab}
        onTabClick={props.onTabClick}
        onTabClosed={props.onTabClosed || handleTabClosed}
        tabs={tabs}
      />
      <div
        className="tabbed-panel-content-container"
        css={css`
          border-top: 1px solid #cdcdcd;
          flex: 1;
          height: calc(100vh - 132px);
        `}
      >
        {props.children}
      </div>
    </div>
  );
}

export default connector(TabbedPanel);
