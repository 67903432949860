import axios from "axios";
import querystring from "querystring";

import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import { IResultPages } from "Types/EVaultAppTypes";

import { MersDatabaseTransactionType } from "../VaultInterfaces";
import { IMersBatch } from "./BatchOperationsInterfaces";
import { IBatchOperationsSearchApiParams } from "./BatchOperationsSlice";

export interface IBatchOperationsPagedData {
  links: IResultPages;
  results: IMersBatch[];
}

export function getBatchOperationsByUrl(url: string): Promise<IBatchOperationsPagedData> {
  return axios.get(url).then((response) => response.data);
}

interface IBatchOperationsSearchPayload {
  searchTerm?: string;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
}

function getBatchOperationsQueryString(params: IBatchOperationsSearchApiParams) {
  const payload: IBatchOperationsSearchPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop, true),
    limit: params.limit,
    offset: params.offset,
    searchTerm: params.searchTerm,
  };

  return "?" + querystring.stringify(payload as any);
}

export function getBatchOperationsUrl(params: IBatchOperationsSearchApiParams): string {
  return `/api/v1/mers/batches${getBatchOperationsQueryString(params)}`;
}

/**
 * This interface represents the group status results within a batch.
 */
export interface IMersBatchGroupStatus {
  count: number;
  transactionStatuses: Record<MersDatabaseTransactionType, { count: number }>;
}

/**
 * This interface represents the data that will be returned in MERS batch status requests.
 */
export interface IMersBatchStatusResponse {
  count: number;
  groupStatuses: {
    complete?: IMersBatchGroupStatus;
    failed?: IMersBatchGroupStatus;
    inProgress?: IMersBatchGroupStatus;
    rejected?: IMersBatchGroupStatus;
  };
}

/**
 * Requests the status summary for the requested batch.
 */
export async function getMersBatchStatus(batchId: string) {
  return await axios.get<IMersBatchStatusResponse>(`/api/v1/mers/batches/${batchId}/status`);
}
