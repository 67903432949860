/** ********************************/

/* Keep these lists alphabetized! */

/** ********************************/
import channels from "Features/Channels/ChannelSlice";
import errors from "Features/Errors/Errors";
import profile from "Features/Profile/ProfileSlice";
import tabs from "Features/Tabs/Tabs";
import allLoans from "Features/Vault/AllLoans/AllLoansSlice";
import archivedLoans from "Features/Vault/ArchivedLoans/ArchivedSlice";
import batchOperations from "Features/Vault/BatchOperations/BatchOperationsSlice";
import clientDefinedDataFields from "Features/Vault/ClientDefinedDataFieldsSlice";
import closedLoans from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import documents from "Features/Vault/Documents/DocumentsSlice";
import inactiveLoans from "Features/Vault/InactiveLoans/InactiveLoansSlice";
import incomingDeliveries from "Features/Vault/Incoming/Deliveries/IncomingDeliveriesSlice";
import incomingTransfers from "Features/Vault/Incoming/Transfers/IncomingTransfersSlice";
import mersDeliveries from "Features/Vault/Mers/MersDelivery/MersDeliverySlice";
import mersOriginators from "Features/Vault/Mers/MersOriginatorSlice";
import mersRecipients from "Features/Vault/Mers/MersRecipients/MersRecipientSlice";
import mersRegistrations from "Features/Vault/Mers/MersRegister/MersRegisterSlice";
import mersTransactions from "Features/Vault/Mers/MersTransaction/MersTransactionSlice";
import mersTransfers from "Features/Vault/Mers/MersTransfer/MersTransferSlice";
import mersUpdates from "Features/Vault/Mers/MersUpdate/MersUpdateSlice";
import queue from "Features/Vault/Mers/Queue/QueueSlice";
import minReconciliation from "Features/Vault/MinReconciliation/MinReconciliationSlice";

// Reducers to combine
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createRootReducer = {
  allLoans,
  archivedLoans,
  batchOperations,
  channels,
  clientDefinedDataFields,
  closedLoans,
  documents,
  errors,
  inactiveLoans,
  incomingDeliveries,
  incomingTransfers,
  mersDeliveries,
  mersOriginators,
  mersRecipients,
  mersRegistrations,
  mersTransactions,
  mersTransfers,
  mersUpdates,
  minReconciliation,
  profile,
  queue,
  tabs,
};

export default createRootReducer;
