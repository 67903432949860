import React, { ReactElement } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import {
  initialInactiveLoansSearchResult,
  resetSearch,
  updateSearchBarParams,
} from "Features/Vault/InactiveLoans/InactiveLoansSlice";
import { executeSearch } from "Features/Vault/InactiveLoans/inactiveLoansService";

const mapStateToProps = (state: RootState) => ({
  searchBarParams: state.inactiveLoans.searchBarParams,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export function InactiveLoansFilterBar(props: PropsFromRedux): ReactElement {
  const { searchBarParams, dispatch } = props;

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialInactiveLoansSearchResult, searchBarParams);

  return (
    <FilterBar
      displayResetButton={displayResetButton}
      onCriteriaChange={handleCriteriaChange}
      onReset={handleReset}
      onSubmit={handleSubmit}
      searchCriterias={constants.SEARCH_BAR_CRITERIA.inactive}
      searchParams={searchBarParams}
    />
  );
}

export default connector(InactiveLoansFilterBar);
