import { useEffect, useState } from "react";

import { IReconciliationClientMersOrg, getReconciliationClientMersOrgs } from "../minReconciliationAdapter";

/**
 * Hook used to extract away logic and functionality from the mers org table
 */
function useClientMersOrgTable(): {
  clientMersOrgs: IReconciliationClientMersOrg[];
  loading: boolean;
  refreshMersOrgs: () => void;
} {
  const [clientMersOrgs, setClientMersOrgs] = useState<IReconciliationClientMersOrg[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshDate, setRefreshDate] = useState<Date | null>(null);
  useEffect(() => {
    setLoading(true);
    getReconciliationClientMersOrgs()
      .then((response) => {
        setClientMersOrgs(response.data);
        setLoading(false);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [refreshDate]);

  /**
   * Forces a refresh of the table data for mers org
   */
  function refreshMersOrgs() {
    setRefreshDate(new Date());
  }

  return {
    clientMersOrgs,
    loading,
    refreshMersOrgs,
  };
}

export default useClientMersOrgTable;
