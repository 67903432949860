import React from "react";

import Box from "@mui/material/Box";

import ScrollContainer from "../../../Components/ScrollContainer";
import BatchOperationsFilterBar from "./BatchOperationsFilterBar";
import BatchOperationsSearchResultsTable from "./BatchOperationsSearchResultsTable";
import BatchTransactionsUpload from "./BatchTransactionsUpload/BatchTransactionUpload";

interface ISearchResultsTabProps {
  keyRoot: string;
}

export default function BatchOperationsSearchResults(props: ISearchResultsTabProps): JSX.Element {
  const { keyRoot } = props;
  return (
    <ScrollContainer>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "8px",
          paddingRight: "8px",
        }}
      >
        <BatchOperationsFilterBar />
        <BatchTransactionsUpload />
      </Box>
      <BatchOperationsSearchResultsTable keyRoot={keyRoot} />
    </ScrollContainer>
  );
}
