import React, { ReactElement, useCallback, useEffect, useMemo } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import ClockIcon from "@mui/icons-material/AccessTime";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import EllipsesIcon from "@mui/icons-material/MoreVert";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import { InProgressIcon } from "Components/Icons";
import Tooltip from "Components/Tooltip";

import { nonce } from "../../../App/App";
import ErrorOverlay from "../../../Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "../../../Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "../../../Components/DataGridCustomComponents/Pagination";
import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import MersManualTransferConfirmationSelector from "../Mers/MersManualTransferConfirmationSelector";
import { updateAction } from "../Mers/Queue/QueueSlice";
import { executeSearch, fetchQueueAction, updateSortByAction } from "../Mers/Queue/queuedLoansService";
import {
  IVaultViewQueueSearchResultTableData,
  MersConfirmationStatus,
  MersTransferStatus,
  QueueAction,
  VaultViewQueuedLoansTabTypes,
} from "../VaultInterfaces";
import { openTabForDocList } from "../documentService";
import { datagridColumns, queueColumns } from "../shared";
import SecuredPartyContextMenu from "./SecuredPartyContextMenu";

interface ISearchResultsTabProps {
  keyRoot: string;
  onSecuredPartyAction: (data: IVaultViewQueueSearchResultTableData, action: QueueAction) => void;
}

const mapStateToProps = (state: RootState) => ({
  error: state.queue.error,
  initialized: state.queue.initialized,
  isLoading: state.queue.isLoading,
  links: state.queue.links,
  profile: state.profile,
  queueLoanProps: state.queue,
  searchResults: state.queue.searchResult,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = ISearchResultsTabProps & TypesFromRedux;

export function QueueSearchResultsTable(props: ComponentProps): ReactElement {
  const { dispatch, error, initialized, isLoading, keyRoot, links, profile, searchResults } = props;

  useEffect(() => {
    if (!initialized) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchQueueAction());
    }
  }, [dispatch, initialized]);

  const handleShowDocList = useCallback(
    async (data: any) => {
      const key: string = createTabKey(keyRoot, VaultViewQueuedLoansTabTypes.DocumentList, data);
      const payload = {
        key,
        loanType: constants.QUEUE,
        rowData: data,
        type: VaultViewQueuedLoansTabTypes.DocumentList,
      };
      await (dispatch as ThunkDispatch<any, undefined, AnyAction>)(openTabForDocList(payload));
    },
    [dispatch, keyRoot]
  );

  const handleConfirmationChange = useCallback(
    (action: MersConfirmationStatus, transferId: string) => {
      dispatch(
        updateAction({
          action,
          transferId,
        })
      );
    },
    [dispatch]
  );

  function onSortChange(model: GridSortModel) {
    if (model[0]) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(updateSortByAction(model[0]));
    }
  }

  const searchColumns: GridColDef[] = useMemo(() => {
    return [
      {
        ...datagridColumns.min,
        renderCell: (params: GridRenderCellParams) => (
          <MersIdentificationNumber
            keyRoot={keyRoot}
            loan={params.row}
            loanType={constants.QUEUE}
            tabKeyRoot={VaultViewQueuedLoansTabTypes.MERSInquiryDetails}
            value={params.row.min}
          />
        ),
      },
      queueColumns.sender,
      queueColumns.initiated,
      queueColumns.effective,
      {
        ...queueColumns.request,
        renderCell: (params: GridRenderCellParams) => {
          const response = params.row;
          return (
            <Tooltip title={response.requestTypeTooltip}>
              <span>{response.request}</span>
            </Tooltip>
          );
        },
      },
      queueColumns.controller,
      queueColumns.location,
      queueColumns.servicer,
      {
        ...queueColumns.subservicer,
        hide: !profile.profile.settings.mers.subservicerEnabled,
      },
      {
        ...queueColumns.transferStatus,
        renderCell: (params: GridRenderCellParams) => {
          const response = params.row;
          let noteTip = "";
          let icon = null;
          switch (response.status) {
            case MersTransferStatus.Accepted: {
              noteTip = "Awaiting transfer completion";
              icon = <InProgressIcon />;
              break;
            }
            case MersTransferStatus.Rejected: {
              noteTip = "Rejected";
              icon = <AlertIcon color="error" />;
              break;
            }
            case MersTransferStatus.Pending: {
              noteTip = "Pending approve/deny";
              icon = <ClockIcon fontSize="small" />;
              break;
            }
            case MersTransferStatus.Completed: {
              noteTip = "Completed";
              icon = <CompleteIcon fontSize="small" />;
              break;
            }
          }
          return (
            <Tooltip title={noteTip}>
              <Grid container item justifyContent="center" alignItems="center">
                {icon}
              </Grid>
            </Tooltip>
          );
        },
      },
      {
        ...queueColumns.confirmation,
        renderCell: (params: GridRenderCellParams) => {
          const response = params.row;
          let confirmation: JSX.Element | null = null;

          switch (response.status) {
            case MersTransferStatus.Accepted:
              confirmation = (
                <MersManualTransferConfirmationSelector
                  packageId={response.packageId}
                  parentTransfer={response.transferId}
                  currentAction={response.action}
                  actions={response.actions}
                  onConfirmationStatusChanged={handleConfirmationChange}
                />
              );
              break;
            case MersTransferStatus.Pending:
              confirmation = (
                <MersManualTransferConfirmationSelector
                  packageId={response.packageId}
                  parentTransfer={response.transferId}
                  currentAction={response.action}
                  actions={response.actions}
                  onConfirmationStatusChanged={handleConfirmationChange}
                />
              );
              break;
          }
          return confirmation;
        },
      },
      {
        field: "contextMenu",
        renderCell: (params: GridRenderCellParams) => {
          const response = params.row;
          return (
            <SecuredPartyContextMenu
              onSecuredPartyAction={props.onSecuredPartyAction}
              queueItem={response}
              onViewDocList={handleShowDocList}
            />
          );
        },
        renderHeader: function renderAcIndicatorHeader() {
          return <EllipsesIcon />;
        },
        sortable: false,
      },
    ];
  }, [profile, props.onSecuredPartyAction, handleConfirmationChange, handleShowDocList, keyRoot]);

  function handleNextPage() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchQueueAction(links?.next));
  }

  function handlePreviousPage() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchQueueAction(links?.previous));
  }

  function handleRetry() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  return (
    <DataGrid
      columnBuffer={0}
      columns={searchColumns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
        Pagination: Pagination,
      }}
      componentsProps={{
        errorOverlay: { error, onRetry: handleRetry },
        pagination: { handleNextPage, handlePreviousPage, isLoading, links },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      error={error}
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSortModelChange={onSortChange}
      pagination
      paginationMode="server"
      rows={searchResults}
      rowsPerPageOptions={[100]}
      scrollbarSize={17}
      sortingMode="server"
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default connector(QueueSearchResultsTable);
