import React, { ReactElement } from "react";

import _ from "lodash";

import { getErrorMessage, getMersTransactionStatusFromString } from "Common/Utilities";

import Loading from "Components/Loading";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { manualConfirmationMERSTransfer, securedPartyAction } from "Adapters/Mers/mersTransferAdapter";

import {
  IMersBatchConfirmationRequest,
  IMersConfirmationRequest,
  IMersConfirmationResponse,
  IMersManualTransferTransaction,
  IMersSecuredPartyTransaction,
  MersConfirmationStatus,
  MersTransactionStatus,
  MersTransactionType,
} from "../VaultInterfaces";

interface IVaultMersTransactionResultsPopupProps {
  onClosed: () => void;
  request: IMersBatchConfirmationRequest;
  open: boolean;
}

interface IContentItem {
  min: string;
  transferId: string;
  status: MersTransactionStatus;
  confirmationStatus: MersConfirmationStatus;
}

interface IVaultMersTransactionResultsPopupState {
  error: Error | null;
  closable: boolean;
  contentItems: IContentItem[];
  loading: boolean;
}

export class VaultMersConfirmationResultsModalPopup extends React.Component<
  IVaultMersTransactionResultsPopupProps,
  IVaultMersTransactionResultsPopupState
> {
  private pendingRequestCount = 0;

  constructor(props: IVaultMersTransactionResultsPopupProps) {
    super(props);

    this.state = {
      closable: false,
      contentItems: [],
      error: null,
      loading: true,
    };

    // One time binding
    this.onCloseClick = this.onCloseClick.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.handleMersConfirmationResponse = this.handleMersConfirmationResponse.bind(this);
    this.finishProcessingTransaction = this.finishProcessingTransaction.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<IVaultMersTransactionResultsPopupProps>): void {
    if (this.props.open && prevProps.request !== this.props.request) {
      this.setState({
        closable: false,
        contentItems: [],
        loading: true,
      });
      this.pendingRequestCount = this.props.request.transfers.length;
      this.executeRequest();
    }
  }

  private onCloseClick() {
    if (this.props.onClosed) {
      this.props.onClosed();
    }
  }

  /**
   * Make the Mers transaction request
   * @returns {void}
   */
  private executeRequest() {
    switch (this.props.request.type) {
      case MersTransactionType.SecuredParty:
        _.each(
          this.props.request.transfers as IMersSecuredPartyTransaction[],
          (mersPackage: IMersSecuredPartyTransaction) => {
            securedPartyAction(mersPackage)
              .then((response) => {
                this.handleSecuredPartyConfirmationResponse(response, mersPackage);
              })
              .catch((error) => {
                this.finishProcessingTransaction(error);
                console.error("Secured party action error", error);
                console.log(error);
              });
          }
        );
        break;
      case MersTransactionType.Transfer:
        _.each(this.props.request.transfers as IMersConfirmationRequest[], (transfer: IMersConfirmationRequest) => {
          const request: IMersManualTransferTransaction = {
            confirmationStatus: transfer.action,
            packageId: transfer.packageId,
            transferId: transfer.transferId,
          };

          manualConfirmationMERSTransfer(request)
            .then((response) => {
              this.handleMersConfirmationResponse(response, request, transfer.min);
            })
            .catch((error) => {
              this.finishProcessingTransaction(error);
              console.error("Confirmation Error", error);
              console.log(error);
            });
        });
        break;
    }
  }

  private getStatus(confirmationStatus: MersConfirmationStatus, status: MersTransactionStatus): JSX.Element | null {
    let result: JSX.Element | null = null;
    switch (confirmationStatus) {
      case MersConfirmationStatus.Accepted:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Accept Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Accepted</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case MersConfirmationStatus.Approve:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Approve Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Approved</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case MersConfirmationStatus.Rejected:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Reject Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Rejected</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case MersConfirmationStatus.Deny:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Deny Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Denied</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case MersConfirmationStatus.Pending:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Reset Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Reset</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case MersConfirmationStatus.None:
        result = <span className="title-fail">Unknown</span>;
        break;
    }
    return result;
  }

  /**
   * Handle the response result from the Mers transaction request
   * @param responses the MERS confirmation responses
   * @param request The MERS transfer transaction
   * @returns {void}
   */
  private handleSecuredPartyConfirmationResponse(
    responses: IMersConfirmationResponse[],
    request: IMersSecuredPartyTransaction
  ) {
    let results;

    if (Array.isArray(responses)) {
      results = responses;
    } else {
      results = [responses];
    }

    const contentItems: IContentItem[] = this.state.contentItems;

    _.each(results, (response) => {
      if (_.size(response.error) || _.size(response.errors)) {
        const newContentItem: IContentItem = {
          confirmationStatus: request.confirmationStatus,
          min: request.min,
          status: MersTransactionStatus.Failed,
          transferId: request.transferId,
        };
        contentItems.push(newContentItem);
      } else {
        _.each(response.transactionResults, (result) => {
          const newContentItem: IContentItem = {
            confirmationStatus: request.confirmationStatus,
            min: request.min,
            status: getMersTransactionStatusFromString(result.status),
            transferId: request.transferId,
          };
          contentItems.push(newContentItem);
        });
      }

      this.finishProcessingTransaction();
    });

    this.setState({
      contentItems,
    });
  }

  /**
   * Handle the response result from the Mers transaction request
   * @param responses the MERS confirmation responses
   * @param request The MERS transfer transaction
   * @param min the min
   * @returns {void}
   */
  private handleMersConfirmationResponse(
    responses: IMersConfirmationResponse[],
    request: IMersManualTransferTransaction,
    min: string
  ) {
    let results;

    if (Array.isArray(responses)) {
      results = responses;
    } else {
      results = [responses];
    }

    const contentItems: IContentItem[] = this.state.contentItems;

    _.each(results, (response) => {
      if (_.size(response.error) || _.size(response.errors)) {
        const newContentItem: IContentItem = {
          confirmationStatus: request.confirmationStatus,
          min,
          status: MersTransactionStatus.Failed,
          transferId: request.transferId,
        };
        contentItems.push(newContentItem);
      } else {
        _.each(response.transactionResults, (result) => {
          const newContentItem: IContentItem = {
            confirmationStatus: request.confirmationStatus,
            min,
            status: getMersTransactionStatusFromString(result.status),
            transferId: request.transferId,
          };
          contentItems.push(newContentItem);
        });
      }

      this.finishProcessingTransaction();
    });

    this.setState({
      contentItems,
    });
  }

  /**
   * A Mers request's processing has been completed
   * @param error Error (Optional)
   * @returns {void}
   */
  private finishProcessingTransaction(error: Error | null = null) {
    if (0 === --this.pendingRequestCount) {
      this.setState({
        closable: true,
        error: error,
        loading: false,
      });
    }
  }

  public render(): ReactElement {
    const groupedResults = _.groupBy(this.state.contentItems, "min");

    const items: JSX.Element[] = [];

    _.each(groupedResults, (resultStack, min) => {
      const itemNumber = items.length + 1;
      let parentStatus: JSX.Element | null = null;

      _.each(resultStack, (result) => {
        parentStatus = this.getStatus(result.confirmationStatus, result.status);
      });

      items.push(
        <div
          key={"merstransaction-item-" + itemNumber}
          className="modalpopup-mers-confirmation-results-dialog-content-item"
        >
          <div className="modalpopup-mers-confirmation-results-dialog-content-item-title">
            <span>
              {itemNumber}. MIN: {min},{" "}
            </span>
            {parentStatus}
          </div>
        </div>
      );
    });

    // Content can be error or items
    let content;
    if (this.state.error) {
      content = getErrorMessage(this.state.error);
    } else {
      content = items;
    }
    content = (
      <div>
        {this.state.loading && <Loading />}
        {content}
      </div>
    );

    return (
      <ModalPopupAlert
        confirmationButtonText="CLOSE"
        disabled={!this.state.closable}
        onClose={this.onCloseClick}
        overlayCloseClick={this.state.closable}
        title="Confirmation results"
        content={content}
        open={this.props.open}
        size="sm"
      />
    );
  }
}
