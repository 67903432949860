import React, { ReactElement, useEffect, useState } from "react";

import { ConnectedProps, connect, useDispatch } from "react-redux";

import AddRecipientIcon from "@mui/icons-material/PersonAdd";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { AppDispatch, RootState } from "App/Store";

import ScrollContainer from "Components/ScrollContainer";

import { IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

import { getMersRecipients } from "../mersOrgService";
import MersRecipientsCreateModal from "./MersRecipientCreateModal";
import MersRecipientSaveBar from "./MersRecipientSaveBar";
import MersRecipientsTable from "./MersRecipientsTable";
import { saveMersRecipients } from "./mersOrgService";

const mapStateToProps = (state: RootState) => ({
  details: getMersRecipients(state),
  isLoading: state.mersRecipients.isLoading,
  mersOriginators: state.mersOriginators.originators,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersRecipients(props: TypesFromRedux): ReactElement {
  const dispatch = useDispatch() as AppDispatch;
  const { details, isLoading } = props;

  const [recipients, setRecipients] = useState<IMersRecipientDetails[]>([]);
  const [originalData, setOriginalData] = useState<IMersRecipientDetails[]>(details);
  const [createRecipientModalOpen, setCreateRecipientModalOpen] = useState(false);

  useEffect(() => {
    setOriginalData(details);
    setRecipients(details);
  }, [details]);

  function handleSaveOrgsClick(changedMERSOrgs: IMersRecipientDetails[]) {
    dispatch(saveMersRecipients(changedMERSOrgs));
  }

  function handleRecipientsChange(recipients: IMersRecipientDetails[]) {
    setRecipients(recipients);
  }

  const handleOpenCreateModal = () => setCreateRecipientModalOpen(true);
  const handleCloseCreateModal = () => setCreateRecipientModalOpen(false);

  return (
    <>
      <Grid container item justifyContent="flex-end" alignItems="center">
        <Tooltip title="Create Recipient">
          <IconButton onClick={handleOpenCreateModal} sx={{ m: 0.5 }}>
            <AddRecipientIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <ScrollContainer>
        <MersRecipientsTable
          isLoading={isLoading}
          onRecipientsChange={handleRecipientsChange}
          recipients={recipients}
        />
      </ScrollContainer>
      <MersRecipientSaveBar
        mersOrgDetails={recipients}
        onSaveClick={handleSaveOrgsClick}
        originalDetails={originalData}
      />

      <MersRecipientsCreateModal open={createRecipientModalOpen} onClose={handleCloseCreateModal} />
    </>
  );
}

export default connector(MersRecipients);
