import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import { IHttpErrorState } from "Types/EVaultAppTypes";

import { IVaultViewAllLoansDocumentDetailData } from "../VaultInterfaces";
import { getDocuments } from "../documentService";

export interface IDocumentState {
  documentsByPackageId: {
    [key: string]: IPackageDocuments;
  };
}

interface IPackageDocuments {
  error?: IHttpErrorState;
  isLoading: boolean;
  documents: IVaultViewAllLoansDocumentDetailData[];
}

const initialDocuments: IDocumentState = {
  documentsByPackageId: {},
};

interface IDocumentResponse {
  documents: any[];
  packageId: string;
}

interface IPackageError extends IHttpErrorState {
  packageId: string;
}

export const fetchDocuments = createAsyncThunk<
  IDocumentResponse,
  string,
  {
    rejectValue: IPackageError;
  }
>("documents/fetch", async (packageId: string, thunkApi) => {
  try {
    const response = await getDocuments(packageId);
    return {
      documents: response.data.map((document: any) => {
        return {
          ...document,
          checkDisabled: document.mediaType !== "PDF",
        };
      }),
      packageId: packageId,
    };
  } catch (err) {
    const error = apiError(err) as IPackageError;
    error.packageId = packageId;
    return thunkApi.rejectWithValue(error);
  }
});

export const documentsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchDocuments.pending, (state, action) => {
      state.documentsByPackageId[action.meta.arg] = {
        documents: [],
        isLoading: true,
      };
    });
    builder.addCase(fetchDocuments.fulfilled, (state, action) => {
      const payload = action.payload;
      state.documentsByPackageId[action.meta.arg] = {
        documents: payload.documents,
        isLoading: false,
      };
    });
    builder.addCase(fetchDocuments.rejected, (state, action) => {
      state.documentsByPackageId[action.meta.arg] = {
        documents: [],
        error: action.payload,
        isLoading: false,
      };
    });
  },
  initialState: initialDocuments,
  name: "documents",
  reducers: {},
});

export default documentsSlice.reducer;
