import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { PackageAction, PackageRegistrationStatus } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import { getRegistrablePackageSearchUrl } from "Adapters/Mers/mersPackageAdapter";

import { profileSelector } from "Features/Profile/ProfileSlice";
import { addTab } from "Features/Tabs/Actions";
import { IClosedLoansSearchApiParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import {
  IMersRecipientDetails,
  IMersRegisterPackage,
  IMersRegisterTransaction,
  IVaultViewClosedLoansSearchResultData,
  RegistrationType,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";

import { canRegister } from "../mersService";
import {
  fetchPackages,
  getRegistrationQueue,
  resetLoadingState,
  setLoadingState,
  updateRegistrations,
  updateSearchBarParams,
} from "./MersRegisterSlice";

export function openRegisterTabForPackage(packageId: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsRegister = canRegister(profile.roles);
    if (rightsRegister) {
      const closedLoans = getRegisterableLoans(state.closedLoans.searchResult);
      const loan = _.find(closedLoans, { packageId });
      if (loan) {
        loan.selected = true;
      }
      dispatch(setLoadingState(false));
      dispatch(updateRegistrationQueue(closedLoans));
      dispatch(goToRegistrationTab());
    }
  };
}

export function openRegisterTabAction(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsRegister = canRegister(profile.roles);
    if (rightsRegister) {
      dispatch(resetLoadingState());
      dispatch(goToRegistrationTab());
    }
  };
}

export function getRegisterableLoans(closedLoans: IVaultViewClosedLoansSearchResultData[]): IMersRegisterPackage[] {
  const filteredLoans = _.filter(closedLoans, (loan: IVaultViewClosedLoansSearchResultData) => {
    return (
      loan.action === PackageAction.Register ||
      loan.mersData.mersRegistrationStatus === PackageRegistrationStatus.RegistrationFailed
    );
  });
  const sortedLoans = _.sortBy(filteredLoans, "loanNumber");
  return sortedLoans.map((loan) => {
    return initializeRegistrationOptionsForPackage(loan);
  });
}

function goToRegistrationTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-ClosedLoans-";
    const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.RegisterMERS, {});
    const tabData = {
      closeable: true,
      key,
      loanType: constants.CLOSED_LOANS,
      title: "Register",
      type: VaultViewClosedLoansTabTypes.RegisterMERS,
    };
    dispatch(addTab(tabData));
  };
}

export const addRegistrationsToQuue = (
  packages: IMersRegisterPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedIds = packages.map((q) => q.id);
    const updatedQueue = getRegistrationQueue(state).map((q) => {
      if (q.selected || selectedIds.indexOf(q.id) < 0) return q;
      return {
        ...q,
        selected: true,
      };
    });

    const newQueue = _.uniqWith(updatedQueue.concat(packages), (arrVal, othVal) => {
      return arrVal.loanNumber === othVal.loanNumber && arrVal.min === othVal.min;
    });
    const sortedLoans = _.sortBy(newQueue, "loanNumber");
    dispatch(updateRegistrations(sortedLoans));
  };
};

export const updateRegistrationQueue = (
  packages: IMersRegisterPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    dispatch(updateRegistrations(packages));
  };
};

function initializeRegistrationOptionsForPackage(
  loan: IVaultViewClosedLoansSearchResultData | IMersRegisterPackage
): IMersRegisterPackage {
  const defaultRecipient: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
  return {
    ...loan,
    dataPointOnly: false,
    delegateeForTransferRecipient: defaultRecipient,
    id: loan.packageId,
    securedPartyDelegateeRecipient: defaultRecipient,
    securedPartyRecipient: defaultRecipient,
    selected: false,
  };
}

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.mersRegistrations.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchRegistrations());
  };
}

export function fetchRegistrations(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mersOrgs = state.mersOriginators;
    let fetchUrl = resourceUrl as string;
    if (!resourceUrl) {
      const searchCriteria = state.mersRegistrations.searchBarParams.data;
      const sortByParams = state.mersRegistrations.sortByParams;
      const params: IClosedLoansSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      fetchUrl = getRegistrablePackageSearchUrl(params);
    }
    const request = {
      mersOrgs: mersOrgs.originators,
      url: fetchUrl,
    };
    dispatch(fetchPackages(request));
  };
}

export function getRegistrationTransactions(
  queue: IMersRegisterPackage[],
  registrationType: RegistrationType
): IMersRegisterTransaction[] {
  return queue.map((mersPackage: IMersRegisterPackage) => {
    const request: IMersRegisterTransaction = {
      dataPointOnly: registrationType === RegistrationType.DataPoint,
      loanNumber: mersPackage.loanNumber,
      packageId: mersPackage.packageId,
    };

    request.delegateeForTransferOrgId = getOrgId(mersPackage.delegateeForTransferRecipient);
    request.delegateeOrgId = getOrgId(mersPackage.servicingAgentRecipient);
    request.securedPartyOrgId = getOrgId(mersPackage.securedPartyRecipient);
    request.securedPartyDelegateeOrgId = getOrgId(mersPackage.securedPartyDelegateeRecipient);

    return request;
  });
}

function getOrgId(recipient?: IMersRecipientDetails): string | undefined {
  if (recipient && recipient.id && recipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
    return recipient.orgId;
  }
  return undefined;
}
