import React, { ReactElement } from "react";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { IVaultsSearchParams } from "Types/EVaultAppTypes";

interface IExportMenuItemProps {
  exportLimit: number;
  getUrl: (searchData: IVaultsSearchParams) => string;
  onClose: () => void;
  open: boolean;
  searchData: IVaultsSearchParams;
}

export default function ExportModal(props: IExportMenuItemProps): ReactElement {
  const { exportLimit, getUrl, onClose, open, searchData } = props;

  function handleExport() {
    const url = getUrl(searchData);
    window.open(url, "_blank");
    onClose();
  }

  return (
    <ModalPopupAlert
      title="Export results?"
      content={`Up to the first ${exportLimit.toLocaleString()} rows of search results will export to an Excel file.`}
      confirmationButtonText="EXPORT"
      onConfirm={handleExport}
      showDeclineLink={true}
      onClose={onClose}
      open={open}
      size="sm"
    />
  );
}
