/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function PenIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M17.7 7.1l-4 6.9c.5.3 1.1.1 1.4-.4l3-5.2c.3-.4.1-1-.4-1.3zM16 3.8l-1-.6c-.8-.5-1.8-.2-2.2.5L6.4 14.9C5.5 16.3 5 18 5 19.7v1.1c0 .4.4.6.8.4l.9-.6c1.5-.9 2.7-2.1 3.6-3.6l6.4-11c.4-.7.1-1.7-.7-2.2z" />
    </SvgIcon>
  );
}
