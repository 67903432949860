import React from "react";

import { startCase, upperCase } from "lodash";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EllipsesIcon from "@mui/icons-material/MoreVert";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid, Icon } from "@mui/material";
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";

import * as constants from "Common/EVaultAppConstants";
import dateTimeField from "Common/Fields/DateTimeField";
import { getMersNoteStatusViewText } from "Common/Utilities";
import { DATETIME_SHORT_WITH_OFFSET, format } from "Common/dateUtilities";

import GridCellTooltip from "Components/DataGridCustomComponents/GridCellTooltip";
import { AuthoritativeCopyYesIcon, DeliveryOutIcon, RegisterIcon, TransferIcon } from "Components/Icons";
import {
  ITablePanelColumn,
  TablePanelColumnDataType,
  TablePanelColumnOrder,
} from "Components/Panels/TablePanel/TablePanelInterfaces";
import Tooltip from "Components/Tooltip";

import localization from "./localization";

export interface DocListPayload {
  alternatePackageType: string;
  loanNumber: string;
  min: string;
  packageId: string;
  packageType: string;
  partyName: string;
  workflowType: string;
}

interface IColumns {
  contextMenu: ITablePanelColumn;
  created: ITablePanelColumn;
  loanNumber: ITablePanelColumn;
  min: ITablePanelColumn;
  primaryBorrowerName: ITablePanelColumn;
}

interface IDataGridColumns {
  acIndicator: GridColDef;
  closingDate: GridColDef;
  contextMenu: GridColDef;
  controlRecipient: GridColDef;
  controlTransferCompleted: GridColDef;
  controller: GridColDef;
  created: GridColDef;
  delegateeForTransferRecipient: GridColDef;
  delegateeForTransfers: GridColDef;
  effectiveDate: GridColDef;
  loanNumber: GridColDef;
  location: GridColDef;
  locationRecipient: GridColDef;
  min: GridColDef;
  noteStatus: GridColDef;
  originator: GridColDef;
  primaryBorrowerName: GridColDef;
  securedPartyDelegateeRecipient: GridColDef;
  securedPartyRecipient: GridColDef;
  servicer: GridColDef;
  servicingAgentRecipient: GridColDef;
  subServicer: GridColDef;
  subServicerRecipient: GridColDef;
}

/*
	DataGrid Columns
	Temporary as we migrate to using the DataGrid component. After all tables
	have been migrated, this should change to `columns` as well as the type change from
	`IDataGridColumns` to `IColumns`.
	- Andrew Mirshafiee 10/25/2021
*/
export const datagridColumns: IDataGridColumns = {
  acIndicator: {
    editable: false,
    field: "acIndicator",
    renderHeader: function renderAcIndicatorHeader() {
      return (
        <Tooltip title="Authoritative Copy">
          <Grid container item justifyContent="center" alignItems="center">
            <AuthoritativeCopyYesIcon />
          </Grid>
        </Tooltip>
      );
    },
    sortable: false,
    width: 56,
  },
  closingDate: {
    editable: false,
    field: "closingDate",
    headerName: "CLOSING",
    minWidth: 100,
    renderCell: GridCellTooltip,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const date = params.row.closingDate;
      return format(date);
    },
  },
  contextMenu: {
    align: "right",
    editable: false,
    field: "contextMenu",
    headerAlign: "right",
    sortable: false,
    width: 56,
  },
  controlRecipient: {
    editable: false,
    field: "controlRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.control),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.controlRecipient.name) {
        return (
          <Tooltip title={params.row.controlRecipient.orgId}>
            <span>{params.row.controlRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.controlRecipient.orgId;
    },
    sortable: false,
  },
  controlTransferCompleted: {
    editable: false,
    field: "controlTransferCompleted",
    flex: 1,
    headerName: "TRANSFERRED (Control)",
    minWidth: 200,
    renderCell: GridCellTooltip,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      const date = params.row.controlTransferCompleted;
      return format(date, DATETIME_SHORT_WITH_OFFSET);
    },
  },
  controller: {
    editable: false,
    field: "controller",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.controller),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      const { name, orgId } = params.row.mersData.mersInitiators.controller;
      if (name) {
        return (
          <Tooltip title={orgId}>
            <span>{name}</span>
          </Tooltip>
        );
      }
      return orgId !== constants.EMPTY_MERS_RECIPIENT.orgId ? orgId : "";
    },
    sortable: false,
  },
  created: {
    editable: false,
    field: "created",
    flex: 1,
    headerName: "CREATED",
    minWidth: 200,
    renderCell: GridCellTooltip,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return dateTimeField.renderForDisplay(params.row.created);
    },
  },
  delegateeForTransferRecipient: {
    editable: false,
    field: "delegateeForTransferRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.delegateeForTransfers),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.delegateeForTransferRecipient.name) {
        return (
          <Tooltip title={params.row.delegateeForTransferRecipient.orgId}>
            <span>{params.row.delegateeForTransferRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.delegateeForTransferRecipient.orgId;
    },
    sortable: false,
  },
  delegateeForTransfers: {
    editable: false,
    field: "delegateeForTransfers",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.delegateeForTransfers),
    renderCell: (params: GridRenderCellParams) => {
      if (!params.row.mersData.mersInitiators.delegateeForTransfer) return null;
      const { name, orgId } = params.row.mersData.mersInitiators.delegateeForTransfer;
      if (name) {
        return (
                <Tooltip title={orgId}>
                  <span>{name}</span>
                </Tooltip>
        );
      }
      return orgId !== constants.EMPTY_MERS_RECIPIENT.orgId ? orgId : "";
    },
    sortable: false,
  },
  effectiveDate: {
    editable: false,
    field: "effectiveDate",
    flex: 1,
    headerName: "EFFECTIVE DATE",
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      return format(params.row.effectiveDate, "MM/dd/yyyy");
    },
    sortable: false,
  },
  loanNumber: {
    editable: false,
    field: "loanNumber",
    flex: 1,
    headerName: "LOAN #",
    minWidth: 100,
    renderCell: GridCellTooltip,
    sortable: true,
  },
  location: {
    editable: false,
    field: "location",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.location),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      const { name, orgId } = params.row.mersData.mersInitiators.location;
      if (name) {
        return (
          <Tooltip title={orgId}>
            <span>{name}</span>
          </Tooltip>
        );
      }
      return orgId !== constants.EMPTY_MERS_RECIPIENT.orgId ? orgId : "";
    },
    sortable: false,
  },
  locationRecipient: {
    editable: false,
    field: "locationRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.location),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.locationRecipient.name) {
        return (
          <Tooltip title={params.row.locationRecipient.orgId}>
            <span>{params.row.locationRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.locationRecipient.orgId;
    },
    sortable: false,
  },
  min: {
    editable: false,
    field: "min",
    flex: 1,
    headerName: "MIN",
    minWidth: 125,
    sortable: true,
  },
  noteStatus: {
    editable: false,
    field: "noteStatus",
    flex: 1,
    headerName: "REASON",
    minWidth: 100,
    renderCell: GridCellTooltip,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      return getMersNoteStatusViewText(false, params.row.noteStatus.toLowerCase());
    },
  },
  originator: {
    editable: false,
    field: "originator",
    flex: 1,
    headerName: "ORIGINATOR",
    minWidth: 100,
    sortable: false,
  },
  primaryBorrowerName: {
    editable: false,
    field: "primaryBorrowerName",
    flex: 1,
    headerName: "PRIMARY NAME",
    minWidth: 200,
    renderCell: GridCellTooltip,
    sortable: false,
    valueGetter: (params: GridValueGetterParams) => {
      let name = params.row.primarySignerLastName;
      const nameParts = [
        params.row.primarySignerFirstName,
        params.row.primarySignerMiddleName,
        params.row.primarySignerSuffix,
      ];
      if ("" !== params.row.primarySignerFirstName || "" !== params.row.primarySignerMiddleName) {
        name += ", " + nameParts.join(" ");
      }
      return name;
    },
  },
  securedPartyDelegateeRecipient: {
    editable: false,
    field: "securedPartyDelegateeRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.securedPartyDelegatee),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.securedPartyDelegateeRecipient.name) {
        return (
          <Tooltip title={params.row.securedPartyDelegateeRecipient.orgId}>
            <span>{params.row.securedPartyDelegateeRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.securedPartyDelegateeRecipient.orgId;
    },
    sortable: false,
  },
  securedPartyRecipient: {
    editable: false,
    field: "securedPartyRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.securedParty),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.securedPartyRecipient.name) {
        return (
          <Tooltip title={params.row.securedPartyRecipient.orgId}>
            <span>{params.row.securedPartyRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.securedPartyRecipient.orgId;
    },
    sortable: false,
  },
  servicer: {
    editable: false,
    field: "servicer",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.servicer),
    renderCell: (params: GridRenderCellParams) => {
      const { name, orgId } = params.row.mersData.mersInitiators.delegatee;
      if (name) {
        return (
          <Tooltip title={orgId}>
            <span>{name}</span>
          </Tooltip>
        );
      }
      return orgId !== constants.EMPTY_MERS_RECIPIENT.orgId ? orgId : "";
    },
    sortable: false,
  },
  servicingAgentRecipient: {
    editable: false,
    field: "servicingAgentRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.servicer),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.servicingAgentRecipient.name) {
        return (
          <Tooltip title={params.row.servicingAgentRecipient.orgId}>
            <span>{params.row.servicingAgentRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.servicingAgentRecipient.orgId;
    },
    sortable: false,
  },
  subServicer: {
    editable: false,
    field: "subServicer",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.subServicer),
    renderCell: (params: GridRenderCellParams) => {
      if (!params.row.mersData.mersInitiators.subServicer) return null;
      const { name, orgId } = params.row.mersData.mersInitiators.subServicer;
      if (name) {
        return (
          <Tooltip title={orgId}>
            <span>{name}</span>
          </Tooltip>
        );
      }
      return orgId !== constants.EMPTY_MERS_RECIPIENT.orgId ? orgId : "";
    },
    sortable: false,
  },
  subServicerRecipient: {
    editable: false,
    field: "subServicerRecipient",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.subServicer),
    minWidth: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.subServicerRecipient.name) {
        return (
          <Tooltip title={params.row.subServicerRecipient.orgId}>
            <span>{params.row.subServicerRecipient.name}</span>
          </Tooltip>
        );
      }
      return params.row.subServicerRecipient.orgId;
    },
    sortable: false,
  },
};

export const columns: IColumns = {
  contextMenu: {
    accessor: "contextMenu",
    dataType: TablePanelColumnDataType.raw,
    header: <EllipsesIcon className="symbol-darkgray" />,
    shrinkToFit: true,
    sortable: false,
  },
  created: {
    accessor: "created",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.dateTime,
    header: "CREATED",
    headerTextAlignLeft: true,
    sortable: true,
  },
  loanNumber: {
    accessor: "loanNumber",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: "LOAN #",
    headerTextAlignLeft: true,
    sortable: true,
  },
  min: {
    accessor: "min",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.raw,
    header: "MIN",
    headerTextAlignLeft: true,
    sortable: true,
  },
  primaryBorrowerName: {
    accessor: "primaryBorrowerName",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: "PRIMARY NAME",
    headerTextAlignLeft: true,
    sortable: false,
  },
};

interface IQueueColumns {
  confirmation: GridColDef;
  controller: GridColDef;
  effective: GridColDef;
  initiated: GridColDef;
  location: GridColDef;
  request: GridColDef;
  sender: GridColDef;
  servicer: GridColDef;
  subservicer: GridColDef;
  transferStatus: GridColDef;
}

export const queueColumns: IQueueColumns = {
  confirmation: {
    editable: false,
    field: "confirmation",
    flex: 1,
    headerName: "CONFIRMATION",
    minWidth: 200,
    sortable: false,
  },
  controller: {
    field: "controller",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.controller),
    sortable: false,
  },
  effective: {
    field: "effective",
    flex: 1,
    headerName: "EFFECTIVE",
    sortable: true,
  },
  initiated: {
    field: "initiated",
    flex: 1,
    headerName: "INITIATED",
    renderCell: GridCellTooltip,
    sortable: true,
    valueGetter: (params: GridValueGetterParams) => {
      return dateTimeField.renderForDisplay(params.row.initiated);
    },
  },
  location: {
    field: "location",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.location),
    sortable: false,
  },
  request: {
    field: "request",
    headerName: "REQUEST",
    sortable: false,
  },
  sender: {
    field: "sender",
    flex: 1,
    headerName: "SENDER",
    sortable: true,
  },
  servicer: {
    field: "servicer",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.servicer),
    sortable: false,
  },
  subservicer: {
    field: "subservicer",
    flex: 1,
    headerName: upperCase(localization.mers.rightsHolder.subServicer),
    sortable: false,
  },
  transferStatus: {
    field: "status",
    renderHeader: function renderAcIndicatorHeader() {
      return (
        <Tooltip title="Transfer status">
          <Grid container item justifyContent="center" alignItems="center">
            <ArrowRightAltIcon />
          </Grid>
        </Tooltip>
      );
    },
    sortable: true,
    width: 56,
  },
};

interface IMersColumns {
  acIndicator: ITablePanelColumn;
  closingDate: ITablePanelColumn;
  controller: ITablePanelColumn;
  controlTransferCompleted: ITablePanelColumn;
  deliveryStatus: ITablePanelColumn;
  loanNumber: ITablePanelColumn;
  location: ITablePanelColumn;
  noteStatus: ITablePanelColumn;
  registrationStatus: ITablePanelColumn;
  servicer: ITablePanelColumn;
  transactionErrorStatus: ITablePanelColumn;
  transferStatus: ITablePanelColumn;
  originator: ITablePanelColumn;
}

export const mersColumns: IMersColumns = {
  acIndicator: {
    accessor: "acIndicator",
    bodyTextAlignLeft: false,
    dataType: TablePanelColumnDataType.raw,
    header: <AuthoritativeCopyYesIcon />,
    shrinkToFit: true,
    sortable: false,
  },
  closingDate: {
    accessor: "closingDate",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.dateTime,
    header: "CLOSING",
    headerTextAlignLeft: true,
    sortable: false,
  },
  controlTransferCompleted: {
    accessor: "controlTransferCompleted",
    bodyTextAlignLeft: true,
    className: "wrap-none",
    dataType: TablePanelColumnDataType.dateTime,
    header: "TRANSFERRED (Control)",
    headerTextAlignLeft: true,
    sortable: false,
  },
  controller: {
    accessor: "controller",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: upperCase(localization.mers.rightsHolder.controller),
    headerTextAlignLeft: true,
    sortable: false,
  },
  deliveryStatus: {
    accessor: "mersDeliveryStatus",
    dataType: TablePanelColumnDataType.raw,
    header: (
      <Tooltip title="Delivery status">
        <Icon className="symbol-darkgray">
          <DeliveryOutIcon />
        </Icon>
      </Tooltip>
    ),
    shrinkToFit: true,
    sortable: false,
  },
  loanNumber: {
    accessor: "loanNumber",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: "LOAN # / MIN",
    headerTextAlignLeft: true,
    orderBy: {
      accessors: ["loanNumber"],
      orderings: [TablePanelColumnOrder.INHERITED],
    },
    sortable: true,
  },
  location: {
    accessor: "location",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: upperCase(localization.mers.rightsHolder.location),
    headerTextAlignLeft: true,
    sortable: false,
  },
  noteStatus: {
    accessor: "noteStatus",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: "REASON",
    headerTextAlignLeft: true,
    sortable: false,
  },
  originator: {
    accessor: "originator",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: "ORIGINATOR",
    headerTextAlignLeft: true,
    sortable: false,
  },
  registrationStatus: {
    accessor: "mersRegistrationStatus",
    dataType: TablePanelColumnDataType.raw,
    header: (
      <Tooltip title="Registration status">
        <Icon className="symbol-darkgray">
          <RegisterIcon />
        </Icon>
      </Tooltip>
    ),
    shrinkToFit: true,
    sortable: false,
  },
  servicer: {
    accessor: "servicer",
    bodyTextAlignLeft: true,
    dataType: TablePanelColumnDataType.text,
    header: upperCase(localization.mers.rightsHolder.servicer),
    headerTextAlignLeft: true,
    sortable: false,
  },
  transactionErrorStatus: {
    accessor: "mersTransactionErrorStatus",
    dataType: TablePanelColumnDataType.raw,
    header: (
      <Tooltip title="Failure status">
        <Icon className="symbol-darkgray">
          <AlertIcon />
        </Icon>
      </Tooltip>
    ),
    shrinkToFit: true,
    sortable: false,
  },
  transferStatus: {
    accessor: "mersTransferStatus",
    dataType: TablePanelColumnDataType.raw,
    header: (
      <Tooltip title="Transfer status">
        <Icon className="symbol-darkgray">
          <TransferIcon />
        </Icon>
      </Tooltip>
    ),
    shrinkToFit: true,
    sortable: false,
  },
};

export const requestTypes = ["", "C / L / S", "C", "L", "S", "C / L", "C / S", "C / SP", "C / L / SP"];
export const requestTypeTooltips = [
  "",
  // eslint-disable-next-line max-len
  `${startCase(localization.mers.rightsHolder.control)} / ${startCase(
    localization.mers.rightsHolder.location
  )} / ${startCase(localization.mers.rightsHolder.servicer)}`,
  startCase(localization.mers.rightsHolder.control),
  startCase(localization.mers.rightsHolder.location),
  startCase(localization.mers.rightsHolder.servicer),
  `${startCase(localization.mers.rightsHolder.control)} / ${startCase(localization.mers.rightsHolder.location)}`,
  `${startCase(localization.mers.rightsHolder.control)} / ${startCase(localization.mers.rightsHolder.servicer)}`,
  `${startCase(localization.mers.rightsHolder.control)} / ${startCase(localization.mers.rightsHolder.securedParty)}`,
  // eslint-disable-next-line max-len
  `${startCase(localization.mers.rightsHolder.control)} / ${startCase(
    localization.mers.rightsHolder.location
  )} / ${startCase(localization.mers.rightsHolder.securedParty)}`,
];
