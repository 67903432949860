/* eslint-disable max-len */
import React, { ReactElement } from "react";

import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/TablePagination";
import { useGridApiContext, useGridState } from "@mui/x-data-grid";

import { IResultPages } from "Types/EVaultAppTypes";

interface DataGridPaginationProps {
  isLoading: boolean;
  handleNextPage: () => void;
  handlePreviousPage: () => void;
  links?: IResultPages;
}

function DataGridPagination({
  handleNextPage,
  handlePreviousPage,
  isLoading,
  links,
}: DataGridPaginationProps): ReactElement {
  const apiRef = useGridApiContext();
  const [state] = useGridState(apiRef);

  function handleLabelDisplayRows({ from, to, count }: any) {
    if (isLoading) {
      return (
        <>
          <Skeleton variant="text" sx={{ display: "inline-block", width: 25 }} /> -{" "}
          <Skeleton variant="text" sx={{ display: "inline-block", width: 25 }} /> of{" "}
          <Skeleton variant="text" sx={{ display: "inline-block", width: 25 }} />
        </>
      );
    }
    return `${from + links?.offset}-${to + links?.offset} of ${count + links?.offset}`;
  }

  function handlePageChange(event: React.MouseEvent<HTMLButtonElement> | null, value: number) {
    const currentPage = state.pagination.page;

    if (currentPage > value) {
      handlePreviousPage();
    } else if (value > currentPage) {
      handleNextPage();
    }
    apiRef.current.setPage(value - 1);
  }

  return (
    <Pagination
      component="div"
      count={state.pagination.rowCount}
      page={state.pagination.page}
      onPageChange={handlePageChange}
      labelDisplayedRows={handleLabelDisplayRows}
      rowsPerPage={100}
      rowsPerPageOptions={[100]}
      backIconButtonProps={{
        disabled: !links?.previous,
      }}
      nextIconButtonProps={{
        disabled: !links?.next,
      }}
    />
  );
}

export default DataGridPagination;
