import React, { ReactElement, useEffect, useState } from "react";

import clsx from "clsx";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { Grid, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { getMersNoteStatusViewText } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import {
  IVaultViewClosedLoansSearchResultData,
  MersChangeStatusTypes,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";
import { datagridColumns } from "Features/Vault/shared";

import MersIdentificationNumber from "../MersIdentificationNumber";
import { IMersUpdateProps } from "./MersUpdate";
import { updateChangeType } from "./MersUpdateSlice";
import { fetchUpdates, updateUpdateQueue } from "./mersUpdateService";

const mapStateToProps = (state: RootState) => ({
  error: state.mersUpdates.error,
  initialized: state.mersUpdates.initialized,
  isLoading: state.mersUpdates.isLoading,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IMersUpdateProps & TypesFromRedux;

export function UpdateStatusTable(props: Props): ReactElement {
  const keyRoot = "VaultTab-ClosedLoans-";
  const dispatch = useDispatch() as AppDispatch;
  const classes = useStyles();
  const { error, initialized, isLoading, queueList, updateSet } = props;

  const [selected, setSelected] = useState<GridSelectionModel>(
    queueList.filter((loan) => loan.selected).map((loan) => loan.packageId)
  );

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchUpdates());
    }
  }, [dispatch, initialized]);

  const columns: GridColDef[] = [
    {
      ...datagridColumns.loanNumber,
      sortable: false,
    },
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewClosedLoansSearchResultData}
          loanType={constants.CLOSED_LOANS}
          tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
      sortable: false,
    },
    datagridColumns.primaryBorrowerName,
    {
      cellClassName: (params: GridCellParams) =>
        clsx({
          invalid: params.row.selected && !params.row.changeStatus,
        }),
      field: "updateTypeSelector",
      flex: 1,
      headerName: updateSet.toUpperCase(),
      renderCell: (params: GridRenderCellParams) => {
        const changeStatusOptions: JSX.Element[] = [
          <TableMenuItem key="" value="">
            none
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.ChargedOff}
            value={MersChangeStatusTypes[MersChangeStatusTypes.ChargedOff]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.ChargedOff)}
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.ConvertedToPaper}
            value={MersChangeStatusTypes[MersChangeStatusTypes.ConvertedToPaper]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.ConvertedToPaper)}
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.PaidOff}
            value={MersChangeStatusTypes[MersChangeStatusTypes.PaidOff]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.PaidOff)}
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.PaperReplacement}
            value={MersChangeStatusTypes[MersChangeStatusTypes.PaperReplacement]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.PaperReplacement)}
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.TransferredToProprietaryRegistry}
            value={MersChangeStatusTypes[MersChangeStatusTypes.TransferredToProprietaryRegistry]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.TransferredToProprietaryRegistry)}
          </TableMenuItem>,
          <TableMenuItem
            key={MersChangeStatusTypes.RegistrationReversal}
            value={MersChangeStatusTypes[MersChangeStatusTypes.RegistrationReversal]}
          >
            {getMersNoteStatusViewText(false, MersChangeStatusTypes.RegistrationReversal)}
          </TableMenuItem>,
        ];

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <TableSelect
              displayEmpty={true}
              fullWidth
              value={params.row.changeStatus || ""}
              name="changeStatus"
              onChange={(e) => handleENoteUpdateChange(e, params.row)}
              size="small"
              variant="standard"
            >
              {changeStatusOptions}
            </TableSelect>
          </Grid>
        );
      },
      sortable: false,
    },
  ];

  function handleENoteUpdateChange(e: any, loan: any) {
    dispatch(
      updateChangeType({
        packageId: loan.packageId,
        property: e.target.name,
        value: e.target.value,
      })
    );
  }

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
    const updates = queueList.map((queue) => {
      return {
        ...queue,
        selected: selected.includes(queue.packageId),
      };
    });
    dispatch(updateUpdateQueue(updates));
  };

  return (
    <DataGrid
      autoHeight
      checkboxSelection
      classes={{
        root: classes.root,
      }}
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
      }}
      componentsProps={{
        errorOverlay: { error },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      editMode="row"
      error={error}
      hideFooter
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selected}
      rows={queueList}
      scrollbarSize={0}
    />
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .invalid": {
      background: `${theme.palette.error.main}20`,
    },
  },
}));

export default connector(UpdateStatusTable);
