/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";

import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";

import CompleteIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import IncompleteIcon from "@mui/icons-material/RadioButtonUnchecked";
import NotApplicableIcon from "@mui/icons-material/RemoveCircle";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowParams, GridSortModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey, getFullDocumentPackageTypeDescription } from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "Components/DataGridCustomComponents/Pagination";
import { PartialIcon, UnknownIcon } from "Components/Icons";

import { getPackagesExportUrl } from "Adapters/packageAdapter";

import { profileSelector } from "Features/Profile/ProfileSlice";
import { updateSelectedPackageId } from "Features/Vault/AllLoans/AllLoansSlice";
import TableContextMenu from "Features/Vault/AllLoans/TableContextMenu";
import { executeSearch, fetchAllLoans, updateSortByAction } from "Features/Vault/AllLoans/allLoansService";
import * as allLoansService from "Features/Vault/AllLoans/allLoansService";

import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import { canUserDownload } from "../documentService";
import { openTabForDocList } from "../documentService";
import { datagridColumns } from "../shared";
import AllLoansTableRowContextMenu from "./AllLoansTableRowContextMenu";
import { VaultViewAllLoansTabTypes } from "./Constants";
import { IVaultViewAllLoansSearchResultData, IVaultViewAllLoansSearchResultTableData } from "./Types";

interface ISearchResultsTableProps {
  keyRoot: string;
}

const mapStateToProps = (state: RootState) => {
  const profile = profileSelector(state);
  return {
    canDownload: canUserDownload(state),
    displayActivity: profile.settings.application.activityPanelEnabled ?? false,
    displayAltPackage: profile.settings.application.displayAltPackage ?? false,
    error: state.allLoans.error,
    initialized: state.allLoans.initialized,
    isLoading: state.allLoans.isLoading,
    links: state.allLoans.links,
  };
};

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux & ISearchResultsTableProps;

function SearchResultsTable(props: Props) {
  const { canDownload, displayActivity, displayAltPackage, error, initialized, isLoading, links, keyRoot } = props;
  const dispatch = useDispatch() as AppDispatch;
  const searchResults = useSelector((state: RootState) =>
    state.allLoans.searchResult.map((loan) => ({ ...loan, id: loan.packageId }))
  );

  useEffect(() => {
    if (!initialized) {
      dispatch(allLoansService.fetchAllLoans());
    }
  }, [dispatch, initialized]);

  function handleNextPage() {
    dispatch(fetchAllLoans(links?.next));
  }

  function handlePreviousPage() {
    dispatch(fetchAllLoans(links?.previous));
  }

  async function handleShowDocList(rowData: IVaultViewAllLoansSearchResultData) {
    selectRow(rowData);
    const key: string = createTabKey(keyRoot, VaultViewAllLoansTabTypes.DocumentList, rowData);
    const payload = {
      displayActivityPanel: true,
      key,
      loanType: constants.ALL_LOANS,
      packageId: rowData.id,
      rowData,
      type: VaultViewAllLoansTabTypes.DocumentList,
    };
    await dispatch(openTabForDocList(payload));
    dispatch(allLoansService.onShowActivity(rowData.packageId));
  }

  function handleShowActivity(rowData: IVaultViewAllLoansSearchResultData): void {
    dispatch(allLoansService.onShowActivity(rowData.packageId));
  }

  function handleRowDoubleClick(params: GridRowParams): void {
    dispatch(updateSelectedPackageId(params.row.packageId));
  }

  function handleInquiryClick(rowData: IVaultViewAllLoansSearchResultData): void {
    selectRow(rowData);
    allLoansService.onShowActivity(rowData.packageId);
  }

  function selectRow(rowData: IVaultViewAllLoansSearchResultTableData | IVaultViewAllLoansSearchResultData): void {
    dispatch(updateSelectedPackageId(rowData.packageId));
  }

  const columns: GridColDef[] = [
    datagridColumns.loanNumber,
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewAllLoansSearchResultData}
          loanType={constants.ALL_LOANS}
          onClick={() => handleInquiryClick(params.row.loanNumber)}
          tabKeyRoot={VaultViewAllLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
    },
    datagridColumns.primaryBorrowerName,
    datagridColumns.created,
    {
      field: "packageLink",
      flex: 1,
      headerName: "PACKAGE",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        let packageTypeDescription;
        switch (params.row.packageType.toLowerCase()) {
          case "closing":
            packageTypeDescription = "Closing";
            break;
          case "initial disclosure":
            packageTypeDescription = "Initials";
            break;
          default:
            packageTypeDescription = params.row.packageType;
            break;
        }

        const fullPackageTypeDescription = getFullDocumentPackageTypeDescription(
          displayAltPackage,
          packageTypeDescription,
          params.row.alternatePackageType
        );
        return <span>{fullPackageTypeDescription}</span>;
      },
      sortable: false,
    },
    {
      align: "center",
      field: "signStatus",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        let signedElement;
        switch (params.row.signatureStatus.toLowerCase()) {
          case "fullysigned":
            signedElement = (
              <Tooltip title="Complete">
                <CompleteIcon fontSize="small" />
              </Tooltip>
            );
            break;

          case "notsignable":
            signedElement = (
              <Tooltip title="Not eSignable">
                <NotApplicableIcon fontSize="small" />
              </Tooltip>
            );
            break;

          case "partiallysigned":
            signedElement = (
              <Tooltip title="Partial">
                <PartialIcon fontSize="small" />
              </Tooltip>
            );
            break;

          case "unsigned":
            signedElement = (
              <Tooltip title="None complete">
                <IncompleteIcon fontSize="small" />
              </Tooltip>
            );
            break;

          default:
            signedElement = (
              <Tooltip title={params.row.signatureStatus.toLowerCase()}>
                <UnknownIcon />
              </Tooltip>
            );
            break;
        }
        return signedElement;
      },
      renderHeader: function renderEsignStatusHeader() {
        return (
          <Tooltip title="eSign status">
            <EditIcon sx={{ color: (theme) => theme.palette.grey[700] }} />
          </Tooltip>
        );
      },
      sortable: false,
      width: 56,
    },
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <AllLoansTableRowContextMenu
            canDownload={canDownload}
            includeActivity={displayActivity}
            item={params.row as IVaultViewAllLoansSearchResultData}
            onShowActivity={handleShowActivity}
            onShowDocList={handleShowDocList}
            rowData={params.row as IVaultViewAllLoansSearchResultData}
          />
        );
      },
      renderHeader: function renderContextMenuHeader() {
        return <TableContextMenu getExportUrl={getPackagesExportUrl} />;
      },
    },
  ];

  function onSortChange(model: GridSortModel) {
    if (model[0]) {
      dispatch(updateSortByAction(model[0]));
    }
  }

  function handleRetry() {
    dispatch(executeSearch());
  }

  return (
    <DataGrid
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
        Pagination: Pagination,
      }}
      componentsProps={{
        errorOverlay: { error, onRetry: handleRetry },
        pagination: { handleNextPage, handlePreviousPage, isLoading, links },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      error={error}
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onRowDoubleClick={handleRowDoubleClick}
      onSortModelChange={onSortChange}
      pagination
      paginationMode="server"
      rows={searchResults}
      rowsPerPageOptions={[100]}
      scrollbarSize={17}
      sortingMode="server"
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default connector(SearchResultsTable);
