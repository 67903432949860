/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AuthoritativeCopyNoIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M17.6 16.6l-2.7-2.7L13 12l-1.6-1.6-.8-.8L6 5 3.2 2.2 1.8 3.6 6 7.8c.5 1.8-.3 3.2-.6 4.1C3.8 17.8 12 20 12 20s2.2-.6 4.1-2l4 4 1.4-1.4-3.9-4zM10.5 13l-.6 2H8.7l1.2-3.3 1.3 1.3h-.7zM11.8 8h.9l.5 1.4 5.3 5.3c.3-.9.3-1.8 0-2.9-.2-1-1.6-3.1.5-5.8l-2-2c-1.9 1.2-3.4 1.1-5 0-1.1.8-2.2 1.1-3.5.7L11.8 8z" />
    </SvgIcon>
  );
}
