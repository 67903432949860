import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { AppDispatch, RootState } from "App/Store";

import { baseSearchCriteria } from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import { HTMLElementTypes } from "Types/EVaultAppTypes";

import { initialUpdateSearchBarParams, resetSearch, updateSearchBarParams } from "./MersUpdateSlice";
import { executeSearch } from "./mersUpdateService";

const updateFilters = [
  ...baseSearchCriteria,
  {
    label: "search",
    name: "searchKey",
    options: [
      {
        apiParamValue: "loanNumber",
        description: "Loan Number",
      },
      {
        apiParamValue: "mortgageIdentificationNumber",
        description: "MIN",
      },
      {
        apiParamValue: "namedSigner1LastName",
        description: "Signer Last Name",
      },
    ],
    type: HTMLElementTypes.DROPDOWN,
  },
];

const mapStateToProps = (state: RootState) => ({
  channels: state.channels,
  searchBarParams: state.mersUpdates.searchBarParams,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function UpdasteFilterBar(props: TypesFromRedux): ReactElement {
  const dispatch = useDispatch() as AppDispatch;
  const { channels, searchBarParams } = props;

  const [searchCriteria, setSearchCriteria] = useState<any[]>(updateFilters);

  useEffect(() => {
    if (channels.channels.length > 1) {
      const filters = updateFilters.slice(0);
      filters.push({
        name: "divider",
        type: HTMLElementTypes.DIVIDERBLOCK,
      });
      filters.push({
        label: "Channels",
        name: "channelIds",
        options: channels.channels.map((channel) => {
          return {
            apiParamValue: channel.id.toString(),
            description: channel.name,
          };
        }),
        type: HTMLElementTypes.DROPDOWNCHECKLIST,
      });
      setSearchCriteria(filters);
    }
  }, [channels.isLoading, channels.channels]);

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    dispatch(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialUpdateSearchBarParams, searchBarParams);

  return (
    <div>
      <FilterBar
        displayResetButton={displayResetButton}
        onCriteriaChange={handleCriteriaChange}
        onReset={handleReset}
        onSubmit={handleSubmit}
        searchCriterias={searchCriteria}
        searchParams={searchBarParams}
      />
    </div>
  );
}

export default connector(UpdasteFilterBar);
