import { PackageAction, PackageTransferStatus } from "Common/Enums";

import { IVaultViewClosedLoansSearchResultData } from "Features/Vault/VaultInterfaces";

export function isPendingTransfer(loan: IVaultViewClosedLoansSearchResultData): boolean {
  return (
    !(
      loan.action === PackageAction.Transfer ||
      loan.mersData.mersTransferStatus === PackageTransferStatus.TransferFailed
    ) && loan.mersData.mersTransferStatus === PackageTransferStatus.TransferPending
  );
}
