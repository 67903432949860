/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";

import ScrollContainer from "Components/ScrollContainer";

import { ITabIdentifier } from "Features/Tabs/Tabs";

import MersHistoryDetails from "./MersHistoryDetails";
import MersInquiryDetails from "./MersInquiryDetails";

export interface IMERSInquiryDetailsProp {
  min: string;
  onClose?: () => any;
  packageId: string;
  tabIdentifier: ITabIdentifier;
}

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IMERSInquiryDetailsProp & TypesFromRedux;

export function MersInquiryContainer(props: Props): ReactElement {
  const { min, packageId } = props;

  return (
    <ScrollContainer>
      <MersInquiryDetails min={min} packageId={packageId} tabIdentifier={props.tabIdentifier} />
      <MersHistoryDetails min={min} packageId={packageId} tabIdentifier={props.tabIdentifier} />
    </ScrollContainer>
  );
}

export default connector(MersInquiryContainer);
