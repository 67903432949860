import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import ClockIcon from "@mui/icons-material/AccessTime";
import CompleteIcon from "@mui/icons-material/CheckCircle";
import EllipsesIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import dateField from "Common/Fields/DateField";
import dateTimeField from "Common/Fields/DateTimeField";
import { createTabKey } from "Common/Utilities";

import { DocumentIcon, TransferIcon } from "Components/Icons";
import { BottomBar } from "Components/Notifications/BottomBar";
import ScrollContainer from "Components/ScrollContainer";
import Tooltip from "Components/Tooltip";

import { fetchIncomingTransfers } from "Features/Vault/Incoming/Transfers/IncomingTransfersSlice";

import { nonce } from "../../../../App/App";
import ErrorOverlay from "../../../../Components/DataGridCustomComponents/ErrorOverlay";
import GridCellTooltip from "../../../../Components/DataGridCustomComponents/GridCellTooltip";
import LoadingOverlay from "../../../../Components/DataGridCustomComponents/LoadingOverlay";
import ENoteElement from "../../ENoteElement";
import AuditLogMenuItem from "../../MenuItems/AuditLogMenuItem";
import MersIdentificationNumber from "../../Mers/MersIdentificationNumber";
import MersManualTransferConfirmationSelector from "../../Mers/MersManualTransferConfirmationSelector";
import TableRowContextMenu from "../../TableRowContextMenu";
import {
  IVaultViewIncomingTransferSearchResultTableData,
  IVaultViewManualTransfersSearchResultData,
  MersConfirmationStatus,
  VaultViewIncomingTabTypes,
} from "../../VaultInterfaces";
import { openTabForDocList } from "../../documentService";
import { canUserDownload } from "../../documentService";
import { datagridColumns, requestTypeTooltips, requestTypes } from "../../shared";
import { TransferConfirmationModal } from "./TransferConfirmationModal";

const mapStateToProps = (state: RootState) => ({
  canDownload: canUserDownload(state),
  error: state.incomingTransfers.error,
  incomingTransferProps: state.incomingTransfers,
  isLoading: state.incomingTransfers.isLoading,
  searchResults: state.incomingTransfers.searchResult,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export interface IIncomingTransfersTabProps {
  onViewDocListCallback: (payload?: any) => void;
}

type Props = TypesFromRedux & IIncomingTransfersTabProps;

export function IncomingTransfersTab(props: Props): ReactElement {
  const keyRoot = "VaultTab-Incoming-";
  const { dispatch, error, isLoading, searchResults } = props;
  const [tableData, setTableData] = useState<IVaultViewIncomingTransferSearchResultTableData[]>([]);
  const [showTransferConfirmation, setShowTransferConfirmation] = useState<boolean>(false);

  useEffect(() => {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchIncomingTransfers());
  }, [dispatch]);

  useEffect(() => {
    populateSearchResult(searchResults);
  }, [searchResults]);

  async function showDocList(response: IVaultViewManualTransfersSearchResultData) {
    const data = {
      loanNumber: response.loanNumber,
      min: response.min,
      packageId: response.packageId,
      packageType: "Closing",
    };
    const key: string = createTabKey(keyRoot, VaultViewIncomingTabTypes.DocumentList, data);
    const payload = {
      key,
      loanType: constants.INCOMING_DELIVERIES_TRANSFERS,
      rowData: response,
      type: VaultViewIncomingTabTypes.DocumentList,
    };
    await (dispatch as ThunkDispatch<any, undefined, AnyAction>)(openTabForDocList(payload));
  }

  function populateSearchResult(responses: IVaultViewManualTransfersSearchResultData[]): void {
    const tableData = responses.map((response: IVaultViewManualTransfersSearchResultData) => {
      // Created date formatting
      const createdSortable = dateTimeField.sortable(response.createDate);

      // Closing date formatting
      const effectiveDate = dateField.renderForTable(response.effectiveDate);
      const effectiveDateSortable = dateTimeField.sortable(response.effectiveDate);

      const pending = response.status === 3;
      let actions: MersConfirmationStatus[];
      let action: MersConfirmationStatus;

      if (pending) {
        actions = [MersConfirmationStatus.None, MersConfirmationStatus.Accepted, MersConfirmationStatus.Rejected];
        action = MersConfirmationStatus.None;
      } else {
        actions = [MersConfirmationStatus.None, MersConfirmationStatus.Pending];
        action = MersConfirmationStatus.None;
      }

      const data: IVaultViewIncomingTransferSearchResultTableData = {
        action,
        actions,
        controllerOrgId: response.controllerOrgId,
        createDate: response.createDate,
        delegateeOrgId: response.delegateeOrgId,
        documentId: response.documentId,
        effectiveDate,
        effectiveDateSortable,
        id: response.id,
        initiatedSortable: createdSortable,
        locationOrgId: response.locationOrgId,
        mersTransferStatusValue: response.status,
        min: response.min,
        packageId: response.packageId,
        parentTransfer: response.transferId,
        request: response.request,
        requestorOrgId: response.requestorOrgId,
        transferId: response.transferId,
      };

      return data;
    });
    setTableData(tableData);
  }

  function handleChange(action: MersConfirmationStatus, transfer: string) {
    const updatedData = _.map(tableData, (resultTransfer: IVaultViewIncomingTransferSearchResultTableData) => {
      if (resultTransfer.transferId !== transfer) {
        return resultTransfer;
      }
      const updatedTransfer = _.clone(resultTransfer);
      updatedTransfer.action = action;
      updatedTransfer.parentTransfer = transfer;
      return updatedTransfer;
    });
    setTableData(updatedData);
  }

  function handleSubmitClick(): void {
    setShowTransferConfirmation(true);
  }

  function hideConfirmation(): void {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchIncomingTransfers());
    setShowTransferConfirmation(false);
  }

  function passesValidation(): boolean {
    return _.some(tableData, function (item) {
      return item.action !== MersConfirmationStatus.None;
    });
  }

  const incomingTransferColumns: GridColDef[] = [
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row}
          loanType={constants.INCOMING_DELIVERIES_TRANSFERS}
          tabKeyRoot={VaultViewIncomingTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
      sortable: true,
    },
    {
      ...datagridColumns.created,
      field: "createDate",
      headerName: "INITIATED",
      valueGetter: (params: GridValueGetterParams) => {
        return dateTimeField.renderForDisplay(params.row.createDate);
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "effectiveDate",
      headerName: "EFFECTIVE",
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.effectiveDate;
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "requestorOrgId",
      headerName: "REQUESTOR",
      renderCell: GridCellTooltip,
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.requestorOrgId;
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "request",
      headerName: "REQUEST",
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        const requestTypeTooltip = requestTypeTooltips[response.request];
        const request = requestTypes[response.request];
        return (
          <Tooltip title={requestTypeTooltip}>
            <span>{request}</span>
          </Tooltip>
        );
      },
    },
    {
      ...datagridColumns.loanNumber,
      field: "eNoteElement",
      headerName: "ENOTE",
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        return (
          <ENoteElement itemId={response.documentId} packageId={response.packageId} canDownload={props.canDownload} />
        );
      },
      sortable: false,
    },
    {
      editable: false,
      field: "mersTransferStatus",
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        const pending = response.mersTransferStatusValue === 3;
        let statusElement;
        if (pending) {
          statusElement = (
            <Tooltip title="Pending accept or reject">
              <ClockIcon fontSize="small" />
            </Tooltip>
          );
        } else {
          statusElement = (
            <Tooltip title="Accepted">
              <CompleteIcon fontSize="small" />
            </Tooltip>
          );
        }
        return statusElement;
      },
      renderHeader: function renderTransferStatusHeader() {
        return (
          <Tooltip title="Transfer status">
            <TransferIcon />
          </Tooltip>
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.loanNumber,
      field: "confirmation",
      headerName: "CONFIRMATION",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<undefined, IVaultViewIncomingTransferSearchResultTableData>) => {
        const response = params.row;
        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersManualTransferConfirmationSelector
              packageId={response.packageId}
              parentTransfer={response.parentTransfer}
              currentAction={response.action}
              actions={response.actions}
              onConfirmationStatusChanged={handleChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => {
        const response = params.row;
        return (
          <TableRowContextMenu
            actions={[
              {
                disabled: !params.row.packageId,
                icon: <DocumentIcon />,
                onClick: () => {
                  showDocList(response);
                },
                title: "Docs",
              },
              <AuditLogMenuItem disabled={!response.packageId} key="auditlog" data={response} />,
            ]}
          />
        );
      },
      renderHeader: function renderContextMenuHeader() {
        return <EllipsesIcon />;
      },
    },
  ];

  return (
    <>
      <ScrollContainer>
        <DataGrid
          columnBuffer={0}
          columns={incomingTransferColumns}
          components={{
            ErrorOverlay: ErrorOverlay,
            LoadingOverlay: LoadingOverlay,
          }}
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          error={error}
          loading={isLoading}
          localeText={{
            noRowsLabel: "No results found",
          }}
          nonce={nonce}
          pagination
          rows={tableData}
          rowsPerPageOptions={[100]}
          rowCount={tableData.length}
          scrollbarSize={17}
          sortingOrder={["desc", "asc"]}
        />
      </ScrollContainer>

      <BottomBar
        contents="Choose how to confirm these incoming transfers."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation(),
            onClick: handleSubmitClick,
            text: "SUBMIT",
          },
        ]}
      />
      <TransferConfirmationModal
        data={tableData}
        incomingTransferProps={props.incomingTransferProps}
        showTransferConfirmation={showTransferConfirmation}
        onHideConfirmation={hideConfirmation}
      />
    </>
  );
}

export default connector(IncomingTransfersTab);
