import React, { ReactElement, useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";
import ScrollContainer from "Components/ScrollContainer";

import * as actions from "Features/Tabs/Actions";
import * as tabActions from "Features/Tabs/Tabs";
import { ILoanTabDetails } from "Features/Tabs/Tabs";

import { fetchOriginators } from "../Mers/MersOriginatorSlice";
import TabStore from "../TabStore";
import { VaultViewArchivedLoansTabTypes } from "../VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  tabs: state.tabs[constants.ARCHIVED_LOANS],
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

const keyRoot = "VaultTab-ArchivedLoans-";

const SearchResultsTab = {
  key: createTabKey(keyRoot, VaultViewArchivedLoansTabTypes.SearchResults),
  loanType: constants.ARCHIVED_LOANS,
  title: "Archived",
  type: VaultViewArchivedLoansTabTypes.SearchResults,
};

export function VaultViewArchivedLoans(props: TypesFromRedux): ReactElement {
  const { dispatch, tabs } = props;

  // Add Search Results as first tab on original mount and make initial query
  useEffect(() => {
    if (!tabs.byId[SearchResultsTab.key]) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(SearchResultsTab));
    }
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchOriginators());
  }, [dispatch, tabs]);

  /**
   * Click handler for switching tabs
   * @param tab the tab we are clicking
   * @returns {void}
   */
  function onTabClick(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.goToTab({
        key: tab.key,
        loanType: constants.ARCHIVED_LOANS,
      })
    );
  }

  /**
   * Callback event for when a tab is closed
   * @param tab the tab to close
   * @returns {void}
   */
  function handleTabClosed(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.removeTab({
        key: tab.key,
        loanType: constants.ARCHIVED_LOANS,
      })
    );
  }

  return (
    <ScrollContainer>
      <TabPanel
        currentTab={tabs.currentTab}
        onTabClick={onTabClick}
        onTabClosed={handleTabClosed}
        tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
      >
        <TabStore tab={tabs.byId[tabs.currentTab]} />
      </TabPanel>
    </ScrollContainer>
  );
}

export default connector(VaultViewArchivedLoans);
