/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function RecipientsIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M13.3,14.3c-1.3-0.5-3.4-1-3.4-1s-0.3-0.6-0.3-0.8c1.1-0.6,1.9-1.9,1.9-3.4C11.4,6.3,9.9,5,8,5C6.1,5,4.5,6.3,4.5,9.2   c0,1.5,0.8,2.8,1.9,3.4c-0.1,0.2-0.3,0.8-0.3,0.8s-2.1,0.4-3.4,1c-1,0.4-1.7,1.4-1.7,2.4V19h14l0-2.3C15,15.7,14.3,14.7,13.3,14.3z    M23,17.1c0-0.9-0.6-1.7-1.4-2.1c-1.1-0.5-2.9-0.8-2.9-0.8s-0.2-0.5-0.3-0.7c0.9-0.5,1.6-1.6,1.6-2.9C19.9,8.1,18.6,7,17,7   c-1.6,0-2.9,1.1-2.9,3.6c0,0.9,0.4,1.8,0.9,2.4c0,0,0.7,0.5,0.7,0.5c0.8,0.8,1.3,2,1.4,3.2l0,2.3h6L23,17.1z"
      />
    </SvgIcon>
  );
}
