import React, { ReactElement } from "react";

import { Icon, IconButton } from "@mui/material";

import dateField from "Common/Fields/DateField";
import { DATE_MED } from "Common/dateUtilities";

import { DocumentIcon, ExternalLinkIcon } from "Components/Icons";

import { ILoanActivityEvent } from "Features/Vault/VaultInterfaces";

interface IActivityPanelProps {
  event: ILoanActivityEvent;
  onShowDocList: () => void;
}

export default function ActivityPanelEvent(props: IActivityPanelProps): ReactElement {
  const date = dateField.renderForDisplay(props.event.date, DATE_MED);

  return (
    <div className="activity-panel-event">
      <Icon className="activity-panel-event-icon">
        <DocumentIcon />
      </Icon>
      <div className="activity-panel-event-info">
        <div className="activity-panel-event-name">{props.event.name}</div>
        <div className="activity-panel-event-date">{date}</div>
      </div>
      <IconButton onClick={props.onShowDocList} className="activity-panel-event-doc-list" size="small">
        <ExternalLinkIcon />
      </IconButton>
    </div>
  );
}
