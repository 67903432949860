import React, { ReactElement } from "react";

import CompleteIcon from "@mui/icons-material/CheckCircle";
import MoveToInbox from "@mui/icons-material/MoveToInbox";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { PackageTransferStatus } from "Common/Enums";
import { getTransferStatusDisplay } from "Common/Utilities";

import { InProgressIcon, UnknownIcon } from "Components/Icons";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { IVaultViewClosedLoansSearchResultData } from "../VaultInterfaces";
import IncomingTransferDetailsModal from "./IncomingTransferDetailsModal";
import TransferDetailsModal from "./TransferDetailsModal";

interface IRegistrationDetails {
  data: IVaultViewClosedLoansSearchResultData;
  keyRoot: string;
  onShowTransferCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

export default function TransferDetails(props: IRegistrationDetails): ReactElement | null {
  const { data } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  let icon;
  const noteTip = getTransferStatusDisplay(data.mersData.mersTransferStatus, data.mersData);

  let detailsModal;
  if (data.mersData.incomingTransferTransaction.referenceId) {
    icon = <MoveToInbox />;
    detailsModal = (
      <IncomingTransferDetailsModal
        data={data}
        keyRoot={props.keyRoot}
        onClose={handleClose}
        onShowTransferCancelConfirmModal={props.onShowTransferCancelConfirmModal}
      />
    );
  } else {
    switch (data.mersData.mersTransferStatus) {
      case PackageTransferStatus.NotTransferred:
        return null;
      case PackageTransferStatus.TransferFailed:
        icon = <AlertIcon color="error" />;
        break;
      case PackageTransferStatus.Transferred:
        icon = <CompleteIcon fontSize="small" />;
        break;
      case PackageTransferStatus.TransferPending:
        icon = <InProgressIcon />;
        break;
      default:
        icon = <UnknownIcon />;
        break;
    }
    detailsModal = (
      <TransferDetailsModal
        data={data}
        keyRoot={props.keyRoot}
        onClose={handleClose}
        onShowTransferCancelConfirmModal={props.onShowTransferCancelConfirmModal}
      />
    );
  }

  return (
    <>
      <Tooltip title={noteTip}>
        <Grid container item justifyContent="center" alignItems="center">
          <IconButton className="success-icon-button" onClick={handleOpen} size="small">
            {icon}
          </IconButton>
        </Grid>
      </Tooltip>
      <ModalPopupAlert
        confirmationButtonText="CLOSE"
        onClose={handleClose}
        open={open}
        size="xs"
        title="Transfer details"
      >
        {detailsModal}
      </ModalPopupAlert>
    </>
  );
}
