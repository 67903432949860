/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { formatInTimeZone } from "date-fns-tz";
import { ConnectedProps, connect } from "react-redux";

import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { getPackageHistory } from "Adapters/Mers/mersAdapter";

import { ITabIdentifier } from "Features/Tabs/Tabs";

import { nonce } from "../../App/App";
import { getDisplayForMersTransactionType } from "../../Common/Utilities";
import RegistrationDetails from "./RegistrationDetails";
import TransactionDetails from "./TransactionDetails";
import { IMersTransactionDetailedData, MersDatabaseTransactionType } from "./VaultInterfaces";

export interface IMERSInquiryDetailsProp {
  min: string;
  onClose?: () => any;
  packageId: string;
  tabIdentifier: ITabIdentifier;
}

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IMERSInquiryDetailsProp & TypesFromRedux;

export function MersHistoryDetails(props: Props): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { min, packageId } = props;
  const [history, setHistory] = useState<IMersTransactionDetailedData[]>([]);

  useEffect(() => {
    async function requestRegistrationHistory() {
      try {
        const history = await getPackageHistory(packageId);
        if (history.status < 400) {
          const transactions = history.data.filter(
            (d) => d.registration || d.type !== MersDatabaseTransactionType.Inquiry
          );
          setHistory(transactions);
          setIsLoading(false);
        }
      } catch (_) {
        setIsLoading(false);
        console.error(_);
      }
    }

    if (isLoading) {
      void requestRegistrationHistory();
    }
  }, [isLoading, min, packageId]);

  const columns: GridColDef[] = [
    {
      field: "requestingOrg",
      flex: 1,
      headerName: "Requesting Org",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.initiator?.name;
      },
    },
    {
      field: "initiatedDate",
      flex: 1,
      headerName: "Initiated",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.value);
        if (date > new Date()) {
          return "current";
        }
        return formatInTimeZone(date, "America/Boise", "MM/dd/yyyy hh:mm:ss:SS a zzz");
      },
    },
    {
      field: "completionDate",
      flex: 1,
      headerName: "Completion Date",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        const date = new Date(params.value);
        if (date > new Date()) {
          return "current";
        }
        return formatInTimeZone(date, "America/Boise", "MM/dd/yyyy hh:mm:ss:SS a zzz");
      },
    },
    {
      field: "type",
      flex: 1,
      headerName: "Transaction Type",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return getDisplayForMersTransactionType(params.value);
      },
    },
    {
      field: "status",
      flex: 1,
      headerName: "Transaction Status",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        return <TransactionDetails details={params.row} packageId={packageId} />;
      },
    },
    {
      field: "registrationDetails",
      flex: 1,
      headerName: "Registration Info",
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.registration == null) return null;
        return <RegistrationDetails details={params.row.registration} />;
      },
    },
  ];
  return (
    <Box sx={{ minHeight: 400 }}>
      <DataGrid
        columnBuffer={0}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        loading={isLoading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        rows={history}
        scrollbarSize={17}
      />
    </Box>
  );
}

export default connector(MersHistoryDetails);
