import * as React from "react";
import { useState } from "react";

import _ from "lodash";

import { Box, TextField } from "@mui/material";

import UploadButton from "../../../../../Components/UploadButton";
import BatchErrorResults from "../../BatchErrorResults";

const RESULTS_LIMIT = 5_000;

interface IFluentValidationError {
  errorMessage: string;
  propertyName: string;
}
export interface IFluentValidationException {
  isValid: boolean;
  errors: IFluentValidationError[];
}

export interface IFileDetails {
  name: string;
  contents: string;
}

interface IBatchUploadDialogContentProps {
  errorMessages?: string[] | IFluentValidationException[];
  onDetailsChange: (details: IFileDetails | undefined) => void;
  value?: IFileDetails;
}
const fileTypes: string[] = ["text/csv", ".csv", "application/vnd.ms-excel"];

/**
 * Renders the component used to perform the batch import request.
 */
function BatchUploadDialogContent(props: IBatchUploadDialogContentProps): JSX.Element {
  const { errorMessages } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  /**
   * Handles errors that are returned from selecting the file for upload.
   */
  function handleUploadError(error: string): void {
    setErrorMessage(error);
  }

  /**
   * Handles the event when a file has been successfully chosen to upload.
   */
  function handleOnUpload(file: File) {
    props.onDetailsChange(undefined);
    if (!fileTypes.includes(file.type) && file.name.indexOf(".csv") === -1) {
      setErrorMessage("Invalid file type");
      return;
    }
    const reader = new FileReader();
    reader.onload = function () {
      const text = reader.result as string;

      let results: string[] = [];
      const rows = text.split("\n");
      rows.forEach((row: string) => {
        const values = row.split(",");
        if (values.length > 0 && values[0] !== "") {
          const value = values[0].replace("\r", "").replace("\n", "");
          results.push(value);
        }
      });

      if (results.length === 0) {
        setErrorMessage("No results found");
      } else {
        results = _.uniq(results);
        let error = "";
        if (results.length > RESULTS_LIMIT) {
          results = results.slice(0, RESULTS_LIMIT);
          error = `Limit exceeded, only the first ${RESULTS_LIMIT} results will be processed.`;
        }
        setErrorMessage(error);
        props.onDetailsChange({
          contents: text,
          name: file.name.replace(/\.[^/.]+$/, ""),
        } as IFileDetails);
      }
    };
    reader.readAsText(file);
  }

  const handleNameUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onDetailsChange({
      ...props.value,
      name: event.target.value,
    } as IFileDetails);
  };

  let errorDisplay = <></>;
  if (errorMessages && errorMessages.length) {
    errorDisplay = <BatchErrorResults errors={errorMessages} />;
  }

  return (
    <>
      <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "16px" }}>
        <UploadButton onError={handleUploadError} onUpload={handleOnUpload} />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: !!props.value?.name }}
          label="Batch Name"
          value={props.value?.name ?? ""}
          onChange={handleNameUpdate}
          sx={{ mb: 2, mt: 1 }}
          variant="standard"
        />
      </Box>
      {props.value?.name && props.value.contents && (
        <>This action cannot be cancelled. Are you sure you want to make these updates?</>
      )}
      {errorMessage !== undefined && <div>{errorMessage}</div>}
      {errorDisplay}
    </>
  );
}

export default BatchUploadDialogContent;
