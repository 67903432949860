import React, { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";
import { ConnectedProps, connect } from "react-redux";

import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import * as errorActions from "Features/Errors/Errors";

import { IClient, getUserClients } from "../../../../Adapters/clientAdapter";
import { apiError, errorEnqueued } from "../../../../Common/error";
import SelectFieldController from "../../../../Components/Form/Fields/SelectFieldController";
import TextFieldController from "../../../../Components/Form/Fields/TextFieldController";
import {
  IReconciliationClientMersOrg,
  ReconciliationCreateClientMersOrg,
  addClientMersOrg,
  mersOrgSchema,
  updateClientMersOrg,
} from "../minReconciliationAdapter";

interface ITabPanelMaxAlertDialogProps {
  mersOrg: IReconciliationClientMersOrg | undefined;
  onClose: () => void;
  onMersOrgSaved: () => void;
}

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;
type Props = ITabPanelMaxAlertDialogProps & TypesFromRedux;

/**
 * Renders a form to add or modify a MIN reconciliation MERS org
 */
function MersOrgFormDialogContent(props: Props) {
  const { dispatch, mersOrg, onClose, onMersOrgSaved } = props;
  const [clients, setClients] = useState<IClient[]>([]);
  const defaultValues: ReconciliationCreateClientMersOrg = {
    clientCode: mersOrg?.clientCode ?? "",
    mersOrgId: mersOrg?.mersOrgId ?? "",
    name: mersOrg?.name ?? "",
  };

  useEffect(() => {
    async function getClients() {
      const response = await getUserClients();
      setClients(response);
    }
    void getClients();
  }, []);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: zodResolver(mersOrgSchema),
  });

  const { formState, handleSubmit } = methods;
  const { errors } = formState;
  const { isDirty, isSubmitting } = formState;

  const onSubmit = async (data: ReconciliationCreateClientMersOrg) => {
    let response;
    try {
      if (!mersOrg) {
        response = await addClientMersOrg(data.clientCode, data);
      } else {
        response = await updateClientMersOrg(mersOrg.id, data, data.clientCode);
      }
      if (response.status < 300) {
        onMersOrgSaved();
        onClose();
      }
    } catch (e) {
      const errorResponse = apiError(e);
      dispatch(
        errorActions.enqueue(
          errorEnqueued(errorResponse.errors ? errorResponse.errors.join(",") : "Unable to update MERS org")
        )
      );
    }
  };

  const title = mersOrg ? "Updating MERS Org" : "Add MERS Org";
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <SelectFieldController disabled={!!mersOrg} label="Client Code" name="clientCode" error={errors.clientCode}>
            {clients.map((c) => (
              <MenuItem key={c.id} value={c.name}>
                {c.name}
              </MenuItem>
            ))}
          </SelectFieldController>
          <TextFieldController label="Org Name" name="name" error={errors.name} />
          <TextFieldController label="Org Id" name="mersOrgId" error={errors.mersOrgId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} type="button">
            Cancel
          </Button>
          <Button disabled={!isDirty || isSubmitting} type="submit">
            SUBMIT
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
}

export default connector(MersOrgFormDialogContent);
