import { IHttpErrorState } from "Types/EVaultAppTypes";

import { IErrorPayload } from "Features/Errors/Errors";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function apiError(err: any): IHttpErrorState {
  let error = {
    ...err,
    message: err.message,
    stack: err.stack,
  };
  if (err.response) {
    error = {
      ...error,
      ...err.response,
    };
    if (err.response.data && err.response.data.length > 0 && typeof err.response.data !== "string") {
      error.errors = err.response.data;
    }
  }
  return error;
}

interface IErrorEnqueued {
  message: string;
  options: IErrorPayload;
}

export function errorEnqueued(message = "An unknown error occurred"): IErrorEnqueued {
  return notificationEnqueued(message, {
    autoHide: true,
    autoHideDuration: 10_000,
    variant: "error",
  });
}

// Error Actions
export function notificationEnqueued(
  message = "An unknown error occurred",
  options: Partial<IErrorPayload> = {}
): IErrorEnqueued {
  const ERROR_VARIANTS = ["error", "info", "success", "warning"];
  const defaultOptions: IErrorPayload = {
    autoHide: false,
    autoHideDuration: null,
    inForm: false,
    variant: "error",
  };
  const mergedOptions = Object.assign({}, defaultOptions, options);

  if (!ERROR_VARIANTS.includes(mergedOptions.variant)) {
    mergedOptions.variant = defaultOptions.variant;
  }

  if (mergedOptions.autoHide && !mergedOptions.autoHideDuration) {
    // Set a sensible duration (in milliseconds) based on the number of
    // words in the message, plus a little buffer.
    mergedOptions.autoHideDuration = (message.split(" ").length * 1000) / 2 + 500;
  }

  return {
    message,
    options: mergedOptions,
  };
}
