import * as React from "react";
import { useState } from "react";

import CompleteIcon from "@mui/icons-material/CheckCircle";
import AlertIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";

import { InProgressIcon, UnknownIcon } from "Components/Icons";

import { getDisplayForMersTransactionStatus, getDisplayForMersTransactionType } from "../../Common/Utilities";
import TransactionDetailsModal from "./TransactionDetailsModal";
import { IMersTransactionDetailedData, MersTransactionStatus } from "./VaultInterfaces";

interface ITransferDetailsModal {
  details: IMersTransactionDetailedData;
  packageId: string;
}

export default function TransactionDetails(props: ITransferDetailsModal) {
  const { details, packageId } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let icon;
  switch (details.status) {
    case MersTransactionStatus.Unknown:
      break;
    case MersTransactionStatus.Failed:
    case MersTransactionStatus.Rejected:
    case MersTransactionStatus.Timeout:
    case MersTransactionStatus.DeliveryNotApproved:
    case MersTransactionStatus.TransferNotApproved:
    case MersTransactionStatus.Canceled:
      icon = <AlertIcon className="symbol-error" />;
      break;
    case MersTransactionStatus.Success:
      icon = <CompleteIcon />;
      break;
    case MersTransactionStatus.Pending:
    case MersTransactionStatus.Initiated:
      icon = <InProgressIcon />;
      break;
    default:
      icon = <UnknownIcon />;
      break;
  }

  function handleOpen() {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
  }
  const noteTip =
    getDisplayForMersTransactionType(details.type) + " - " + getDisplayForMersTransactionStatus(details.status);
  return (
    <>
      <IconButton className="success-icon-button" onClick={handleOpen} aria-label={noteTip}>
        {icon}
      </IconButton>
      <TransactionDetailsModal open={isOpen} onClose={handleClose} details={details} packageId={packageId} />
    </>
  );
}
