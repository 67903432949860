/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */

/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function RowSelectPromptIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <rect
        css={css`
          fill: #555555;
          opacity: 0.6;
        `}
        y="22.2"
        width="72"
        height="14.4"
      />
      <rect
        css={css`
          fill: #a0a0a0;
          opacity: 0.32;
        `}
        y="6"
        width="72"
        height="14.4"
      />
      <rect
        css={css`
          fill: #a0a0a0;
          opacity: 0.32;
        `}
        y="38.4"
        width="72"
        height="14.4"
      />
      <path
        css={css`
          fill: #555555;
        `}
        d="M56.39,43.71l-36-14.14,14.14,36,3.34-13.12L50,64.57l5.4-5.4L43.27,47.05Z"
      />
    </SvgIcon>
  );
}
