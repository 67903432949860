import axios from "axios";
import querystring from "querystring";

import { SortOrderType } from "Common/Enums";
import { getAdjustedTimes, getTimesFormatted } from "Common/dateUtilities";

import * as types from "Types/EVaultAppTypes";

import { getTransferredQuery } from "Features/Vault/ArchivedLoans/archivedLoansAdapter";
import { IClosedLoansSearchApiParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import {
  IVaultViewClosedLoansSearchResultData,
  IVaultViewTransferableClosedLoansSearchResultData,
} from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../../Features/Vault/Constants";

/**
 * Get the search results for the Transferred Loans Admin page
 * @param params search criteria values
 * @returns a promise for the request
 */
export function getTransferredMERSPackages(params: IClosedLoansSearchApiParams): Promise<() => void> {
  return axios.get(`/api/v1/packages/closed${getTransferredMersQuery(params)}`).then((response) => response.data);
}

function getTransferredMersQuery(params: IClosedLoansSearchApiParams): string {
  const dates = getAdjustedTimes(params.start, params.stop);
  const formattedDates = getTimesFormatted(dates.start, dates.stop);
  const payload: IClosedSeachPayload = {
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchTerm: params.searchTerm,
    start: formattedDates.start,
    stop: formattedDates.stop,
  };

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  return "?" + querystring.stringify(payload as any);
}

interface IClosedSeachPayload {
  includeClientDefinedDataFields?: boolean;
  searchKey: QueryStringSearchKey;
  searchScope?: string;
  searchTerm: string;
  sortField?: string;
  sortOrderType?: SortOrderType;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
}

export interface IClosedSearchPayload extends IClosedSeachPayload {
  channelIds: string;
}

export function getMERSPackageSearchUrl(params: IClosedLoansSearchApiParams): string {
  return `/api/v1/packages/closed${getClosedLoansQuery(params)}`;
}

export function getTransferableMERSPackageSearchUrl(params: IClosedLoansSearchApiParams): string {
  return `/api/v1/packages/transferable${getClosedLoansQuery(params)}`;
}

export function getRegistrablePackageSearchUrl(params: IClosedLoansSearchApiParams): string {
  return `/api/v1/packages/registrable${getClosedLoansQuery(params)}`;
}

function getClosedLoansQuery(params: IClosedLoansSearchApiParams): string {
  const adjustedTimes = getAdjustedTimes(params.start, params.stop);
  const payload: IClosedSearchPayload = {
    ...getTimesFormatted(adjustedTimes.start, adjustedTimes.stop),
    channelIds: params.channelIds && params.channelIds.join(","),
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchScope: params.searchScope,
    searchTerm: params.searchTerm,
  };
  if (params.includeClientDefinedDataFields) {
    payload.includeClientDefinedDataFields = true;
  }

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  return "?" + querystring.stringify(payload as any);
}

export interface IMersPackageResponse {
  links: types.IResultPages;
  results: IVaultViewClosedLoansSearchResultData[];
}

export async function getMersPackagesByUrl(url: string): Promise<IMersPackageResponse> {
  return axios.get(url).then((response) => response.data);
}

export interface IMersTransferPackageResponse {
  links: types.IResultPages;
  results: IVaultViewTransferableClosedLoansSearchResultData[];
}

export async function getTransferableMersPackagesByUrl(url: string): Promise<IMersTransferPackageResponse> {
  return axios.get(url).then((response) => response.data);
}

export async function getLoansForUpload(loanIds: string[]): Promise<IVaultViewClosedLoansSearchResultData[]> {
  return axios.post("/api/packages/closed/search", { LoanIds: loanIds }).then((response) => response.data);
}

/**
 * Get Archived Loans Export URL
 * @param params Search criteria values for export
 * @returns Export API's URL
 */
export function getArchivedLoansExportUrl(params: IClosedLoansSearchApiParams): string {
  return `/api/packages/archived/export${getTransferredQuery(params)}`;
}

/**
 * Get Closed Loans Export URL
 * @param params Search criteria values for export
 * @param includeClientDefinedDataFields This parameter determines whether client defined data fields will be returned.
 * @returns Export API's URL
 */
export function getClosedLoansExportUrl(
  params: IClosedLoansSearchApiParams,
  includeClientDefinedDataFields: boolean
): string {
  const query: IClosedLoansSearchApiParams = {
    ...params,
    includeClientDefinedDataFields,
    limit: 5000,
    offset: 0,
  };
  return `/api/packages/closed/export${getClosedLoansQuery(query)}`;
}

/**
 * Get Inactive loans export URL
 * @param params Search criteria values for export
 * @returns Export API's URL
 */
export function getInactiveLoansExportUrl(params: IClosedLoansSearchApiParams): string {
  return `/api/packages/inactive/export${getClosedLoansQuery(params)}`;
}
