/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function VaultIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M20.8 16v-1.5c0-1.4-1.4-2.5-2.8-2.5s-2.8 1.1-2.8 2.5V16c-.6 0-1.2.6-1.2 1.2v3.5c0 .7.6 1.3 1.2 1.3h5.5c.7 0 1.3-.6 1.3-1.2v-3.5c0-.7-.6-1.3-1.2-1.3zm-1.3 0h-3v-1.5-.3c.1-.6.8-1 1.5-1 .8 0 1.5.5 1.5 1.3V16zM18 10V5c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13c0 1.1.9 2 2 2h8v-2.8c0-1 .5-1.9 1.2-2.5v-.2c0-2.4 2.2-4.5 4.8-4.5zm-9 6H5v-1h4v1zm2-4H5v-1h6v1zm3-4H5V7h9v1z" />
    </SvgIcon>
  );
}
