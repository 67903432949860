import React, { ReactElement, forwardRef } from "react";

import { NavLink as BaseNavLink } from "react-router-dom";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@mui/styles";

import { ICollapsibleSideMenuListSubItem } from "./CollapsibleSideMenuInterfaces";

interface IVaultExpandedMenu {
  subItems: ICollapsibleSideMenuListSubItem[];
}

const useStyles = makeStyles(() => ({
  indicator: {
    height: 10,
    marginRight: 10,
    width: 8,
  },
  listItem: {
    paddingBottom: 8,
  },
  root: {
    backgroundColor: "#FFF",
    borderBottom: "solid 1px #F0F0F0",
    color: "#323232",
    fontSize: 13,
  },
}));

const NavLink = forwardRef(({ ...props }, ref) => {
  return (
    <BaseNavLink
      // @ts-ignore
      ref={ref}
      {...props}
      className={({ isActive }) =>
        [
          // @ts-ignore
          props.className,
          isActive ? "Mui-selected" : null,
        ]
          .filter(Boolean)
          .join(" ")
      }
    />
  );
});

NavLink.displayName = "NavLink";

export default function VaultExpandedMenu(props: IVaultExpandedMenu): ReactElement {
  const { subItems } = props;
  const classes = useStyles();
  const subItemElements: JSX.Element[] = subItems.map((item) => {
    return (
      <ListItem disablePadding key={item.id}>
        {/*
				// @ts-ignore */}
        <ListItemButton component={NavLink} to={item.to}>
          {item.title}
        </ListItemButton>
      </ListItem>
    );
  });
  return <List className={classes.root}>{subItemElements}</List>;
}
