import React, { ReactElement } from "react";

import ClockIcon from "@mui/icons-material/AccessTime";

import Tooltip from "../../Components/Tooltip";

interface IENoteElementProps {
  canDownload: boolean;
  itemId: number;
  packageId?: string;
}

export default function ENoteElement(props: IENoteElementProps): ReactElement {
  const linkPrefix = `/packages/${props.packageId}/Documents/${props.itemId}/`;
  if (!props.packageId) {
    return (
      <Tooltip title="Pending Delivery">
        <ClockIcon />
      </Tooltip>
    );
  }

  if (props.itemId === 0 || !props.canDownload) {
    return <span>N/A</span>;
  }
  return (
    <span className="float-right">
      <a href={"/api" + linkPrefix + "smart-doc-content"} target="_blank">
        View
      </a>
      <span className="divider">|</span>
      <a href={"/app" + linkPrefix + "smart-doc-data"} target="_blank">
        Data
      </a>
      <span className="divider">|</span>
      <a href={"/api" + linkPrefix + "smart-doc-download"} target="_blank">
        Download
      </a>
    </span>
  );
}
