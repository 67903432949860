import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import { VaultViewAllLoansTabTypes } from "../Vault/AllLoans/Constants";

export interface ITabsState {
  [constants.ALL_LOANS]: ILoanTab;
  [constants.ARCHIVED_LOANS]: ILoanTab;
  [constants.BATCH_OPERATIONS]: IBatchOperationsTab;
  [constants.CLOSED_LOANS]: ILoanTab;
  [constants.INACTIVE_LOANS]: ILoanTab;
  [constants.INCOMING_DELIVERIES_TRANSFERS]: ILoanTab;
  [constants.QUEUE]: ILoanTab;
  [constants.MIN_RECONCILIATION]: IMinReconciliationTab;
}

export interface ILoanTab {
  allIds: string[];
  byId: {
    [key: string]: any;
  };
  currentTab: string;
}

export interface IBatchOperationsTab {
  allIds: string[];
  byId: {
    [key: string]: any;
  };
  currentTab: string;
}

export interface IMinReconciliationTab {
  allIds: string[];
  byId: {
    [key: string]: any;
  };
  currentTab: string;
}

export interface ITabIdentifier {
  key: string;
  loanType: loanTypes;
}

export interface ILoanTabDetails {
  closeable: boolean;
  id: string;
  min?: string;
  title: string;
  type: string;
  [key: string]: any;
}

export type loanTypes =
  | "allLoans"
  | "archivedLoans"
  | "closedLoans"
  | "inactiveLoans"
  | "incomingDeliveriesTransfers"
  | "minReconciliation"
  | "queue"
  | "batchOperations";

export interface ITabPayload {
  key: string;
  loanType: loanTypes;
  [key: string]: any;
}

const initialLoanTabs: ILoanTab = {
  allIds: [],
  byId: {},
  currentTab: "",
};

const initialBatchOperationsTab: IBatchOperationsTab = {
  allIds: [],
  byId: {},
  currentTab: "",
};

const initialMinReconciliationTab: IBatchOperationsTab = {
  allIds: [],
  byId: {},
  currentTab: "",
};

export const initialState: ITabsState = {
  allLoans: {
    ...initialLoanTabs,
    currentTab: createTabKey("VaultTab-AllLoans-", VaultViewAllLoansTabTypes.SearchResults),
  },
  archivedLoans: initialLoanTabs,
  batchOperations: initialBatchOperationsTab,
  closedLoans: initialLoanTabs,
  inactiveLoans: initialLoanTabs,
  incomingDeliveriesTransfers: initialLoanTabs,
  minReconciliation: initialMinReconciliationTab,
  queue: initialLoanTabs,
};

export const tabSlice = createSlice({
  initialState,
  name: "tabs",
  reducers: {
    addTab: (state, action: PayloadAction<ITabPayload>) => {
      let byId: { [key: string]: any } = {};
      let allIds: string[] = [];
      const tab: ITabPayload = action.payload;
      // If tab already exists, update currentTab only
      byId = {
        ...state[tab.loanType].byId,
        [tab.key]: tab,
      };
      if (state[tab.loanType].byId[tab.key]) {
        return {
          ...state,
          [tab.loanType]: {
            ...state[tab.loanType],
            byId,
            currentTab: tab.key,
          },
        };
      }
      // Add new tab
      allIds = state[tab.loanType].allIds.concat(tab.key);

      return {
        ...state,
        [tab.loanType]: {
          allIds,
          byId,
          currentTab: tab.key,
        },
      };
    },
    goToTab: (state, action: PayloadAction<ITabPayload>) => {
      const tab: ITabPayload = action.payload;
      return {
        ...state,
        [tab.loanType]: {
          ...state[tab.loanType],
          currentTab: tab.key,
        },
      };
    },
    removeTab: (state, action: PayloadAction<ITabPayload>) => {
      const byId: { [key: string]: any } = {};
      let allIds: string[] = [];
      const tab: ITabPayload = action.payload;
      let tabIndex;
      allIds = state[tab.loanType].allIds.slice();
      allIds.forEach((id, i) => {
        if (id !== tab.key) {
          byId[id] = { ...state[tab.loanType].byId[id] };
          return;
        }
        tabIndex = i;
      });
      if (tabIndex == null) return state;
      // If current tab open is the one that is removed, currentTab shifts
      const changeTab = allIds[tabIndex - 1] ? allIds[tabIndex - 1] : allIds[tabIndex + 1];
      const currentTab = tab.key === state[tab.loanType].currentTab ? changeTab : state[tab.loanType].currentTab;
      return {
        ...state,
        [tab.loanType]: {
          allIds: allIds.filter((id) => id !== tab.key),
          byId,
          currentTab,
        },
      };
    },
    updateTab: (state, action: PayloadAction<ITabPayload>) => {
      const tab: ITabPayload = action.payload;
      return {
        ...state,
        [tab.loanType]: {
          ...state[tab.loanType],
          byId: {
            ...state[tab.loanType].byId,
            [tab.key]: tab,
          },
        },
      };
    },
  },
});

export const { addTab, goToTab, removeTab, updateTab } = tabSlice.actions;

export const { caseReducers } = tabSlice;

export default tabSlice.reducer;
