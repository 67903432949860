import React, { ReactElement, useState } from "react";

import HelpIcon from "@mui/icons-material/Help";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { makeStyles } from "@mui/styles";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import Tooltip from "Components/Tooltip";

const useStyles = makeStyles(() => ({
  iconStyle: {
    minWidth: 40,
  },
}));

export default function VaultMenu(): ReactElement {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const classes = useStyles();

  function handleShowPopup() {
    setShowPopup(true);
  }

  function handleClosePopup() {
    setShowPopup(false);
  }

  const modalContent = (
    <div>
      <p>
        <strong>User guide</strong>
      </p>
      <p>
        The information below gives some brief descriptions of the Vault. For a more comprehensive guide, please open
        the{" "}
        <a href="/evault_user_guide.pdf" target="_blank">
          user guide
        </a>
        .
      </p>
      <br />
      <p>
        <strong>All Loans</strong>
      </p>
      <p>Displays eSign and eDisclose packages. You can:</p>
      <ul>
        <li>Search for specific packages</li>
        <li>View package & doc-level info</li>
        <li>View/save docs & audit logs</li>
      </ul>
      <br />
      <p>
        <strong>Closed Loans</strong>
      </p>
      <p>
        Displays closing packages that have eNotes for which the user&apos;s institution is an authorized rights holder.
        You can:
      </p>
      <ul>
        <li>Perform MERS eNote inquiry</li>
        <li>Manage recipients</li>
        <li>Track, register, transfer, & update eNotes*</li>
      </ul>
      <br />
      <p>
        <strong>Archived Loans</strong>
      </p>
      <p>Displays closed loans for which the user&apos;s institution is not an authorized rights holder. You can:</p>
      <ul>
        <li>Search for transferred loans</li>
        <li>View transfer-related loan info</li>
      </ul>
      <br />
      <p>
        <strong>Inactive Loans</strong>
      </p>
      <p>Displays loans that have had a status or modification update and are therefore inactive. You can:</p>
      <ul>
        <li>View loan info</li>
        <li>Perform reverse updates to re-activate eNotes</li>
      </ul>
      <br />
      <p>
        <strong>Incoming</strong>
      </p>
      <p>Displays the incoming deliveries and transfers that require a manual decision.</p>
      <br />
      <p>* Rights required</p>
      <br />
      <p>
        <strong>Queue</strong>
      </p>
      <p>Displays the list of loans that the secured party is assigned to.</p>
      <br />
      <p>* Rights required</p>
    </div>
  );

  return (
    <>
      <ListItemButton onClick={handleShowPopup}>
        <Tooltip title="Learn more">
          <ListItemIcon className={classes.iconStyle}>
            <HelpIcon />
          </ListItemIcon>
        </Tooltip>
        Learn More
      </ListItemButton>
      <ModalPopupAlert
        title="Vault Guide"
        content={modalContent}
        onClose={handleClosePopup}
        onConfirm={handleClosePopup}
        open={showPopup}
        confirmationButtonText="CLOSE"
        size="md"
      />
    </>
  );
}
