/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ExpandIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M8,2,6.6,3.4,11.2,8,6.6,12.6,8,14l6-6ZM0,16H2V0H0Z" fill="#a0a0a0" />
    </SvgIcon>
  );
}
