import React, { ReactElement } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import { initialArchivedLoansSearchBarParams, resetSearch, updateSearchBarParams } from "./ArchivedSlice";
import { executeSearch } from "./archivedLoansService";

const mapStateToProps = (state: RootState) => ({
  searchBarParams: state.archivedLoans.searchBarParams,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

export function ArchivedLoansFilterBar(props: TypesFromRedux): ReactElement {
  const { searchBarParams, dispatch } = props;

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialArchivedLoansSearchBarParams, searchBarParams);

  return (
    <FilterBar
      displayResetButton={displayResetButton}
      onCriteriaChange={handleCriteriaChange}
      onReset={handleReset}
      onSubmit={handleSubmit}
      searchCriterias={constants.SEARCH_BAR_CRITERIA.archived}
      searchParams={searchBarParams}
    />
  );
}

export default connector(ArchivedLoansFilterBar);
