/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function InProgressIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M17 6.4C15.5 4.9 13.5 4 11.3 4c-4.4 0-8 3.6-8 8h2c0-3.3 2.7-6 6-6 1.7 0 3.1.7 4.2 1.8L13.3 10H19.4V4L17 6.4zM11.3 18c-1.7 0-3.1-.7-4.2-1.8L9.3 14h-6v6l2.4-2.4C7.1 19 9.1 20 11.3 20c4.4 0 8-3.6 8-8h-2c0 3.3-2.7 6-6 6z" />
    </SvgIcon>
  );
}
