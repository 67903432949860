import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { PackageRegistrationStatus } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import { getMERSPackageSearchUrl } from "Adapters/Mers/mersPackageAdapter";

import { profileSelector } from "Features/Profile/ProfileSlice";
import { addTab } from "Features/Tabs/Actions";
import { IClosedLoansSearchApiParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";

import {
  IDeliveryDocument,
  IMersDeliveryPackage,
  IVaultViewClosedLoansSearchResultData,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";
import { getDocuments } from "../documentService";
import {
  fetchPackages,
  resetLoadingState,
  setLoadingState,
  updateDeliveries,
  updateDocuments,
  updateSearchBarParams,
} from "./MersDelivery/MersDeliverySlice";
import { getDefaultRecipient } from "./MersRecipients/mersOrgService";
import { canTransfer } from "./mersService";

export function openDeliveryTabForPackage(packageId: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsTransfer = canTransfer(profile.roles);
    if (rightsTransfer) {
      const closedLoans = getDeliverableLoans(state.closedLoans.searchResult);
      const loan = _.find(closedLoans, { packageId });
      if (loan) {
        loan.selected = true;
      }

      dispatch(setLoadingState(false));
      dispatch(updateDeliveryQueue(closedLoans));
      dispatch(goToDeliveryTab());
    } else {
      console.error("Vault Rights Error> You do not have sufficient rights to transfer");
    }
  };
}

export function initializeDeliveryOptionsFroPackage(state: RootState, loan: IMersDeliveryPackage): void {
  if (!loan.deliveryRecipient) {
    loan.deliveryRecipient = getDefaultRecipient(
      state.mersRecipients.recipients,
      state.mersOriginators.originators,
      loan.originatorOrgId
    );
  }
}

export const updateDeliveryQueue = (
  packages: IMersDeliveryPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const queue = _.map(packages, (deliveryPackage: IMersDeliveryPackage) => {
      const delivery = { ...deliveryPackage };
      initializeDeliveryOptionsFroPackage(state, delivery);
      return delivery;
    });
    dispatch(updateDeliveries(queue));
    dispatch(fetchDocumentsForQueue(queue));
  };
};

export const addDeliveriesToQueue = (
  packages: IMersDeliveryPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const queue = state.mersDeliveries.deliveries;
    const selectedIds = packages.map((q) => q.id);
    const updatedQueue = queue.map((q) => {
      if (q.selected || selectedIds.indexOf(q.id) < 0) return q;
      return {
        ...q,
        selected: true,
      };
    });
    const newQueue = _.uniqWith(updatedQueue.concat(packages), (arrVal, othVal) => {
      return arrVal.loanNumber === othVal.loanNumber && arrVal.min === othVal.min;
    });
    const sortedLoans = _.sortBy(newQueue, "loanNumber");
    dispatch(updateDeliveries(sortedLoans));
    dispatch(fetchDocumentsForQueue(sortedLoans));
  };
};

export function getDeliverableLoans(closedLoans: IVaultViewClosedLoansSearchResultData[]): IMersDeliveryPackage[] {
  // const loans = closedLoans.concat(uploadedLoans);
  const deliverableLoans = _.filter(closedLoans, (loan: IVaultViewClosedLoansSearchResultData) => {
    return loan.mersData.mersRegistrationStatus !== PackageRegistrationStatus.Unregistered;
  });

  return deliverableLoans.map((loan: IVaultViewClosedLoansSearchResultData) => {
    return {
      ...loan,
      documents: [],
      mersInitiators: loan.mersData.mersInitiators,
      packageId: loan.packageId,
      selected: false,
    };
  });
}

function fetchDocumentsForQueue(packages: IMersDeliveryPackage[]) {
  return (dispatch: any) => {
    packages.forEach((deliveryPackage: IMersDeliveryPackage) => {
      if (deliveryPackage.documents.length === 0 && deliveryPackage.selected) {
        getDocuments(deliveryPackage.packageId, false, false).then((payload: any) => {
          const documents: IDeliveryDocument[] = _.map(payload.data, (item: any) => {
            return {
              id: item.id,
              name: item.name,
              selected: item.formType === "Note" && item.hasAssociatedSMARTDoc,
            };
          });
          dispatch(
            updateDocuments({
              documents,
              packageId: deliveryPackage.packageId,
            })
          );
        });
      }
    });
  };
}

export const openDeliveryTabAction = (): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch, getState) => {
    const state = getState();
    const profile = profileSelector(state);
    const rightsTransfer = canTransfer(profile.roles);
    if (rightsTransfer) {
      dispatch(resetLoadingState());
      dispatch(goToDeliveryTab());
    } else {
      console.error("Vault Rights Error> You do not have sufficient rights to transfer");
    }
  };
};

function goToDeliveryTab(): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const keyRoot = "VaultTab-ClosedLoans-";
    const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.DeliverMERS, {});
    const tabData = {
      closeable: true,
      key,
      loanType: constants.CLOSED_LOANS,
      title: "Deliver",
      type: VaultViewClosedLoansTabTypes.DeliverMERS,
    };
    return dispatch(addTab(tabData));
  };
}

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.mersDeliveries.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchDeliveries());
  };
}

export function fetchDeliveries(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mersOrgs = state.mersOriginators;
    let fetchUrl = resourceUrl as string;
    if (!resourceUrl) {
      const searchCriteria = state.mersDeliveries.searchBarParams.data;
      const sortByParams = state.mersDeliveries.sortByParams;
      const params: IClosedLoansSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      fetchUrl = getMERSPackageSearchUrl(params);
    }
    const request = {
      mersOrgs: mersOrgs.originators,
      state,
      url: fetchUrl,
    };
    dispatch(fetchPackages(request));
  };
}
