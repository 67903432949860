import React, { ReactElement, useState } from "react";

import { BottomBar } from "Components/Notifications/BottomBar";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

import { mapMersTableListDataToApiData, updateDetailsForOrgChanges } from "./mersOrgService";
import { validateMersOrg } from "./mersOrgValidation";

interface IMersOrgSaveBarProps {
  mersOrgDetails: IMersRecipientDetails[];
  onSaveClick: (mersOrgIds: IMersRecipientDetails[]) => any;
  originalDetails: IMersRecipientDetails[];
}

export default function MersRecipientSaveBar({
  mersOrgDetails,
  onSaveClick,
  originalDetails,
}: IMersOrgSaveBarProps): ReactElement {
  const [showValidationMessages, setShowValidationMessages] = useState(false);
  const [validationMessages, setValidationMessages] = useState<string[]>([]);

  /**
   * Handles the closing of the Validation Modal
   * @returns nothing
   */
  function handleCloseValidationModal() {
    handleValidationMessages([]);
  }

  /**
   * Handles the validation messages
   * @param validationMessages Validation messages to handle
   * @returns Flag to indicate if validation was successful
   */
  function handleValidationMessages(validationMessages: string[]): boolean {
    setValidationMessages(validationMessages);
    const isValidationError: boolean = validationMessages && validationMessages.length > 0;
    setShowValidationMessages(isValidationError);
    return isValidationError;
  }

  /**
   * Gets the validation messages content for the modal
   * @param validationMessages Validation messages for the modal
   * @returns JSX.Element to be displayed in the modal
   */
  function getValidationMessagesContent(validationMessages: string[]): JSX.Element {
    const modalContent = () => {
      return (
        <ul>
          {validationMessages.map((validationMessage: string) => {
            return (
              <li key={validationMessage} title={validationMessage}>
                {validationMessage}
              </li>
            );
          })}
        </ul>
      );
    };

    return modalContent();
  }

  /**
   * Handles the Save's click event
   * @returns nothing
   */
  function handleSaveClick() {
    // Validate
    const isValidationError = handleValidationMessages(validateMersOrg(mersOrgDetails));

    // Save
    if (!isValidationError) {
      const data: IMersRecipientDetails[] = mapMersTableListDataToApiData(mersOrgDetails);
      updateDetailsForOrgChanges(data, originalDetails);
      onSaveClick(data);
    }
  }

  return (
    <>
      <BottomBar
        contents="Create or modify recipients. A unique, 7-digit MERS Org ID and Institution are required."
        buttons={[
          {
            closable: false,
            disabled: false,
            onClick: handleSaveClick,
            text: "SAVE",
          },
        ]}
      />

      <ModalPopupAlert
        title="Recipient validation errors"
        content={getValidationMessagesContent(validationMessages)}
        onClose={handleCloseValidationModal}
        open={showValidationMessages}
        size="sm"
      />
    </>
  );
}
