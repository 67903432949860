/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import { useDispatch } from "react-redux";

import { CloudUpload } from "@mui/icons-material";
import Button from "@mui/material/Button";

import { IMersRegisterPackage } from "Features/Vault/VaultInterfaces";

import { AppDispatch } from "../../../../App/Store";
import MersRegistrationUploadModal from "./UploadModal";
import { addRegistrationsToQuue } from "./mersRegistrationService";

export function MersRegisterUpload(): ReactElement {
  const dispatch = useDispatch() as AppDispatch;

  const [showUpload, setShowUpload] = useState<boolean>(false);

  function handleUpload(): void {
    setShowUpload(true);
  }

  function onUploadClose() {
    setShowUpload(false);
  }

  async function onUploadSubmit(registrations: IMersRegisterPackage[]): Promise<any> {
    dispatch(addRegistrationsToQuue(registrations));
    setShowUpload(false);
  }

  return (
    <>
      <Button
        color="secondary"
        css={css`
          margin-left: 8px;
          min-width: 100px;
        `}
        onClick={handleUpload}
        startIcon={<CloudUpload />}
        variant="contained"
      >
        Upload
      </Button>
      <MersRegistrationUploadModal open={showUpload} onClose={onUploadClose} onSubmit={onUploadSubmit} />
    </>
  );
}

export default MersRegisterUpload;
