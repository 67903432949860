import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { GridSortItem } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import { SortOrderType } from "Common/Enums";

import { ISortByParams } from "Types/EVaultAppTypes";

import { getMERSPackageSearchUrl } from "Adapters/Mers/mersPackageAdapter";

import {
  IClosedLoansSearchApiParams,
  fetchPackages,
  updateSearchBarParams,
  updateSortBy,
} from "Features/Vault/ClosedLoans/ClosedLoansSlice";

import { clientDefinedDataFieldsSelector } from "../../Profile/ProfileSlice";
import { QueryStringSearchKey } from "../Constants";
import { getMersOrgForView } from "../Mers/mersOrgService";
import { IVaultViewClosedLoansSearchResultData } from "../VaultInterfaces";

/**
 * Get the closed loans' search results
 * @param state State containing closed loans data
 * @returns Closed loans' search results
 */
export function getClosedLoansSearchResult(state: RootState): IVaultViewClosedLoansSearchResultData[] {
  let searchResults: IVaultViewClosedLoansSearchResultData[] = state.closedLoans.searchResult;
  searchResults = _.map(searchResults, (loan: IVaultViewClosedLoansSearchResultData) => {
    return {
      ...loan,
      // Map Originator data
      originator: getMersOrgForView(state, loan.originatorOrgId),
    };
  });

  return searchResults;
}

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.closedLoans.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchClosedLoans());
  };
}

export function updateSortByAction({ field, sort }: GridSortItem): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    // Return if missing necessary arguments
    if (!field || !sort) return;

    const { sortField: prevSortField, sortOrderType: prevSortOrderType } = getState().closedLoans.sortByParams;
    const newSortOrderType = sort === "asc" ? SortOrderType.ASCENDING : SortOrderType.DESCENDING;

    // If no sort property changes, exit without fetching new data
    if (prevSortField === field && prevSortOrderType === newSortOrderType) return;

    const newSortOrderParams: ISortByParams = {
      sortField: field,
      sortOrderType: newSortOrderType,
    };
    dispatch(updateSortBy(newSortOrderParams));
    dispatch(fetchClosedLoans());
  };
}

export function fetchClosedLoans(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mersOrgs = state.mersOriginators;
    let fetchUrl = resourceUrl as string;
    if (!resourceUrl) {
      const searchCriteria = state.closedLoans.searchBarParams.data;
      const sortByParams = state.closedLoans.sortByParams;
      const params: IClosedLoansSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      if (params.searchTerm) dispatch(updateSearchBarParams(params));
      const newParams: IClosedLoansSearchApiParams = {
        ...params,
        includeClientDefinedDataFields: clientDefinedDataFieldsSelector(state),
      };
      if (params.searchKey && params.searchKey.startsWith("clientDefinedDataField")) {
        newParams.searchScope = params.searchKey.replace("clientDefinedDataField:", "");
        newParams.searchKey = QueryStringSearchKey.ClientDefinedDataField;
      }

      fetchUrl = getMERSPackageSearchUrl(newParams);
    }
    const request = {
      mersOrgs: mersOrgs.originators,
      url: fetchUrl,
    };
    dispatch(fetchPackages(request));
  };
}
