/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent, { DialogContentProps } from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface IModalPopupAlertProps {
  children?: React.ReactNode | React.ReactNode[];
  confirmationButtonClassName?: string;
  confirmationButtonText?: string;
  content?: string | JSX.Element;
  declineLinkClassName?: string;
  declineLinkText?: string;
  dialogContentProps?: DialogContentProps;
  disabled?: boolean;
  onClose?: () => any;
  onConfirm?: () => any;
  onDecline?: () => any;
  open: boolean;
  overlayCloseClick?: boolean;
  showDeclineLink?: boolean;
  title?: string;
  size: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export function ModalPopupAlert(props: IModalPopupAlertProps): ReactElement {
  const {
    children,
    confirmationButtonText,
    content,
    dialogContentProps,
    disabled,
    onClose,
    onConfirm,
    onDecline,
    open,
    overlayCloseClick = true,
    size,
    showDeclineLink,
    title,
  } = props;

  function onOverlayCloseClick() {
    if (overlayCloseClick && onClose) {
      onClose();
    }
  }

  const declineLinkText = props.declineLinkText ? props.declineLinkText : "CANCEL";
  const handleClick = onConfirm
    ? onConfirm
    : onClose ||
      (() => {
        return;
      });
  const handleDeclineClick = onDecline
    ? onDecline
    : onClose ||
      (() => {
        return;
      });
  return (
    <Dialog fullWidth={true} id="modal-popup-alert" maxWidth={size} onClose={onOverlayCloseClick} open={open}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers {...dialogContentProps}>
        {content}
        {children}
      </DialogContent>
      <DialogActions>
        {true === showDeclineLink && (
          <Button color="secondary" variant="text" size="large" onClick={handleDeclineClick}>
            {declineLinkText}
          </Button>
        )}
        <Button color="secondary" variant="contained" size="large" onClick={handleClick} disabled={disabled}>
          {confirmationButtonText ? confirmationButtonText : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
