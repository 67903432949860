/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";

import {
  DeliveryAccessors,
  IDeliveryDocument,
  IMersDeliveryPackage,
  IMersRecipientDetails,
  IVaultViewClosedLoansSearchResultData,
  RecipientAccessors,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";
import { datagridColumns } from "Features/Vault/shared";

import MersIdentificationNumber from "../MersIdentificationNumber";
import MersRecipientSelector from "../MersRecipientSelector";
import { fetchDeliveries, updateDeliveryQueue } from "../mersDeliveryService";
import MersDeliveryDocuments from "./MersDeliveryDocuments";
import { updateRecipient } from "./MersDeliverySlice";

const mapStateToProps = (state: RootState) => ({
  error: state.mersDeliveries.error,
  initialized: state.mersDeliveries.initialized,
  isLoading: state.mersDeliveries.isLoading,
  mersRecipients: state.mersRecipients.recipients,
  queueList: state.mersDeliveries.deliveries.map((loan) => ({
    ...loan,
    id: loan.packageId,
  })),
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersDeliveryTable(props: TypesFromRedux): ReactElement {
  const keyRoot = "VaultTab-ClosedLoans-";
  const { error, initialized, isLoading, queueList } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [selected, setSelected] = useState<GridSelectionModel>([]);

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchDeliveries());
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    setSelected(queueList.filter((loan) => loan.selected).map((loan) => loan.id));
  }, [queueList]);

  const columns: GridColDef[] = [
    {
      ...datagridColumns.loanNumber,
      sortable: false,
    },
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewClosedLoansSearchResultData}
          loanType={constants.CLOSED_LOANS}
          tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
      sortable: false,
    },
    datagridColumns.primaryBorrowerName,
    {
      editable: true,
      field: "deliveryRecipient",
      flex: 1,
      headerName: "RECIPIENT",
      minWidth: 200,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.deliveryRecipient.name) {
          return (
            <Tooltip title={params.row.deliveryRecipient.orgId}>
              <span>{params.row.deliveryRecipient.name}</span>
            </Tooltip>
          );
        }
        return params.row.deliveryRecipient.orgId;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        const { id, value, api, field } = params;

        function handleRecipientChange(recipient: IMersRecipientDetails, loan: IMersDeliveryPackage) {
          api.setEditCellValue({ field, id, value: recipient });
          dispatch(
            updateRecipient({
              packageId: loan.packageId,
              recipient,
              type: RecipientAccessors.deliveryRecipient,
            })
          );
        }

        return (
          <Grid container item alignItems="center" justifyContent="center" sx={{ px: 1 }}>
            <MersRecipientSelector
              currentRecipient={value as IMersRecipientDetails}
              isDisabled={false}
              type={RecipientAccessors.deliveryRecipient}
              parentLoan={params.row as IMersDeliveryPackage}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      field: DeliveryAccessors.delivery,
      flex: 1,
      headerName: "DELIVERY",
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.selected) {
          return <div>Not selected for delivery</div>;
        }
        return (
          <MersDeliveryDocuments
            deliveryPackage={params.row as IMersDeliveryPackage}
            onDeliveryPackageSelectionModalSubmit={handleDeliveryPackageSelectionModalSubmit}
          />
        );
      },
      sortable: false,
    },
  ];

  function handleQueueChange(list: IMersDeliveryPackage[]) {
    dispatch(updateDeliveryQueue(list));
  }

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
    const updatedDeliveries = queueList.map((queue) => {
      return {
        ...queue,
        selected: selected.includes(queue.id),
      };
    });
    dispatch(updateDeliveryQueue(updatedDeliveries));
  };

  function handleDeliveryPackageSelectionModalSubmit(loan: IMersDeliveryPackage, documents: IDeliveryDocument[]) {
    const editedLoanIndex = _.indexOf(queueList, loan);
    const clonedQueueList: IMersDeliveryPackage[] = [...queueList];
    clonedQueueList[editedLoanIndex] = {
      ...clonedQueueList[editedLoanIndex],
      documents,
    };

    handleQueueChange(clonedQueueList);
  }

  return (
    <>
      <DataGrid
        css={css`
          min-height: ${error ? "200px" : "0px"};
        `}
        checkboxSelection
        columnBuffer={0}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlay,
          LoadingOverlay: LoadingOverlay,
        }}
        componentsProps={{
          errorOverlay: { error },
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        editMode="row"
        error={error}
        getRowClassName={(params) => {
          if (params.row.isInvalidState) {
            return "MuiDataGrid-row-error";
          }
          return "";
        }}
        hideFooter
        loading={isLoading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selected}
        rows={queueList}
        scrollbarSize={17}
      />
    </>
  );
}

export default connector(MersDeliveryTable);
