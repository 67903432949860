import axios from "axios";
import querystring from "querystring";

import { SortOrderType } from "Common/Enums";
import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import { IResultPages } from "Types/EVaultAppTypes";

import { IAllLoansSearchApiParams, IVaultViewAllLoansSearchResultData } from "Features/Vault/AllLoans/Types";
import { QueryStringSearchKey } from "Features/Vault/Constants";

export interface IPackageResponse {
  links: IResultPages;
  results: IVaultViewAllLoansSearchResultData[];
}

interface IPackageSearchPayload {
  includeEDisclose: boolean;
  includeEClose: boolean;
  includeESign: boolean;
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortField?: string;
  sortOrderType?: SortOrderType;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
}

export function getPackageSearchUrl(params: IAllLoansSearchApiParams): string {
  const includeEDisclose = params.deliveryTypes.indexOf("edisclose") > -1;
  const includeEClose = params.deliveryTypes.indexOf("eclose") > -1;
  const includeESign = params.deliveryTypes.indexOf("esign") > -1;
  const payload: IPackageSearchPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop),
    includeEClose,
    includeEDisclose,
    includeESign,
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchTerm: params.searchTerm,
  };

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  if (params.searchTerm) {
    payload.searchTerm = params.searchTerm;
  }

  return `/api/packages/all${getAllLoansQuery(params)}`;
}

function getAllLoansQuery(params: IAllLoansSearchApiParams) {
  const includeEDisclose = params.deliveryTypes.includes("edisclose");
  const includeEClose = params.deliveryTypes.includes("eclose");
  const includeESign = params.deliveryTypes.includes("esign");
  const payload: IPackageSearchPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop),
    includeEClose,
    includeEDisclose,
    includeESign,
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchTerm: params.searchTerm,
  };

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  // Search Term
  if (params.searchTerm) {
    payload.searchTerm = params.searchTerm;
  }

  // Default offset if not specified
  if (!params.offset) {
    payload.offset = 0;
  }

  return "?" + querystring.stringify(payload as any);
}

export function getPackagesByUrl(url: string): Promise<IPackageResponse> {
  return axios.get(url).then((response) => response.data);
}

export async function getLoanActivity(packageId: string): Promise<any> {
  return await axios.get(`/api/packages/${packageId}/activity`).then((response) => response.data);
}

export function getPackagesExportUrl(data: IAllLoansSearchApiParams): string {
  return `/api/packages/all/export${getAllLoansQuery(data)}`;
}
