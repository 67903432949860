/* eslint-disable sort-keys */

/* eslint-disable quote-props */
import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { RootState } from "App/Store";

import { uploadPermissions } from "Features/Vault//ModalPopups/CsvFileUpload";
import MersRecipientSelector from "Features/Vault/Mers/MersRecipientSelector";
import { IMersOriginator, IMersRecipientDetails, RecipientAccessors } from "Features/Vault/VaultInterfaces";

import { datagridColumns } from "../../../shared";

export interface RecipientSelection {
  delegateeForTransferRecipient?: IMersRecipientDetails;
  id: string;
  mersOrgId: string;
  originator: IMersOriginator;
  securedPartyRecipient?: IMersRecipientDetails;
  securedPartyDelegateeRecipient?: IMersRecipientDetails;
}

interface ResultsProps {
  onChange: (recipients: RecipientSelection[]) => void;
  permissions: uploadPermissions;
  recipients: RecipientSelection[];
}

const mapStateToProps = (state: RootState) => ({
  mersOriginators: state.mersOriginators.originators,
  mersRecipients: state.mersRecipients.recipients,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux & ResultsProps;

export function RecipientConfiguration(props: Props): ReactElement {
  const classes = useStyles();
  const { recipients, permissions } = props;
  const { securedPartyEnabled, securedPartyDelegateeEnabled, delegateeForTransferEnabled } = permissions;

  const columns: GridColDef[] = [
    {
      field: "mersOrgId",
      flex: 1,
      headerName: "ORIGINATOR",
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => params.row.originator.name,
    },
    {
      ...datagridColumns.securedPartyRecipient,
      hide: !securedPartyEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={params.row.securedPartyRecipient}
              isDisabled={false}
              originatorOrgId={params.row.mersOrgId}
              type={RecipientAccessors.securedPartyRecipient}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.securedPartyDelegateeRecipient,
      hide: !securedPartyDelegateeEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={params.row.securedPartyDelegateeRecipient}
              isDisabled={false}
              originatorOrgId={params.row.mersOrgId}
              type={RecipientAccessors.securedPartyDelegateeRecipient}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
    {
      ...datagridColumns.delegateeForTransferRecipient,
      hide: !delegateeForTransferEnabled,
      renderCell: function renderRecipientSelector(params: GridCellParams) {
        return (
          <Grid container item alignItems="center" justifyContent="center">
            <MersRecipientSelector
              currentRecipient={params.row.delegateeForTransferRecipient}
              isDisabled={false}
              originatorOrgId={params.row.mersOrgId}
              type={RecipientAccessors.delegateeForTransferRecipient}
              mersRecipients={props.mersRecipients}
              onMersRecipientIdSelectionChanged={handleRecipientChange}
            />
          </Grid>
        );
      },
      sortable: false,
    },
  ];

  function handleRecipientChange(recipient: IMersRecipientDetails, originatorOrgId: any, type: any) {
    const updatedRecipients = recipients.map((defaultRecipient) => {
      if (originatorOrgId !== defaultRecipient.mersOrgId) return defaultRecipient;
      return {
        ...defaultRecipient,
        [type]: recipient,
      };
    });
    props.onChange(updatedRecipients);
  }

  return (
    <Box sx={{ mb: 1, mt: 3 }}>
      <DataGrid
        autoHeight
        classes={{
          columnHeader: classes.columnHeader,
          main: classes.main,
        }}
        columnBuffer={0}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        hideFooter
        localeText={{
          noRowsLabel: "No originators found for your organization",
        }}
        nonce={nonce}
        rows={recipients}
        scrollbarSize={0}
      />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  columnHeader: {
    background: "#f5f5f5",
  },
  main: {
    border: "1px solid #F0F0F0",
  },
}));

export default connector(RecipientConfiguration);
