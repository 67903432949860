import React from "react";

import { ModalPopupAlert } from "../../../Components/Notifications/ModalPopupAlert";

interface IRemoveDelegateeForTransfersModalProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

export function RemoveSubservicerModal(props: IRemoveDelegateeForTransfersModalProps) {
  return (
    <ModalPopupAlert
      title="Initiate Remove Subservicer"
      content="This cannot be cancelled. Are you sure you want to initiate the remove subservicer?"
      confirmationButtonText="REMOVE"
      onConfirm={props.onConfirm}
      showDeclineLink={true}
      size="sm"
      onClose={props.onClose}
      open={props.open}
    />
  );
}
