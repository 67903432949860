import React, { ReactElement } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { ConnectedProps, connect, useDispatch } from "react-redux";
import * as Yup from "yup";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { AppDispatch, RootState } from "App/Store";

import {
  IMersRecipientDetails,
  MersRecipientAcceptStatusType,
  MersRecipientStatusType,
} from "Features/Vault/VaultInterfaces";

import { saveMersRecipients } from "./mersOrgService";

interface MersRecipientCreateModalProps {
  open: boolean;
  onClose: () => void;
}

const mapStateToProps = (state: RootState) => ({
  details: state.mersRecipients.recipients,
  isLoading: state.mersRecipients.isLoading,
  mersOriginators: state.mersOriginators.originators,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = MersRecipientCreateModalProps & TypesFromRedux;

function MersRecipientsCreateModal({ mersOriginators, onClose, open }: Props): ReactElement {
  const dispatch = useDispatch() as AppDispatch;
  const FORM_ID = "create-recipient-form";

  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm<IMersRecipientDetails>({
    mode: "onChange",
    resolver: yupResolver(createRecipientFormSchema),
  });

  const getMersRecipientAcceptStatusOptionValue = (option: MersRecipientAcceptStatusType) => {
    switch (option) {
      case MersRecipientAcceptStatusType.Reject:
        return "Auto-Disapprove";
      case MersRecipientAcceptStatusType.Accept:
        return "Auto-Accept";
      case MersRecipientAcceptStatusType.Approve:
        return "Auto-Approve";
      case MersRecipientAcceptStatusType.Manual:
        return "Manual";
    }
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (values: IMersRecipientDetails) => {
    if (!isDirty) return;

    const createPayload: IMersRecipientDetails = {
      contactEmail: values.contactEmail ?? "",
      contactName: values.contactName ?? "",
      contactPhone: values.contactPhone ?? "",
      deliveryAutoAcceptStatus: values.deliveryAutoAcceptStatus,
      id: -1,
      mersOriginatorId: values.mersOriginatorId,
      name: values.name,
      orgId: values.orgId.toString(),
      status: MersRecipientStatusType.Default,
      transferAutoAcceptStatus: values.transferAutoAcceptStatus,
    };
    dispatch(saveMersRecipients([createPayload], handleClose));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Create New Recipient</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          <Grid container item xs={12} spacing={3} sx={{ pt: 1 }}>
            <Grid item xs={12}>
              <Controller
                name="mersOriginatorId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Originator"
                    fullWidth
                    select
                    required
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  >
                    {mersOriginators.map((originator) => (
                      <MenuItem key={originator.id} value={originator.id}>
                        {originator.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="orgId"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Mers Org ID"
                    required
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Institution"
                    required
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="contactName"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Contact Name"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="contactPhone"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Contact Phone"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="contactEmail"
                control={control}
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Contact Email"
                    fullWidth
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="deliveryAutoAcceptStatus"
                control={control}
                defaultValue={MersRecipientAcceptStatusType.Approve}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Delivery (In)"
                    required
                    fullWidth
                    select
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  >
                    {[
                      MersRecipientAcceptStatusType.Approve,
                      MersRecipientAcceptStatusType.Reject,
                      MersRecipientAcceptStatusType.Manual,
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {getMersRecipientAcceptStatusOptionValue(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="transferAutoAcceptStatus"
                control={control}
                defaultValue={MersRecipientAcceptStatusType.Manual}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Transfers (In)"
                    required
                    fullWidth
                    select
                    error={Boolean(error)}
                    helperText={error?.message ?? ""}
                  >
                    {[
                      MersRecipientAcceptStatusType.Accept,
                      MersRecipientAcceptStatusType.Reject,
                      MersRecipientAcceptStatusType.Manual,
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {getMersRecipientAcceptStatusOptionValue(option)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="secondary" variant="contained" form={FORM_ID} type="submit" disabled={!isDirty || !isValid}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export const createRecipientFormSchema = Yup.object()
  .shape({
    contactEmail: Yup.string().email("Must be a valid email format."),
    contactName: Yup.string(),
    contactPhone: Yup.string(),
    deliveryAutoAcceptStatus: Yup.string().required("Delivery Status is required.").nullable(true),
    mersOriginatorId: Yup.number().required("Originator is required.").nullable(true),
    name: Yup.string().required("Institution is required."),
    orgId: Yup.number()
      .typeError("Org ID must be a positive, 7-digit integer.")
      .required("Org ID is required.")
      .positive("Org ID must be a positive, 7-digit integer.")
      .integer("Org ID cannot contain decimals.")
      .moreThan(999_999, "Org ID must be a 7-digit value.")
      .lessThan(10_000_000, "Org ID must be a 7-digit value."),
    transferAutoAcceptStatus: Yup.string().required("Transfer Status is required.").nullable(true),
  })
  .required();

export default connector(MersRecipientsCreateModal);
