import React, { ReactElement } from "react";

import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Main from "App/Main";
import Packages from "App/PackageRoutes";

import GlobalNotification from "Features/GlobalNotification/GlobalNotification";

import InvalidToken from "./Security/InvalidToken";
import SignedOut from "./Security/SignedOut";
import TimedOut from "./Security/TimedOut";
import VaultUnauthorized from "./Security/VaultUnauthorized";

export default function App(): ReactElement {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/app/timed-out" element={<TimedOut />} />
          <Route path="/app/signed-out" element={<SignedOut />} />
          <Route path="/app/invalid-token" element={<InvalidToken />} />
          <Route path="/app/unauthorized" element={<VaultUnauthorized />} />
          <Route path="/app/packages/*" element={<Packages />} />
          <Route path="/" element={<Navigate to="/app/vault/all" />} />
          <Route path="/*" element={<Main />} />
        </Routes>
      </Router>
      <GlobalNotification />
    </>
  );
}
