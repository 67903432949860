import React from "react";

import ScrollContainer from "Components/ScrollContainer";

import AllLoansFilterBar from "./AllLoansFilterBar";
import AllLoansSearchResultsTable from "./SearchResultsTable";

interface ISearchResultsTabProps {
  keyRoot: string;
}

function SearchResultsTab(props: ISearchResultsTabProps): JSX.Element {
  const { keyRoot } = props;

  return (
    <ScrollContainer>
      <AllLoansFilterBar />
      <AllLoansSearchResultsTable keyRoot={keyRoot} />
    </ScrollContainer>
  );
}

export default SearchResultsTab;
