import React, { ReactElement } from "react";

import CompleteIcon from "@mui/icons-material/CheckCircle";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid, IconButton, Tooltip } from "@mui/material";

import { PackageDeliveryStatus } from "Common/Enums";
import { getDeliveryStatusDisplay } from "Common/Utilities";

import { InProgressIcon, UnknownIcon } from "Components/Icons";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { IVaultViewClosedLoansSearchResultData } from "../VaultInterfaces";
import DeliveryDetailsModal from "./DeliveryDetailsModal";

interface IRegistrationDetails {
  data: IVaultViewClosedLoansSearchResultData;
  keyRoot: string;
  onShowDeliveryCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

export default function DeliveryDetails(props: IRegistrationDetails): ReactElement | null {
  const { data, keyRoot } = props;
  const [open, setOpen] = React.useState<boolean>(false);

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  let icon;
  const noteTip = getDeliveryStatusDisplay(data.mersData.mersDeliveryStatus);

  switch (data.mersData.mersDeliveryStatus) {
    case PackageDeliveryStatus.NotDelivered:
      return null;
    case PackageDeliveryStatus.DeliveryFailed:
      icon = <AlertIcon color="error" />;
      break;
    case PackageDeliveryStatus.Delivered:
      icon = <CompleteIcon fontSize="small" />;
      break;
    case PackageDeliveryStatus.DeliveryPending:
      icon = <InProgressIcon />;
      break;
    default:
      icon = <UnknownIcon />;
      break;
  }

  return (
    <>
      <Tooltip title={noteTip}>
        <Grid container item justifyContent="center" alignItems="center">
          <IconButton className="success-icon-button" onClick={handleOpen} size="small">
            {icon}
          </IconButton>
        </Grid>
      </Tooltip>
      <ModalPopupAlert
        confirmationButtonText="CLOSE"
        onClose={handleClose}
        open={open}
        size="xs"
        title="Delivery details"
      >
        <DeliveryDetailsModal
          data={data}
          keyRoot={keyRoot}
          onClose={handleClose}
          onShowDeliveryCancelConfirmModal={props.onShowDeliveryCancelConfirmModal}
        />
      </ModalPopupAlert>
    </>
  );
}
