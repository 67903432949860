import React, { ReactElement, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import Tooltip from "Components/Tooltip";

import { IMersRecipientDetails, MersRecipientStatusType } from "Features/Vault/VaultInterfaces";

interface IMersOrgDeleteButtonProps {
  mersOrgRecipient: IMersRecipientDetails;
  onDelete: (recipient: IMersRecipientDetails) => void;
}

export default function MersRecipientDeleteButton({
  mersOrgRecipient,
  onDelete,
}: IMersOrgDeleteButtonProps): ReactElement {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  function handleDeleteClick() {
    setShowDeleteConfirmation(true);
  }

  function handleCloseClick() {
    setShowDeleteConfirmation(false);
  }

  function handleConfirmDelete() {
    onDelete(mersOrgRecipient);
    setShowDeleteConfirmation(false);
  }

  return (
    <>
      <Tooltip title="Delete">
        <div>
          <IconButton
            disabled={MersRecipientStatusType.Default === mersOrgRecipient.status}
            onClick={handleDeleteClick}
            size="small"
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </div>
      </Tooltip>
      {showDeleteConfirmation && (
        <ModalPopupAlert
          title="Deleting Recipient"
          content="Are you sure you want to delete this recipient? Once deleted, it cannot be recovered."
          confirmationButtonText="DELETE"
          onConfirm={handleConfirmDelete}
          showDeclineLink={true}
          onClose={handleCloseClick}
          open={showDeleteConfirmation}
          size="sm"
        />
      )}
    </>
  );
}
