import React from "react";

import ScrollContainer from "Components/ScrollContainer";

import {
  IVaultViewClosedLoansSearchResultData,
  MersTransactionType,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";
import ClosedLoansFilterBar from "./ClosedLoansFilterBar";
import ClosedSearchResultsTable from "./ClosedSearchResultsTable";

interface ISearchResultsTabProps {
  handleAddTab: (type: VaultViewClosedLoansTabTypes, payload?: any) => void;
  keyRoot: string;
  openLastResultModal: (response: IVaultViewClosedLoansSearchResultData, type: MersTransactionType) => void;
  showDeliveryCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showRemoveDelegateeForTransferConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showReverseModificationConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showReverseRegistrationConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showTransferCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

type Props = ISearchResultsTabProps;

function ClosedSearchResults(props: Props): JSX.Element {
  const {
    handleAddTab,
    keyRoot,
    openLastResultModal,
    showDeliveryCancelConfirmModal,
    showRemoveDelegateeForTransferConfirmModal,
    showReverseModificationConfirmModal,
    showReverseRegistrationConfirmModal,
    showTransferCancelConfirmModal,
  } = props;

  return (
    <ScrollContainer>
      <ClosedLoansFilterBar />
      <ClosedSearchResultsTable
        handleAddTab={handleAddTab}
        keyRoot={keyRoot}
        openLastResultModal={openLastResultModal}
        showDeliveryCancelConfirmModal={showDeliveryCancelConfirmModal}
        showRemoveDelegateeForTransferConfirmModal={showRemoveDelegateeForTransferConfirmModal}
        showReverseRegistrationConfirmModal={showReverseRegistrationConfirmModal}
        showReverseModificationConfirmModal={showReverseModificationConfirmModal}
        showTransferCancelConfirmModal={showTransferCancelConfirmModal}
      />
    </ScrollContainer>
  );
}

export default ClosedSearchResults;
