import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { removeSubservicer } from "../../../Adapters/Mers/mersRegistrationAdapter";
import { AppDispatch } from "../../../App/Store";
import { RemovePartyIcon } from "../../../Components/Icons";
import { batchTransactionAction } from "../Mers/MersTransaction/MersTransactionSlice";
import { IVaultViewClosedLoansSearchResultData, MersTransactionType } from "../VaultInterfaces";
import { RemoveSubservicerModal } from "./RemoveSubservicerModal";

interface IRemoveDelegateeForTransfersMenuItemProps {
  package: IVaultViewClosedLoansSearchResultData;
}

export function RemoveSubservicerMenuItem(props: IRemoveDelegateeForTransfersMenuItemProps) {
  const [removeSubservicerConfirmModal, setRemoveSubservicerConfirmModal] = useState<boolean>(false);

  const dispatch = useDispatch() as AppDispatch;

  function handleMenuClick() {
    setRemoveSubservicerConfirmModal(true);
  }

  function handleClose() {
    setRemoveSubservicerConfirmModal(false);
  }

  function handleConfirm() {
    setRemoveSubservicerConfirmModal(false);
    dispatch(
      batchTransactionAction(
        uuid(),
        props.package.loanNumber,
        () => removeSubservicer(props.package.id, props.package.min),
        MersTransactionType.RemoveSubservicer
      )
    );
  }

  return (
    <>
      <MenuItem onClick={handleMenuClick}>
        <ListItemIcon>
          <RemovePartyIcon />
        </ListItemIcon>
        <ListItemText>Remove Subservicer</ListItemText>
      </MenuItem>
      <RemoveSubservicerModal onClose={handleClose} onConfirm={handleConfirm} open={removeSubservicerConfirmModal} />
    </>
  );
}
