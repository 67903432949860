import React from "react";

export default function useInterval(callback: () => void, delay: number): void {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      if (savedCallback != null && savedCallback.current != null) {
        savedCallback.current();
      }
    }

    if (delay != null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
