/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function InkSignedDocumentIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M12,15.2v-0.7c0-0.3-0.2-0.5-0.5-0.5h-3C8.2,14,8,14.2,8,14.5l0,0.7L4.6,17c-0.3,0.1-0.5,0.5-0.4,0.8l1.1,3.6   C5.3,21.8,5.6,22,5.9,22h8.1c0.3,0,0.6-0.2,0.7-0.6l1.1-3.6c0.1-0.3-0.1-0.7-0.4-0.8L12,15.2z" />
      <path d="M19.5,3.2c-2.2-0.1-4.5,0-6.6,2.1c-1.8,1.8-2.8,4.1-3.4,6.7c0.7,0.2,0.9,0.1,1.1-0.4c0.1-0.3,0.8-2,1.5-3.3   c1.1-1.8,2.1-2.5,2.2-2.6c0.2-0.2,0.5-0.2,0.7,0c0.2,0.2,0.2,0.5,0,0.7c0,0-1.2,1.2-2.1,2.4c-0.4,0.5-0.7,1.3-1,1.9   c2.2-0.2,4.2-1,5.8-2.6c0.3-0.3,0.6-0.6,0.8-0.9c-0.5-0.7-0.9-1.4-0.9-1.4s0.7-0.2,1.6-0.4C19.4,4.8,19.4,4.1,19.5,3.2z" />
    </SvgIcon>
  );
}
