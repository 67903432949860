import React, { ReactElement } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { RemovePartyIcon } from "Components/Icons";

import DocListContextMenu from "../ContextMenus/DocListContextMenu";
import SmartDocContextMenu from "../ContextMenus/SmartDocContextMenu";
import { IVaultViewQueueSearchResultTableData, MersTransferStatus, QueueAction } from "../VaultInterfaces";

interface ISecuredPartyContextMenu {
  onSecuredPartyAction: (data: IVaultViewQueueSearchResultTableData, action: QueueAction) => void;
  queueItem: IVaultViewQueueSearchResultTableData;
  onViewDocList: (payload?: any) => void;
}

export default function SecuredPartyContextMenu(props: ISecuredPartyContextMenu): ReactElement {
  const { onSecuredPartyAction, queueItem, onViewDocList } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuOpen(event: any) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  const hasPackage =
    queueItem.packageId != null &&
    queueItem.packageId !== "" &&
    queueItem.packageId !== "00000000-0000-0000-0000-000000000000";

  return (
    <>
      <IconButton size="small" onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <DocListContextMenu
          disabled={queueItem.smartDocId === 0}
          loanNumber={queueItem.min}
          min={queueItem.min}
          onViewDocList={onViewDocList}
          packageId={queueItem.packageId}
          packageType="Closing"
          primarySignerLastName=""
        />

        <Divider />

        <SecuredPartyConfirmation
          actionSecuredPartyClick={onSecuredPartyAction}
          data={queueItem}
          disabled={!hasPackage}
        />
        <MenuItem disabled={!hasPackage} onClick={() => onSecuredPartyAction(queueItem, QueueAction.Remove)}>
          <ListItemIcon>
            <RemovePartyIcon />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>

        <Divider />

        <SmartDocContextMenu packageId={queueItem.packageId} smartDocId={queueItem.smartDocId} />
      </Menu>
    </>
  );
}

interface ISecuredPartyConfirmationProps {
  actionSecuredPartyClick: (data: IVaultViewQueueSearchResultTableData, action: QueueAction) => void;
  data: IVaultViewQueueSearchResultTableData;
  disabled: boolean;
}

function SecuredPartyConfirmation(props: ISecuredPartyConfirmationProps) {
  const { actionSecuredPartyClick, data, disabled } = props;
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const menuDisabled =
    disabled || (data.status !== MersTransferStatus.Pending && data.status !== MersTransferStatus.Accepted);

  function handleSubMenuClick() {
    setSubMenuOpen(!subMenuOpen);
  }

  return (
    <>
      <ListItemButton disabled={menuDisabled} onClick={handleSubMenuClick} dense>
        <ListItemText>Confirmation</ListItemText>
        {subMenuOpen ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
      </ListItemButton>
      <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            dense
            disabled={disabled || data.status !== MersTransferStatus.Pending}
            onClick={() => actionSecuredPartyClick(data, QueueAction.Approve)}
            sx={{ pl: 4 }}
          >
            <ListItemText>Approve</ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            disabled={disabled || data.status !== MersTransferStatus.Pending}
            onClick={() => actionSecuredPartyClick(data, QueueAction.Deny)}
            sx={{ pl: 4 }}
          >
            <ListItemText>Deny</ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            disabled={disabled || data.status !== MersTransferStatus.Pending}
            onClick={() => actionSecuredPartyClick(data, QueueAction.Reset)}
            sx={{ pl: 4 }}
          >
            <ListItemText>Reset</ListItemText>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
