import React from "react";

import SearchIcon from "@mui/icons-material/Search";

import { DocumentIcon } from "Components/Icons";

import { HTMLElementTypes } from "Types/EVaultAppTypes";

import { IMersRecipient, IMersRecipientDetails } from "Features/Vault/VaultInterfaces";

export const EMPTY_MERS_RECIPIENT_DETAILS: IMersRecipientDetails = {
  contactEmail: "",
  contactName: "",
  contactPhone: "",
  deliveryAutoAcceptStatus: 0,
  id: -1,
  mersOriginatorId: -1,
  name: "",
  orgId: "none",
  status: 0,
  transferAutoAcceptStatus: 0,
};

export const EMPTY_MERS_RECIPIENT: IMersRecipient = {
  name: EMPTY_MERS_RECIPIENT_DETAILS.name,
  orgId: EMPTY_MERS_RECIPIENT_DETAILS.orgId,
};

/**
 * Search bar criteria constants
 */

export const baseSearchCriteria = [
  {
    name: "start",
    options: [
      {
        apiParamValue: "",
        description: "",
      },
    ],
    type: HTMLElementTypes.CALENDAR,
  },
  {
    name: "stop",
    options: [
      {
        apiParamValue: "",
        description: "",
      },
    ],
    type: 1,
  },
  {
    name: "divider",
    type: HTMLElementTypes.DIVIDERBLOCK,
  },
  {
    name: "searchTerm",
    options: [
      {
        apiParamValue: "",
        description: "",
      },
    ],
    type: 2,
  },
];

export const SEARCH_BAR_CRITERIA = {
  // Loan Types
  all: [
    ...baseSearchCriteria,
    {
      name: "searchKey",
      options: [
        {
          apiParamValue: "loanNumber",
          description: "Loan Number",
        },
        {
          apiParamValue: "mortgageIdentificationNumber",
          description: "MIN",
        },
        {
          apiParamValue: "namedSigner1LastName",
          description: "Signer Last Name",
        },
      ],
      type: HTMLElementTypes.DROPDOWN,
    },
    {
      name: "divider",
      type: HTMLElementTypes.DIVIDERBLOCK,
    },
    {
      label: "Delivery Type",
      name: "deliveryTypes",
      options: [
        {
          apiParamValue: "edisclose",
          description: "eDisclose",
        },
        {
          apiParamValue: "esign",
          description: "eSign",
        },
        {
          apiParamValue: "eclose",
          description: "eClose",
        },
      ],
      type: HTMLElementTypes.DROPDOWNCHECKLIST,
    },
  ],
  archived: [
    ...baseSearchCriteria,
    {
      name: "searchKey",
      options: [
        {
          apiParamValue: "loanNumber",
          description: "Loan Number",
        },
        {
          apiParamValue: "mortgageIdentificationNumber",
          description: "MIN",
        },
        {
          apiParamValue: "namedSigner1LastName",
          description: "Signer Last Name",
        },
      ],
      type: 0,
    },
  ],
  closed: [
    ...baseSearchCriteria,
    {
      name: "searchKey",
      options: [
        {
          apiParamValue: "loanNumber",
          description: "Loan Number",
        },
        {
          apiParamValue: "mortgageIdentificationNumber",
          description: "MIN",
        },
        {
          apiParamValue: "namedSigner1LastName",
          description: "Signer Last Name",
        },
      ],
      type: HTMLElementTypes.DROPDOWN,
    },
  ],
  inactive: [
    ...baseSearchCriteria,
    {
      name: "searchKey",
      options: [
        {
          apiParamValue: "loanNumber",
          description: "Loan Number",
        },
        {
          apiParamValue: "mortgageIdentificationNumber",
          description: "MIN",
        },
        {
          apiParamValue: "namedSigner1LastName",
          description: "Signer Last Name",
        },
      ],
      type: HTMLElementTypes.DROPDOWN,
    },
  ],
};

// ##################################
// Additional Store key names

export const ALL_LOANS = "allLoans";

export const CLOSED_LOANS = "closedLoans";

export const ARCHIVED_LOANS = "archivedLoans";

export const INACTIVE_LOANS = "inactiveLoans";

export const INCOMING_DELIVERIES_TRANSFERS = "incomingDeliveriesTransfers";

export const QUEUE = "queue";

export const BATCH_OPERATIONS = "batchOperations";

export const MIN_RECONCILIATION = "minReconciliation";

export const RESULTS_TAB = {
  icon: <SearchIcon fontSize="small" sx={{ height: 18, width: 17 }} />,
  title: "Results",
};

export const DOC_LIST_TAB = {
  icon: <DocumentIcon fontSize="small" sx={{ height: 18, width: 17 }} />,
  title: "Doc List",
};

export const ERRORMESSAGE_GENERIC = "Oops! An error occurred.";
