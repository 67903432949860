import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { apiError } from "Common/error";

import { IHttpErrorState, IResultPages, ISortByParams } from "Types/EVaultAppTypes";
import * as types from "Types/EVaultAppTypes";

import { getMersPackagesByUrl } from "Adapters/Mers/mersPackageAdapter";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import {
  IDeliveryDocument,
  IMersDeliveryPackage,
  IMersOriginator,
  IMersRecipientDetails,
  RecipientAccessors,
} from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../../Constants";
import { getDeliverableLoans, initializeDeliveryOptionsFroPackage } from "../mersDeliveryService";

const initialDeliveries: IMersDeliveryPackage[] = [];

export interface deliverySearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortByParams?: ISortByParams;
  start: Date;
  stop: Date;
}

export interface IVaultDeliverySearchBarParams {
  data: deliverySearchParams;
}

const initialDeliveryApiCallParams: deliverySearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialDeliverySearchBarParams: IVaultDeliverySearchBarParams = {
  data: initialDeliveryApiCallParams,
};

export interface IMersDeliverySliceState {
  error?: IHttpErrorState;
  isLoading: boolean;
  initialized: boolean;
  links?: IResultPages;
  deliveries: IMersDeliveryPackage[];
  resourceUrl?: string;
  searchBarParams: IVaultDeliverySearchBarParams;
  sortByParams: ISortByParams;
}

const initialState: IMersDeliverySliceState = {
  deliveries: initialDeliveries,
  initialized: false,
  isLoading: false,
  searchBarParams: initialDeliverySearchBarParams,
  sortByParams: initialSortByParams(),
};

export interface IDeliveryRecipientUpdate {
  packageId: string;
  recipient: IMersRecipientDetails;
  type: RecipientAccessors;
}

export interface IDeliveryDocumentUpdate {
  documents: IDeliveryDocument[];
  packageId: string;
}

export interface IDeliveryFetch {
  url: string;
  mersOrgs: IMersOriginator[];
  state: RootState;
}

export interface IMersPackageResponse {
  links: types.IResultPages;
  results: IMersDeliveryPackage[];
}

export const fetchPackages = createAsyncThunk<
  IMersPackageResponse,
  IDeliveryFetch,
  {
    rejectValue: IHttpErrorState;
  }
>("mersDeliveries/fetch", async (payload: IDeliveryFetch, thunkApi) => {
  try {
    const response = await getMersPackagesByUrl(payload.url);
    const deliverableLoans = getDeliverableLoans(response.results);
    const packages: IMersDeliveryPackage[] = deliverableLoans.map((loan) => {
      initializeDeliveryOptionsFroPackage(payload.state, loan);
      return {
        ...loan,
      };
    });
    return {
      ...response,
      results: packages,
    };
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const mersDeliverySlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.deliveries = [];
      state.error = undefined;
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.deliveries = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState,
  name: "mersDeliveries",
  reducers: {
    resetDeliveries: (state) => {
      state.deliveries = [];
    },
    resetLoadingState: (state) => {
      state.isLoading = false;
      state.initialized = false;
    },
    resetSearch: (state) => {
      state.searchBarParams = initialState.searchBarParams;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      if (!action.payload) {
        state.initialized = true;
      }
    },
    updateDeliveries: (state, action: PayloadAction<IMersDeliveryPackage[]>) => {
      state.deliveries = action.payload.map((delivery) => {
        return {
          ...delivery,
          isInvalidState: !isDeliveryValid(delivery),
        };
      });
    },
    updateDocuments: (state, action: PayloadAction<IDeliveryDocumentUpdate>) => {
      const delivery = state.deliveries.find((delivery) => delivery.packageId === action.payload.packageId);
      if (delivery) {
        delivery.documents = action.payload.documents;
        delivery.isInvalidState = !isDeliveryValid(delivery);
      }
    },
    updateRecipient: (state, action: PayloadAction<IDeliveryRecipientUpdate>) => {
      const registration = state.deliveries.find((reg) => reg.packageId === action.payload.packageId);
      if (registration) {
        (registration as any)[action.payload.type] = action.payload.recipient || EMPTY_MERS_RECIPIENT_DETAILS;
        registration.isInvalidState = !isDeliveryValid(registration);
      }
    },
    updateSearchBarParams: (state, action: PayloadAction<deliverySearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

function isDeliveryValid(delivery: IMersDeliveryPackage): boolean {
  if (!delivery.selected) return true;
  return (
    delivery.documents.length > 0 &&
    delivery.deliveryRecipient != null &&
    delivery.deliveryRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  );
}

export const {
  resetLoadingState,
  resetSearch,
  updateRecipient,
  resetDeliveries,
  setLoadingState,
  updateDeliveries,
  updateDocuments,
  updateSearchBarParams,
  updateSortBy,
} = mersDeliverySlice.actions;

export default mersDeliverySlice.reducer;
