import React, { ReactElement } from "react";

import { makeStyles } from "@mui/styles";

import Loading from "Components/Loading";

import AppBar from "Features/Navigation/AppBar";
import Footer from "Features/Navigation/Footer";

const useStyles = makeStyles(() => ({
  body: {
    alignItems: "center",
    backgroundColor: "#ebebeb",
    display: "flex",
    height: "100vh",
  },
}));

export default function LoadingPage(): ReactElement {
  const classes = useStyles();
  return (
    <>
      <AppBar />
      <div className={classes.body}>
        <Loading />
      </div>
      <Footer />
    </>
  );
}
