import React, { ReactElement } from "react";

import EditIcon from "@mui/icons-material/Edit";

import { DOC_LIST_TAB, RESULTS_TAB } from "Common/EVaultAppConstants";

import { DeliveryOutIcon, MersInquiryIcon, RecipientsIcon, RegisterIcon, TransferIcon } from "Components/Icons";
import TabbedPanelTab from "Components/Panels/TabbedPanel/TabbedPanelTab";

import { ILoanTabDetails } from "../Tabs/Tabs";
import { VaultViewAllLoansTabTypes } from "./AllLoans/Constants";
import {
  VaultViewArchivedLoansTabTypes,
  VaultViewBatchOperationsTabTypes,
  VaultViewClosedLoansTabTypes,
  VaultViewInactiveLoansTabTypes,
  VaultViewIncomingTabTypes,
  VaultViewQueuedLoansTabTypes,
} from "./VaultInterfaces";

interface ITabHeaderProps {
  currentTab: string;
  onTabClick: (tab: ILoanTabDetails) => void;
  onTabClosed?: (tab: ILoanTabDetails) => void;
  tab: ILoanTabDetails;
}

export default function TabHeader(props: ITabHeaderProps): ReactElement | null {
  const { onTabClick, onTabClosed, tab } = props;
  if (!tab) return null;
  const commonProps = {
    id: tab.key,
    onTabClick,
    onTabClosed,
    selected: tab.key === props.currentTab,
    tab,
    title: tab.title,
  };
  let header: JSX.Element = <TabbedPanelTab {...commonProps} />;
  switch (tab.type) {
    case VaultViewAllLoansTabTypes.SearchResults:
    case VaultViewArchivedLoansTabTypes.SearchResults:
    case VaultViewInactiveLoansTabTypes.SearchResults:
    case VaultViewQueuedLoansTabTypes.SearchResults:
    case VaultViewIncomingTabTypes.IncomingDeliveries:
    case VaultViewIncomingTabTypes.IncomingTransfers:
    case VaultViewBatchOperationsTabTypes.SearchResults:
      header = <TabbedPanelTab icon={RESULTS_TAB.icon} {...commonProps} />;
      break;

    case VaultViewClosedLoansTabTypes.SearchResults:
      header = <TabbedPanelTab {...commonProps} icon={RESULTS_TAB.icon} key={tab.key} title={tab.title} />;
      break;

    case VaultViewAllLoansTabTypes.DocumentList:
    case VaultViewArchivedLoansTabTypes.DocumentList:
    case VaultViewClosedLoansTabTypes.DocumentList:
    case VaultViewInactiveLoansTabTypes.DocumentList:
    case VaultViewIncomingTabTypes.DocumentList:
    case VaultViewQueuedLoansTabTypes.DocumentList:
      header = (
        <TabbedPanelTab
          {...commonProps}
          closeable={true}
          key={tab.key}
          title={tab.title}
          icon={DOC_LIST_TAB.icon}
          noteTip={!tab.loanNumber || tab.loanNumber.length === 0 ? "MIN " + tab.min : "Loan # " + tab.loanNumber}
        />
      );
      break;

    case VaultViewAllLoansTabTypes.MERSInquiryDetails:
    case VaultViewArchivedLoansTabTypes.MERSInquiryDetails:
    case VaultViewClosedLoansTabTypes.MERSInquiryDetails:
    case VaultViewInactiveLoansTabTypes.MERSInquiryDetails:
    case VaultViewQueuedLoansTabTypes.MERSInquiryDetails:
      const min = tab.min as string;
      const parts: string[] = [min.substr(0, 7), min.substr(7, 10), min.substr(17, 1)];
      header = (
        <TabbedPanelTab
          {...commonProps}
          icon={<MersInquiryIcon fontSize="small" sx={{ height: 18, width: 17 }} />}
          noteTip={`MIN ${parts.join(" ")}`}
        />
      );
      break;

    case VaultViewClosedLoansTabTypes.MERSOrgDetails:
      header = (
        <TabbedPanelTab {...commonProps} icon={<RecipientsIcon fontSize="small" sx={{ height: 18, width: 17 }} />} />
      );
      break;

    case VaultViewClosedLoansTabTypes.DeliverMERS:
      header = <TabbedPanelTab {...commonProps} icon={<DeliveryOutIcon fontSize="small" />} />;
      break;

    case VaultViewClosedLoansTabTypes.TransferMERS:
      header = (
        <TabbedPanelTab {...commonProps} icon={<TransferIcon fontSize="small" sx={{ height: 18, width: 17 }} />} />
      );
      break;

    case VaultViewClosedLoansTabTypes.RegisterMERS:
      header = (
        <TabbedPanelTab {...commonProps} icon={<RegisterIcon fontSize="small" sx={{ height: 18, width: 17 }} />} />
      );
      break;

    case VaultViewClosedLoansTabTypes.UpdateMERS:
      header = <TabbedPanelTab {...commonProps} icon={<EditIcon fontSize="small" sx={{ height: 18, width: 17 }} />} />;
      break;

    case VaultViewBatchOperationsTabTypes.BatchOperationsStatusDetails:
      header = (
        <TabbedPanelTab
          {...commonProps}
          icon={<EditIcon fontSize="small" sx={{ height: 18, width: 17 }} />}
          noteTip="Click on a Button Below for Additional Detail in Each Category"
        />
      );
      break;

    default:
      break;
  }
  return header;
}
