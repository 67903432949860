/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import _ from "lodash";

import WarningIcon from "@mui/icons-material/WarningRounded";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  CsvRow,
  MersActionType,
  csvHeaders,
  renderCsvProps,
  uploadPermissions,
} from "Features/Vault/ModalPopups/CsvFileUpload";

interface Props {
  actionType: MersActionType;
  filename: string;
  permissions: uploadPermissions;
  results: CsvRow[];
}

export default function Verification({ actionType, filename, permissions, results }: Props): ReactElement {
  const actionTypeHeaderOptions = renderCsvProps(actionType, permissions).headers;
  const headerKeys = actionTypeHeaderOptions.map((value) => _.findKey(csvHeaders, (val) => val === value) ?? "");
  const omittedColumns = Object.keys(results[0]).filter(
    (key) => !actionTypeHeaderOptions.includes((csvHeaders as any)[key])
  );

  const oneOrMoreColumnsOmitted = Boolean(omittedColumns.length);
  return (
    <div>
      <h3>Results from {filename}</h3>
      {oneOrMoreColumnsOmitted && (
        <Card
          variant="outlined"
          elevation={0}
          sx={{
            background: (theme) => `${theme.palette.warning.light}30`,
            fontSize: 12,
            my: 2,
            p: 1.5,
          }}
        >
          <Grid container item xs={12} alignItems="center">
            <WarningIcon sx={{ color: (theme) => theme.palette.warning.light, mr: 1.5 }} />
            Omitted columns found in your CSV which don&apos;t match up with the {actionType} specifications:
          </Grid>
          <ul
            css={css`
              margin: 0;
              padding-left: 60px;
            `}
          >
            {omittedColumns.map((column) => (
              <li key={column}>{column}</li>
            ))}
          </ul>
        </Card>
      )}
      <TableContainer
        component={Paper}
        sx={{
          border: (theme) => `1px solid ${theme.palette.grey[300]}`,
          boxShadow: "none",
        }}
      >
        <Table size="small">
          <TableHead sx={{ background: (theme) => theme.palette.grey[100] }}>
            <TableRow>
              {headerKeys.map((key) => (
                <TableCell key={key}>{(csvHeaders as any)[key]}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((row) => (
              <TableRow key={row.loanNumberOrMin}>
                {headerKeys.map((key) => (
                  <TableCell
                    key={key}
                    sx={{
                      background: !row[key] ? "#7DC9F020" : "none",
                    }}
                  >
                    {row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
