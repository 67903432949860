import React, { ReactElement } from "react";

import { ActivityIcon, DocumentIcon } from "Components/Icons";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import TableRowContextMenu, { TableRowContextMenuItem } from "../TableRowContextMenu";
import { IVaultViewAllLoansSearchResultData } from "./Types";

interface ITableRowContextMenu {
  canDownload: boolean;
  includeActivity: boolean;
  item: IVaultViewAllLoansSearchResultData;
  onShowActivity: (rowData: any) => void;
  onShowDocList: (rowData: any) => void;
  rowData: any;
}

export default function AllLoansTableRowContextMenu(props: ITableRowContextMenu): ReactElement {
  const { canDownload, includeActivity, item, onShowActivity, onShowDocList, rowData } = props;

  function handleDocLink() {
    onShowDocList(rowData);
  }

  function handleActivityLink() {
    onShowActivity(rowData);
  }

  const actions: TableRowContextMenuItem[] = [];

  if (canDownload) {
    actions.push({
      disabled: false,
      icon: <DocumentIcon />,
      onClick: handleDocLink,
      title: "Docs",
    });
    actions.push(<AuditLogMenuItem data={item} key="audit-log" />);
  }
  if (includeActivity) {
    actions.push({
      disabled: false,
      icon: <ActivityIcon />,
      onClick: handleActivityLink,
      title: "Show Activity",
    });
  }

  return <TableRowContextMenu actions={actions} />;
}
