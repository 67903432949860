import React, { ReactElement } from "react";

import { Route, Routes } from "react-router-dom";

import SmartDocData from "Features/Vault/SmartDocData";

export default function App(): ReactElement {
  return (
    <Routes>
      <Route path="/:packageId/documents/:docId/smart-doc-data" element={<SmartDocData />} />
    </Routes>
  );
}
