/* eslint-disable camelcase */
import * as React from "react";

import { startCase } from "lodash";

import { List, ListItem } from "@mui/material";

import { getOrgDisplay } from "Common/Utilities";

import { IMERSInquiryResults, IRightsHolderDifference } from "Features/Vault/VaultInterfaces";

import { ModalPopupAlert } from "../../Components/Notifications/ModalPopupAlert";
import localization from "./localization";

interface IMERSInquiryDetailsProp {
  inquiry: IMERSInquiryResults;
}

export default function InquiryUpdatesDisplay(props: IMERSInquiryDetailsProp) {
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);
  const { inquiry } = props;

  React.useEffect(() => {
    if (inquiry.differences.length || inquiry.updates.length) {
      setModalVisible(true);
    }
  }, [inquiry]);

  function handleModalClose() {
    setModalVisible(false);
  }

  if (!modalVisible) return null;

  return (
    <ModalPopupAlert
      title={"Rights holders updated for " + inquiry.min}
      confirmationButtonText="CLOSE"
      onClose={handleModalClose}
      open={modalVisible}
      size="sm"
    >
      The following changes were made while processing the inquiry:
      <List>
        <RightsHolderRow
          keyValue="controller"
          label={startCase(localization.mers.rightsHolder.controller)}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="location"
          label={startCase(localization.mers.rightsHolder.location)}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="delegatee"
          label={startCase(localization.mers.rightsHolder.servicer)}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="securedParty"
          label={startCase(localization.mers.rightsHolder.securedParty)}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="securedPartyDelegatee"
          label={startCase(localization.mers.rightsHolder.securedPartyDelegatee)}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="delegateeForTransfers"
          label={localization.mers.rightsHolder.delegateeForTransfersStartCase}
          differences={inquiry.differences}
        />
        <RightsHolderRow
          keyValue="subServicer"
          label={startCase(localization.mers.rightsHolder.subServicer)}
          differences={inquiry.differences}
        />
        {inquiry.updates.map((update) => {
          return (
            <ListItem
              sx={{
                display: "block",
              }}
              disableGutters={true}
              key={update.key}
            >
              <p className="detail-row-label">{update.key}</p>
              <p>{update.description}</p>
            </ListItem>
          );
        })}
      </List>
    </ModalPopupAlert>
  );
}

interface RightsHolderRowProps {
  keyValue: string;
  label: string;
  differences: IRightsHolderDifference[];
}

function RightsHolderRow(props: RightsHolderRowProps) {
  const { differences, keyValue: key, label } = props;
  const diff = differences.find((d) => d.key === key);
  if (!diff) return null;
  let display = "";
  if (diff.newValue && !diff.oldValue) {
    display = "Added " + getOrgDisplay(diff.newValue);
  } else if (diff.oldValue && !diff.newValue) {
    display = "Removed " + getOrgDisplay(diff.oldValue);
  } else {
    display = getOrgDisplay(diff.oldValue) + " changed to " + getOrgDisplay(diff.newValue);
  }
  return (
    <ListItem
      disableGutters={true}
      key={key}
      sx={{
        display: "block",
      }}
    >
      <p className="detail-row-label">{label}</p>
      <p>{display}</p>
    </ListItem>
  );
}
