import React, { ReactElement } from "react";

import _ from "lodash";

import Box from "@mui/material/Box";

import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import { DeliveryRecipientStatus, ManualDeliveryStatus } from "../VaultInterfaces";

interface IMersManualDeliveryConfirmationSelectorProps {
  actions: DeliveryRecipientStatus[];
  currentAction?: DeliveryRecipientStatus;
  parentDelivery: string;
  manualDeliveryStatus: ManualDeliveryStatus;
  onConfirmationStatusChanged: (
    action: DeliveryRecipientStatus,
    manualDeliveryStatus: ManualDeliveryStatus,
    delivery: string
  ) => void;
}

export default function MersManualDeliveryConfirmationSelector(
  props: IMersManualDeliveryConfirmationSelectorProps
): ReactElement {
  function handleChangeClick(event: any) {
    props.onConfirmationStatusChanged(
      props.actions[parseInt(event.target.value)],
      props.manualDeliveryStatus,
      props.parentDelivery
    );
  }

  const recipientSelectStylings: string[] = ["width-full"];
  const options = props.actions.map((action: string, iterator: number) => {
    return (
      <TableMenuItem key={iterator} value={iterator}>
        {action}
      </TableMenuItem>
    );
  });
  let currentIndex = -1;

  _.each(props.actions, (action: DeliveryRecipientStatus, iteratee: number) => {
    if (props.currentAction && action === props.currentAction) {
      currentIndex = iteratee;
    }
  });

  return (
    <Box sx={{ lineHeight: "normal", width: "100%" }}>
      <div>
        <TableSelect
          onChange={handleChangeClick}
          className={recipientSelectStylings.join(" ")}
          size="small"
          value={currentIndex.toString()}
          variant="standard"
        >
          {options}
        </TableSelect>
      </div>
    </Box>
  );
}
