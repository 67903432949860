import React, { ReactElement } from "react";

import { MenuItem, MenuItemProps } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { AuditLogIcon } from "Components/Icons";

import { IVaultLoanSearchResult } from "../VaultInterfaces";
import * as documentService from "../documentService";

interface IAuditLogMenuItemProps {
  data: IVaultLoanSearchResult;
  disabled?: boolean;
  onClick?: () => void;
}

type Props = MenuItemProps & IAuditLogMenuItemProps;

export default function AuditLogMenuItem(props: Props): ReactElement {
  const { data, disabled, onClick, dense = false } = props;

  function handleDocumentDetailsClick(item: any) {
    if (onClick) {
      onClick();
    }
    const url = documentService.getAuditLogLink(item);
    window.open(url);
  }

  return (
    <MenuItem
      key="document-details"
      onClick={() => handleDocumentDetailsClick(data)}
      disabled={disabled || data.workflowType === "MERS"}
      dense={dense}
    >
      <ListItemIcon>
        <AuditLogIcon fontSize={dense ? "small" : "medium"} />
      </ListItemIcon>
      <ListItemText>Audit Log</ListItemText>
    </MenuItem>
  );
}
