import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState, IResultPages, IVaultSearchBarParams, IVaultsSearchParams } from "Types/EVaultAppTypes";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import { IVaultViewInactiveLoansSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";
import { IMersInactivePackagePageResponse, getInactiveMERSPackagesByUrl } from "./inactiveLoanAdapters";

export interface IInactiveLoans {
  initialized: boolean;
  links?: IResultPages;
  resourceUrl?: string;
  searchResult: IVaultViewInactiveLoansSearchResultData[];
  searchBarParams: IVaultSearchBarParams;
  isLoading: boolean;
  sortByParams: types.ISortByParams;
  error?: IHttpErrorState;
}

export const initialInactiveLoansSearchResult: IVaultViewInactiveLoansSearchResultData[] = [];

const initialInactiveLoansApiCallParams: types.IVaultsSearchParams = {
  searchDateOption: "",
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

const initialInactiveLoansSearchBarParams: types.IVaultSearchBarParams = {
  data: initialInactiveLoansApiCallParams,
};

const initialInactiveLoans: IInactiveLoans = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialInactiveLoansSearchBarParams,
  searchResult: initialInactiveLoansSearchResult,
  sortByParams: initialSortByParams("primaryBorrowerName"),
};

export const fetchPackages = createAsyncThunk<
  IMersInactivePackagePageResponse,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("inactiveLoans/fetch", async (url: string, thunkApi) => {
  try {
    return await getInactiveMERSPackagesByUrl(url);
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const inactiveLoansSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: initialInactiveLoans,
  name: "inactiveLoans",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = initialInactiveLoans.searchBarParams;
    },
    updateSearchBarParams: (state, action: PayloadAction<IVaultsSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<types.ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateSearchBarParams, updateSortBy } = inactiveLoansSlice.actions;

export default inactiveLoansSlice.reducer;
