import React from "react";

import ScrollContainer from "Components/ScrollContainer";

import InactiveLoansFilterBar from "./InactiveLoansFilterBar";
import InactiveSearchResultsTable from "./InactiveSearchResultsTable";

interface ISearchResultsTabProps {
  keyRoot: string;
}

type Props = ISearchResultsTabProps;

function InactiveSearchResults(props: Props): JSX.Element {
  const { keyRoot } = props;

  return (
    <ScrollContainer>
      <InactiveLoansFilterBar />
      <InactiveSearchResultsTable keyRoot={keyRoot} />
    </ScrollContainer>
  );
}

export default InactiveSearchResults;
