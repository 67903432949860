import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import { IHttpErrorState, ISortByParams } from "Types/EVaultAppTypes";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";
import {
  IArchivedLoans,
  IVaultArchivedSearchBarParams,
  IVaultViewArchivedLoansSearchResultData,
  archivedLoanSearchParams,
} from "./Types";
import { IPagedArchivedLoans, getTransferredMERSPackagesByUrl } from "./archivedLoansAdapter";

const initialArchivedLoansSearchResult: IVaultViewArchivedLoansSearchResultData[] = [];

const initialArchivedLoansApiCallParams: archivedLoanSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialArchivedLoansSearchBarParams: IVaultArchivedSearchBarParams = {
  data: initialArchivedLoansApiCallParams,
};

const archivedLoans: IArchivedLoans = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialArchivedLoansSearchBarParams,
  searchResult: initialArchivedLoansSearchResult,
  sortByParams: initialSortByParams("primaryBorrowerName"),
};

export const fetchArchivedLoans = createAsyncThunk<
  IPagedArchivedLoans,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("archived/fetch", async (url: string, thunkAPI) => {
  try {
    return await getTransferredMERSPackagesByUrl(url);
  } catch (err) {
    const error = apiError(err);
    return thunkAPI.rejectWithValue(error);
  }
});

export const queueSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchArchivedLoans.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchArchivedLoans.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchArchivedLoans.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: archivedLoans,
  name: "archived",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = archivedLoans.searchBarParams;
    },
    updateSearchBarParams: (state, action: PayloadAction<archivedLoanSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateSearchBarParams, updateSortBy } = queueSlice.actions;

export default queueSlice.reducer;
