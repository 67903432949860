import React, { ReactElement } from "react";

import Tooltip from "@mui/material/Tooltip";
import { createStyles, makeStyles } from "@mui/styles";
import { GridRenderCellParams } from "@mui/x-data-grid";

export default function renderCellTooltip(params: GridRenderCellParams): ReactElement {
  return <GridCellTooltip value={params.value ? params.value.toString() : ""} width={params.colDef.computedWidth} />;
}

interface GridCellTooltipProps {
  value: string;
  width: number;
}

const GridCellTooltip = React.memo(function GridCellTooltip(props: GridCellTooltipProps) {
  const { width, value } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowTooltip(isCurrentlyOverflown);
    // setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <div ref={wrapper} className={classes.root} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div
        ref={cellDiv}
        style={{
          display: "block",
          height: 1,
          position: "absolute",
          top: 0,
          width,
        }}
      />
      <div ref={cellValue} className="cellValue">
        <Tooltip title={showTooltip ? value : ""} placement="top-start">
          <span>{value}</span>
        </Tooltip>
      </div>
    </div>
  );
});

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .cellValue": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      alignItems: "center",
      display: "flex",
      height: "100%",
      lineHeight: "24px",
      position: "relative",
      width: "100%",
    },
  })
);
