import React, { ReactElement } from "react";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers/DesktopDatePicker";

const useStyles = makeStyles({
  input: {
    fontSize: ".81rem",
    width: "100%",
  },
});

type ITableDatePickerProps = Omit<DesktopDatePickerProps<Date, Date>, "renderInput">;

function TableDatePicker(props: ITableDatePickerProps): ReactElement {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <>
      <DesktopDatePicker
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.input}
            helperText={null}
            inputProps={{
              ...params.inputProps,
              className: classes.input,
            }}
            size="small"
            margin="dense"
            variant="standard"
          />
        )}
      />
    </>
  );
}

export default TableDatePicker;
