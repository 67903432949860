import * as React from "react";

import { CloudDownload } from "@mui/icons-material";
import Link from "@mui/material/Link";

interface ITemplateDownloadProps {
  downloadUrl: string;
}

declare global {
  interface Window {
    appSettings: {
      solexCdn: string;
    };
  }
}

const TemplateDownload = ({ downloadUrl }: ITemplateDownloadProps) => {
  return (
    <Link
      className="pseudolink__body"
      color="primary"
      download
      sx={{ alignItems: "center", display: "flex" }}
      href={`${window.appSettings.solexCdn}${downloadUrl}`}
    >
      <CloudDownload sx={{ mr: 0.5 }} />
      <span>Download Template</span>
    </Link>
  );
};

export default TemplateDownload;
