import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import { addTab } from "Features/Tabs/Actions";

export interface IInquiryTab {
  key: string;
  min: string;
  packageId: string;
  loanType: string;
  type: string;
}

export function openTabForInquiry(payload: IInquiryTab): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(
      addTab({
        ...payload,
        closeable: true,
        packageId: payload.packageId,
        title: "MERS Inquiry",
      })
    );
  };
}
