/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ErrorPage from "Components/ErrorPage";

import { logUserOut } from "Adapters/profileAdapter";

export default function VaultUnauthorized(): ReactElement {
  async function handleSignIn() {
    // Force the user to log out to ensure all cookies are cleared
    try {
      await logUserOut();
    } catch (e) {
      console.log("Error logging user out");
    }
    // Redirect after logout to attempt to see if the user is now authorized
    window.location.href = "/login";
  }

  return (
    <ErrorPage title="Unauthorized">
      <Typography variant="body1">
        You are unauthorized to access eVault. Access your session again to check authorization.
      </Typography>
      <Button
        css={css`
          display: block;
          margin: 32px auto 0;
        `}
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleSignIn}
      >
        Sign In
      </Button>
    </ErrorPage>
  );
}
