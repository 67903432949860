import { RootState } from "App/Store";

import { profileSelector } from "Features/Profile/ProfileSlice";

import { Roles } from "../VaultInterfaces";

export function canRegister(roles: Roles[]): boolean {
  return roles.indexOf("evault.mers.registrar") > -1 || roles.indexOf("evault.mers.admin") > -1;
}

export function canTransfer(roles: Roles[]): boolean {
  return roles.indexOf("evault.mers.transferor") > -1 || roles.indexOf("evault.mers.admin") > -1;
}

export function canTransferSelector(state: RootState): boolean {
  const profile = profileSelector(state);
  const roles = profile.roles;
  return roles.indexOf("evault.mers.transferor") > -1 || roles.indexOf("evault.mers.admin") > -1;
}

export function canRegisterSelector(state: RootState): boolean {
  const profile = profileSelector(state);
  const roles = profile.roles;
  return roles.indexOf("evault.mers.registrar") > -1 || roles.indexOf("evault.mers.admin") > -1;
}
