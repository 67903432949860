import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState } from "Types/EVaultAppTypes";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";
import { IBatchOperationsState, IMersBatch } from "./BatchOperationsInterfaces";
import { IBatchOperationsPagedData, getBatchOperationsByUrl } from "./batchOperationsAdapter";

const initialBatchOperationsSearchResult: IMersBatch[] = [];

export interface batchOperationsSearchParams {
  searchKey: QueryStringSearchKey;
  searchTerm?: string;
  start?: Date;
  stop?: Date;
}

export interface IBatchOperationsSearchApiParams extends batchOperationsSearchParams {
  limit: number;
  offset: number;
}

const initialBatchOperationsApiCallParams: batchOperationsSearchParams = {
  searchKey: QueryStringSearchKey.BatchId,
  searchTerm: "",
  stop: new Date(),
};

export interface IVaultBatchOperationsSearchBarParams {
  data: batchOperationsSearchParams;
}

export const initialBatchOperationsSearchBarParams: IVaultBatchOperationsSearchBarParams = {
  data: initialBatchOperationsApiCallParams,
};

const batchOperations: IBatchOperationsState = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialBatchOperationsSearchBarParams,
  searchResult: initialBatchOperationsSearchResult,
  sortByParams: initialSortByParams("initiated"),
};

export const fetchBatchOperations = createAsyncThunk<
  IBatchOperationsPagedData,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("batchOperations/fetch", async (url: string, thunkApi) => {
  try {
    return await getBatchOperationsByUrl(url);
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const batchOperationsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchBatchOperations.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchBatchOperations.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchBatchOperations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  initialState: batchOperations,
  name: "batchOperations",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = batchOperations.searchBarParams;
    },
    updateSearchBarParams: (state, action: PayloadAction<batchOperationsSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<types.ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateSearchBarParams, updateSortBy } = batchOperationsSlice.actions;

export default batchOperationsSlice.reducer;
