import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ArrowDropDown, Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

import { RootState } from "App/Store";

import { createTabKey } from "Common/Utilities";

import { DeliveryOutIcon, RecipientsIcon, RegisterIcon, TransferIcon } from "Components/Icons";

import { openRecipientsTab } from "Features/Vault/Mers/MersRecipients/mersOrgService";
import { openRegisterTabAction } from "Features/Vault/Mers/MersRegister/mersRegistrationService";
import { openTransferTabAction } from "Features/Vault/Mers/MersTransfer/mersTransferTabService";
import { openUpdateTab } from "Features/Vault/Mers/MersUpdate/mersUpdateTabService";
import { openDeliveryTabAction } from "Features/Vault/Mers/mersDeliveryService";
import { canRegister, canTransfer } from "Features/Vault/Mers/mersService";
import { VaultViewClosedLoansTabTypes } from "Features/Vault/VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  profile: state.profile.profile,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function VaultHeaderActions(props: TypesFromRedux): ReactElement | null {
  const { dispatch, profile } = props;
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current != null && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleDeliveryClick() {
    goToClosed(() => {
      dispatch<any>(openDeliveryTabAction());
    });
  }

  function handleRegisterClick() {
    goToClosed(() => {
      dispatch<any>(openRegisterTabAction());
    });
  }

  function handleTransferClick() {
    goToClosed(() => {
      dispatch<any>(openTransferTabAction());
    });
  }

  function handleUpdateClick() {
    goToClosed(() => {
      dispatch<any>(openUpdateTab());
    });
  }

  function goToClosed(callback: () => void) {
    navigate("/app/vault/closed");
    setOpen(false);
    setTimeout(() => {
      callback();
    });
  }

  function handleRecipientsClick() {
    goToClosed(() => {
      setOpen(false);
      const keyRoot = "VaultTab-ClosedLoans-";
      const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.MERSOrgDetails);
      dispatch<any>(openRecipientsTab(key));
    });
  }
  const actions: JSX.Element[] = [];
  if (canTransfer(profile.roles) && profile.settings.mers.independentDeliveryEnabled) {
    actions.push(
      <MenuItem onClick={handleDeliveryClick} key="delivery">
        <ListItemIcon>
          <DeliveryOutIcon className="svg-icon" />
        </ListItemIcon>
        <ListItemText>Deliver</ListItemText>
      </MenuItem>
    );
  }
  if (canRegister(profile.roles)) {
    actions.push(
      <MenuItem onClick={handleRegisterClick} key="register">
        <ListItemIcon>
          <RegisterIcon />
        </ListItemIcon>
        <ListItemText>Register</ListItemText>
      </MenuItem>
    );
  }
  if (canTransfer(profile.roles)) {
    actions.push(
      <MenuItem onClick={handleTransferClick} key="transfer">
        <ListItemIcon>
          <TransferIcon />
        </ListItemIcon>
        <ListItemText>Transfer</ListItemText>
      </MenuItem>
    );
  }

  if (canTransfer(profile.roles)) {
    actions.push(
      <MenuItem onClick={handleUpdateClick} key="update">
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText>Update</ListItemText>
      </MenuItem>
    );
  }
  if (actions.length > 0) {
    actions.push(<Divider key="divider" />);
  }
  if (canTransfer(profile.roles) || canRegister(profile.roles)) {
    actions.push(
      <MenuItem onClick={handleRecipientsClick} key="recipients">
        <ListItemIcon>
          <RecipientsIcon />
        </ListItemIcon>
        <ListItemText>Manage Recipients</ListItemText>
      </MenuItem>
    );
  }

  if (actions.length === 0) return null;

  return (
    <Box
      sx={{
        color: "#FFF",
        display: "flex",
        zIndex: 1000,
      }}
    >
      <div>
        <Button
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-label="Open Main Menu"
          aria-haspopup="true"
          color="inherit"
          id="main-menu__opener"
          onClick={handleToggle}
          ref={anchorRef}
          tabIndex={1}
        >
          Actions
          <ArrowDropDown />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                    {actions}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Box>
  );
}

export default connector(VaultHeaderActions);
