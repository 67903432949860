import axios from "axios";

interface ISmartDocDataRow {
  field: string;
  value: string;
}

export interface ISmartDocData {
  title: string;
  rows: ISmartDocDataRow[];
}

export function getSmartDocDataForDocument(packageId: string, docId: string): Promise<ISmartDocData> {
  return axios.get(`/api/packages/${packageId}/documents/${docId}/smart-doc-data`).then((response) => response.data);
}
