import React, { ReactElement } from "react";

interface IDetailRowProps {
  label: string;
  value: React.ReactNode;
}

export default function DetailRow(props: IDetailRowProps): ReactElement {
  return (
    <p>
      <span className="detail-row-label">{props.label}:</span>
      <span>{props.value}</span>
    </p>
  );
}
