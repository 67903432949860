import * as React from "react";
import { useState } from "react";

import Information from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import RegistrationDetailsModal from "./RegistrationDetailsModal";
import { IRegistrationHistory } from "./VaultInterfaces";

interface ITransferDetailsModal {
  details: IRegistrationHistory;
}

export default function RegistrationDetails(props: ITransferDetailsModal) {
  const { details } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (details == null) return null;

  function handleOpen() {
    setIsOpen(true);
  }
  function handleClose() {
    setIsOpen(false);
  }
  return (
    <>
      <IconButton onClick={handleOpen}>
        <Information />
      </IconButton>
      <RegistrationDetailsModal open={isOpen} onClose={handleClose} details={details} />
    </>
  );
}
