/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import { Box, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import { HTMLElementTypes } from "Types/EVaultAppTypes";
import * as types from "Types/EVaultAppTypes";

interface ICustomHTMLElementProps {
  label?: string;
  options: any[];
  name: string;
  index: number;
  htmlType: number;
  searchParams: types.IVaultsSearchParams;
  eventCallback: (event: any) => void;
}

interface SelectedOption {
  apiParamValue: string;
  description: string;
}

const useStyles = makeStyles({
  dateInput: {
    fontSize: ".81rem",
    width: 140,
  },
  fontOverride: {
    fontSize: ".81rem",
  },
  select: {
    width: 120,
  },
});

export default function CustomHTMLElement(props: ICustomHTMLElementProps): ReactElement {
  const classes = useStyles();
  const breakerClass = (props.index + 1) % 5 === 0 ? "sxa-breaker" : "";
  switch (props.htmlType) {
    case HTMLElementTypes.DROPDOWN:
      return (
        <FormControl margin="normal">
          <Select
            autoWidth={false}
            classes={{
              select: classes.fontOverride,
            }}
            css={css`
              margin-right: 4px;
              width: 160px;
            `}
            name={props.name}
            onChange={(event) => props.eventCallback(event)}
            size="small"
            value={props.searchParams[props.name]}
          >
            {props.options.map((item: types.ISearchBarHTMLElementsValues) => (
              <MenuItem key={item.apiParamValue} value={item.apiParamValue}>
                {item.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case HTMLElementTypes.CALENDAR:
      return (
        <DesktopDatePicker
          css={css`
            padding: 0;
          `}
          disableFuture={true}
          onChange={(event) =>
            props.eventCallback({
              date: event,
              target: {
                name: props.name,
              },
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.dateInput}
              helperText={null}
              inputProps={{
                ...params.inputProps,
                className: classes.dateInput,
              }}
              size="small"
              margin="normal"
              css={css`
                margin-right: 8px;
                width: 180px;
              `}
            />
          )}
          value={props.searchParams[props.name] ?? null}
        />
      );

    case HTMLElementTypes.INPUT:
      return (
        <>
          <TextField
            inputProps={{
              className: classes.dateInput,
            }}
            margin="normal"
            name={props.name}
            onChange={(event) => props.eventCallback(event)}
            placeholder="search value"
            size="small"
            value={props.searchParams[props.name]}
          />
          <div
            css={css`
              margin: 8px 8px 0px 8px;
            `}
          >
            in
          </div>
        </>
      );

    case HTMLElementTypes.DROPDOWNCHECKLIST:
      const dropdown = (
        <FormControl margin="normal">
          <InputLabel
            css={css`
              font-size: 0.81rem;
            `}
            id={`filter-${props.name}`}
            size="small"
          >
            {props.label}
          </InputLabel>
          <Select
            autoWidth={false}
            classes={{
              select: classes.fontOverride,
            }}
            css={css`
              margin-right: 4px;
              width: 160px;
            `}
            input={<OutlinedInput label={props.name} />}
            labelId={`filter-${props.name}`}
            multiple
            name={props.name}
            onChange={(event) => {
              props.eventCallback(event);
            }}
            renderValue={(selected: SelectedOption[]) =>
              selected
                .map((s: SelectedOption) => {
                  const item = props.options.find((o) => o.apiParamValue === s);
                  if (item) {
                    return item.description;
                  }
                  return s.description;
                })
                .join(", ")
            }
            size="small"
            value={props.searchParams[props.name] || []}
          >
            {props.options.map((item: types.ISearchBarHTMLElementsValues) => (
              <MenuItem key={item.apiParamValue} value={item.apiParamValue}>
                <Checkbox checked={props.searchParams[props.name].indexOf(item.apiParamValue) > -1} color="primary" />
                <ListItemText primary={item.description} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
      return dropdown;

    case HTMLElementTypes.DIVIDERBLOCK:
      return (
        <Box
          css={css`
            align-items: center;
            display: flex;
            height: 40px;
            margin: 0 8px 0 4px;
            padding-top: 8px;
            width: fit-content;
          `}
        >
          <Divider
            css={css`
              margin-right: 4px;
            `}
            flexItem
            orientation="vertical"
          />
          <Divider flexItem orientation="vertical" />
        </Box>
      );

    default:
      return <span className={`${breakerClass}`} />;
  }
}
