import React from "react";

import { ConnectedProps, connect } from "react-redux";

import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import { RootState } from "App/Store";

import * as errorActions from "Features/Errors/Errors";

const mapStateToProps = (state: RootState) => ({
  errors: state.errors,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

function GlobalNotification(props: TypesFromRedux) {
  const { dispatch, errors } = props;

  function handleClose(e: any, reason: string) {
    if (reason === "clickaway") {
      return;
    }
    dispatch(errorActions.close());
  }

  function handleAlertClose() {
    dispatch(errorActions.close());
  }

  function handleExited() {
    dispatch(errorActions.dequeue());
  }

  if (!errors.all.length) return null;
  const currentError = errors.all[errors.all.length - 1];
  return currentError.inForm ? null : (
    <Snackbar
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      open={errors.isOpen}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
      style={{
        top: "-4px",
      }}
      autoHideDuration={currentError.autoHide ? currentError.autoHideDuration : null}
    >
      <MuiAlert elevation={6} onClose={handleAlertClose} severity={currentError.variant}>
        {currentError.message}
      </MuiAlert>
    </Snackbar>
  );
}

export default connector(GlobalNotification);
