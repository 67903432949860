/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function MersInquiryIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M13 16.7V20h3v-4.5c-1.1 0-2.1-.2-3-.7v1.9zm-3.2-4.9l-1.3 4L5.4 6H1v14h3V10l3.4 10h2.2l2-6c-.7-.6-1.3-1.3-1.8-2.2zm10.1-.9c.4-.7.7-1.5.7-2.4 0-2.5-2-4.5-4.5-4.5s-4.5 2-4.5 4.5 2 4.5 4.5 4.5c.9 0 1.7-.3 2.4-.7l3.1 3.1L23 14l-3.1-3.1zm-3.8.1c-1.4 0-2.5-1.1-2.5-2.5S14.7 6 16.1 6s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5z" />
    </SvgIcon>
  );
}
