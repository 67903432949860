/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import { GridRowId } from "@mui/x-data-grid";

import TableMenuItem from "Components/Table/TableMenuItem";
import TableSelect from "Components/Table/TableSelect";

import { MersRecipientAcceptStatusType } from "Features/Vault/VaultInterfaces";

interface IMersOrgDetailsAutoAcceptSelectorProps {
  currentOption: MersRecipientAcceptStatusType;
  disabled?: boolean;
  isDelivery: boolean;
  onSelectionChange: (option: MersRecipientAcceptStatusType, rowId: GridRowId, isDelivery: boolean) => void;
  rowId: GridRowId;
}

function MersOrgDetailsAutoAcceptSelector(props: IMersOrgDetailsAutoAcceptSelectorProps): ReactElement {
  const handleChangeClick = (event: any) => {
    props.onSelectionChange(parseInt(event.target.value), props.rowId, props.isDelivery);
  };

  const getOptionValue = (option: MersRecipientAcceptStatusType) => {
    switch (option) {
      case MersRecipientAcceptStatusType.Reject:
        return "Auto disapprove";
      case MersRecipientAcceptStatusType.Accept:
        return "Auto accept";
      case MersRecipientAcceptStatusType.Approve:
        return "Auto approve";
      case MersRecipientAcceptStatusType.Manual:
        return "Manual";
    }
  };

  let optionsArray: MersRecipientAcceptStatusType[];
  if (props.isDelivery) {
    optionsArray = [
      MersRecipientAcceptStatusType.Approve,
      MersRecipientAcceptStatusType.Reject,
      MersRecipientAcceptStatusType.Manual,
    ];
  } else {
    optionsArray = [
      MersRecipientAcceptStatusType.Accept,
      MersRecipientAcceptStatusType.Reject,
      MersRecipientAcceptStatusType.Manual,
    ];
  }

  const options = optionsArray.map((option: MersRecipientAcceptStatusType) => {
    return (
      <TableMenuItem key={option} value={option}>
        {getOptionValue(option)}
      </TableMenuItem>
    );
  });
  const currentOption = props.currentOption == null ? optionsArray[0] : props.currentOption;
  return (
    <TableSelect
      value={currentOption.toString()}
      disabled={props.disabled}
      onChange={handleChangeClick}
      css={css`
        min-width: 150px;
      `}
      size="small"
      variant="standard"
    >
      {options}
    </TableSelect>
  );
}

export default MersOrgDetailsAutoAcceptSelector;
