import * as React from "react";

import DownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle, Grid, Link, Skeleton } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import { makeStyles } from "@mui/styles";

import { MersTransactionGroupStatus } from "./BatchOperationsInterfaces";

const useStyles = makeStyles({
  alert: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface IMersGroupStatus {
  count: number;
}

interface IMersGroupStatusProps {
  id: string;
  loading: boolean;
  status?: IMersGroupStatus;
  type?: MersTransactionGroupStatus;
  totalCount: number;
}

/**
 * Component that displays a status group for the batch.
 */
function BatchOperationsGroupStatus(props: IMersGroupStatusProps): JSX.Element {
  const { type, status, totalCount } = props;
  const classes = useStyles();
  let severity: AlertColor = "info";
  let text = "In Progress";
  let query = `?status=${type}`;
  if (type === "Failed") {
    text = "Fail";
  } else if (type === "Complete") {
    text = "Complete";
  } else if (type === "Rejected") {
    text = "Rejected by Recipient";
  } else if (type === "All") {
    text = "All Results";
    query = "";
  }

  if (type === "Failed" || type === "Rejected") {
    severity = "error";
  }
  if (type === "Complete") {
    severity = "success";
  }

  if (props.loading) {
    return (
      <Grid item xs={3} xl={2}>
        <Skeleton height={128} />
      </Grid>
    );
  }

  return (
    <Grid item xs={3} xl={2}>
      <Link href={`/api/v1/mers/batches/${props.id}/export${query}`} underline="none" target="_blank">
        <Alert action={<DownloadIcon />} className={classes.alert} severity={severity} variant="filled">
          <AlertTitle>
            {status?.count ?? 0} of {totalCount}
          </AlertTitle>
          {text}
        </Alert>
      </Link>
    </Grid>
  );
}

export default BatchOperationsGroupStatus;
