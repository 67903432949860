import React, { ReactElement, useState } from "react";

import _ from "lodash";

import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef, GridSelectionModel } from "@mui/x-data-grid";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { nonce } from "../../../App/App";
import ErrorOverlay from "../../../Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "../../../Components/DataGridCustomComponents/LoadingOverlay";
import { IDeliveryDocument, IMersDeliveryPackage } from "../VaultInterfaces";

interface IDeliveryDocumentSelectionModalProps {
  deliveryPackage?: IMersDeliveryPackage;
  open: boolean;
  onClose: () => void;
  onSubmit: (deliveryPackage: IMersDeliveryPackage, selectedDocuments: IDeliveryDocument[]) => void;
}

const useStyles = makeStyles({
  dialogContentRoot: {
    padding: 0,
  },
});

export default function DeliveryDocumentSelectionModal(props: IDeliveryDocumentSelectionModalProps): ReactElement {
  const [disabled, setDisabled] = useState<boolean>(false);
  const selectedDocs = props.deliveryPackage?.documents.filter((item) => item.selected).map((item) => item.id);
  const [selected, setSelected] = useState<GridSelectionModel>(selectedDocs || []);
  const classes = useStyles();

  function handleCloseClick(): void {
    props.onClose();
  }

  function onConfirmClick(): void {
    props.onClose();
    if (props.deliveryPackage) {
      props.onSubmit(props.deliveryPackage, getUpdatedDocuments());
    }
  }

  function getUpdatedDocuments(): IDeliveryDocument[] {
    const result: IDeliveryDocument[] = [];
    const documents = props.deliveryPackage?.documents;
    if (documents != null) {
      _.each(documents, (doc) => {
        result.push({
          id: doc.id,
          name: doc.name,
          selected: selected.indexOf(doc.id) > -1,
        });
      });
    }

    return result;
  }

  const columns: GridColDef[] = [
    {
      field: "name",
      flex: 1,
      headerName: "Document Name",
      sortable: false,
    },
  ];

  const onSelectionModelChange = (selected: GridSelectionModel) => {
    setSelected(selected);
    setDisabled(selected.length === 0);
  };

  const content = (
    <DataGrid
      autoHeight
      checkboxSelection
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      hideFooter
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSelectionModelChange={onSelectionModelChange}
      selectionModel={selected}
      rows={props.deliveryPackage?.documents || []}
      scrollbarSize={0}
    />
  );

  return (
    <ModalPopupAlert
      confirmationButtonText="CONTINUE"
      dialogContentProps={{
        classes: {
          root: classes.dialogContentRoot,
        },
      }}
      showDeclineLink={true}
      disabled={disabled}
      onClose={handleCloseClick}
      onConfirm={onConfirmClick}
      overlayCloseClick={false}
      title="Document Selection"
      content={content}
      open={props.open}
      size="sm"
    />
  );
}
