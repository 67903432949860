/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";

import AboutUs from "./AboutUs";
import TermsOfUse from "./TermsOfUse";

const dividerCss = css`
  margin-left: 8px;
  margin-right: 8px;
`;

export default function Footer(): ReactElement {
  return (
    <div
      css={css`
        align-items: center;
        background-color: #ebebeb;
        border-top: 1px solid #d2d2d2;
        bottom: 0;
        color: rgba(50, 50, 50, 0.9);
        display: flex;
        font-family: "Roboto Regular", "Roboto", "Arial", sans-serif;
        font-size: 10px;
        height: 30px;
        justify-content: flex-end;
        padding: 0 24px;
        position: fixed;
        width: 100%;
        z-index: 2100;
      `}
      id="site-footer"
    >
      <div
        css={css`
          display: flex;
          a,
          * a {
            font-size: 10px;
            color: rgba(50, 50, 50, 0.9);
          }
        `}
        id="site-footer-content"
        className="right"
      >
        <TermsOfUse />
        <Divider css={dividerCss} flexItem orientation="vertical" />
        <span id="site-footer-link-privacy" className="wrap-none">
          <Link
            href="https://www.firstam.com/privacy-policy/"
            id="site-footer-link-privacy-policy"
            target="_blank"
            underline="none"
          >
            Privacy
          </Link>
        </span>
        <Divider css={dividerCss} flexItem orientation="vertical" />
        <AboutUs />
        <Divider css={dividerCss} flexItem orientation="vertical" />
        <span id="site-footer-copyright-year-site-company">
          <span id="site-footer-copyright-year" className="wrap-none">
            &copy;&nbsp;{new Date().getFullYear()}&nbsp;
          </span>
          <span id="site-footer-site-company">
            <span id="site-footer-site">First American Financial Corporation and/or its affiliates.</span>
          </span>
        </span>
        <span id="site-footer-all-rights-reserved" className="wrap-none">
          All rights reserved
        </span>
      </div>
    </div>
  );
}
