import React, { ReactElement } from "react";

import Tab from "Components/Panels/TabbedPanel/TabbedPanelPane";

import { ILoanTabDetails } from "../Tabs/Tabs";
import { VaultViewAllLoansTabTypes } from "./AllLoans/Constants";
import AllLoansSearchResults from "./AllLoans/SearchResultsTab";
import ArchivedLoansSearchResults from "./ArchivedLoans/ArchivedSearchResults";
import BatchOperationsDetails from "./BatchOperations/BatchOperationsDetails";
import BatchOperationsSearchResults from "./BatchOperations/BatchOperationsSearchResults";
import ClosedLoansSearchResults from "./ClosedLoans/ClosedSearchResults";
import DocumentList from "./DocumentList";
import InactiveLoansSearchResults from "./InactiveLoans/InactiveSearchResults";
import IncomingDeliveries from "./Incoming/Deliveries/IncomingDeliveriesTab";
import IncomingTransfers from "./Incoming/Transfers/IncomingTransfersTab";
import MersDelivery from "./Mers/MersDelivery/MersDelivery";
import MersOrgDetails from "./Mers/MersRecipients";
import MersRegister from "./Mers/MersRegister/MersRegister";
import MersTransfer from "./Mers/MersTransfer/MersTransfer";
import MersUpdate from "./Mers/MersUpdate/MersUpdate";
import MersInquiryContainer from "./MersInquiryContainer";
import MinReconciliationMersOrgs from "./MinReconciliation/MinReconciliationMersOrgs";
import MinReconciliationReportDetails from "./MinReconciliation/MinReconciliationReportDetails";
import MinReconciliationReportSearchResults from "./MinReconciliation/MinReconciliationReportSearchResults";
import QueueLoansSearchResults from "./Queue/QueueSearchResults";
import {
  VaultViewArchivedLoansTabTypes,
  VaultViewBatchOperationsTabTypes,
  VaultViewClosedLoansTabTypes,
  VaultViewInactiveLoansTabTypes,
  VaultViewIncomingTabTypes,
  VaultViewMinReconciliationTabTypes,
  VaultViewQueuedLoansTabTypes,
} from "./VaultInterfaces";

interface ITabStoreProps {
  tab: ILoanTabDetails;
}

export default function TabStore(props: ITabStoreProps): ReactElement | null {
  const { tab } = props;
  let body: JSX.Element;
  if (!tab) return null;

  switch (tab.type) {
    case VaultViewAllLoansTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <AllLoansSearchResults keyRoot={tab.key} />
        </Tab>
      );
      break;

    case VaultViewBatchOperationsTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <BatchOperationsSearchResults keyRoot={tab.key} />
        </Tab>
      );
      break;
    case VaultViewMinReconciliationTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <MinReconciliationReportSearchResults keyRoot={tab.key} />
        </Tab>
      );
      break;

    case VaultViewArchivedLoansTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <ArchivedLoansSearchResults keyRoot={tab.key} />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <ClosedLoansSearchResults
            handleAddTab={tab.handleAddTab}
            keyRoot={tab.key}
            openLastResultModal={tab.openLastResultModal}
            showDeliveryCancelConfirmModal={tab.showDeliveryCancelConfirmModal}
            showRemoveDelegateeForTransferConfirmModal={tab.showRemoveDelegateeForTransferConfirmModal}
            showReverseRegistrationConfirmModal={tab.showReverseRegistrationConfirmModal}
            showTransferCancelConfirmModal={tab.showTransferCancelConfirmModal}
            showReverseModificationConfirmModal={tab.showReverseModificationConfirmModal}
          />
        </Tab>
      );
      break;

    case VaultViewInactiveLoansTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <InactiveLoansSearchResults keyRoot={tab.key} />
        </Tab>
      );
      break;

    case VaultViewQueuedLoansTabTypes.SearchResults:
      body = (
        <Tab key={tab.key}>
          <QueueLoansSearchResults
            keyRoot={tab.key}
            onAddInquiryDetails={tab.onAddInquiryDetails}
            onUpdateCallback={tab.onUpdateCallback}
          />
        </Tab>
      );
      break;

    case VaultViewIncomingTabTypes.IncomingDeliveries:
      body = (
        <Tab key={tab.key}>
          <IncomingDeliveries onViewDocListCallback={tab.onViewDocListCallback} />
        </Tab>
      );
      break;

    case VaultViewIncomingTabTypes.IncomingTransfers:
      body = (
        <Tab key={tab.key}>
          <IncomingTransfers onViewDocListCallback={tab.onViewDocListCallback} />
        </Tab>
      );
      break;

    case VaultViewAllLoansTabTypes.DocumentList:
    case VaultViewArchivedLoansTabTypes.DocumentList:
    case VaultViewClosedLoansTabTypes.DocumentList:
    case VaultViewInactiveLoansTabTypes.DocumentList:
    case VaultViewIncomingTabTypes.DocumentList:
    case VaultViewQueuedLoansTabTypes.DocumentList:
      body = (
        <Tab key={tab.key}>
          <DocumentList
            isLoading={tab.isLoading}
            key={tab.key}
            loanNumber={tab.loanNumber}
            packageId={tab.packageId}
            packageType={tab.packageType}
            alternatePackageType={tab.alternatePackageType}
            partyName={tab.partyName}
            tabIdentifier={{
              key: tab.key,
              loanType: tab.loanType,
            }}
            workflowType={tab.workflowType}
          />
        </Tab>
      );
      break;

    case VaultViewAllLoansTabTypes.MERSInquiryDetails:
    case VaultViewArchivedLoansTabTypes.MERSInquiryDetails:
    case VaultViewClosedLoansTabTypes.MERSInquiryDetails:
    case VaultViewInactiveLoansTabTypes.MERSInquiryDetails:
    case VaultViewQueuedLoansTabTypes.MERSInquiryDetails:
    case VaultViewIncomingTabTypes.MERSInquiryDetails:
      body = (
        <Tab key={tab.key}>
          <MersInquiryContainer
            key={tab.key}
            min={tab.min as string}
            packageId={tab.packageId}
            tabIdentifier={{
              key: tab.key,
              loanType: tab.loanType,
            }}
          />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.MERSOrgDetails:
      body = (
        <Tab key={tab.key}>
          <MersOrgDetails key={tab.key} />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.DeliverMERS:
      body = (
        <Tab key={tab.key}>
          <MersDelivery />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.TransferMERS:
      body = (
        <Tab key={tab.key}>
          <MersTransfer />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.RegisterMERS:
      body = (
        <Tab key={tab.key}>
          <MersRegister />
        </Tab>
      );
      break;

    case VaultViewClosedLoansTabTypes.UpdateMERS:
      body = (
        <Tab key={tab.key}>
          <MersUpdate />
        </Tab>
      );
      break;

    case VaultViewBatchOperationsTabTypes.BatchOperationsStatusDetails:
      body = (
        <Tab key={tab.key}>
          <BatchOperationsDetails batch={tab.batch} />
        </Tab>
      );
      break;

    case VaultViewMinReconciliationTabTypes.MinReconciliationStatusDetails:
      body = (
        <Tab key={tab.key}>
          <MinReconciliationReportDetails report={tab.report} />
        </Tab>
      );
      break;
    case VaultViewMinReconciliationTabTypes.MinReconciliationClientMersOrgs:
      body = (
        <Tab key={tab.key}>
          <MinReconciliationMersOrgs />
        </Tab>
      );
      break;

    default:
      body = <span>Unknown tab type</span>;
      break;
  }
  return body;
}
