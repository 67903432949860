import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { apiError } from "Common/error";

import { IHttpErrorState, IResultPages, ISortByParams } from "Types/EVaultAppTypes";
import * as types from "Types/EVaultAppTypes";

import { getTransferableMersPackagesByUrl } from "Adapters/Mers/mersPackageAdapter";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import {
  IMersInitiators,
  IMersOriginator,
  IMersRecipientDetails,
  IMersTransferPackage,
  MersTransferTypes,
  RecipientAccessors,
} from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../../Constants";
import { getMERsTransferType, initializeTransferOptionsForPackage } from "./mersTransferService";
import { getTransferableLoansForTransferable } from "./mersTransferTabService";

const initialTransfers: IMersTransferPackage[] = [];

export interface transferSearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortByParams?: ISortByParams;
  start: Date;
  stop: Date;
}

export interface IVaultTransferSearchBarParams {
  data: transferSearchParams;
}

const initialTransferApiCallParams: transferSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialTransferSearchBarParams: IVaultTransferSearchBarParams = {
  data: initialTransferApiCallParams,
};

export interface IMersTransferSliceState {
  error?: IHttpErrorState;
  initialized: boolean;
  isLoading: boolean;
  links?: IResultPages;
  resourceUrl?: string;
  searchBarParams: IVaultTransferSearchBarParams;
  sortByParams: ISortByParams;
  transfers: IMersTransferPackage[];
}

const initialState: IMersTransferSliceState = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialTransferSearchBarParams,
  sortByParams: initialSortByParams(),
  transfers: initialTransfers,
};

interface IEffectiveDateUpdate {
  effectiveDate: Date;
  packageId: string;
}

interface ITransferRecipientUpdate {
  packageId: string;
  recipient: IMersRecipientDetails;
  type: RecipientAccessors;
}

interface ITransferDeliveryUpdate {
  includeDelivery: boolean;
  packageId: string;
}

export interface IMersTransferFetch {
  url: string;
  mersOrgs: IMersOriginator[];
  state: RootState;
}

export interface IMersTransferPackageResponse {
  links: types.IResultPages;
  results: IMersTransferPackage[];
}

export const fetchPackages = createAsyncThunk<
  IMersTransferPackageResponse,
  IMersTransferFetch,
  {
    rejectValue: IHttpErrorState;
  }
>("mersTransfers/fetch", async (payload: IMersTransferFetch, thunkApi) => {
  try {
    const response = await getTransferableMersPackagesByUrl(payload.url);
    const transferrrableLoans = getTransferableLoansForTransferable(response.results);
    const packages: IMersTransferPackage[] = transferrrableLoans.map((loan) => {
      initializeTransferOptionsForPackage(payload.state, loan);
      return {
        ...loan,
        effectiveDate: new Date(),
        selected: false,
      };
    });
    return {
      ...response,
      results: packages,
    };
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const mersTransferSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.transfers = [];
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.transfers = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState,
  name: "mersTransfers",
  reducers: {
    resetLoadingState: (state) => {
      state.isLoading = false;
      state.initialized = false;
    },
    resetMersTransfers: (state) => {
      state.transfers = [];
    },
    resetSearch: (state) => {
      state.searchBarParams = initialState.searchBarParams;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      if (!action.payload) {
        state.initialized = true;
      }
    },
    updateIncludeDelivery: (state, action: PayloadAction<ITransferDeliveryUpdate>) => {
      const transfer = state.transfers.find((transfer) => transfer.packageId === action.payload.packageId);
      if (transfer) {
        transfer.includeDelivery = action.payload.includeDelivery;
        setCalculatedStates(transfer);
      }
    },
    updateMersTransfers: (state, action: PayloadAction<IMersTransferPackage[]>) => {
      state.transfers = action.payload.map((transfer) => {
        setCalculatedStates(transfer);
        return transfer;
      });
    },
    updateRecipient: (state, action: PayloadAction<ITransferRecipientUpdate>) => {
      const transfer = state.transfers.find((transfer) => transfer.packageId === action.payload.packageId);
      if (transfer) {
        (transfer as any)[action.payload.type] = (action.payload.recipient || EMPTY_MERS_RECIPIENT_DETAILS) as any;
        setCalculatedStates(transfer);
      }
    },
    updateSearchBarParams: (state, action: PayloadAction<transferSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
    updateTransferDate: (state, action: PayloadAction<IEffectiveDateUpdate>) => {
      state.transfers = state.transfers.map((transfer) => {
        if (transfer.packageId !== action.payload.packageId) return transfer;
        transfer.effectiveDate = action.payload.effectiveDate;
        setCalculatedStates(transfer);
        return transfer;
      });
    },
  },
});

function setCalculatedStates(transfer: IMersTransferPackage) {
  transfer.transactionType = getMERsTransferType(transfer);
  transfer.isInvalidState = false;
  if (transfer.selected) {
    transfer.isInvalidState = !isTransferValid(transfer);
  }
}

/**
 * Used to set loans queued for transfer to error state in the table
 * @param transferPackage the package to be evaluated
 * @returns Whether the row is invalid
 */
function isTransferValid(transferPackage: IMersTransferPackage): boolean {
  if (!transferPackage.selected) return false;
  const transactionType = getMERsTransferType(transferPackage);
  if (!isRightsHolderAuthorized(transferPackage)) return false;

  if (!isDelegateeForTransferValid(transferPackage, transactionType)) return false;
  if (!areSecuredPartyRightsValid(transferPackage, transactionType)) return false;
  return isSubServicerValid(transferPackage, transactionType);
}

/**
 * Validates that the secured party is a valid recipient for the current transaction
 * @param transferPackage the package to be evaluated
 * @param transactionType the type of transfer
 * @returns whether the secured party can be included
 */
function areSecuredPartyRightsValid(
  transferPackage: IMersTransferPackage,
  transactionType: MersTransferTypes
): boolean {
  if (
    (transferPackage.securedPartyRecipient &&
      transferPackage.securedPartyRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) ||
    (transferPackage.securedPartyDelegateeRecipient &&
      transferPackage.securedPartyDelegateeRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id)
  ) {
    if (
      transactionType !== MersTransferTypes.ControlAndLocationWithSecuredParty &&
      transactionType !== MersTransferTypes.ControlWithSecuredParty
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Validates that the delegatee for transfers can be included in the current transfer.
 * The DFT may not be included in transfers only including servicers and location rights holders.
 * @param transferPackage the package to be evaluated
 * @param transactionType the type of transfer
 * @returns whether the delegatee for transfers can be included
 */
function isDelegateeForTransferValid(
  transferPackage: IMersTransferPackage,
  transactionType: MersTransferTypes
): boolean {
  if (
    transferPackage.delegateeForTransfersRecipient &&
    transferPackage.delegateeForTransfersRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    if (
      transactionType === MersTransferTypes.Servicer ||
      transactionType === MersTransferTypes.LocationServicer ||
      transactionType === MersTransferTypes.Location
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Validates that the subservicer can be included in the transfer. Subservicer may only be included in servicing transfers
 * @param transferPackage the package to be evaluated
 * @param transactionType the type of transfer
 * @returns whether the subservicer can be included
 */
function isSubServicerValid(transferPackage: IMersTransferPackage, transactionType: MersTransferTypes): boolean {
  if (
    transferPackage.subServicerRecipient &&
    transferPackage.subServicerRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    if (transactionType !== MersTransferTypes.Servicer) {
      return false;
    }
  }
  return true;
}

function isRightsHolderAuthorized(transferPackage: IMersTransferPackage) {
  let authorized = false;
  const transactionType = getMERsTransferType(transferPackage);
  const initiators = transferPackage.mersInitiators;

  if (
    transactionType === MersTransferTypes.Control ||
    transactionType === MersTransferTypes.ControlWithSecuredParty ||
    transactionType === MersTransferTypes.ControlLocation ||
    transactionType === MersTransferTypes.ControlAndLocationWithSecuredParty ||
    transactionType === MersTransferTypes.ControlServicer ||
    transactionType === MersTransferTypes.All
  ) {
    if (initiators.controller && initiators.controller.orgId === transferPackage.originatorOrgId) authorized = true;
    if (initiators.delegateeForTransfer && initiators.delegateeForTransfer.orgId === transferPackage.originatorOrgId)
      authorized = true;
    if (initiators.securedParty && initiators.securedParty.orgId === transferPackage.originatorOrgId) authorized = true;
    if (initiators.securedPartyDelegatee && initiators.securedPartyDelegatee.orgId === transferPackage.originatorOrgId)
      authorized = true;
  } else if (transactionType === MersTransferTypes.Location) {
    authorized = canTransferLocation(initiators, transferPackage.originatorOrgId);
  } else if (transactionType === MersTransferTypes.Servicer) {
    if (initiators.controller && initiators.controller.orgId === transferPackage.originatorOrgId) authorized = true;
    if (initiators.delegateeForTransfer && initiators.delegateeForTransfer.orgId === transferPackage.originatorOrgId)
      authorized = true;
    if (initiators.delegatee && initiators.delegatee.orgId === transferPackage.originatorOrgId) authorized = true;
    if (initiators.subServicer && initiators.subServicer.orgId === transferPackage.originatorOrgId) authorized = true;
  }
  return authorized;
}

export function canTransferControl(initiators: IMersInitiators, mersOrgId: string) {
  if (initiators.controller && initiators.controller.orgId === mersOrgId) return true;
  if (initiators.securedParty && initiators.securedParty.orgId === mersOrgId) return true;
  if (initiators.securedPartyDelegatee && initiators.securedPartyDelegatee.orgId === mersOrgId) return true;
  if (initiators.delegateeForTransfer && initiators.delegateeForTransfer.orgId === mersOrgId) return true;
  return false;
}

export function canTransferLocation(initiators: IMersInitiators, mersOrgId: string) {
  if (canTransferControl(initiators, mersOrgId)) return true;
  if (initiators.delegatee && initiators.delegatee.orgId === mersOrgId) return true;
  if (initiators.subServicer && initiators.subServicer.orgId === mersOrgId) return true;
  return false;
}

export function canTransfer(initiators: IMersInitiators, mersOrgId: string) {
  return canTransferLocation(initiators, mersOrgId);
}

export function canTransferServicer(initiators: IMersInitiators, mersOrgId: string) {
  if (canTransferControl(initiators, mersOrgId)) return true;
  if (initiators.delegatee && initiators.delegatee.orgId === mersOrgId) return true;
  if (initiators.subServicer && initiators.subServicer.orgId === mersOrgId) return true;
  return false;
}

export function canTransferDelegateeForTransfers(initiators: IMersInitiators, mersOrgId: string) {
  return canTransferControl(initiators, mersOrgId);
}

export function canTransferSecuredParty(initiators: IMersInitiators, mersOrgId: string) {
  return canTransferControl(initiators, mersOrgId);
}

export function canTransferSecuredPartyDelegatee(initiators: IMersInitiators, mersOrgId: string) {
  return canTransferControl(initiators, mersOrgId);
}

export function canTransferSubServicer(initiators: IMersInitiators, mersOrgId: string) {
  if (initiators.controller && initiators.controller.orgId === mersOrgId) return true;
  if (initiators.delegateeForTransfer && initiators.delegateeForTransfer.orgId === mersOrgId) return true;
  if (initiators.delegatee && initiators.delegatee.orgId === mersOrgId) return true;
  if (initiators.subServicer && initiators.subServicer.orgId === mersOrgId) return true;
  return false;
}

export const {
  resetLoadingState,
  resetMersTransfers,
  resetSearch,
  setLoadingState,
  updateIncludeDelivery,
  updateMersTransfers,
  updateRecipient,
  updateSearchBarParams,
  updateSortBy,
  updateTransferDate,
} = mersTransferSlice.actions;

export default mersTransferSlice.reducer;
