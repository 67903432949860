import React from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ScrollContainer from "../../../Components/ScrollContainer";
import MinReconciliationReportFilterBar from "./MinReconciliationReportFilterBar";
import MinReconciliationReportSearchResultsTable from "./MinReconciliationReportSearchResultsTable";
import { goToMinReconciliationClientMersOrgsTab } from "./minReconciliationReportService";

interface ISearchResultsTabProps {
  keyRoot: string;
}
const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = ISearchResultsTabProps & TypesFromRedux;

export function MinReconciliationReportSearchResults(props: Props): JSX.Element {
  const { dispatch } = props;
  function handleManageMersOrgsClick() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(goToMinReconciliationClientMersOrgsTab());
  }

  const { keyRoot } = props;
  return (
    <ScrollContainer>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "8px",
          paddingRight: "8px",
        }}
      >
        <MinReconciliationReportFilterBar />
        <Button onClick={handleManageMersOrgsClick} type="button" variant="contained" color="secondary">
          Manage MERS Orgs
        </Button>
      </Box>
      <MinReconciliationReportSearchResultsTable keyRoot={keyRoot} />
    </ScrollContainer>
  );
}

export default connector(MinReconciliationReportSearchResults);
