import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "App/Store";

import { apiError } from "Common/error";

import { IHttpErrorState, ISortByParams } from "Types/EVaultAppTypes";

import { IPackageResponse, getPackagesByUrl } from "Adapters/packageAdapter";

import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";
import { IAllLoans, IVaultAllSearchBarParams, IVaultViewAllLoansSearchResultData, allLoansSearchParams } from "./Types";

const initialAllLoansSearchResult: IVaultViewAllLoansSearchResultData[] = [];

const initialAllLoansApiCallParams: allLoansSearchParams = {
  deliveryTypes: ["eclose", "edisclose", "esign"],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialAllLoansSearchBarParams: IVaultAllSearchBarParams = {
  data: initialAllLoansApiCallParams,
};

const initialAllLoans: IAllLoans = {
  activityVisible: false,
  initialized: false,
  isLoading: false,
  searchBarParams: initialAllLoansSearchBarParams,
  searchResult: initialAllLoansSearchResult,
  selectedPackage: undefined,
  sortByParams: initialSortByParams(),
};

export const fetchPackages = createAsyncThunk<
  IPackageResponse,
  string,
  {
    rejectValue: IHttpErrorState;
  }
>("allLoans/fetch", async (url: string, thunkApi) => {
  try {
    return await getPackagesByUrl(url);
  } catch (err: any) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const allLoansSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.searchResult = [];
      state.initialized = true;
      state.isLoading = true;
      state.selectedPackage = undefined;
      state.activityVisible = false;
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: initialAllLoans,
  name: "allLoans",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = initialAllLoans.searchBarParams;
    },
    updateActivityVisible: (state, action: PayloadAction<boolean>) => {
      state.activityVisible = action.payload;
    },
    updateSearchBarParams: (state, action: PayloadAction<allLoansSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSelectedPackageId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedPackage = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateActivityVisible, updateSearchBarParams, updateSelectedPackageId, updateSortBy } =
  allLoansSlice.actions;

export function selectSearchBarParams(state: RootState): IVaultAllSearchBarParams {
  return state.allLoans.searchBarParams;
}

export function selectAllLoansSearchResult(state: RootState): IVaultViewAllLoansSearchResultData[] {
  return state.allLoans.searchResult;
}

export default allLoansSlice.reducer;
