import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";

import { RootState } from "App/Store";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

const mapStateToProps = (state: RootState) => ({
  invalidAntiforgery: state.profile.antiforgeryError,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function InvalidAntiforgeryToken(props: TypesFromRedux): ReactElement {
  function handlePageRefresh() {
    window.location.reload();
  }

  return (
    <ModalPopupAlert
      title="Invalid token"
      confirmationButtonText="REFRESH PAGE"
      onConfirm={handlePageRefresh}
      open={props.invalidAntiforgery}
      size="sm"
    >
      <p>You are attempting to use an expired token to access the system. Refresh the page to renew the token.</p>
    </ModalPopupAlert>
  );
}

export default connector(InvalidAntiforgeryToken);
