/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

interface TransferIconProps {
  twoTone?: boolean;
}
type Props = TransferIconProps & SvgIconProps;

export default function TransferIcon({ twoTone = true, ...props }: Props): ReactElement {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-344.682 -93.757)">
        <path
          d="M352,105.757,347.682,110,352,114.243V111h14v-2H352Zm0,0L347.682,110,352,114.243V111h14v-2H352Z"
          fill={twoTone ? "#a0a0a0" : "#555"}
        />
        <path d="M362,106.243,366.318,102,362,97.757V101H348v2h14Z" fill="#555" />
      </g>
    </SvgIcon>
  );
}
