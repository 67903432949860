import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { useAsyncCallback } from "react-async-hook";
import { ConnectedProps, connect } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { PackageAction, PackageRegistrationStatus } from "Common/Enums";

import { getLoansForUpload } from "Adapters/Mers/mersPackageAdapter";

import { profileSelector } from "Features/Profile/ProfileSlice";
import Verification from "Features/Vault/Mers/MersRegister/UploadModal/Verification";
import CsvFileUpload, { CsvRow, MersActionType } from "Features/Vault/ModalPopups/CsvFileUpload";
import {
  IMersRecipientDetails,
  IMersRegisterPackage,
  IVaultViewClosedLoansSearchResultData,
  MersRecipientStatusType,
} from "Features/Vault/VaultInterfaces";

import RecipientConfiguration, { RecipientSelection } from "./RecipientConfiguration";
import Results from "./Results";

interface IMersRegistrationUploadModalProps {
  onClose: () => void;
  onSubmit: (results: IMersRegisterPackage[]) => void;
}

export interface IMersRegisterPackageImport extends IMersRegisterPackage {
  id: string;
  delegateeForTransferModificationWarning: boolean;
  securedPartyDelegateeModificationWarning: boolean;
  securedPartyModificationWarning: boolean;
}

const mapStateToProps = (state: RootState) => ({
  mersOriginators: state.mersOriginators.originators,
  recipients: state.mersRecipients.recipients,
  settings: profileSelector(state).settings,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IMersRegistrationUploadModalProps & TypesFromRedux;

const allSteps = ["Upload", "Verify Upload", "Set Defaults", "Search"];

enum STEP {
  UPLOAD,
  VERIFY,
  DEFAULTS,
  SEARCH,
}

export function Content(props: Props): ReactElement {
  const { mersOriginators, onSubmit, recipients, settings } = props;

  const permissions = {
    delegateeForTransferEnabled: settings.mers.delegateeForTransferEnabled,
    securedPartyDelegateeEnabled: settings.mers.securedPartyDelegateeEnabled,
    securedPartyEnabled: settings.mers.securedPartyEnabled,
    subservicerEnabled: settings.mers.subservicerEnabled,
  };
  const recipientEnabled =
    permissions.securedPartyEnabled ||
    permissions.securedPartyDelegateeEnabled ||
    permissions.delegateeForTransferEnabled;

  const [filename, setFilename] = useState<string>("");
  const [uploadResults, setUploadResults] = useState<CsvRow[]>([]);
  const [activeStep, setActiveStep] = useState<number>(STEP.UPLOAD);
  const [defaultRecipients, setDefaultRecipients] = useState<RecipientSelection[]>([]);

  const {
    error: searchError,
    execute: executeSearch,
    loading: queueLoading,
    result,
  } = useAsyncCallback(initializeSearch);

  useEffect(() => {
    setDefaultRecipients(
      mersOriginators.map((originator) => {
        const originatorRecipient = recipients.find(
          (recipient) =>
            recipient.mersOriginatorId === originator.id && recipient.status === MersRecipientStatusType.Default
        );
        const defaultRecipient = originatorRecipient || EMPTY_MERS_RECIPIENT_DETAILS;
        return {
          delegateeForTransferRecipient: defaultRecipient,
          id: originator.mersOrgId,
          mersOrgId: originator.mersOrgId,
          originator,
          securedPartyDelegateeRecipient: defaultRecipient,
          securedPartyRecipient: defaultRecipient,
        };
      })
    );
  }, [mersOriginators, recipients]);

  useEffect(() => {
    if (activeStep === STEP.SEARCH) {
      executeSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  function onConfirmClick(): void {
    const registers = result?.registers ?? [];
    onSubmit(registers);
  }

  async function initializeSearch() {
    const loanIds = uploadResults.map((result) => result.loanNumberOrMin);
    const results = await getLoansForUpload(loanIds);
    const loanIdsNotFound = loanIds.filter(
      (id) => !results.find((result) => result.loanNumber === id || result.min === id)
    );
    const nonRegistrableLoanIds: string[] = [];
    const registerableLoans = _.filter(results, (loan: IVaultViewClosedLoansSearchResultData) => {
      if (
        loan.action === PackageAction.Register ||
        loan.mersData.mersRegistrationStatus === PackageRegistrationStatus.RegistrationFailed
      ) {
        return true;
      } else {
        nonRegistrableLoanIds.push(loan.loanNumber);
        return false;
      }
    });
    // User's permissions
    const { securedPartyEnabled, securedPartyDelegateeEnabled, delegateeForTransferEnabled } = permissions;

    const registers: IMersRegisterPackageImport[] = [];
    _.each(registerableLoans, (result: IVaultViewClosedLoansSearchResultData) => {
      let securedParty: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
      let securedPartyDelegatee: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
      let delegateeForTransfer: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
      // Default recipients assigned at STEP.DEFAULTS
      const defaultRecipient = defaultRecipients.find((recipient) => recipient.mersOrgId === result.originatorOrgId);

      // Deconstruct loan details from CSV
      const {
        securedParty: securedPartyOrgId,
        securedPartyDelegatee: securedPartyDelegateeOrgId,
        delegateeForTransfer: delegateeForTransferOrgId,
      } = uploadResults.find(
        (csvResult) => csvResult.loanNumberOrMin === result.loanNumber || csvResult.loanNumberOrMin === result.min
      ) ?? { delegateeForTransfer, securedParty, securedPartyDelegatee };

      // Retreive the loan's originator id and ensure delegated recipient is affiliated with the loan's originator
      const mersOriginatorId = mersOriginators.find((o) => o.mersOrgId === result.originatorOrgId)?.id;
      const availableRecipients = recipients.filter((r) => r.mersOriginatorId === mersOriginatorId);

      // Set recipients based on csv data provided or defaults if the users has the necessary permissions
      if (securedPartyEnabled && (securedPartyOrgId || defaultRecipient)) {
        const delegatedSecuredParty = availableRecipients.find((r) => r.orgId === securedPartyOrgId);
        securedParty = delegatedSecuredParty
          ? delegatedSecuredParty
          : defaultRecipient?.securedPartyRecipient || EMPTY_MERS_RECIPIENT_DETAILS;
      }
      if (securedPartyDelegateeEnabled && (securedPartyDelegateeOrgId || defaultRecipient)) {
        const delegatedSecuredPartyDelegatee = availableRecipients.find((r) => r.orgId === securedPartyDelegateeOrgId);
        securedPartyDelegatee = delegatedSecuredPartyDelegatee
          ? delegatedSecuredPartyDelegatee
          : defaultRecipient?.securedPartyDelegateeRecipient || EMPTY_MERS_RECIPIENT_DETAILS;
      }
      if (delegateeForTransferEnabled && (delegateeForTransferOrgId || defaultRecipient)) {
        const delegatedDelegateeForTransfer = availableRecipients.find((r) => r.orgId === delegateeForTransferOrgId);
        delegateeForTransfer = delegatedDelegateeForTransfer
          ? delegatedDelegateeForTransfer
          : defaultRecipient?.delegateeForTransferRecipient || EMPTY_MERS_RECIPIENT_DETAILS;
      }

      // Determine warning booleans
      const securedPartyModificationWarning =
        securedPartyEnabled && securedPartyOrgId ? securedPartyOrgId !== securedParty.orgId : false;
      const securedPartyDelegateeModificationWarning =
        securedPartyDelegateeEnabled && securedPartyDelegateeOrgId
          ? securedPartyDelegateeOrgId !== securedPartyDelegatee.orgId
          : false;
      const delegateeForTransferModificationWarning =
        delegateeForTransferEnabled && delegateeForTransferOrgId
          ? delegateeForTransferOrgId !== delegateeForTransfer.orgId
          : false;

      registers.push({
        ...result,
        dataPointOnly: false,
        delegateeForTransferModificationWarning,
        delegateeForTransferRecipient: delegateeForTransfer,
        loanNumber: result.loanNumber,
        min: result.min,
        originatorOrgId: result.originatorOrgId,
        packageId: result.packageId,
        securedPartyDelegateeModificationWarning,
        securedPartyDelegateeRecipient: securedPartyDelegatee,
        securedPartyModificationWarning,
        securedPartyRecipient: securedParty,
        selected: true,
      });
    });
    return {
      loanIdsNotFound,
      nonRegistrableLoanIds,
      registers,
    };
  }

  function handleFileUploadChange(filename: string, rows: CsvRow[]) {
    setFilename(filename);
    setUploadResults(rows);
    handleNextStep();
  }

  function handleStepButton(step: STEP) {
    setActiveStep(step);
  }

  function handleNextStep() {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === STEP.VERIFY) {
        return recipientEnabled ? STEP.DEFAULTS : STEP.SEARCH;
      }
      return prevActiveStep + 1;
    });
  }

  function handleRecipientChange(recipients: RecipientSelection[]) {
    setDefaultRecipients(recipients);
  }

  function getStepContent(step: STEP) {
    switch (step) {
      case STEP.UPLOAD:
        return (
          <CsvFileUpload
            onChange={handleFileUploadChange}
            actionType={MersActionType.Register}
            permissions={permissions}
          />
        );

      case STEP.VERIFY:
        return (
          <Verification
            filename={filename}
            results={uploadResults}
            actionType={MersActionType.Register}
            permissions={permissions}
          />
        );

      case STEP.DEFAULTS:
        return (
          <RecipientConfiguration
            onChange={handleRecipientChange}
            recipients={defaultRecipients}
            permissions={permissions}
          />
        );

      case STEP.SEARCH:
        return (
          <Results error={searchError?.message} isLoading={queueLoading} results={result} permissions={permissions} />
        );

      default:
        return;
    }
  }

  function renderActionButton() {
    switch (activeStep) {
      case STEP.VERIFY:
      case STEP.DEFAULTS:
        return (
          <Button color="secondary" variant="contained" onClick={handleNextStep}>
            Next
          </Button>
        );

      case STEP.SEARCH:
        return (
          <Button color="secondary" variant="contained" onClick={onConfirmClick} disabled={!result?.registers.length}>
            Confirm
          </Button>
        );

      default:
        return <React.Fragment />;
    }
  }

  return (
    <>
      <DialogTitle sx={{ pb: 1 }}>Upload List to Register Queue</DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 2 }}>
          <Stepper activeStep={activeStep}>
            {allSteps
              .filter((step) => {
                return !recipientEnabled && step === "Set Defaults" ? false : true;
              })
              .map((label, index) => {
                return (
                  <Step key={label}>
                    <StepButton onClick={() => handleStepButton(index)}>{label}</StepButton>
                  </Step>
                );
              })}
          </Stepper>
          <Box sx={{ pt: 1 }}>{getStepContent(activeStep)}</Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="text" onClick={props.onClose}>
          Cancel
        </Button>
        {renderActionButton()}
      </DialogActions>
    </>
  );
}

export default connector(Content);
