import * as React from "react";

import { ConnectedProps, connect } from "react-redux";

import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";

import * as errorActions from "Features/Errors/Errors";

import { errorEnqueued } from "../../../../Common/error";
import { IReconciliationClientMersOrg, deleteClientMersOrg } from "../minReconciliationAdapter";

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

interface ITabPanelMaxAlertDialogProps {
  mersOrg?: IReconciliationClientMersOrg;
  onClose: () => void;
  onMersOrgSaved: () => void;
  open: boolean;
}

type Props = ITabPanelMaxAlertDialogProps & TypesFromRedux;

/**
 * Renders a confirmation dialog to delete a MERS org.
 */
function DeleteMersOrgFormDialog(props: Props) {
  const { dispatch, mersOrg, onClose, onMersOrgSaved, open } = props;

  const handleDeleteMersOrg = async () => {
    if (mersOrg) {
      try {
        const response = await deleteClientMersOrg(mersOrg.id, mersOrg.clientCode);
        if (response.status < 300) {
          onMersOrgSaved();
          onClose();
        }
      } catch (e) {
        dispatch(errorActions.enqueue(errorEnqueued("Unable to delete MERS org")));
      }
    }
  };

  const title = "Delete MERS Org";
  return (
    <Dialog title={title} open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>Are you sure you would like to delete the MERS org?</DialogContent>
      <DialogActions>
        <Button onClick={onClose} type="button">
          Cancel
        </Button>
        <Button onClick={handleDeleteMersOrg} type="button">
          CONTINUE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connector(DeleteMersOrgFormDialog);
