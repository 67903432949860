import axios from "axios";

import {
  DeliveryRecipientOption,
  DeliveryRecipientStatus,
  IMersConfirmationResponse,
  IMersDeliveryTransaction,
  IMersManualDeliveryTransaction,
  IMersTransactionResponse,
  IVaultViewManualDeliveriesSearchResultData,
} from "Features/Vault/VaultInterfaces";

/**
 * Get the Manual Delivery search results
 * @returns A promise for the request
 */
export function getManualDeliveries(): Promise<IVaultViewManualDeliveriesSearchResultData[]> {
  return axios.get("/api/mers/deliveries/manual").then((response) => response.data);
}

export async function retrieveDeliveryDetails(referenceId: string): Promise<any> {
  return await axios.get(`/api/mers/transactions/${referenceId}/delivery-details`).then((response) => response.data);
}

export function deliverMERSPackage(data: IMersDeliveryTransaction): Promise<IMersTransactionResponse[]> {
  return axios
    .post(`/api/v1/packages/${data.packageId}/mers/deliveries`, {
      documents: data.documents,
      recipients: data.recipients,
    })
    .then((response) => response.data);
}

export function manualAcceptDeliveryPackage(
  data: IMersManualDeliveryTransaction
): Promise<IMersConfirmationResponse[]> {
  let status: string;
  switch (data.deliveryRecipientStatus) {
    case DeliveryRecipientStatus.Accept:
      status = DeliveryRecipientOption.Accepted;
      break;
    case DeliveryRecipientStatus.Reject:
      status = DeliveryRecipientOption.Rejected;
      break;
    case DeliveryRecipientStatus.Approve:
      status = DeliveryRecipientOption.Approved;
      break;
    case DeliveryRecipientStatus.Disapprove:
      status = DeliveryRecipientOption.Disapproved;
      break;
    default:
      status = DeliveryRecipientOption.Other;
      break;
  }
  const payload = {
    deliveryRecipientStatus: status,
    manualStatus: data.manualDeliveryStatus,
  };
  return axios.post(`/api/mers/deliveries/${data.id}/manual`, payload).then((response) => response.data);
}

export async function cancelPendingDeliveryMERSPackage(packageId: string): Promise<any> {
  return await axios.post(`/api/v1/packages/${packageId}/mers/deliveries/cancel`).then((response) => response.data);
}
