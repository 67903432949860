import { startCase } from "lodash";

import { EMPTY_MERS_RECIPIENT, EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import * as constants from "Common/EVaultAppConstants";
import { PackageDeliveryStatus, PackageRegistrationStatus, PackageTransferStatus } from "Common/Enums";

import {
  IMersData,
  IMersInitiators,
  IMersOrg,
  IMersRecipient,
  IMersTransactionData,
  IRegistrationHistory,
  MersChangeStatusTypes,
  MersDatabaseTransactionType,
  MersInitiatorPositions,
  MersInitiatorTypes,
  MersNoteStatus,
  MersRegistrationStatus,
  MersTransactionStatus,
  MersTransactionType,
  VaultViewArchivedLoansTabTypes,
  VaultViewBatchOperationsTabTypes,
  VaultViewClosedLoansTabTypes,
  VaultViewInactiveLoansTabTypes,
  VaultViewIncomingTabTypes,
  VaultViewMinReconciliationTabTypes,
  VaultViewQueuedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";

import { VaultViewAllLoansTabTypes } from "../Features/Vault/AllLoans/Constants";
import localization from "../Features/Vault/localization";

/**
 * Creates a unique tab key
 * @param keyRoot The loan component's key root
 * @param tabType The tab type being created
 * @param payload Payload used to create a unique key
 * @returns {void}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createTabKey(keyRoot: string, tabType: string, payload?: any): string {
  let keyPredicate;
  switch (tabType) {
    case VaultViewAllLoansTabTypes.SearchResults:
    case VaultViewArchivedLoansTabTypes.SearchResults:
    case VaultViewClosedLoansTabTypes.SearchResults:
    case VaultViewInactiveLoansTabTypes.SearchResults:
    case VaultViewQueuedLoansTabTypes.SearchResults:
    case VaultViewMinReconciliationTabTypes.SearchResults:
      keyPredicate = "SearchResultsTab";
      break;
    case VaultViewIncomingTabTypes.IncomingDeliveries:
      keyPredicate = "DeliveriesTab";
      break;
    case VaultViewIncomingTabTypes.IncomingTransfers:
      keyPredicate = "TransfersTab";
      break;
    case VaultViewAllLoansTabTypes.DocumentList:
    case VaultViewArchivedLoansTabTypes.DocumentList:
    case VaultViewClosedLoansTabTypes.DocumentList:
    case VaultViewInactiveLoansTabTypes.DocumentList:
    case VaultViewIncomingTabTypes.DocumentList:
    case VaultViewQueuedLoansTabTypes.DocumentList:
      keyPredicate = "DocumentList" + payload.packageId;
      break;
    case VaultViewAllLoansTabTypes.MERSInquiryDetails:
    case VaultViewArchivedLoansTabTypes.MERSInquiryDetails:
    case VaultViewClosedLoansTabTypes.MERSInquiryDetails:
    case VaultViewInactiveLoansTabTypes.MERSInquiryDetails:
    case VaultViewQueuedLoansTabTypes.MERSInquiryDetails:
      keyPredicate = "Inquiry" + payload.min;
      break;
    case VaultViewClosedLoansTabTypes.MERSOrgDetails:
      keyPredicate = "MERSOrgDetails";
      break;
    case VaultViewClosedLoansTabTypes.DeliverMERS:
      keyPredicate = "Deliver";
      break;
    case VaultViewClosedLoansTabTypes.TransferMERS:
      keyPredicate = "Transfer";
      break;
    case VaultViewClosedLoansTabTypes.RegisterMERS:
      keyPredicate = "RegisterMERS";
      break;
    case VaultViewClosedLoansTabTypes.UpdateMERS:
      keyPredicate = "Update";
      break;
    case VaultViewBatchOperationsTabTypes.BatchOperationsStatusDetails:
      keyPredicate = "BatchDetails" + payload.batchId;
      break;
    case VaultViewMinReconciliationTabTypes.MinReconciliationStatusDetails:
      keyPredicate = "MinReconciliationStatusDetails" + payload.reportId;
      break;
    default:
      keyPredicate = "";
  }
  return keyRoot + keyPredicate;
}

export function getMERsPositionViewText(initiators: IMersInitiators, position: MersInitiatorPositions): string {
  return getMersOrgDisplayName(initiators[position]);
}

export function getMersOrgDisplayName(org?: IMersRecipient): string {
  let displayName = "";
  if (org) {
    displayName = org.orgId !== EMPTY_MERS_RECIPIENT.orgId ? org.orgId : "";
    if (org.name && org.name.trim() !== "") {
      displayName += " (" + org.name + ")";
    }
  }
  return displayName;
}

export function getMERsInitiatorPositions(myOrgId: string, initiators: IMersInitiators): MersInitiatorTypes {
  if (!initiators) {
    return MersInitiatorTypes.None;
  }

  if (!initiators.controller) {
    initiators.controller = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegatee) {
    initiators.delegatee = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.delegateeForTransfer) {
    initiators.delegateeForTransfer = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!initiators.location) {
    initiators.location = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  // controller only
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.Controller;
  }

  // controller + servicer
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.ControllerServicer;
  }

  // controller + DforT
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.ControllerDelegateeForTransfer;
  }

  // location only
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId &&
    initiators.location.orgId === myOrgId
  ) {
    return MersInitiatorTypes.Location;
  }

  // servicer
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.Servicer;
  }

  // servicer + DforT
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.ServicerDelegateeForTransfer;
  }

  // DforT
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.DelegateeForTransfer;
  }

  // none
  if (
    initiators.controller.orgId !== myOrgId &&
    initiators.delegatee.orgId !== myOrgId &&
    initiators.delegateeForTransfer.orgId !== myOrgId &&
    initiators.location.orgId !== myOrgId
  ) {
    return MersInitiatorTypes.None;
  }

  // All
  if (
    initiators.controller.orgId === myOrgId &&
    initiators.delegatee.orgId === myOrgId &&
    initiators.delegateeForTransfer.orgId === myOrgId
  ) {
    return MersInitiatorTypes.All;
  }

  return MersInitiatorTypes.None;
}

/**
 * Get the Mers transaction type from a string
 * @param type The type of the transaction
 * @returns The corresponding transaction type
 */
export function getMersTransactionTypeFromString(type: string | null): MersTransactionType {
  let result: MersTransactionType;
  type = type ? type.toLowerCase() : null;
  switch (type) {
    case "delivery":
      result = MersTransactionType.Delivery;
      break;

    case "registration":
      result = MersTransactionType.Registration;
      break;

    case "reverseregistration":
      result = MersTransactionType.ReverseRegistration;
      break;

    case "changestatus":
      result = MersTransactionType.ChangeStatus;
      break;

    case "changedata":
      result = MersTransactionType.ChangeData;
      break;

    case "transfer":
      result = MersTransactionType.Transfer;
      break;

    case "transferconfirmation":
      result = MersTransactionType.TransferConfirmation;
      break;

    case "canceltransfer":
      result = MersTransactionType.CancelTransfer;
      break;

    case "deliveryapproval":
      result = MersTransactionType.DeliveryApproval;
      break;

    case "deliveryconfirmation":
      result = MersTransactionType.DeliveryConfirmation;
      break;

    case "releasesecuredparty":
      result = MersTransactionType.SecuredParty;
      break;

    case "unknown":
      result = MersTransactionType.Unknown;
      break;

    default:
      result = MersTransactionType.Unknown;
      break;
  }

  return result;
}

/**
 * Get the view text for the note status (change status types and modification types)
 * @param isReversal Whether the MERS note status is a reversal
 * @param noteStatus The note status
 * @returns the view text for the MERS note
 */
export function getMersNoteStatusViewText(isReversal: boolean, noteStatus: MersChangeStatusTypes | string): string {
  let viewText = "";
  if (isReversal) {
    viewText += "Reverse ";
  }

  switch (
    noteStatus // Add additional as needed
  ) {
    case MersChangeStatusTypes.ChargedOff:
    case "chargedoff":
      viewText += "Charged Off";
      break;
    case MersChangeStatusTypes.ConvertedToPaper:
    case "convertedtopaper":
      viewText += "Converted to Paper";
      break;
    case MersChangeStatusTypes.PaidOff:
    case "paidoff":
      viewText += "Paid Off";
      break;
    case MersChangeStatusTypes.PaperReplacement:
    case "paperreplacement":
      viewText += "Paper Replacement";
      break;
    case MersChangeStatusTypes.TransferredToProprietaryRegistry:
    case "transferredtoproprietaryregistry":
      viewText += "Transferred to Proprietary Registry";
      break;
    case MersChangeStatusTypes.RegistrationReversal:
      viewText += "Reverse Register";
      break;
  }

  return viewText;
}

/**
 * Get the Mers transaction status from a string
 * @param status The status of the MERS transaction
 * @returns The corresponding transaction status
 */
export function getMersTransactionStatusFromString(status: string | null): MersTransactionStatus {
  let result: MersTransactionStatus;
  status = status ? status.toLowerCase() : null;
  switch (status) {
    case "failed":
      result = MersTransactionStatus.Failed;
      break;

    case "pending":
      result = MersTransactionStatus.Pending;
      break;

    case "success":
      result = MersTransactionStatus.Success;
      break;

    case "unknown":
    default:
      result = MersTransactionStatus.Unknown;
      break;
  }

  return result;
}

export function isMersTransactionFailed(status: number): boolean {
  return (
    status === MersTransactionStatus.Failed ||
    status === MersTransactionStatus.Rejected ||
    status === MersTransactionStatus.Timeout ||
    status === MersTransactionStatus.DeliveryNotApproved ||
    status === MersTransactionStatus.TransferNotApproved
  );
}

export function getTransactionRightsDisplay(details: IMersTransactionData): string[] {
  const rights = [];
  if (details.controlOrg) {
    rights.push(getOrgDisplay(details.controlOrg) + " - " + startCase(localization.mers.rightsHolder.control));
  }

  if (details.locationOrg) {
    rights.push(getOrgDisplay(details.locationOrg) + " - " + startCase(localization.mers.rightsHolder.location));
  }

  if (details.servicerOrg) {
    rights.push(getOrgDisplay(details.servicerOrg) + " - " + startCase(localization.mers.rightsHolder.servicer));
  }

  if (details.subServicerOrg) {
    rights.push(getOrgDisplay(details.subServicerOrg) + " - " + startCase(localization.mers.rightsHolder.subServicer));
  }

  if (details.securedPartyOrg) {
    rights.push(
      getOrgDisplay(details.securedPartyOrg) + " - " + startCase(localization.mers.rightsHolder.securedParty)
    );
  }

  if (details.securedPartyDelegateeOrg) {
    rights.push(
      getOrgDisplay(details.securedPartyDelegateeOrg) +
        " - " +
        startCase(localization.mers.rightsHolder.securedPartyDelegatee)
    );
  }

  if (details.delegateeForTransfersOrg) {
    rights.push(
      getOrgDisplay(details.delegateeForTransfersOrg) +
        " - " +
        startCase(localization.mers.rightsHolder.delegateeForTransfers)
    );
  }

  return rights;
}

export function getOrgDisplay(org: IMersOrg | null): string {
  if (org == null) {
    return "";
  }
  let recipient = org.orgId;
  if (org.name) {
    recipient += " (" + org.name + ")";
  }
  return recipient;
}

export function getRegistrationStatusDisplay(status: PackageRegistrationStatus): string {
  switch (status) {
    case PackageRegistrationStatus.Registered:
      return "Registered";
    case PackageRegistrationStatus.RegistrationPending:
      return "In progress";
    case PackageRegistrationStatus.Unregistered:
      return "";
    case PackageRegistrationStatus.RegistrationFailed:
      return "Failure (registration)";
    default:
      return "Unknown";
  }
}

export function getDeliveryStatusDisplay(status: PackageDeliveryStatus): string {
  switch (status) {
    case PackageDeliveryStatus.NotDelivered:
      return "";
    case PackageDeliveryStatus.DeliveryFailed:
      return "Failure (delivery)";
    case PackageDeliveryStatus.Delivered:
      return "Delivered";
    case PackageDeliveryStatus.DeliveryPending:
      return "In progress";
    default:
      return "Unknown";
  }
}

export function getTransferStatusDisplay(status: PackageTransferStatus, data: IMersData): string {
  if (data.incomingTransferTransaction && data.incomingTransferTransaction.referenceId) {
    return "Transfer (In)";
  }
  switch (status) {
    case PackageTransferStatus.NotTransferred:
      return "";
    case PackageTransferStatus.TransferFailed:
      return "Failure (transfer)";
    case PackageTransferStatus.Transferred:
      return "Transferred";
    case PackageTransferStatus.TransferPending:
      return "In progress";
    default:
      return "Unknown";
  }
}

/**
 * Compare the current users org ID against the Loans location and controller OrdId
 * @param myOrgId current user org id
 * @param locationOrgId loans current location
 * @param controllerOrgId loans current controller
 * @param mersRegistrationStatus MERS registration status for the package
 * @returns Value representing whether this is an authoritative copy
 */
export function getIsAuthoritativeCopy(
  myOrgId: string,
  locationOrgId: string,
  controllerOrgId: string,
  mersRegistrationStatus: PackageRegistrationStatus
): boolean {
  return (
    mersRegistrationStatus !== PackageRegistrationStatus.RegistrationFailed &&
    ((myOrgId === controllerOrgId && locationOrgId === EMPTY_MERS_RECIPIENT.orgId) || myOrgId === locationOrgId)
  );
}

/**
 * Determines the full package type description.
 * @param includeAltPackageInDescription boolean of whether or not the alternate package type should be included if it has a value.
 * The plan is to remove this in the future if possible.
 * @param packageType the document package type.
 * @param alternatePackageType the alternate document package type.
 * @returns the full document package type description
 */
export function getFullDocumentPackageTypeDescription(
  includeAltPackageInDescription: boolean,
  packageType: string,
  alternatePackageType: string
): string {
  let fullpackageTypeDescription = "";

  if (alternatePackageType && includeAltPackageInDescription) {
    fullpackageTypeDescription = alternatePackageType + " (" + packageType + ")";
  } else if (packageType) {
    fullpackageTypeDescription = packageType;
  }

  return fullpackageTypeDescription;
}

/**
 * Get an error message
 * @param error Error to display
 * @param message Default message
 * @returns the error message
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getErrorMessage(error: any, message: string = constants.ERRORMESSAGE_GENERIC): string {
  if (error) {
    message = `${message} ${error}`;
  }

  return message;
}

/**
 * Get the Mers transaction type from a string
 * @param type The type of the transaction
 * @returns The corresponding transaction type
 */
export function getDisplayForMersTransactionType(type: MersDatabaseTransactionType): string {
  switch (type) {
    case MersDatabaseTransactionType.ConnectivityTest:
      return "Connectivity Test";
    case MersDatabaseTransactionType.DeliveryConfirmation:
      return "Delivery Confirmation";
    case MersDatabaseTransactionType.ChangeStatus:
      return "Change Status";
    case MersDatabaseTransactionType.ChangeStatusNotification:
      return "Change Status Notification";
    case MersDatabaseTransactionType.ChangeDataNotification:
      return "Change Data Notification";
    case MersDatabaseTransactionType.RegistrationNotification:
      return "Registration Notification";
    case MersDatabaseTransactionType.ReleaseSecuredParty:
      return "Release Secured Party";
    case MersDatabaseTransactionType.PendingTransferNotification:
      return "Incoming Transfer";
    case MersDatabaseTransactionType.Delivery:
      return "Delivery";
    case MersDatabaseTransactionType.Registration:
      return "Registration";
    case MersDatabaseTransactionType.ChangeData:
      return "Change data";
    case MersDatabaseTransactionType.Transfer:
      return "Transfer Requested";
    case MersDatabaseTransactionType.TransferConfirmation:
      return "Transfer Confirmation";
    case MersDatabaseTransactionType.DeliveryApproval:
      return "Delivery Approval";
    case MersDatabaseTransactionType.Inquiry:
      return "Inquiry";
    case MersDatabaseTransactionType.Unknown:
      return "Unknown";

    default:
      return "Unknown";
  }
}

export function getDisplayForMersRegistrationStatus(status: MersRegistrationStatus) {
  switch (status) {
    case MersRegistrationStatus.None:
      return "None";
    case MersRegistrationStatus.Unregistered:
      return "Unregistered";
    case MersRegistrationStatus.RegistrationPending:
      return "Registration pending";
    case MersRegistrationStatus.RegistrationFailed:
      return "Registration failed";
    case MersRegistrationStatus.Registered:
      return "Registered";
    case MersRegistrationStatus.TransferPending:
      return "Transfer Pending";
    case MersRegistrationStatus.TransferFailed:
      return "Transfer Failed";
    case MersRegistrationStatus.Transferred:
      return "Transferred";
    case MersRegistrationStatus.Accepted:
      return "Accepted";
    case MersRegistrationStatus.Rejected:
      return "Rejected";
    case MersRegistrationStatus.Archived:
      return "Archived";
    case MersRegistrationStatus.IndeterminateTransfer:
      return "Indeterminate Transfer";
  }
}

// eslint-disable-next-line valid-jsdoc
/**
 * Get the view text for an actual eNote status
 * @returns the view text for the MERS note
 */
export function getMersNoteStatusText(noteStatus: MersNoteStatus): string {
  switch (noteStatus) {
    case MersNoteStatus.Unknown:
      return "Unknown";
    case MersNoteStatus.Active:
      return "Active";
    case MersNoteStatus.ChargedOff:
      return "Changed Off";
    case MersNoteStatus.PaidOff:
      return "Paid Off";
    case MersNoteStatus.RegistrationReversal:
      return "Registration Reversal";
    case MersNoteStatus.TransferredToProprietaryRegistry:
      return "Transferred to Proprietary Registrary";
    case MersNoteStatus.Other:
      return "Other";
    case MersNoteStatus.ChargedOffReversal:
      return "Changed Off Reversal";
    case MersNoteStatus.ConvertedToPaperReversal:
      return "Converted to Paper Reversal";
    case MersNoteStatus.TransferredToProprietaryRegistryReversal:
      return "Converted to Proprietary Registry Reversal";
    case MersNoteStatus.PaidOffReversal:
      return "Paid Off Reversal";
    case MersNoteStatus.Removed:
      return "Removed";
    case MersNoteStatus.ConvertedToPaper:
      return "Converted to Paper";
    case MersNoteStatus.PaperReplacement:
      return "Paper Replacement";
    case MersNoteStatus.PaperReplacementReversal:
      return "Paper Replacement Reversal";
  }
}

export function getTransactionRightsForRegistrationDisplay(details: IRegistrationHistory): string[] {
  const rights = [];
  if (details.controller) {
    rights.push(
      getOrgDisplay(details.originalRegistrant) + " - " + startCase(localization.mers.rightsHolder.originalRegistrant)
    );
  }

  if (details.controller) {
    rights.push(getOrgDisplay(details.controller) + " - " + startCase(localization.mers.rightsHolder.control));
  }

  if (details.location) {
    rights.push(getOrgDisplay(details.location) + " - " + startCase(localization.mers.rightsHolder.location));
  }

  if (details.delegatee) {
    rights.push(getOrgDisplay(details.delegatee) + " - " + startCase(localization.mers.rightsHolder.servicer));
  }

  if (details.subServicer) {
    rights.push(getOrgDisplay(details.subServicer) + " - " + startCase(localization.mers.rightsHolder.subServicer));
  }

  if (details.securedParty) {
    rights.push(getOrgDisplay(details.securedParty) + " - " + startCase(localization.mers.rightsHolder.securedParty));
  }

  if (details.securedPartyDelegatee) {
    rights.push(
      getOrgDisplay(details.securedPartyDelegatee) +
        " - " +
        startCase(localization.mers.rightsHolder.securedPartyDelegatee)
    );
  }

  if (details.delegateeForTransfers) {
    rights.push(
      getOrgDisplay(details.delegateeForTransfers) +
        " - " +
        startCase(localization.mers.rightsHolder.delegateeForTransfers)
    );
  }

  return rights;
}

export function getDisplayForMersTransactionStatus(status: MersTransactionStatus): string {
  switch (status) {
    case MersTransactionStatus.Unknown:
      return "Unknown";
    case MersTransactionStatus.Pending:
      return "Pending";
    case MersTransactionStatus.Rejected:
      return "Rejected";
    case MersTransactionStatus.Timeout:
      return "Timeout";
    case MersTransactionStatus.TransferNotApproved:
      return "Transfer Not Approved";
    case MersTransactionStatus.Canceled:
      return "Cancelled";
    case MersTransactionStatus.DeliveryNotApproved:
      return "Delivery not approved";
    case MersTransactionStatus.Failed:
      return "Failed";
    case MersTransactionStatus.Initiated:
      return "Initiated";
    case MersTransactionStatus.Success:
      return "Success";
    default:
      return "Unknown";
  }
}
