import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Link from "@mui/material/Link";

import { PackageRegistrationStatus } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import Tooltip from "Components/Tooltip";

import { IVaultViewAllLoansSearchResultData } from "../AllLoans/Types";
import { IVaultViewClosedLoansSearchResultData, IVaultViewManualDeliveriesSearchResultData } from "../VaultInterfaces";
import { openTabForInquiry } from "./mersInquiryService";

interface IMersOrgIdProps {
  className?: string;
  clickable?: boolean;
  keyRoot: string;
  loan:
    | IVaultViewClosedLoansSearchResultData
    | IVaultViewAllLoansSearchResultData
    | IVaultViewManualDeliveriesSearchResultData
    | IPackageMersIdentifier;
  loanType: string;
  tabKeyRoot: string;
  onClick?: () => void;
  value: string;
}

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;
type Types = TypesFromRedux & IMersOrgIdProps;

export interface IPackageMersIdentifier {
  packageId: string;
  min: string;
}

export function MersIdentificationNumber(props: Types): ReactElement {
  const { className, dispatch, keyRoot, loan, loanType, onClick } = props;
  let minElement;
  if (props.value.length === 18) {
    const parts: string[] = [props.value.substr(0, 7), props.value.substr(7, 10), props.value.substr(17, 1)];
    minElement = (
      <span>
        {parts[0]}&nbsp;{parts[1]}&nbsp;{parts[2]}
      </span>
    );
  } else {
    minElement = <span>{props.value}</span>;
  }

  let mersClickable = props.clickable;
  if ((loan as IVaultViewClosedLoansSearchResultData).mersData) {
    const registrationStatus = (loan as IVaultViewClosedLoansSearchResultData).mersData.mersRegistrationStatus;
    mersClickable =
      registrationStatus === PackageRegistrationStatus.Registered ||
      registrationStatus === PackageRegistrationStatus.RegistrationPending;
  } else if ((loan as IVaultViewAllLoansSearchResultData).registrationStatus) {
    const registrationStatus = (loan as IVaultViewAllLoansSearchResultData).registrationStatus.toLowerCase();
    mersClickable = registrationStatus === "registered" || registrationStatus === "registrationpending";
  } else if ((loan as IVaultViewManualDeliveriesSearchResultData).manualDeliveryStatus) {
    mersClickable =
      loan.packageId != null && loan.packageId !== "" && loan.packageId !== "00000000-0000-0000-0000-000000000000";
  }

  function handleDispatchInquiry() {
    const inquiryDetails = {
      min: loan.min,
      packageId: loan.packageId,
    };
    const key: string = createTabKey(keyRoot, props.tabKeyRoot, inquiryDetails);

    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(
      openTabForInquiry({
        key,
        loanType,
        min: loan.min,
        packageId: loan.packageId,
        type: props.tabKeyRoot,
      })
    );
    if (onClick) {
      onClick();
    }
  }

  if (mersClickable) {
    const classes = ["pseudolink"];
    if (className) {
      classes.push(className);
    }
    minElement = (
      <Tooltip title="Open MERS Inquiry">
        <Link className={classes.join(" ")} onClick={handleDispatchInquiry} role="button">
          {minElement}
        </Link>
      </Tooltip>
    );
  }
  return minElement;
}

export default connector(MersIdentificationNumber);
