/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import CircularProgress from "@mui/material/CircularProgress";

export default function Loading(): ReactElement {
  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      `}
    >
      <CircularProgress size={20} />
    </div>
  );
}
