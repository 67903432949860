import React, { ReactElement } from "react";

import _ from "lodash";

import { getErrorMessage, getMersTransactionStatusFromString } from "Common/Utilities";

import Loading from "Components/Loading";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { manualAcceptDeliveryPackage } from "Adapters/Mers/mersDeliveryAdapter";

import {
  DeliveryRecipientStatus,
  IMersBatchDeliveryConfirmationRequest,
  IMersConfirmationResponse,
  IMersDeliveryConfirmationRequest,
  IMersManualDeliveryTransaction,
  MersTransactionStatus,
} from "../VaultInterfaces";

interface IVaultMersTransactionResultsPopupProps {
  onClosed: () => void;
  open: boolean;
  request: IMersBatchDeliveryConfirmationRequest;
}

interface IContentItem {
  min: string;
  transferId: string;
  status: MersTransactionStatus;
  deliveryRecipientStatus: DeliveryRecipientStatus;
}

interface IVaultMersTransactionResultsPopupState {
  error: Error | null;
  closable: boolean;
  contentItems: IContentItem[];
  loading: boolean;
  pendingRequestCount: number;
}

export class VaultMersDeliveryConfirmationResultsModalPopup extends React.Component<
  IVaultMersTransactionResultsPopupProps,
  IVaultMersTransactionResultsPopupState
> {
  constructor(props: IVaultMersTransactionResultsPopupProps) {
    super(props);

    this.state = {
      closable: false,
      contentItems: [],
      error: null,
      loading: true,
      pendingRequestCount: 0,
    };

    // One time binding
    this.onCloseClick = this.onCloseClick.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.handleMersConfirmationResponse = this.handleMersConfirmationResponse.bind(this);
    this.finishProcessingTransaction = this.finishProcessingTransaction.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<IVaultMersTransactionResultsPopupProps>): void {
    if (this.props.open && prevProps.request !== this.props.request) {
      this.setState({
        closable: false,
        contentItems: [],
        loading: true,
        pendingRequestCount: this.props.request.deliveries.length,
      });
      this.executeRequest();
    }
  }

  private onCloseClick() {
    if (this.props.onClosed) {
      this.props.onClosed();
    }
  }

  /**
   * Make the Mers transaction request
   * @returns {void}
   */
  private executeRequest() {
    _.each(this.props.request.deliveries, (delivery: IMersDeliveryConfirmationRequest) => {
      const request: IMersManualDeliveryTransaction = {
        deliveryRecipientStatus: delivery.action,
        id: delivery.id,
        manualDeliveryStatus: delivery.manualDeliveryStatus,
        transferId: delivery.transferId,
      };

      manualAcceptDeliveryPackage(request)
        .then((response) => {
          this.handleMersConfirmationResponse(response, request, delivery.min);
        })
        .catch((error) => {
          this.finishProcessingTransaction(error);
          console.error("Confirmation Error", error);
          console.log(error);
        });
    });
  }

  private getStatus(deliveryStatus: DeliveryRecipientStatus, status: MersTransactionStatus): JSX.Element | null {
    let result: JSX.Element | null = null;
    switch (deliveryStatus) {
      case DeliveryRecipientStatus.Accept:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Accept Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Accepted</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case DeliveryRecipientStatus.Reject:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Reject Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Rejected</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case DeliveryRecipientStatus.Approve:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Approve Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Approved</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case DeliveryRecipientStatus.Disapprove:
        switch (status) {
          case MersTransactionStatus.Failed:
            result = <span className="title-fail">Disapprove Failed</span>;
            break;

          case MersTransactionStatus.Pending:
          case MersTransactionStatus.Success:
            result = <span className="title-success">Disapproved</span>;
            break;

          case MersTransactionStatus.Unknown:
          default:
            result = <span className="title-fail">Unknown</span>;
            break;
        }
        break;
      case DeliveryRecipientStatus.None:
        result = <span className="title-fail">Unknown</span>;
        break;
    }
    return result;
  }

  /**
   * Handle the response result from the Mers transaction request
   * @param responses the MERS confirmation responses
   * @param request The delivery transactions
   * @param min The mins
   * @returns {void}
   */
  private handleMersConfirmationResponse(
    responses: IMersConfirmationResponse[],
    request: IMersManualDeliveryTransaction,
    min: string
  ) {
    let results;

    if (Array.isArray(responses)) {
      results = responses;
    } else {
      results = [responses];
    }

    const contentItems: IContentItem[] = this.state.contentItems;

    _.each(results, (response) => {
      if (
        (null != response.error && 0 !== response.error.length) ||
        (null != response.errors && 0 !== response.errors.length)
      ) {
        const newContentItem: IContentItem = {
          deliveryRecipientStatus: request.deliveryRecipientStatus,
          min,
          status: MersTransactionStatus.Failed,
          transferId: request.transferId,
        };
        contentItems.push(newContentItem);
      } else {
        _.each(response.transactionResults, (result) => {
          const newContentItem: IContentItem = {
            deliveryRecipientStatus: request.deliveryRecipientStatus,
            min,
            status: getMersTransactionStatusFromString(result.status),
            transferId: request.transferId,
          };
          contentItems.push(newContentItem);
        });
      }

      this.finishProcessingTransaction();
    });

    this.setState({
      contentItems: _.uniq(contentItems),
    });
  }

  /**
   * A Mers request's processing has been completed
   * @param error Error (Optional)
   * @returns {void}
   */
  private finishProcessingTransaction(error: Error | null = null) {
    const newCount = this.state.pendingRequestCount - 1;
    this.setState({
      closable: newCount <= 1,
      error: error,
      loading: newCount > 1,
      pendingRequestCount: newCount < 0 ? 0 : newCount,
    });
  }

  public render(): ReactElement {
    const groupedResults = _.groupBy(this.state.contentItems, "min");

    const items: JSX.Element[] = [];

    _.each(groupedResults, (resultStack, min) => {
      const itemNumber = items.length + 1;
      let parentStatus: JSX.Element | null = null;

      if (resultStack.length > 0) {
        const firstResult = resultStack[0];
        parentStatus = this.getStatus(firstResult.deliveryRecipientStatus, firstResult.status);
      }

      items.push(
        <div
          key={"merstransaction-item-" + itemNumber}
          className="modalpopup-mers-confirmation-results-dialog-content-item"
        >
          <div className="modalpopup-mers-confirmation-results-dialog-content-item-title">
            <span>
              {itemNumber}. MIN: {min},{" "}
            </span>
            {parentStatus}
          </div>
        </div>
      );
    });

    // Content can be error or items
    let content;
    if (this.state.error) {
      content = getErrorMessage(this.state.error);
    } else {
      content = items;
    }
    content = (
      <div>
        {this.state.loading && <Loading />}
        {content}
      </div>
    );

    return (
      <ModalPopupAlert
        confirmationButtonText="CLOSE"
        disabled={!this.state.closable}
        onClose={this.onCloseClick}
        overlayCloseClick={this.state.closable}
        title="Confirmation Results"
        content={content}
        open={this.props.open}
        size="sm"
      />
    );
  }
}
