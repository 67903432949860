/**
 * The DocumentListPrintMenu component handles displaying options for prints document details
 * for all documents in the list
 */
import React, { ReactElement } from "react";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrintIcon from "@mui/icons-material/Print";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { GridSelectionModel } from "@mui/x-data-grid";

import { IVaultViewAllLoansDocumentDetailData } from "../VaultInterfaces";
import { DocumentOptions } from "./ListContextMenu";
import documentListService from "./documentListService";

interface IListPrintMenuProps {
  canDownload: boolean;
  checkedRows: GridSelectionModel;
  documentListData: IVaultViewAllLoansDocumentDetailData[];
  onPrint(option: DocumentOptions): void;
  workflowType?: string;
}

export default function DocumentListPrintMenu(props: IListPrintMenuProps): ReactElement {
  const { canDownload, checkedRows, documentListData, onPrint, workflowType } = props;
  const [subMenuOpen, setSubMenuOpen] = React.useState(false);
  const auditLogDisabled = workflowType === "MERS";
  const menuDisabled = !canDownload || auditLogDisabled;

  function handleSubMenuClick() {
    setSubMenuOpen(!subMenuOpen);
  }
  return (
    <>
      <ListItem button key="export" disabled={menuDisabled} onClick={handleSubMenuClick} dense>
        <ListItemIcon>
          <PrintIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Print</ListItemText>
        {subMenuOpen ? <ExpandLess sx={{ ml: 2 }} fontSize="small" /> : <ExpandMore sx={{ ml: 2 }} fontSize="small" />}
      </ListItem>
      <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            dense
            disabled={documentListService.shouldDisableOption(documentListData, DocumentOptions.All)}
            onClick={() => onPrint(DocumentOptions.All)}
            sx={{ pl: 4 }}
          >
            <ListItemText>All</ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            disabled={documentListService.shouldDisableOption(documentListData, DocumentOptions.Selected, checkedRows)}
            onClick={() => onPrint(DocumentOptions.Selected)}
            sx={{ pl: 4 }}
          >
            <ListItemText>Selected</ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            disabled={documentListService.shouldDisableOption(documentListData, DocumentOptions.InkSign)}
            onClick={() => onPrint(DocumentOptions.InkSign)}
            sx={{ pl: 4 }}
          >
            <ListItemText>Ink</ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            disabled={documentListService.shouldDisableOption(documentListData, DocumentOptions.eSign)}
            onClick={() => onPrint(DocumentOptions.eSign)}
            sx={{ pl: 4 }}
          >
            <ListItemText>eSign</ListItemText>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
}
