import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { getErrorMessage } from "Common/Utilities";
import { errorEnqueued, notificationEnqueued } from "Common/error";

import * as mersOrgClient from "Adapters/Mers/mersOrgAdapter";

import * as errorActions from "Features/Errors/Errors";
import { addTab } from "Features/Tabs/Actions";
import { fetchRecipients } from "Features/Vault/Mers/MersRecipients/MersRecipientSlice";
import {
  IMersOriginator,
  IMersRecipientDetails,
  MersRecipientAcceptStatusType,
  MersRecipientStatusType,
  VaultViewClosedLoansTabTypes,
} from "Features/Vault/VaultInterfaces";

export function mapMersTableDataToApiData(org: IMersRecipientDetails): IMersRecipientDetails {
  return {
    contactEmail: org.contactEmail.substring(0, 254).trim(),
    contactName: org.contactName.substring(0, 70).trim(),
    contactPhone: org.contactPhone.substring(0, 35).trim(),
    deliveryAutoAcceptStatus: org.deliveryAutoAcceptStatus,
    id: org.id,
    mersOriginatorId: org.mersOriginatorId,
    name: org.name.substring(0, 100).trim(),
    orgId: org.orgId.trim(),
    status: org.status,
    transferAutoAcceptStatus: org.transferAutoAcceptStatus,
  };
}

export function deleteMersRecipient(
  recipient: IMersRecipientDetails
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      await mersOrgClient.deleteMERSRecipient(recipient);
      await dispatch(fetchRecipients());
    } catch (ex) {
      dispatch(errorActions.enqueue(errorEnqueued(getErrorMessage(ex))));

      console.error("deleteMersRecipient error", ex);
      console.dir(ex);
    }
  };
}

export function saveMersRecipients(
  changedMERSOrgs: IMersRecipientDetails[],
  ...callbacksOnSuccess: (() => void)[]
): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch) => {
    try {
      await mersOrgClient.updateMERSRecipients(changedMERSOrgs);
      await dispatch(fetchRecipients());
      dispatch(
        errorActions.enqueue(
          notificationEnqueued("Recipient data was saved", {
            variant: "success",
          })
        )
      );
      for (const callbackOnSuccess of callbacksOnSuccess) {
        callbackOnSuccess();
      }
    } catch (ex) {
      dispatch(errorActions.enqueue(errorEnqueued(getErrorMessage(ex, "Error saving MERS recipients."))));

      console.error("saveMersRecipients error", ex);
      console.dir(ex);
    }
  };
}

export function openRecipientsTab(key: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    dispatch(
      addTab({
        closeable: true,
        key,
        loanType: constants.CLOSED_LOANS,
        title: "Recipients",
        type: VaultViewClosedLoansTabTypes.MERSOrgDetails,
      })
    );
  };
}

export function mapMersTableListDataToApiData(orgs: IMersRecipientDetails[]): IMersRecipientDetails[] {
  // Ensure at least one is default
  orgs = _.filter(orgs, (org) => {
    return !!org.orgId;
  });
  if (orgs.length === 1) {
    orgs[0].status = MersRecipientStatusType.Default;
  }
  return _.map(orgs, mapMersTableDataToApiData);
}

/**
 * Updates the MERS recipient details. This modifies the data array.
 * @param data The recipient details array we are mutating
 * @param originalDetails The original MERS recipient details
 * @returns void
 */
export function updateDetailsForOrgChanges(
  data: IMersRecipientDetails[],
  originalDetails: IMersRecipientDetails[]
): void {
  _.each(data, (details) => {
    if (-1 !== details.id) {
      const sourceResults = _.find(originalDetails, { id: details.id });
      if (sourceResults) {
        if (sourceResults.orgId !== details.orgId) {
          // Get the original and add it to the update list with a hidden status
          const original = {
            ...sourceResults,
          };
          original.status = MersRecipientStatusType.Hidden;
          data.push(original);
        }
      }
    }
  });
}

export function mapMERSOrgsToTableData(mersOrgs: IMersRecipientDetails[]): IMersRecipientDetails[] {
  const data: IMersRecipientDetails[] = [];

  // Add a new empty row
  data.push(getDefaultOrg());

  return data;
}

export function getDefaultOrg(): IMersRecipientDetails {
  return {
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    deliveryAutoAcceptStatus: MersRecipientAcceptStatusType.Approve,
    id: -1,
    mersOriginatorId: -1,
    name: "",
    orgId: "",
    status: MersRecipientStatusType.Active,
    transferAutoAcceptStatus: MersRecipientAcceptStatusType.Manual,
  };
}

export function getDefaultRecipient(
  recipients: IMersRecipientDetails[],
  originators: IMersOriginator[],
  originatorOrgId: string
): IMersRecipientDetails {
  let defaultRecipient: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
  const originator = originators.find((originator) => originator.mersOrgId === originatorOrgId);
  if (originator != null) {
    const originatorRecipient = recipients.find(
      (recipient) =>
        recipient.mersOriginatorId === originator.id && recipient.status === MersRecipientStatusType.Default
    );
    if (originatorRecipient != null) {
      defaultRecipient = originatorRecipient;
    }
  }
  return defaultRecipient;
}
