import * as React from "react";

import { Controller, ControllerRenderProps, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { GlobalError } from "react-hook-form/dist/types/errors";

import { TextField } from "@mui/material";

interface ITextFieldControllerProps {
  error?: GlobalError;
  helperText?: string;
  label?: string;
  name: string;
  value?: string;
}

interface IRenderTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
}

/**
 * The text field controller wraps all the props for text fields so that React context can be leveraged in forms.
 */
function TextFieldController(props: ITextFieldControllerProps): JSX.Element {
  const { error, helperText, label, name } = props;
  const { control } = useFormContext();

  let helperDisplay = helperText;
  if (error && error.message) {
    helperDisplay = error.message;
  }

  /**
   * This method will render the form field with the props passed from the Form context and any other custom props for the component.
   */
  function renderField({ field }: IRenderTextFieldProps) {
    return (
      <TextField
        error={!!error}
        fullWidth
        helperText={helperDisplay}
        label={label}
        margin="normal"
        variant="standard"
        {...field}
      />
    );
  }

  return <Controller control={control} name={name} render={renderField} />;
}

export default TextFieldController;
