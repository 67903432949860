/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UploadIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M9,17v-6H5l7-7l7,7h-4v6H9 M5,21v-2h14v2H5z" />
    </SvgIcon>
  );
}
