/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import { ILoanTabDetails } from "Features/Tabs/Tabs";
import TabHeader from "Features/Vault/TabHeader";

interface ITabbedPanelHeaderProps {
  currentTab: string;
  onTabClick: (tab: ILoanTabDetails) => void;
  onTabClosed?: (tab: ILoanTabDetails) => void;
  tabs: ILoanTabDetails[];
}

export default function TabbedPanelHeader(props: ITabbedPanelHeaderProps): ReactElement {
  const { currentTab, tabs } = props;
  return (
    <div
      className="tabbed-panel-tab-wrapper"
      css={css`
        margin: 0;
        padding: 0;
        position: relative;
      `}
    >
      <div
        className="tabbed-panel-tab-container"
        css={css`
          display: flex;
          height: 36px;
        `}
      >
        {tabs.map((tab) => (
          <TabHeader
            currentTab={currentTab}
            key={tab.key}
            onTabClick={props.onTabClick}
            onTabClosed={props.onTabClosed}
            tab={tab}
          />
        ))}
      </div>
    </div>
  );
}
