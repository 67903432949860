import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { GridSortItem } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import { SortOrderType } from "Common/Enums";
import { createTabKey } from "Common/Utilities";

import { ISortByParams } from "Types/EVaultAppTypes";

import { getPackageSearchUrl } from "Adapters/packageAdapter";

import { openTabForDocList } from "../documentService";
import {
  fetchPackages,
  updateActivityVisible,
  updateSearchBarParams,
  updateSelectedPackageId,
  updateSortBy,
} from "./AllLoansSlice";
import { ALL_LOANS, VaultViewAllLoansTabTypes } from "./Constants";
import {
  IAllLoansSearchApiParams,
  IVaultViewAllLoansSearchResultData,
  IVaultViewAllLoansSearchResultTableData,
} from "./Types";

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const data = state.allLoans.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchAllLoans());
  };
}

export function updateSortByAction({ field, sort }: GridSortItem): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    // Return if missing necessary arguments
    if (!field || !sort) return;

    const { sortField: prevSortField, sortOrderType: prevSortOrderType } = getState().allLoans.sortByParams;
    const newSortOrderType = sort === "asc" ? SortOrderType.ASCENDING : SortOrderType.DESCENDING;

    // If no sort property changes, exit without fetching new data
    if (prevSortField === field && prevSortOrderType === newSortOrderType) return;

    const newSortOrderParams: ISortByParams = {
      sortField: field,
      sortOrderType: newSortOrderType,
    };
    dispatch(updateSortBy(newSortOrderParams));
    dispatch(fetchAllLoans());
  };
}

export function fetchAllLoans(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    if (resourceUrl) {
      dispatch(fetchPackages(resourceUrl));
    } else {
      const searchCriteria = state.allLoans.searchBarParams.data;
      const sortByParams = state.allLoans.sortByParams;
      const params: IAllLoansSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      const url = getPackageSearchUrl(params);
      dispatch(fetchPackages(url));
    }
  };
}

export const handleShowDocList = (
  rowData: IVaultViewAllLoansSearchResultData
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async (dispatch) => {
    selectRow(rowData, true);
    const keyRoot = "VaultTab-AllLoans-";
    const key: string = createTabKey(keyRoot, VaultViewAllLoansTabTypes.DocumentList, rowData);
    const payload = {
      key,
      loanType: ALL_LOANS,
      rowData,
      type: VaultViewAllLoansTabTypes.DocumentList,
    };
    onShowActivity(rowData.packageId);
    dispatch(openTabForDocList(payload));
  };
};

export const selectRow = (
  rowData: IVaultViewAllLoansSearchResultTableData | IVaultViewAllLoansSearchResultData,
  forceSelect = false
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const activityVisible = state.allLoans.activityVisible;
    const rows: string[] = [];
    if (activityVisible || forceSelect) {
      rows.push(rowData.packageId);
    }
    updateSelectedPackageId(rowData.packageId);
  };
};

export const onShowActivity = (packageId: string): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch) => {
    dispatch(updateSelectedPackageId(packageId));
    dispatch(updateActivityVisible(true));
  };
};
