import * as React from "react";

import { Dialog } from "@mui/material";

import { IReconciliationClientMersOrg } from "../minReconciliationAdapter";
import MersOrgFormDialogContent from "./MersOrgFormDialogContent";

interface ITabPanelMaxAlertDialogProps {
  mersOrg: IReconciliationClientMersOrg | undefined;
  onClose: () => void;
  onMersOrgSaved: () => void;
  open: boolean;
}

/**
 * Renders a form to add, modify or delete a mers org for MIN reconciliation
 */
function MersOrgFormDialog(props: ITabPanelMaxAlertDialogProps): JSX.Element {
  const { mersOrg, onClose, onMersOrgSaved, open } = props;

  const title = mersOrg ? "Updating Mers Org" : "Add Mers Org";
  return (
    <Dialog title={title} open={open} onClose={onClose} fullWidth maxWidth="sm">
      <MersOrgFormDialogContent mersOrg={mersOrg} onClose={onClose} onMersOrgSaved={onMersOrgSaved} />
    </Dialog>
  );
}

export default MersOrgFormDialog;
