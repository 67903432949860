/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _ from "lodash";
import { ConnectedProps, connect, useDispatch } from "react-redux";

import { AppDispatch, RootState } from "App/Store";

import { BottomBar } from "Components/Notifications/BottomBar";
import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";
import ScrollContainer from "Components/ScrollContainer";

import DeliveryFilterBar from "Features/Vault/ClosedLoans/DeliveryFilterBar";
import { VaultMersTransactionResultsModalPopup } from "Features/Vault/ModalPopups/VaultMersTransactionResultsModalPopup";
import {
  IDeliveryDocument,
  IMersBatchDeliveryRequest,
  IMersDeliveryPackage,
  MersTransactionType,
} from "Features/Vault/VaultInterfaces";

import { executeSearch } from "../mersDeliveryService";
import MersDeliveryTable from "./MersDeliveryTable";

const mapStateToProps = (state: RootState) => ({
  isLoading: state.mersDeliveries.isLoading,
  mersRecipients: state.mersRecipients.recipients,
  queueList: state.mersDeliveries.deliveries,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function MersDelivery(props: TypesFromRedux): ReactElement {
  const { isLoading, mersRecipients, queueList } = props;
  const dispatch = useDispatch() as AppDispatch;

  const [alertNoMersOrgToTransferTo, setAlertNoMersOrgToTransferTo] = useState<boolean>(false);
  const [showDeliveryConfirmation, setShowDeliveryConfirmation] = useState<boolean>(false);
  const [mersDeliveryResultsOpen, setMersDeliveryResultsOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<IMersBatchDeliveryRequest>({
    packages: [],
    type: MersTransactionType.Delivery,
  });

  function handleDeliverClick() {
    if (queueList.length && mersRecipients.length && !showDeliveryConfirmation) {
      setShowDeliveryConfirmation(true);
    } else if (!mersRecipients.length) {
      setAlertNoMersOrgToTransferTo(true);
    }
  }

  function deliverLoans() {
    setShowDeliveryConfirmation(false);

    const loansToDeliver = _.filter(queueList, "selected");

    const newRequest: IMersBatchDeliveryRequest = {
      packages: [],
      type: MersTransactionType.Delivery,
    };

    _.each(loansToDeliver, (loan: IMersDeliveryPackage) => {
      if (loan.deliveryRecipient != null) {
        newRequest.packages.push({
          documents: getSelectedDocuments(loan),
          packageId: loan.packageId,
          recipients: [
            {
              name: loan.deliveryRecipient.name,
              orgId: loan.deliveryRecipient.orgId,
            },
          ],
        });
      }
    });
    setMersDeliveryResultsOpen(true);
    setRequest(newRequest);
  }

  function getSelectedDocuments(loan: IMersDeliveryPackage): number[] {
    const selectedDocuments = _.filter(loan.documents, (item) => item.selected);
    return _.map(selectedDocuments, (item: IDeliveryDocument) => {
      return item.id;
    });
  }

  function handleCloseDeliveryConfirmation() {
    setShowDeliveryConfirmation(false);
  }

  const deliverConfirmationModal = (
    <ModalPopupAlert
      title="Deliver?"
      content="This will initiate sending the selected deliveries."
      confirmationButtonText="DELIVER"
      onConfirm={deliverLoans}
      showDeclineLink={true}
      onClose={handleCloseDeliveryConfirmation}
      open={showDeliveryConfirmation}
      size="sm"
    />
  );

  function handleCloseNoMersOrg() {
    setAlertNoMersOrgToTransferTo(false);
  }

  const modalNoMERSOrgAlert = (
    <ModalPopupAlert
      title="No MERS Org"
      content="You must select a MERS Org in order to deliver this eNote."
      onClose={handleCloseNoMersOrg}
      open={alertNoMersOrgToTransferTo}
      size="sm"
    />
  );

  const closeDeliveryRequestResults = () => {
    setMersDeliveryResultsOpen(false);
    setShowDeliveryConfirmation(false);
    dispatch(executeSearch());
  };

  function passesValidation(): boolean {
    const loansToDelivery = _.filter(queueList, "selected");

    let allLoansAreValid = true;

    _.each(loansToDelivery, (loan: IMersDeliveryPackage) => {
      if (loan.isInvalidState) {
        allLoansAreValid = false;
      }
    });

    return loansToDelivery.length > 0 && allLoansAreValid;
  }

  const transferResultsModal = (
    <VaultMersTransactionResultsModalPopup
      onClosed={closeDeliveryRequestResults}
      request={request}
      open={mersDeliveryResultsOpen}
    />
  );

  return (
    <>
      {modalNoMERSOrgAlert}
      {deliverConfirmationModal}
      {transferResultsModal}
      <ScrollContainer>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 8px;
            padding-right: 8px;
          `}
        >
          <DeliveryFilterBar />
        </div>
        <MersDeliveryTable />
      </ScrollContainer>
      <BottomBar
        contents="Add loan(s), select recipient(s), and then click deliver."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation() || isLoading,
            onClick: handleDeliverClick,
            text: "DELIVER",
          },
        ]}
      />
    </>
  );
}

export default connector(MersDelivery);
