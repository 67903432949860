import React, { useEffect } from "react";

import { ConnectedProps, connect, useDispatch, useSelector } from "react-redux";

import CancelIcon from "@mui/icons-material/Block";
import AlertIcon from "@mui/icons-material/Warning";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";

import { nonce } from "App/App";
import { AppDispatch, RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT } from "Common/EVaultAppConstants";
import * as constants from "Common/EVaultAppConstants";
import { PackageAction, PackageDeliveryStatus, PackageRegistrationStatus, PackageTransferStatus } from "Common/Enums";
import {
  createTabKey,
  getDeliveryStatusDisplay,
  getRegistrationStatusDisplay,
  getTransferStatusDisplay,
  isMersTransactionFailed,
} from "Common/Utilities";

import ErrorOverlay from "Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "Components/DataGridCustomComponents/Pagination";
import {
  DeliveryOutIcon,
  DocumentIcon,
  RegisterIcon,
  ReverseRegisterationIcon,
  TransferIcon,
  UndoIcon,
  UploadIcon,
} from "Components/Icons";

import { getClosedLoansExportUrl } from "Adapters/Mers/mersPackageAdapter";

import { clientDefinedDataFieldsSelector, profileSelector } from "Features/Profile/ProfileSlice";

import AuditLogMenuItem from "../MenuItems/AuditLogMenuItem";
import AuthoritativeCopy from "../Mers/AuthoritativeCopy";
import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import { openRegisterTabForPackage } from "../Mers/MersRegister/mersRegistrationService";
import { canTransfer } from "../Mers/MersTransfer/MersTransferSlice";
import { canRemoveSubservicer } from "../Mers/MersTransfer/mersTransferService";
import { openTransferTabForPackage } from "../Mers/MersTransfer/mersTransferTabService";
import { openUpdateTabForPackage } from "../Mers/MersUpdate/mersUpdateTabService";
import { openDeliveryTabForPackage } from "../Mers/mersDeliveryService";
import { getMERsInitiatorPositions, getMersOrgs, getMersRecipients } from "../Mers/mersOrgService";
import { canRegisterSelector, canTransferSelector } from "../Mers/mersService";
import TableContextMenu from "../TableContextMenu";
import TableRowContextMenu, { TableRowContextMenuItem } from "../TableRowContextMenu";
import {
  IVaultViewClosedLoansSearchResultData,
  MersTransactionStatus,
  MersTransactionType,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";
import { openTabForDocList } from "../documentService";
import { datagridColumns } from "../shared";
import DeliveryDetails from "./DeliveryDetails";
import RegistrationDetails from "./RegistrationDetails";
import { RemoveDelegateeForTransfersMenuItem } from "./RemoveDelegateeForTransfersMenuItem";
import { RemoveSubservicerMenuItem } from "./RemoveSubservicerMenuItem";
import TransferDetails from "./TransferDetails";
import { executeSearch, fetchClosedLoans, getClosedLoansSearchResult, updateSortByAction } from "./closedLoansService";

const ICON_COLUMN_WIDTH = 56;

const mapStateToProps = (state: RootState) => ({
  clientDefinedDataFields: state.clientDefinedDataFields,
  error: state.closedLoans.error,
  includeClientDefinedDataFields: clientDefinedDataFieldsSelector(state),
  independentDelivery: profileSelector(state).settings.mers.independentDeliveryEnabled ?? false,
  initialized: state.closedLoans.initialized,
  isLoading: state.closedLoans.isLoading,
  links: state.closedLoans.links,
  mersOrgs: getMersOrgs(state),
  mersRecipients: getMersRecipients(state),
  profile: state.profile,
  rightsRegister: canRegisterSelector(state),
  rightsTransfer: canTransferSelector(state),
  searchParams: state.closedLoans.searchBarParams,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

interface ISearchResultsTabProps {
  handleAddTab: (type: VaultViewClosedLoansTabTypes, payload?: any) => void;
  keyRoot: string;
  openLastResultModal: (response: IVaultViewClosedLoansSearchResultData, type: MersTransactionType) => void;
  showDeliveryCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showRemoveDelegateeForTransferConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showReverseRegistrationConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showReverseModificationConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
  showTransferCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

type Props = TypesFromRedux & ISearchResultsTabProps;

function ClosedSearchResultsTable(props: Props) {
  const {
    clientDefinedDataFields,
    error,
    includeClientDefinedDataFields,
    independentDelivery,
    initialized,
    isLoading,
    links,
    keyRoot,
    mersOrgs,
    openLastResultModal,
    profile,
    rightsRegister,
    rightsTransfer,
    searchParams,
    showDeliveryCancelConfirmModal,
    showReverseRegistrationConfirmModal,
    showReverseModificationConfirmModal,
    showTransferCancelConfirmModal,
  } = props;
  const dispatch = useDispatch() as AppDispatch;
  const searchResults = useSelector((state: RootState) =>
    getClosedLoansSearchResult(state).map((loan) => ({
      ...loan,
      id: loan.packageId,
    }))
  );

  useEffect(() => {
    if (!initialized) {
      dispatch(fetchClosedLoans());
    }
  }, [dispatch, initialized]);

  async function showDocList(response: IVaultViewClosedLoansSearchResultData) {
    const key: string = createTabKey(keyRoot, VaultViewClosedLoansTabTypes.DocumentList, response);
    const payload = {
      key,
      loanType: constants.CLOSED_LOANS,
      rowData: response,
      type: VaultViewClosedLoansTabTypes.DocumentList,
    };
    dispatch(openTabForDocList(payload));
  }

  function handleRegisterRequestAction(loanData: IVaultViewClosedLoansSearchResultData): void {
    dispatch(openRegisterTabForPackage(loanData.packageId));
  }

  /**
   * Handle the request to transfer the loan
   * @param packageId Handles the delivery request for the package
   * @returns {void}
   */
  function handleDeliverRequestAction(packageId: string): void {
    dispatch(openDeliveryTabForPackage(packageId));
  }

  /**
   * Handle the request to transfer the loan
   * @param packageId Handles the transfer request for the package
   * @returns {void}
   */
  function handleTransferRequestAction(packageId = ""): void {
    dispatch(openTransferTabForPackage(packageId));
  }

  /**
   * Handle the request to update the loan
   * @param packageId The id for the package to update
   * @returns {void}
   */
  function handleUpdateRequestAction(packageId = ""): void {
    dispatch(openUpdateTabForPackage(packageId));
  }

  /**
   * Creates the error symbol (with a tooltip) which, on click, opens a modal showing the last result of the passed in type
   * @param response The loan for which we are getting the symbol
   * @param type The type of teh transaction
   * @param noteTipText The note tip text
   * @returns The MERS error symbol
   */
  function getMersErrorSymbol(
    response: IVaultViewClosedLoansSearchResultData,
    type: MersTransactionType,
    noteTipText: string
  ) {
    return (
      <Tooltip title={noteTipText}>
        <IconButton
          onClick={() => {
            openLastResultModal(response, type);
          }}
          size="small"
        >
          <AlertIcon color="error" />
        </IconButton>
      </Tooltip>
    );
  }

  const cdfColumns: GridColDef[] = [];
  clientDefinedDataFields.data
    .filter((f) => f.enabled)
    .forEach((cdf) => {
      cdfColumns.push({
        ...datagridColumns.originator,
        field: cdf.id,
        headerName: cdf.name,
        renderCell: (params: GridRenderCellParams) => {
          const response: IVaultViewClosedLoansSearchResultData = params.row;
          const dataFields = response.clientDefinedDataFields;
          if (dataFields) {
            const dataField = dataFields.find((df: any) => df.clientDefinedDataFieldId === cdf.id);
            if (dataField) {
              return dataField.value;
            }
          }
          return null;
        },
      });
    });

  function getExportUrl(searchData: any) {
    return getClosedLoansExportUrl(searchData, includeClientDefinedDataFields);
  }

  const columns: GridColDef[] = [
    datagridColumns.loanNumber,
    {
      ...datagridColumns.min,
      renderCell: (params: GridRenderCellParams) => (
        <MersIdentificationNumber
          keyRoot={keyRoot}
          loan={params.row as IVaultViewClosedLoansSearchResultData}
          loanType={constants.CLOSED_LOANS}
          tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
          value={params.row.min}
        />
      ),
    },
    {
      ...datagridColumns.acIndicator,
      renderCell: (params: GridRenderCellParams) => (
        <Grid container item justifyContent="center" alignItems="center">
          <AuthoritativeCopy mersOrgs={mersOrgs} loan={params.row as IVaultViewClosedLoansSearchResultData} />
        </Grid>
      ),
    },
    datagridColumns.primaryBorrowerName,
    datagridColumns.closingDate,
    datagridColumns.created,
    {
      ...datagridColumns.originator,
      renderCell: (params: GridRenderCellParams) => {
        const originatorOrgId = params.row.originatorOrgId;
        const mersOrg = mersOrgs.find((org) => org.mersOrgId === originatorOrgId);
        if (mersOrg) {
          return (
            <Tooltip title={mersOrg.mersOrgId}>
              <span>{mersOrg.name}</span>
            </Tooltip>
          );
        }
        return originatorOrgId;
      },
    },
    datagridColumns.controller,
    datagridColumns.location,
    datagridColumns.servicer,
    {
      ...datagridColumns.subServicer,
      hide: !profile.profile.settings.mers.subservicerEnabled,
    },
    {
      ...datagridColumns.delegateeForTransfers,
      hide: !profile.profile.settings.mers.delegateeForTransferEnabled,
    },
    {
      field: "mersRegistrationStatus",
      renderCell: (params: GridRenderCellParams) => {
        if (
          params.row.mersData.registrationTransaction != null &&
          isMersTransactionFailed(params.row.mersData.registrationTransaction.status)
        ) {
          return getMersErrorSymbol(
            params.row as IVaultViewClosedLoansSearchResultData,
            MersTransactionType.LastResultRegistration,
            getRegistrationStatusDisplay(PackageRegistrationStatus.RegistrationFailed)
          );
        } else if (
          params.row.mersData.changeStatusTransaction != null &&
          isMersTransactionFailed(params.row.mersData.changeStatusTransaction.status)
        ) {
          return getMersErrorSymbol(
            params.row as IVaultViewClosedLoansSearchResultData,
            MersTransactionType.LastResultReverseRegistration,
            "Failure (reverse registration)"
          );
        } else {
          const myMERsPositions = getMERsInitiatorPositions(
            mersOrgs,
            params.row as IVaultViewClosedLoansSearchResultData
          );
          return (
            <RegistrationDetails
              keyRoot={keyRoot}
              mersOriginators={mersOrgs}
              data={params.row as IVaultViewClosedLoansSearchResultData}
              myMERsPositions={myMERsPositions}
              onShowReverseRegistrationModal={showReverseRegistrationConfirmModal}
            />
          );
        }
      },
      renderHeader: function renderRegisterHeader() {
        return (
          <Tooltip title="Registration status">
            <Grid container item justifyContent="center" alignItems="center">
              <RegisterIcon />
            </Grid>
          </Tooltip>
        );
      },
      sortable: false,
      width: ICON_COLUMN_WIDTH,
    },
    {
      field: "mersDeliveryStatus",
      renderCell: (params: GridRenderCellParams) => {
        if (
          params.row.mersData.deliveryTransaction != null &&
          params.row.mersData.deliveryTransaction.status === MersTransactionStatus.Failed
        ) {
          return getMersErrorSymbol(
            params.row as IVaultViewClosedLoansSearchResultData,
            MersTransactionType.LastResultDelivery,
            getDeliveryStatusDisplay(PackageDeliveryStatus.DeliveryFailed)
          );
        } else {
          return (
            <DeliveryDetails
              data={params.row as IVaultViewClosedLoansSearchResultData}
              keyRoot={keyRoot}
              onShowDeliveryCancelConfirmModal={showDeliveryCancelConfirmModal}
            />
          );
        }
      },
      renderHeader: function renderRegisterHeader() {
        return (
          <Tooltip title="Delivery status">
            <Grid container item justifyContent="center" alignItems="center">
              <DeliveryOutIcon />
            </Grid>
          </Tooltip>
        );
      },
      sortable: false,
      width: 50,
    },
    {
      field: "mersTransferStatus",
      renderCell: (params: GridRenderCellParams<null, IVaultViewClosedLoansSearchResultData>) => {
        if (
          params.row.mersData.transferTransaction != null &&
          isMersTransactionFailed(params.row.mersData.transferTransaction.status)
        ) {
          return getMersErrorSymbol(
            params.row as IVaultViewClosedLoansSearchResultData,
            MersTransactionType.LastResultTransfer,
            getTransferStatusDisplay(PackageTransferStatus.TransferFailed, params.row.mersData)
          );
        } else {
          return (
            <TransferDetails
              data={params.row as IVaultViewClosedLoansSearchResultData}
              keyRoot={keyRoot}
              onShowTransferCancelConfirmModal={showTransferCancelConfirmModal}
            />
          );
        }
      },
      renderHeader: function renderRegisterHeader() {
        return (
          <Tooltip title="Transfer status">
            <Grid container item justifyContent="center" alignItems="center">
              <TransferIcon />
            </Grid>
          </Tooltip>
        );
      },
      sortable: false,
      width: ICON_COLUMN_WIDTH,
    },
    ...cdfColumns,
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => {
        const response: IVaultViewClosedLoansSearchResultData = params.row;
        const availableActions: TableRowContextMenuItem[] = [
          {
            disabled: false,
            icon: <DocumentIcon />,
            onClick: async () => {
              await showDocList(response);
            },
            title: "Docs",
          },
          <AuditLogMenuItem data={response} key={response.packageId} />,
        ];
        if (
          response.mersData.mersRegistrationStatus !== PackageRegistrationStatus.Unregistered &&
          independentDelivery &&
          response.mersData.mersDeliveryStatus !== PackageDeliveryStatus.DeliveryPending
        ) {
          availableActions.push({
            icon: <DeliveryOutIcon />,
            onClick: () => {
              handleDeliverRequestAction(response.packageId);
            },
            title: "Deliver",
          });
        }
        if (
          response.action === PackageAction.Transfer ||
          response.mersData.mersTransferStatus === PackageTransferStatus.TransferFailed
        ) {
          if (rightsTransfer && canTransfer(response.mersData.mersInitiators, response.originatorOrgId)) {
            availableActions.push({
              icon: <TransferIcon twoTone={false} />,
              onClick: () => {
                handleTransferRequestAction(response.packageId);
              },
              title: "Transfer",
            });

            availableActions.push({
              icon: <ReverseRegisterationIcon />,
              onClick: () => {
                showReverseRegistrationConfirmModal(response as IVaultViewClosedLoansSearchResultData);
              },
              title: "Reverse Register",
            });

            const hasDelegateeForTransfer =
              response.mersData.mersInitiators.delegateeForTransfer != null &&
              response.mersData.mersInitiators.delegateeForTransfer.orgId !== EMPTY_MERS_RECIPIENT.orgId;
            if (hasDelegateeForTransfer) {
              availableActions.push(<RemoveDelegateeForTransfersMenuItem package={response} />);
            }

            const hasSubservicer =
              response.mersData.mersInitiators.subServicer != null &&
              response.mersData.mersInitiators.subServicer.orgId !== EMPTY_MERS_RECIPIENT.orgId;
            if (hasSubservicer && canRemoveSubservicer(response.mersData.mersInitiators, response.originatorOrgId)) {
              availableActions.push(<RemoveSubservicerMenuItem package={response} />);
            }

            availableActions.push({
              icon: <UploadIcon />,
              onClick: () => {
                handleUpdateRequestAction(response.packageId);
              },
              title: "Update",
            });
          }
        } else if (response.mersData.mersTransferStatus === PackageTransferStatus.TransferPending) {
          if (rightsTransfer) {
            availableActions.push({
              icon: <CancelIcon />,
              onClick: () => {
                showTransferCancelConfirmModal(response as IVaultViewClosedLoansSearchResultData);
              },
              title: "Cancel pending transfer",
            });
          }
        } else if (
          response.action === PackageAction.Register &&
          !(response.mersData.incomingTransferTransaction && response.mersData.incomingTransferTransaction.referenceId)
        ) {
          if (rightsRegister) {
            availableActions.push({
              icon: <RegisterIcon />,
              onClick: () => {
                handleRegisterRequestAction(response as IVaultViewClosedLoansSearchResultData);
              },
              title: "Register",
            });
          }
        }

        if (
          response.mersData.mersDeliveryStatus === PackageDeliveryStatus.DeliveryPending &&
          response.mersData.mersTransferStatus !== PackageTransferStatus.TransferPending
        ) {
          if (rightsTransfer) {
            availableActions.push({
              icon: <CancelIcon />,
              onClick: () => {
                showDeliveryCancelConfirmModal(response as IVaultViewClosedLoansSearchResultData);
              },
              title: "Cancel pending delivery",
            });
          }
        }

        if (response.noteModificationDate && rightsRegister) {
          availableActions.push({
            icon: <UndoIcon />,
            onClick: () => {
              showReverseModificationConfirmModal(response as IVaultViewClosedLoansSearchResultData);
            },
            title: "Reverse modification",
          });
        }

        return <TableRowContextMenu actions={availableActions} />;
      },
      renderHeader: function renderContextMenuHeader() {
        return <TableContextMenu getExportUrl={getExportUrl} searchData={searchParams.data} />;
      },
    },
  ];

  function handleNextPage() {
    dispatch(fetchClosedLoans(links?.next));
  }

  function handlePreviousPage() {
    dispatch(fetchClosedLoans(links?.previous));
  }

  function onSortChange(model: GridSortModel) {
    if (model[0]) {
      dispatch(updateSortByAction(model[0]));
    }
  }

  function handleRetry() {
    dispatch(executeSearch());
  }

  return (
    <DataGrid
      columnBuffer={0}
      columns={columns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
        Pagination: Pagination,
      }}
      componentsProps={{
        errorOverlay: { error, onRetry: handleRetry },
        pagination: { handleNextPage, handlePreviousPage, isLoading, links },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      error={error}
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      onSortModelChange={onSortChange}
      pagination
      paginationMode="server"
      rows={searchResults}
      rowsPerPageOptions={[100]}
      scrollbarSize={17}
      sortingMode="server"
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default connector(ClosedSearchResultsTable);
