import React, { ReactElement, useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";
import ScrollContainer from "Components/ScrollContainer";

import * as actions from "Features/Tabs/Actions";
import * as tabActions from "Features/Tabs/Tabs";
import { ILoanTabDetails } from "Features/Tabs/Tabs";

import ActivityPanel from "../ActivityPanel/ActivityPanel";
import TabStore from "../TabStore";
import { ILoanActivity } from "../VaultInterfaces";
import { updateActivityVisible, updateSelectedPackageId } from "./AllLoansSlice";
import { VaultViewAllLoansTabTypes } from "./Constants";
import * as allLoansService from "./allLoansService";
import { getAllLoanTabs } from "./tabStore";

const mapStateToProps = (state: RootState) => ({
  activityVisible: state.allLoans.activityVisible,
  selectedPackageId: state.allLoans.selectedPackage,
  tabs: getAllLoanTabs(state),
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = TypesFromRedux;

const keyRoot = "VaultTab-AllLoans-";

const SearchResultsTab = {
  activityVisible: false,
  key: createTabKey(keyRoot, VaultViewAllLoansTabTypes.SearchResults),
  loanType: constants.ALL_LOANS,
  title: "All",
  type: VaultViewAllLoansTabTypes.SearchResults,
};

export function VaultViewAllLoans(props: Props): ReactElement {
  const { activityVisible, dispatch, selectedPackageId, tabs } = props;

  // Add Search Results as first tab on original mount
  useEffect(() => {
    if (!tabs.byId[SearchResultsTab.key]) {
      (dispatch as any)(actions.addTab(SearchResultsTab));
    }
  }, [dispatch, tabs]);

  /**
   * Click handler for switching tabs
   * @param tab The tab that is being clicked
   * @returns {void}
   */
  function onTabClick(tab: ILoanTabDetails): void {
    const key = tab.key;
    const index = tabs.allIds.indexOf(key);
    dispatch(
      tabActions.goToTab({
        key,
        loanType: constants.ALL_LOANS,
      })
    );
    // Return if Search Bar tab is clicked
    if (index === 0) return;
    // Otherwise make call to update Activity Panel
    const { packageId } = tabs.byId[key];
    dispatch(updateSelectedPackageId(packageId));
  }

  function handleActivityPanelClosed() {
    dispatch(updateActivityVisible(false));
    dispatch(updateSelectedPackageId(undefined));
  }

  function handleShowDocList(rowData: ILoanActivity) {
    (dispatch as any)(allLoansService.handleShowDocList(rowData as any));
  }

  return (
    <>
      <ScrollContainer>
        <TabPanel
          currentTab={tabs.currentTab}
          onTabClick={onTabClick}
          tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
          tabGroup={constants.ALL_LOANS}
        >
          <TabStore tab={tabs.byId[tabs.currentTab]} />
        </TabPanel>
      </ScrollContainer>
      {activityVisible && (
        <ActivityPanel
          packageId={selectedPackageId}
          onClose={handleActivityPanelClosed}
          onDocListClick={handleShowDocList}
        />
      )}
    </>
  );
}

export default connector(VaultViewAllLoans);
