import React, { ReactElement, useState } from "react";

import Link from "@mui/material/Link";

import * as constants from "Common/EVaultAppConstants";
import { PackageTransferStatus } from "Common/Enums";
import dateTimeField from "Common/Fields/DateTimeField";
import { getOrgDisplay, getTransactionRightsDisplay } from "Common/Utilities";

import ErrorState from "Components/DataTable/ErrorState";
import DetailList from "Components/DetailList";
import DetailRow from "Components/DetailRow";

import { retrieveLastMersTransactionData } from "Adapters/Mers/mersAdapter";

import MersIdentificationNumber from "../Mers/MersIdentificationNumber";
import {
  IMersTransactionData,
  IVaultViewClosedLoansSearchResultData,
  VaultViewClosedLoansTabTypes,
} from "../VaultInterfaces";

interface ITransferDetailsModal {
  data: IVaultViewClosedLoansSearchResultData;
  keyRoot: string;
  onClose: () => void;
  onShowTransferCancelConfirmModal: (response: IVaultViewClosedLoansSearchResultData) => void;
}

export default function TransferDetailsModal(props: ITransferDetailsModal): ReactElement {
  const { data, keyRoot, onClose } = props;
  const [details, setDetails] = React.useState<IMersTransactionData | null>(null);
  const [showError, setShowError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { packageId } = data;

  React.useEffect(() => {
    function retrieveDetails() {
      setShowError(false);
      retrieveLastMersTransactionData(packageId, "Transfer")
        .then((response) => {
          if (!response) {
            setShowError(true);
            setIsLoading(false);
          } else {
            setDetails(response);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setShowError(true);
          setIsLoading(false);
          console.error("Last result retrieval error", error);
          console.log(error);
        });
    }
    if (isLoading) {
      retrieveDetails();
    }
  }, [isLoading, packageId]);

  function handleRetry() {
    setIsLoading(true);
  }

  let cancelTransferLink;
  if (data.mersData.mersTransferStatus === PackageTransferStatus.TransferPending) {
    cancelTransferLink = (
      <p className="margin-top-xxsmall">
        <span>To abort this transfer, </span>
        <Link className="pseudolink__body" onClick={handleShowTransferCancelConfirmation} role="button">
          click here
        </Link>
      </p>
    );
  }

  let initiator = "Unknown";
  let rights: string[] = [];
  let completionDate = "Unknown";
  let initiatedDate = "Unknown";

  if (details != null) {
    if (details.initiator) {
      initiator = getOrgDisplay(details.initiator);
    }

    rights = getTransactionRightsDisplay(details);

    if (data.mersData.mersTransferStatus === PackageTransferStatus.Transferred) {
      // TODO: When the backend starts returning better data, this can remove the default
      completionDate = dateTimeField.renderForDisplay(details.completionDate || completionDate);
    } else if (data.mersData.mersTransferStatus === PackageTransferStatus.TransferPending) {
      completionDate = "In progress";
    }

    // TODO: When the backend starts returning better data, this tmp variable can be removed
    initiatedDate = dateTimeField.renderForDisplay(details.initiatedDate || initiatedDate);
  }

  function handleShowTransferCancelConfirmation() {
    onClose();
    props.onShowTransferCancelConfirmModal(data);
  }

  let content = (
    <div>
      <DetailRow label="Complete" value={completionDate} />
      <DetailRow label="Initiated" value={initiatedDate} />
      <DetailRow label="Initiator" value={initiator} />
      <DetailRow label="Loan #" value={data.loanNumber || "Unknown"} />
      <DetailRow
        label="MIN"
        value={
          <MersIdentificationNumber
            className="pseudolink__body"
            keyRoot={keyRoot}
            loan={data}
            loanType={constants.CLOSED_LOANS}
            tabKeyRoot={VaultViewClosedLoansTabTypes.MERSInquiryDetails}
            value={data.min}
          />
        }
      />

      {rights.length === 0 && <DetailRow label="Rights" value="Unknown" />}
      {rights.length > 0 && <DetailList label="Rights" values={rights} />}
      {cancelTransferLink}
    </div>
  );

  if (showError) {
    content = <ErrorState message="An error occurred while loading transfer details." onRetry={handleRetry} />;
  }

  return content;
}
