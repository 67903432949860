import axios, { AxiosResponse } from "axios";

import { IMERSInquiry, IMersTransactionDetailedData, IMersTransactionResponse } from "Features/Vault/VaultInterfaces";

export function inquireMERS(min: string, packageId: string): Promise<IMERSInquiry[]> {
  return axios.get(`/api/v1/packages/${packageId}/mers/inquiry?min=${min}`).then((response) => response.data);
}

export function getPackageHistory(packageId: string): Promise<AxiosResponse<IMersTransactionDetailedData[]>> {
  return axios.get<IMersTransactionDetailedData[]>(`/api/v1/packages/${packageId}/mers/transactions/detailed-history`);
}

export function getMERSPackageLastResult(packageId: string): Promise<IMersTransactionResponse[]> {
  return axios.get("/api/v1/packages/" + packageId + "/mers/transactions/history").then((response) => response.data);
}

export function retrieveLastMersTransactionData(packageId: string, type: string): Promise<() => any> {
  return axios.get(`/api/v1/packages/${packageId}/mers/transactions/types/${type}`).then((response) => response.data);
}

export function retrieveMersTransactionData(packageId: string, referenceId: string): Promise<() => any> {
  return axios.get(`/api/v1/packages/${packageId}/mers/transactions/${referenceId}`).then((response) => response.data);
}
