/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";
import { Route, Routes } from "react-router-dom";

import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import { IAdminProfileViewModel } from "Adapters/profileAdapter";

import VaultHeaderActions from "Features/Vault/ClosedLoans/VaultHeaderActions";

import Logo from "./Logo";
import CustomMenu from "./Menu";

interface IAdminProps {
  profile?: IAdminProfileViewModel;
}

export default function AppBar(props: IAdminProps): ReactElement | null {
  const { profile } = props;

  return (
    <MuiAppBar
      color="primary"
      css={css`
        z-index: 1300;
      `}
      elevation={0}
      position="fixed"
    >
      <Toolbar>
        <Logo />
        <div
          css={css`
            flex-grow: 1;
          `}
        />
        <Routes>
          <Route path="/app/vault/*" element={<VaultHeaderActions />} />
        </Routes>
        {profile && <CustomMenu />}
      </Toolbar>
    </MuiAppBar>
  );
}
