import React, { ReactElement, useState } from "react";

import { useDispatch } from "react-redux";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { fetchIncomingDeliveries } from "Features/Vault/Incoming/Deliveries/IncomingDeliveriesSlice";

import { AppDispatch } from "../../../../App/Store";
import { VaultMersDeliveryConfirmationResultsModalPopup } from "../../ModalPopups/VaultMersDeliveryConfirmationResultsModalPopup";
import {
  DeliveryRecipientStatus,
  IMersBatchDeliveryConfirmationRequest,
  IVaultViewManualDeliveriesSearchResultExtendedData,
} from "../../VaultInterfaces";

export interface IDeliveryConfirmationModalProps {
  data: IVaultViewManualDeliveriesSearchResultExtendedData[];
  showDeliveryConfirmation: boolean;
  onHideConfirmation: () => void;
}

export function DeliveryConfirmationModal(props: IDeliveryConfirmationModalProps): ReactElement {
  const { data, onHideConfirmation, showDeliveryConfirmation } = props;

  const [batchRequest, setBatchRequest] = useState<IMersBatchDeliveryConfirmationRequest>({
    deliveries: [],
  });
  const [showBatchRequests, setShowBatchRequests] = useState<boolean>(false);
  const dispatch = useDispatch() as AppDispatch;

  const closeDeliveryRequestResults = () => {
    dispatch(fetchIncomingDeliveries());
    onHideConfirmation();

    setShowBatchRequests(false);
  };

  const confirmDeliveryConfirmations = (): void => {
    onHideConfirmation();

    const batchRequest: IMersBatchDeliveryConfirmationRequest = {
      deliveries: [],
    };

    for (const item of data) {
      const delivery = item;
      if (delivery.action !== DeliveryRecipientStatus.None) {
        const request = {
          action: delivery.action,
          id: delivery.id,
          manualDeliveryStatus: delivery.manualDeliveryStatus,
          min: delivery.min,
          transferId: delivery.transferId,
        };
        batchRequest.deliveries.push(request);
      }
    }
    setBatchRequest(batchRequest);
    setShowBatchRequests(true);
  };

  return (
    <div>
      <ModalPopupAlert
        title="Submit confirmations"
        content="Are you sure you want to submit the confirmations for the selected incoming deliveries and approvals?"
        confirmationButtonText="CONTINUE"
        onConfirm={confirmDeliveryConfirmations}
        showDeclineLink={true}
        onClose={onHideConfirmation}
        open={showDeliveryConfirmation}
        size="sm"
      />
      <VaultMersDeliveryConfirmationResultsModalPopup
        onClosed={closeDeliveryRequestResults}
        request={batchRequest}
        open={showBatchRequests}
      />
    </div>
  );
}
