import React, { ReactElement } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import { SelectProps } from "@mui/material/Select";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    fontSize: ".81rem",
  },
  select: {
    height: "100%",
    margin: 0,
    minWidth: 120,
  },
});

function TableSelect(props: SelectProps): ReactElement {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <FormControl fullWidth>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <MuiSelect
        className={props.className + " " + classes.select}
        classes={{
          select: classes.root,
        }}
        fullWidth
        {...rest}
      >
        {children}
      </MuiSelect>
    </FormControl>
  );
}

export default TableSelect;
