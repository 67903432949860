import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";

import { getTransferableMERSPackageSearchUrl } from "Adapters/Mers/mersPackageAdapter";

import { IClosedLoansSearchApiParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import { IMersInitiators, IMersTransferPackage, MersTransferTypes } from "Features/Vault/VaultInterfaces";

import { getDefaultRecipient } from "../MersRecipients/mersOrgService";
import { getMERsInitiatorPositionsForInitiators, getMersOrgs } from "../mersOrgService";
import {
  canTransferControl,
  canTransferLocation,
  canTransferServicer,
  fetchPackages,
  updateMersTransfers,
  updateSearchBarParams,
} from "./MersTransferSlice";

export const updateTransferQueue = (
  packages: IMersTransferPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    let updatedQueue = _.uniqBy(packages, "min");

    updatedQueue = _.map(updatedQueue, (l: IMersTransferPackage) => {
      const loan = { ...l };
      initializeTransferOptionsForPackage(state, loan);
      return loan;
    });
    dispatch(updateMersTransfers(updatedQueue));
  };
};

export const addTransfersToQueue = (
  packages: IMersTransferPackage[]
): ThunkAction<void, RootState, unknown, AnyAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const queue = state.mersTransfers.transfers;
    const selectedIds = packages.map((q) => q.id);
    const updatedQueue = queue.map((q) => {
      if (q.selected || selectedIds.indexOf(q.id) < 0) return q;
      return {
        ...q,
        selected: true,
      };
    });
    const newQueue = _.uniqWith(packages.concat(updatedQueue), (arrVal, othVal) => {
      return arrVal.loanNumber === othVal.loanNumber && arrVal.min === othVal.min;
    });
    const sortedLoans = _.sortBy(newQueue, "loanNumber");
    dispatch(updateTransferQueue(sortedLoans));
  };
};

export function initializeTransferOptionsForPackage(state: RootState, loan: IMersTransferPackage): void {
  const mersOrgs = getMersOrgs(state);
  const defaultRecipient = getDefaultRecipient(
    state.mersRecipients.recipients,
    state.mersOriginators.originators,
    loan.originatorOrgId
  );
  loan.id = loan.packageId;

  // set Transfer/Location defaults if no values are set
  if (!loan.controlRecipient) {
    loan.controlRecipient = defaultRecipient;
  }

  if (!loan.locationRecipient) {
    loan.locationRecipient = defaultRecipient;
  }

  if (!loan.servicingAgentRecipient) {
    loan.servicingAgentRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!loan.subServicerRecipient) {
    loan.subServicerRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!loan.securedPartyRecipient) {
    loan.securedPartyRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!loan.securedPartyDelegateeRecipient) {
    loan.securedPartyDelegateeRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!loan.delegateeForTransfersRecipient) {
    loan.delegateeForTransfersRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (!loan.subServicerRecipient) {
    loan.subServicerRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (loan.includeDelivery === undefined) {
    loan.includeDelivery = true;
  }
  loan.currentUserInitiatorType = getMERsInitiatorPositionsForInitiators(
    mersOrgs,
    loan.originatorOrgId,
    loan.mersInitiators
  );

  const disableControlDropdown = !canTransferControl(loan.mersInitiators, loan.originatorOrgId);
  const disableServicerDropdown = !canTransferServicer(loan.mersInitiators, loan.originatorOrgId);
  const disableLocationDropdown = !canTransferLocation(loan.mersInitiators, loan.originatorOrgId);

  if (disableControlDropdown) {
    loan.controlRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (disableLocationDropdown) {
    loan.locationRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }

  if (disableServicerDropdown) {
    loan.servicingAgentRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
    loan.subServicerRecipient = EMPTY_MERS_RECIPIENT_DETAILS;
  }
}

export function canRemoveSubservicer(initiators: IMersInitiators, mersOrgId: string) {
  if (initiators.delegatee && initiators.delegatee.orgId === mersOrgId) return true;
  if (initiators.subServicer && initiators.subServicer.orgId === mersOrgId) return true;
  return false;
}

export function getMERsTransferType(loan: IMersTransferPackage): MersTransferTypes {
  let myType = MersTransferTypes.Invalid;

  if (loan.controlRecipient && loan.controlRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
    myType = MersTransferTypes.Control;

    if (loan.securedPartyRecipient && loan.securedPartyRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
      myType = MersTransferTypes.ControlWithSecuredParty;
    }
  }

  if (loan.locationRecipient && loan.locationRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
    myType = MersTransferTypes.Location;
  }

  if (loan.servicingAgentRecipient && loan.servicingAgentRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
    myType = MersTransferTypes.Servicer;
  }

  if (
    loan.controlRecipient &&
    loan.controlRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id &&
    loan.locationRecipient &&
    loan.locationRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id &&
    loan.servicingAgentRecipient &&
    loan.servicingAgentRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    myType = MersTransferTypes.All;
  } else if (
    loan.controlRecipient &&
    loan.controlRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id &&
    loan.locationRecipient &&
    loan.locationRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    myType = MersTransferTypes.ControlLocation;

    if (loan.securedPartyRecipient && loan.securedPartyRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id) {
      myType = MersTransferTypes.ControlAndLocationWithSecuredParty;
    }
  } else if (
    loan.controlRecipient &&
    loan.controlRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id &&
    loan.servicingAgentRecipient &&
    loan.servicingAgentRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    myType = MersTransferTypes.ControlServicer;
  } else if (
    loan.locationRecipient &&
    loan.locationRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id &&
    loan.servicingAgentRecipient &&
    loan.servicingAgentRecipient.id !== EMPTY_MERS_RECIPIENT_DETAILS.id
  ) {
    myType = MersTransferTypes.LocationServicer; // invalid type
  }

  return myType;
}

export function executeSearch(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.mersTransfers.searchBarParams.data;
    const searchParams = {
      ...data,
      searchTerm: data.searchTerm?.trim(),
    };
    dispatch(updateSearchBarParams(searchParams));
    dispatch(fetchTransfers());
  };
}

export function fetchTransfers(resourceUrl?: string): ThunkAction<void, RootState, unknown, AnyAction> {
  return async (dispatch, getState) => {
    const state: RootState = getState();
    const mersOrgs = state.mersOriginators;
    let fetchUrl = resourceUrl as string;
    if (!resourceUrl) {
      const searchCriteria = state.mersTransfers.searchBarParams.data;
      const sortByParams = state.mersTransfers.sortByParams;
      const params: IClosedLoansSearchApiParams = {
        ...searchCriteria,
        limit: 100,
        offset: 0,
        sortByParams,
      };
      fetchUrl = getTransferableMERSPackageSearchUrl(params);
    }
    const request = {
      mersOrgs: mersOrgs.originators,
      state,
      url: fetchUrl,
    };
    dispatch(fetchPackages(request));
  };
}
