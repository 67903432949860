const location = {
  mers: {
    rightsHolder: {
      control: "control",
      controller: "controller",
      delegateeForTransfers: "delegatee for transfers",
      delegateeForTransfersStartCase: "Delegatee for Transfers",
      location: "location",
      originalRegistrant: "original registrant",
      securedParty: "secured party",
      securedPartyDelegatee: "secured party delegatee",
      servicer: "master servicer",
      subServicer: "subservicer",
    },
  },
  remove: "REMOVE",
  removeDelegateeForTransfersModal: {
    content: "This cannot be cancelled. Are you sure you want to initiate the remove delegatee for transfers?",
    title: "Initiate Remove Delegatee for Transfers",
  },
};

export default location;
