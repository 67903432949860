import * as React from "react";

import { isAfter } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import {
  getDisplayForMersTransactionStatus,
  getDisplayForMersTransactionType,
  getOrgDisplay,
  getTransactionRightsDisplay,
} from "../../Common/Utilities";
import DetailList from "../../Components/DetailList";
import DetailRow from "../../Components/DetailRow";
import MersIdentificationNumber, { IPackageMersIdentifier } from "./Mers/MersIdentificationNumber";
import { IMersTransactionDetailedData } from "./VaultInterfaces";

interface ITransferDetailsModal {
  onClose: () => void;
  open: boolean;
  details: IMersTransactionDetailedData;
  packageId: string;
}

export default function TransactionDetailsModal(props: ITransferDetailsModal) {
  const { details, onClose, open, packageId } = props;

  let initiator = "Unknown";
  let rights: string[] = [];
  let initiatedDate = "Unknown";

  if (details != null) {
    if (details.initiator) {
      initiator = getOrgDisplay(details.initiator);
    }

    rights = getTransactionRightsDisplay(details);

    if (details.initiatedDate && isAfter(new Date(details.initiatedDate), new Date(1800, 1, 1))) {
      initiatedDate = formatInTimeZone(new Date(details.initiatedDate), "America/Boise", "M/d/yyyy h:mm a zzz");
    }
  }
  const identifier: IPackageMersIdentifier = {
    min: details.min,
    packageId,
  };
  return (
    <>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle>Transaction Details</DialogTitle>
        <DialogContent>
          <DetailRow label="Type" value={getDisplayForMersTransactionType(details.type)} />
          <DetailRow label="Status" value={getDisplayForMersTransactionStatus(details.status)} />
          <DetailRow label="Initiated" value={initiatedDate} />
          <DetailRow label="Initiator" value={initiator} />
          <DetailRow
            label="MIN"
            value={
              <MersIdentificationNumber
                className="pseudolink__body"
                keyRoot=""
                value={details.min}
                tabKeyRoot=""
                loan={identifier}
                loanType=""
              />
            }
          />

          {rights.length > 0 && <DetailList label="Rights" values={rights} />}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" size="large" onClick={onClose}>
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
