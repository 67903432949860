import axios from "axios";

export interface IChannel {
  id: number;
  name: string;
}

/**
 * Gets the channels for the currently logged in user
 * @returns A promise for the request
 */
export async function getUserChannels(): Promise<IChannel[]> {
  const response = await axios.get("/api/v1/channels");
  return response.data;
}
