/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useState } from "react";

import { css } from "@emotion/react";
import _, { startCase } from "lodash";
import { ParseResult } from "papaparse";
import { CSVLink } from "react-csv";
import { usePapaParse } from "react-papaparse";

import FileIcon from "@mui/icons-material/InsertDriveFile";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

import UploadButton from "Components/UploadButton";

import localization from "../localization";

const RESULTS_LIMIT = 100;
const fileTypes: string[] = ["text/csv", ".csv", "application/vnd.ms-excel"];

export enum MersActionType {
  Deliver = "Deliver",
  Register = "Register",
  Transfer = "Transfer",
}

export const csvHeaders = {
  controller: startCase(localization.mers.rightsHolder.control),
  delegateeForTransfer: localization.mers.rightsHolder.delegateeForTransfersStartCase,
  effectiveDate: "Effective Date",
  loanNumberOrMin: "Loan # or MIN",
  location: startCase(localization.mers.rightsHolder.location),
  orgId: "Org ID",
  securedParty: startCase(localization.mers.rightsHolder.securedParty),
  securedPartyDelegatee: startCase(localization.mers.rightsHolder.securedPartyDelegatee),
  servicer: startCase(localization.mers.rightsHolder.servicer),
  subServicer: startCase(localization.mers.rightsHolder.subServicer),
};

export interface CsvRow {
  loanNumberOrMin: string;
  [key: string]: string;
}

interface csvProperties {
  csvFilename: string;
  headers: string[];
}

export interface uploadPermissions {
  delegateeForTransferEnabled: boolean;
  securedPartyDelegateeEnabled: boolean;
  securedPartyEnabled: boolean;
  subservicerEnabled: boolean;
}

export function renderCsvProps(actionType: MersActionType, permissions: uploadPermissions): csvProperties {
  let headers: string[] = [];
  let csvFilename = "upload-template.csv";
  if (actionType === MersActionType.Deliver) {
    headers = [csvHeaders.loanNumberOrMin, csvHeaders.orgId];
    csvFilename = "mers-deliver-upload-template.csv";
  } else if (actionType === MersActionType.Register) {
    headers = [csvHeaders.loanNumberOrMin];
    // Check permissions for to determine potential csv headers for registering loans
    if (permissions.securedPartyEnabled) headers.push(csvHeaders.securedParty);
    if (permissions.securedPartyDelegateeEnabled) headers.push(csvHeaders.securedPartyDelegatee);
    if (permissions.delegateeForTransferEnabled) headers.push(csvHeaders.delegateeForTransfer);
    csvFilename = "mers-register-upload-template.csv";
  } else if (actionType === MersActionType.Transfer) {
    headers = [csvHeaders.loanNumberOrMin, csvHeaders.controller, csvHeaders.location, csvHeaders.servicer];
    if (permissions.subservicerEnabled) headers.push(csvHeaders.subServicer);
    if (permissions.securedPartyEnabled) headers.push(csvHeaders.securedParty);
    if (permissions.securedPartyDelegateeEnabled) headers.push(csvHeaders.securedPartyDelegatee);
    if (permissions.delegateeForTransferEnabled) headers.push(csvHeaders.delegateeForTransfer);
    headers.push(csvHeaders.effectiveDate);
    csvFilename = "mers-transfer-upload-template.csv";
  }
  return { csvFilename, headers };
}

interface FileUploadProps {
  actionType: MersActionType;
  onChange: (filename: string, items: CsvRow[]) => void;
  permissions: uploadPermissions;
}

function CsvFileUpload(props: FileUploadProps): ReactElement {
  const { actionType, onChange, permissions } = props;
  const { readString } = usePapaParse();

  const [filename, setFilename] = useState<string>("");
  const [error, setError] = useState<string | undefined>();

  function onError(error: string) {
    setError(error);
  }

  function handleOnUpload(file: any): void {
    if (!fileTypes.includes(file.type) && file.name.indexOf(".csv") === -1) {
      onError("Invalid file type");
      return;
    }
    const reader = new FileReader();
    reader.onload = function () {
      const csvText = reader.result as string;
      const headersArray: [keyof typeof csvHeaders] = Object.keys(csvHeaders) as [keyof typeof csvHeaders];
      readString(csvText, {
        complete: (parsedCsv: ParseResult<CsvRow>) => {
          const { data, meta } = parsedCsv;
          // Check to make sure csv has required header
          if (!meta.fields?.includes("loanNumberOrMin")) {
            onError(`File missing required column of "${csvHeaders.loanNumberOrMin}"`);
            return;
          }
          // Filter out results that don't have the required column
          const results = data.filter((row) => row.loanNumberOrMin);
          if (results.length === 0) {
            onError(`No results found in ${file.name}`);
          } else {
            const uniqueResults = _.uniqBy(results, "loanNumberOrMin");

            if (uniqueResults.length > RESULTS_LIMIT) {
              onError(`Limit exceeded, cannot upload a file with more than ${RESULTS_LIMIT} results.`);
            } else {
              setFilename(file.name);
              onChange(file.name, uniqueResults);
            }
          }
        },
        header: true,
        transformHeader: (header) =>
          headersArray.find(
            (key) => csvHeaders[key].replace(/\s+/g, "").toLowerCase() === header.replace(/\s+/g, "").toLowerCase()
          ) ?? header,
        // @ts-ignore
        worker: false,
      });
    };
    reader.readAsText(file);
  }

  return (
    <Grid container item xs={12} justifyContent="center" alignItems="center" flexDirection="column" sx={{ pt: 4 }}>
      {
        /* Initial message to get started */
        (error || filename === "") && (
          <Grid item xs={12}>
            Upload a file to get started
          </Grid>
        )
      }
      <Grid item xs={12}>
        <UploadButton onError={onError} onUpload={handleOnUpload} />
      </Grid>
      {
        /* Initial file limitation warning */
        !error && filename === "" && (
          <Grid item xs={12} sx={{ fontSize: 12, mt: 1 }}>
            File must not exceed 100 results.
          </Grid>
        )
      }
      {
        /* Error display */
        error && (
          <Grid
            aria-label="error-container"
            item
            xs={12}
            sx={{
              color: (theme) => theme.palette.error.main,
              fontSize: 14,
              mt: 1,
            }}
          >
            {error}
          </Grid>
        )
      }
      {/* CSV Template Link*/}
      <Grid container item xs={12} sx={{ pt: 2 }}>
        <Tooltip title="Download CSV Template">
          <span>
            <CSVLink
              css={css`
                &:hover {
                  color: #323232;
                }
                align-items: center;
                display: inline-flex;
                color: #555555;
                border-radius: 2px;
              `}
              headers={renderCsvProps(actionType, permissions).headers}
              data={[{}]}
              filename={renderCsvProps(actionType, permissions).csvFilename}
              target="_blank"
            >
              <FileIcon fontSize="small" sx={{ fontSize: 14, mr: 0.25 }} />
              CSV Template
            </CSVLink>
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default CsvFileUpload;
