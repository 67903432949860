import React, { ReactElement } from "react";

import createCache from "@emotion/cache";
import { CacheProvider, ThemeProvider as EmotionThemeProvider, Theme } from "@emotion/react";
import { Provider } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Routes from "Pages/Routes";

import CookieConsentAlert from "../Components/CookieConsentAlert";
import { store } from "./Store";
import theme from "./Theme";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const nonce = document.getElementsByTagName("meta")[0]?.content;

const cacheRtl = createCache({
  key: "mui",
  nonce,
  prepend: true,
});

export default function App(): ReactElement {
  return (
    <StyledEngineProvider injectFirst>
      <CacheProvider value={cacheRtl}>
        <MuiThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme}>
            <CssBaseline />
            <Provider store={store}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CookieConsentAlert />
                <Routes />
              </LocalizationProvider>
            </Provider>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </StyledEngineProvider>
  );
}
