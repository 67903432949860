import React from "react";

import { ConnectedProps, connect } from "react-redux";

import EllipsisIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { RootState } from "App/Store";

import { DataTableIcon } from "Components/Icons";

import { profileSelector } from "Features/Profile/ProfileSlice";
import ExportModal from "Features/Vault/ExportModal";

interface IAllLoansContextMenuProps {
  exportLimit?: number;
  getExportUrl: (searchData: any) => string;
}

const mapStateToProps = (state: RootState) => ({
  exportLimit: profileSelector(state).settings.application.exportLimit,
  searchData: state.allLoans.searchBarParams.data,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

type Props = IAllLoansContextMenuProps & TypesFromRedux;

function TableContextMenu(props: Props) {
  const { exportLimit = 5000, getExportUrl, searchData } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [exportOpen, setExportOpen] = React.useState<boolean>(false);

  function handleMenuOpen(event: any) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleShowExport() {
    handleMenuClose();
    setExportOpen(true);
  }

  function handleHideExport() {
    setExportOpen(false);
  }

  return (
    <>
      <IconButton size="small" onClick={handleMenuOpen}>
        <EllipsisIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem key="export" onClick={handleShowExport}>
          <ListItemIcon>
            <DataTableIcon />
          </ListItemIcon>
          <ListItemText>Export</ListItemText>
        </MenuItem>
      </Menu>
      <ExportModal
        exportLimit={exportLimit}
        getUrl={getExportUrl}
        onClose={handleHideExport}
        open={exportOpen}
        searchData={searchData}
      />
    </>
  );
}

export default connector(TableContextMenu);
