/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import ErrorPage from "Components/ErrorPage";

export default function SignedOut(): ReactElement {
  function handleSignIn() {
    window.location.href = "/login";
  }

  return (
    <ErrorPage title="Signed Out">
      <Typography variant="body1">
        You are now signed out. Access your session again to view or change your settings.
      </Typography>
      <Button
        css={css`
          display: block;
          margin: 32px auto 0;
        `}
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleSignIn}
      >
        Sign In
      </Button>
    </ErrorPage>
  );
}
