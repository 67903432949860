/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { IContextMenuOption } from "./ContextMenu";

export type TableRowContextMenuItem = IContextMenuOption | JSX.Element;

interface ITableRowContextMenu {
  actions: TableRowContextMenuItem[];
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export default function TableRowContextMenu(props: ITableRowContextMenu): ReactElement | null {
  const { actions, open } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    if (open !== undefined) {
      if (!open) {
        setAnchorEl(null);
      }
    }
  }, [open]);

  if (actions.length === 0) {
    return null;
  }

  function handleMenuOpen(event: any) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    if (props.onOpen) props.onOpen();
  }

  function handleMenuClose() {
    setAnchorEl(null);
    if (props.onClose) props.onClose();
  }

  const handleClick = (onClick?: () => void) => () => {
    if (onClick != null) {
      onClick();
    }
    handleMenuClose();
  };

  const menuContents = actions.map((action: TableRowContextMenuItem) => {
    if ((action as IContextMenuOption).icon) {
      const menuAction: IContextMenuOption = action as IContextMenuOption;
      return (
        <MenuItem key={menuAction.title} onClick={handleClick(menuAction.onClick)} disabled={menuAction.disabled}>
          <ListItemIcon>{menuAction.icon}</ListItemIcon>
          <ListItemText>{menuAction.title}</ListItemText>
        </MenuItem>
      );
    } else {
      const element = action as JSX.Element;
      return React.cloneElement(element, {
        onClick: handleMenuClose,
        ...element.props,
      });
    }
  });

  return (
    <Box className={`table-panel-row--context-menu ${anchorEl && "active"}`}>
      <IconButton size="small" onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        {menuContents}
      </Menu>
    </Box>
  );
}
