import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState } from "Types/EVaultAppTypes";

import { getManualDeliveries } from "Adapters/Mers/mersDeliveryAdapter";

import { IVaultViewManualDeliveriesSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../../Constants";

const initialManualDeliveriesSearchResult: IVaultViewManualDeliveriesSearchResultData[] = [];

const initialManualDeliveriesApiCallParams: types.IVaultsSearchParams = {
  searchDateOption: "",
  searchKey: QueryStringSearchKey.MIN,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

const initialManualDeliveriesSearchBarParams: types.IVaultSearchBarParams = {
  data: initialManualDeliveriesApiCallParams,
};

const incomingDeliveries: types.IManualDeliveries = {
  isLoading: false,
  searchBarParams: initialManualDeliveriesSearchBarParams,
  searchResult: initialManualDeliveriesSearchResult,
};

export const fetchIncomingDeliveries = createAsyncThunk<
  IVaultViewManualDeliveriesSearchResultData[],
  void,
  {
    rejectValue: IHttpErrorState;
  }
>("incomingDeliveries/fetch", async (_, thunkApi) => {
  try {
    return await getManualDeliveries();
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const incomingDeliveriesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchIncomingDeliveries.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchIncomingDeliveries.fulfilled, (state, action) => {
      state.searchResult = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchIncomingDeliveries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: incomingDeliveries,
  name: "incomingDeliveries",
  reducers: {},
});

export default incomingDeliveriesSlice.reducer;
