import React, { ReactElement } from "react";

import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";

import CollapseIcon from "Components/Icons/Collapse";
import ExpandIcon from "Components/Icons/Expand";
import Tooltip from "Components/Tooltip";

interface ICollapsibleSideMenuControllerProps {
  isExpanded: boolean;
  onToggleExpand: () => void;
}

const useStyles = makeStyles({
  container: {
    paddingLeft: "4px",
  },
  icon: {
    margin: "8px",
    padding: 0,
  },
});

export default function CollapsibleSideMenuController(props: ICollapsibleSideMenuControllerProps): ReactElement {
  const { isExpanded, onToggleExpand } = props;
  const classes = useStyles();

  const noteTipText = isExpanded ? "Collapse Panel" : "Expand Panel";
  const icon = isExpanded ? (
    <CollapseIcon sx={{ height: 14, width: 16 }} className={classes.icon} viewBox="0 0 14 16" />
  ) : (
    <ExpandIcon sx={{ height: 14, width: 16 }} className={classes.icon} viewBox="0 0 14 16" />
  );
  return (
    <div className={classes.container}>
      <Tooltip title={noteTipText}>
        <IconButton className={classes.icon} onClick={onToggleExpand} size="small">
          {icon}
        </IconButton>
      </Tooltip>
    </div>
  );
}
