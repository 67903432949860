/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function AuthoritativeCopyYesIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M12 9l-1.1 3h2.2L12 9z" />
      <path d="M19 6l-2-2c-1.9 1.2-3.4 1.1-5 0-1.6 1.1-3.1 1.2-5 0L5 6c2.1 2.7.7 4.8.4 5.8C3.8 17.8 12 20 12 20s8.2-2.2 6.6-8.2c-.3-1-1.7-3.1.4-5.8zm-4.9 9l-.6-2h-3l-.6 2H8.7l2.5-7h1.5l2.5 7h-1.1z" />
    </SvgIcon>
  );
}
