import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { v4 as uuid } from "uuid";

import { IMersTransactionResponse, MersTransactionStatus, MersTransactionType } from "Features/Vault/VaultInterfaces";

import { RootState } from "../../../../App/Store";
import { getMersTransactionStatusFromString, getMersTransactionTypeFromString } from "../../../../Common/Utilities";

interface ITransactionResult {
  errors: string[];
  loanNumber: string;
  status: MersTransactionStatus;
  type: MersTransactionType;
  warnings: string[];
}

interface IRequest {
  complete: boolean;
  error?: Error;
  id: string;
  loanNumber: string;
  transactions: ITransactionResult[];
  type: MersTransactionType;
}

interface ITransactionBatch {
  id: string;
  requests: IRequest[];
  viewed: boolean;
  type: MersTransactionType;
}

interface ITransactionSliceState {
  batches: ITransactionBatch[];
}

const initialState: ITransactionSliceState = {
  batches: [],
};

interface IBatchResponse {
  batchId: string;
  loanNumber?: string;
  requestId: string;
  responses: IMersTransactionResponse[] | IMersTransactionResponse;
}

interface ICreateRequestAction {
  batchId: string;
  loanNumber: string;
  requestId: string;
  type: MersTransactionType;
}

interface IRequestErrorAction {
  batchId: string;
  error: Error;
  requestId: string;
}

interface IBatchAction {
  batchId: string;
  type: MersTransactionType;
}

interface IViewBatchResponse {
  batchId: string;
}

export const mersTransactionSlice = createSlice({
  initialState,
  name: "mersTransactions",
  reducers: {
    createBatchAction: (state, action: PayloadAction<IBatchAction>) => {
      if (state.batches.find((batch) => batch.id === action.payload.batchId)) return;
      state.batches.unshift({
        id: action.payload.batchId,
        requests: [],
        type: action.payload.type,
        viewed: false,
      });
    },
    createRequestAction: (state, action: PayloadAction<ICreateRequestAction>) => {
      const batchItem: ITransactionBatch | undefined = state.batches.find(
        (batch) => batch.id === action.payload.batchId
      );
      if (batchItem == null) return;
      batchItem.requests.push({
        complete: false,
        id: action.payload.requestId,
        loanNumber: action.payload.loanNumber,
        transactions: [],
        type: action.payload.type,
      });
    },
    handleResponseAction: (state, action: PayloadAction<IBatchResponse>) => {
      let results;
      const batchItem: ITransactionBatch | undefined = state.batches.find(
        (batch) => batch.id === action.payload.batchId
      );
      if (batchItem == null) return;
      const requestItem: IRequest | undefined = batchItem.requests.find(
        (request) => request.id === action.payload.requestId
      );
      if (requestItem == null) return;

      if (Array.isArray(action.payload.responses)) {
        results = action.payload.responses;
      } else {
        results = [action.payload.responses];
      }
      _.each(results, (response) => {
        const responseLoanNumber = action.payload.loanNumber || response.loanNumber || "[Unknown]";

        // TODO errors need to be fixed from the backend and handled in one way here - RW
        if (null != response.error && 0 !== response.error.length) {
          // An error occurred

          let standardizedError: string;
          try {
            const errorObject = JSON.parse(response.error) as { error: string };
            standardizedError = errorObject.error;
          } catch (e) {
            console.log("Error response was not a valid JSON. Using response as literal.");
            standardizedError = response.error;
          }

          const newContentItem: ITransactionResult = {
            errors: [standardizedError],
            loanNumber: responseLoanNumber,
            status: MersTransactionStatus.Failed,
            type: MersTransactionType.Unknown,
            warnings: [],
          };

          requestItem.transactions = _.uniq(requestItem.transactions.concat(newContentItem));
        } else if (null != response.errors && 0 !== response.errors.length) {
          // An error occurred

          const newContentItem: ITransactionResult = {
            errors: response.errors,
            loanNumber: responseLoanNumber,
            status: MersTransactionStatus.Failed,
            type: getMersTransactionTypeFromString(response.type),
            warnings: [],
          };

          requestItem.transactions = _.uniq(requestItem.transactions.concat(newContentItem));
        }

        if (response.type === "Transfer" && response.status === "TransferNotApproved") {
          const newContentItem: ITransactionResult = {
            errors: ["Transfer Not Approved"],
            loanNumber: responseLoanNumber,
            status: MersTransactionStatus.TransferNotApproved,
            type: MersTransactionType.Transfer,
            warnings: [],
          };

          requestItem.transactions = _.uniq(requestItem.transactions.concat(newContentItem));
        }

        _.each(response.transactionResults, (result) => {
          const errors: string[] = [];
          const warnings: string[] = [];

          const resultLoanNumber = action.payload.loanNumber || result.loanNumber || "[Unknown]";

          _.each(result.statusDetails, (detail) => {
            switch (detail.type.toLowerCase()) {
              case "error":
                errors.push(detail.description ? detail.description : detail.name);
                break;

              case "warning":
                warnings.push(detail.description ? detail.description : detail.name);
                break;

              default:
                break;
            }
          });

          const newContentItem: ITransactionResult = {
            errors,
            loanNumber: resultLoanNumber,
            status: getMersTransactionStatusFromString(result.status),
            type: getMersTransactionTypeFromString(result.type),
            warnings,
          };

          requestItem.transactions = _.uniq(requestItem.transactions.concat(newContentItem));
        });
      });
      requestItem.complete = true;
    },
    handleResponseError: (state, action: PayloadAction<IRequestErrorAction>) => {
      const batchItem: ITransactionBatch | undefined = state.batches.find(
        (batch) => batch.id === action.payload.batchId
      );
      if (batchItem == null) return;
      const requestItem: IRequest | undefined = batchItem.requests.find(
        (request) => request.id === action.payload.requestId
      );
      if (requestItem == null) return;
      requestItem.error = action.payload.error;
      requestItem.complete = true;
    },
    viewBatchAction: (state, action: PayloadAction<IViewBatchResponse>) => {
      const batchItem: ITransactionBatch | undefined = state.batches.find(
        (batch) => batch.id === action.payload.batchId
      );
      if (batchItem == null) return;
      batchItem.viewed = true;
    },
  },
});

export const { viewBatchAction } = mersTransactionSlice.actions;

export function batchTransactionAction(
  batchId: string,
  loanNumber: string,
  request: () => Promise<any>,
  type: MersTransactionType
): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch) => {
    const requestId = uuid();
    dispatch(
      mersTransactionSlice.actions.createBatchAction({
        batchId,
        type,
      })
    );

    dispatch(
      mersTransactionSlice.actions.createRequestAction({
        batchId,
        loanNumber,
        requestId,
        type,
      })
    );
    request().then(
      (response) => {
        const action: IBatchResponse = {
          batchId,
          requestId,
          responses: response,
        };
        dispatch(mersTransactionSlice.actions.handleResponseAction(action));
      },
      (error) => {
        const action: IRequestErrorAction = {
          batchId,
          error,
          requestId,
        };
        dispatch(mersTransactionSlice.actions.handleResponseError(action));
      }
    );
  };
}

export default mersTransactionSlice.reducer;
