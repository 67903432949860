import React, { useEffect } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import * as constants from "Common/EVaultAppConstants";
import { createTabKey } from "Common/Utilities";

import TabPanel from "Components/Panels/TabbedPanel/TabbedPanel";
import ScrollContainer from "Components/ScrollContainer";

import * as actions from "Features/Tabs/Actions";
import { ILoanTabDetails, ITabPayload } from "Features/Tabs/Tabs";
import * as tabActions from "Features/Tabs/Tabs";

import { fetchQueueAction } from "../Mers/Queue/queuedLoansService";
import TabStore from "../TabStore";
import { VaultViewQueuedLoansTabTypes } from "../VaultInterfaces";

const mapStateToProps = (state: RootState) => ({
  tabs: state.tabs[constants.QUEUE],
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

function VaultViewQueue(props: TypesFromRedux) {
  const keyRoot = "VaultTab-QueueLoans-";
  const SearchResultsTab: ITabPayload = {
    key: createTabKey(keyRoot, VaultViewQueuedLoansTabTypes.SearchResults),
    loanType: "queue",
    onUpdateCallback: onUpdateCallback,
    title: "Queue",
    type: VaultViewQueuedLoansTabTypes.SearchResults,
  };

  const { dispatch, tabs } = props;

  // Add Search Results as first tab on original mount and make initial query
  useEffect(() => {
    if (!tabs.byId[SearchResultsTab.key]) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(actions.addTab(SearchResultsTab));
    } else {
      dispatch(tabActions.updateTab(SearchResultsTab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Click handler for switching tabs
   * @param tab tab being clicked
   * @returns {void}
   */
  function onTabClick(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.goToTab({
        key: tab.key,
        loanType: constants.QUEUE,
      })
    );
  }

  /**
   * Callback after closing Secured Party Confirmation Modal
   * @returns {void}
   */
  function onUpdateCallback() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchQueueAction());
  }

  /**
   * Callback event for when a tab is closed
   * @param tab the tab to close
   * @returns {void}
   */
  function handleTabTabClosed(tab: ILoanTabDetails): void {
    dispatch(
      tabActions.removeTab({
        key: tab.key,
        loanType: constants.QUEUE,
      })
    );
  }

  return (
    <ScrollContainer>
      <TabPanel
        currentTab={tabs.currentTab}
        onTabClick={onTabClick}
        onTabClosed={handleTabTabClosed}
        tabs={tabs.allIds.map((id: string) => tabs.byId[id])}
      >
        <TabStore tab={tabs.byId[tabs.currentTab]} />
      </TabPanel>
    </ScrollContainer>
  );
}

export default connector(VaultViewQueue);
