import React, { ReactElement } from "react";
import { useEffect, useState } from "react";

import _ from "lodash";

import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";

import ErrorState from "Components/DataTable/ErrorState";
import { InboxIcon, RowSelectPromptIcon } from "Components/Icons";
import Loading from "Components/Loading";

import { getLoanActivity } from "Adapters/packageAdapter";

import { ILoanActivity, ILoanActivityEvent } from "Features/Vault/VaultInterfaces";

import ActivityPanelEvent from "./ActivityPanelEvent";

interface IActivityPanelProps {
  onClose: () => void;
  onDocListClick: (loanActivity: ILoanActivity) => void;
  packageId: string | undefined;
}

export default function ActivityPanel(props: IActivityPanelProps): ReactElement {
  const { packageId } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [loanActivity, setLoanActivity] = useState<ILoanActivity>();
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    function retrieveData() {
      if (packageId) {
        setShowError(false);
        setLoanActivity(undefined);
        setLoading(true);

        getLoanActivity(packageId)
          .then((response: any) => {
            const activity: ILoanActivity = response;
            setLoanActivity(activity);
          })
          .catch((err) => {
            setShowError(true);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoanActivity(undefined);
      }
    }
    if (packageId) {
      retrieveData();
    }
  }, [packageId]);

  function handleRetry() {
    setLoading(true);
  }

  function getEventElements(loanActivityEvents: ILoanActivityEvent[]): JSX.Element[] {
    return _.map(loanActivityEvents, (event: ILoanActivityEvent) => {
      return <ActivityPanelEvent key={event.name} onShowDocList={onShowDocList} event={event} />;
    });
  }

  function onShowDocList(): void {
    if (loanActivity != null) {
      props.onDocListClick(loanActivity);
    }
  }

  const title = loanActivity == null || loading ? "Activity Feed" : loanActivity.loanNumber;

  return (
    <Grid
      className="activity-panel"
      sx={{
        borderLeft: (theme) => `1px solid ${theme.palette.grey[300]}`,
        minWidth: 360,
        width: 360,
      }}
    >
      <Grid
        container
        item
        alignItems="center"
        sx={{
          borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
          padding: (theme) => theme.spacing(3, 2),
        }}
      >
        <IconButton onClick={props.onClose} size="small">
          <CloseIcon />
        </IconButton>
        <Tooltip title={title}>
          <Typography noWrap variant="h6" sx={{ flex: 1, ml: 2 }}>
            {title}
          </Typography>
        </Tooltip>
      </Grid>

      {loading && (
        <Grid container item alignItems="center" sx={{ py: 5 }}>
          <Loading />
        </Grid>
      )}

      {!loading && !showError && (loanActivity == null || loanActivity.events.length === 0) && (
        <Grid container item xs={12} sx={{ mt: 8 }}>
          <Grid container item justifyContent="center">
            {loanActivity == null ? (
              <RowSelectPromptIcon viewBox="0 0 76 76" sx={{ height: 50, width: 50 }} />
            ) : (
              <InboxIcon viewBox="0 0 76 76" sx={{ height: 50, width: 50 }} />
            )}
          </Grid>
          <Grid container item justifyContent="center" sx={{ mt: 1 }}>
            {loanActivity == null
              ? "Select a row to view its activity."
              : "No activity has occurred for the selected row."}
          </Grid>
        </Grid>
      )}
      {showError && <ErrorState message="Error loading package activity" onRetry={handleRetry} />}

      {!loading && loanActivity != null && getEventElements(loanActivity.events)}
    </Grid>
  );
}
