import * as React from "react";
import { useEffect, useState } from "react";

import axios from "axios";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ModalProps } from "@mui/material";

import { IMersBatch } from "../../BatchOperationsInterfaces";
import BatchUploadDialogContent, { IFileDetails } from "./BatchUploadDialogContent";
import TemplateDownload from "./TemplateDownload";

interface IBatchBatchUploadModalProps {
  onBatchComplete: (result: IMersBatch) => void;
  onCancel: () => void;
  onClose: ModalProps["onClose"];
  open: boolean;
  title: string;
  url: string;
  templateUrl: string;
}

/**
 * This component renders the batch upload component
 */
function BatchUploadModal(props: IBatchBatchUploadModalProps): JSX.Element {
  const { open } = props;
  const [fileDetails, setFileDetails] = useState<IFileDetails | undefined>();
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [uploading, setUploading] = useState<boolean>(false);

  useEffect(() => {
    setFileDetails(undefined);
    setErrorMessages(undefined);
  }, [open]);

  /**
   * When the confirmation is triggered,
   * the request is made to the requested URL to upload the file contents for batch processing.
   */
  async function handleConfirmation() {
    if (fileDetails) {
      setUploading(true);
      try {
        const result = await axios.post<IMersBatch>(props.url, {
          instructions: fileDetails.contents,
          name: fileDetails.name,
        });
        console.log("result", result);
        if (result.data) {
          props.onBatchComplete(result.data);
        }
        setUploading(false);
      } catch (e: any) {
        setUploading(false);
        if (e.response.data && e.response.data.length && typeof e.response.data !== "string") {
          setErrorMessages(e.response.data);
        } else if (e.response.data && e.response.data.ErrorMessage === "An unhandled exception occurred") {
          setErrorMessages(["Something went wrong with your request."]);
        } else if (e.response.data && typeof e.response.data === "string") {
          setErrorMessages([e.response.data]);
        } else {
          setErrorMessages([`The upload request failed with a status code ${e.response.status}`]);
        }
      }
    }
  }

  /**
   * This event is triggered whenever the file details have been changed in the upload button.
   */
  function handleFileDetailsChange(details: IFileDetails | undefined) {
    setFileDetails(details);
    setErrorMessages(undefined);
  }

  return (
    <Dialog title={props.title} open={props.open} onClose={props.onClose} fullWidth={true} maxWidth="sm">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <BatchUploadDialogContent
          value={fileDetails}
          errorMessages={errorMessages}
          onDetailsChange={handleFileDetailsChange}
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
        <TemplateDownload downloadUrl={props.templateUrl} />
        <div>
          <Button onClick={props.onCancel} style={{ marginRight: "4px" }}>
            CANCEL
          </Button>
          <Button
            color="secondary"
            disabled={!fileDetails?.contents || !fileDetails.name || uploading}
            onClick={handleConfirmation}
            variant="contained"
          >
            CONTINUE
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default BatchUploadModal;
