import React, { ReactElement } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Link from "@mui/material/Link";

import Tooltip from "Components/Tooltip";

import { IMersBatch } from "./BatchOperationsInterfaces";
import { goToBatchOperationsDetailsTab } from "./batchOperationsService";

const connector = connect();
export interface IBatchIdColumnProps {
  batch: IMersBatch;
}

type TypesFromRedux = ConnectedProps<typeof connector>;
type Types = TypesFromRedux & IBatchIdColumnProps;

export function BatchIdColumn(props: Types): ReactElement {
  const { batch, dispatch } = props;
  function handleDispatchBatchDetails() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(goToBatchOperationsDetailsTab(batch));
  }

  const classes = ["pseudolink"];
  return (
    <Tooltip title="Show Batch Status">
      <Link className={classes.join(" ")} onClick={handleDispatchBatchDetails} role="button">
        {batch.name}
      </Link>
    </Tooltip>
  );
}

export default connector(BatchIdColumn);
