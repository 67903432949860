import * as React from "react";
import { useState } from "react";

import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { nonce } from "../../../../App/App";
import ErrorOverlay from "../../../../Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "../../../../Components/DataGridCustomComponents/LoadingOverlay";
import TableRowContextMenu from "../../TableRowContextMenu";
import { datagridColumns } from "../../shared";
import { IReconciliationClientMersOrg } from "../minReconciliationAdapter";
import DeleteMersOrgFormDialog from "./DeleteMersOrgFormDialog";
import MersOrgFormDialog from "./MersOrgFormDialog";
import MersOrgMainContextMenu from "./MersOrgMainContextMenu";
import useClientMersOrgTable from "./useClientMersOrgTable";

/**
 * Handles displaying the MIN Reconciliation MERS orgs returned in the search
 */
function MinReconciliationMersOrgTable(): JSX.Element {
  const { loading, clientMersOrgs, refreshMersOrgs } = useClientMersOrgTable();
  const [mersOrgDialogOpen, setMersOrgDialogOpen] = useState(false);
  const [mersOrg, setMersOrg] = useState<IReconciliationClientMersOrg | undefined>();

  const [deleteMersOrgDialogOpen, setDeleteMersOrgDialogOpen] = useState(false);
  const [deleteMersOrg, setDeleteMersOrg] = useState<IReconciliationClientMersOrg | undefined>();
  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Sets the requested item to be edited. This will open the mersOrg dialog.
   */
  function handleModifyMersOrg(mersOrg: IReconciliationClientMersOrg) {
    setMenuOpen(false);
    setMersOrg(mersOrg);
    setMersOrgDialogOpen(true);
  }

  /**
   * Sets the requested item to be edited. This will open the mersOrg dialog.
   */
  function handleDeleteMersOrg(mersOrg: IReconciliationClientMersOrg) {
    setMenuOpen(false);
    setDeleteMersOrg(mersOrg);
    setDeleteMersOrgDialogOpen(true);
  }

  /**
   * Sets the mers org dialog to open without a MERS org item.
   */
  function handleAddClientMersOrg() {
    setMersOrg(undefined);
    setMersOrgDialogOpen(true);
  }

  function handleMenuOpen() {
    setMenuOpen(true);
  }

  function handleMenuClose() {
    setMenuOpen(false);
  }

  const columns: GridColDef[] = [
    {
      editable: false,
      field: "clientCode",
      flex: 1,
      headerName: "Client",
      sortable: false,
    },
    {
      editable: false,
      field: "name",
      flex: 1,
      headerName: "Org Name",
      sortable: false,
    },
    {
      editable: false,
      field: "mersOrgId",
      flex: 1,
      headerName: "Org Id",
      sortable: false,
    },
    {
      ...datagridColumns.contextMenu,
      renderCell: (params: GridRenderCellParams) => {
        const response: IReconciliationClientMersOrg = params.row;
        return (
          <TableRowContextMenu
            open={menuOpen}
            onClose={handleMenuClose}
            onOpen={handleMenuOpen}
            actions={[
              <MenuItem
                key="Edit"
                onClick={() => {
                  handleModifyMersOrg(response);
                }}
              >
                <ListItemText>Edit</ListItemText>
              </MenuItem>,
              <MenuItem
                key="Edit"
                onClick={() => {
                  handleDeleteMersOrg(response);
                }}
              >
                <ListItemText>Delete</ListItemText>
              </MenuItem>,
            ]}
          />
        );
      },

      renderHeader: function renderContextMenuHeader() {
        return <MersOrgMainContextMenu onAddMersOrg={handleAddClientMersOrg} />;
      },
    },
  ];

  /**
   * Handles closing the mers org dialog
   */
  function handleCloseClientMersOrgDialog() {
    setMersOrg(undefined);
    setMersOrgDialogOpen(false);
  }

  /**
   * Handles closing the delete mers org dialog
   */
  function handleCloseDeleteClientMersOrgDialog() {
    setDeleteMersOrg(undefined);
    setDeleteMersOrgDialogOpen(false);
  }

  /**
   * Handles mers org data saved in the dialog
   */
  function handleClientMersOrgSaved() {
    refreshMersOrgs();
    setMersOrg(undefined);
  }

  return (
    <>
      <MersOrgFormDialog
        mersOrg={mersOrg}
        onClose={handleCloseClientMersOrgDialog}
        onMersOrgSaved={handleClientMersOrgSaved}
        open={mersOrgDialogOpen}
      />
      <DeleteMersOrgFormDialog
        mersOrg={deleteMersOrg}
        onClose={handleCloseDeleteClientMersOrgDialog}
        onMersOrgSaved={handleClientMersOrgSaved}
        open={deleteMersOrgDialogOpen}
      />

      <DataGrid
        columnBuffer={0}
        columns={columns}
        components={{
          ErrorOverlay: ErrorOverlay,
          LoadingOverlay: LoadingOverlay,
        }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        loading={loading}
        localeText={{
          noRowsLabel: "No results found",
        }}
        nonce={nonce}
        pagination
        paginationMode="server"
        rows={clientMersOrgs}
        rowsPerPageOptions={[100]}
        scrollbarSize={17}
        sortingMode="server"
        sortingOrder={["desc", "asc"]}
      />
    </>
  );
}

export default MinReconciliationMersOrgTable;
