import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as channelAdapter from "Adapters/channelAdapter";
import { IChannel } from "Adapters/channelAdapter";

/**
 * Slice of the channels in the store
 */

export interface IChannelStore {
  channels: IChannel[];
  initialized: boolean;
  isLoading: boolean;
}

export const initialState: IChannelStore = {
  channels: [],
  initialized: false,
  isLoading: false,
};

export const fetchChannels = createAsyncThunk<IChannel[]>("channel/fetch", async () => {
  return await channelAdapter.getUserChannels();
});

export const channelSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchChannels.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchChannels.fulfilled, (state, action) => {
      state.channels = action.payload;
      state.initialized = true;
      state.isLoading = false;
    });
  },
  initialState: initialState,
  name: "channel",
  reducers: {},
});

export default channelSlice.reducer;
