import axios from "axios";

export interface IClient {
  id: number;
  name: string;
}

/**
 * Gets all the clients for the user
 * @returns A promise for the request
 */
export async function getUserClients(): Promise<IClient[]> {
  const response = await axios.get("/api/v1/clients");
  return response.data;
}
