import React, { ReactElement, useState } from "react";

import DownloadIcon from "@mui/icons-material/GetApp";
import EllipsesIcon from "@mui/icons-material/MoreVert";
import PrintIcon from "@mui/icons-material/Print";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import SmartDocContextMenu from "../ContextMenus/SmartDocContextMenu";

interface IDocumentContextMenuProps {
  onPrint: (documentId: number) => void;
  onDownload: (documentId: number) => void;
  packageId: string;
  showSmartDoc: boolean;
  smartDocId: number;
}

export function DocumentContextMenu(props: IDocumentContextMenuProps): ReactElement {
  const { onDownload, onPrint, packageId, showSmartDoc, smartDocId } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function handleMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }

  function handleMenuClose(event: React.MouseEvent) {
    event.stopPropagation();
    setAnchorEl(null);
  }

  function handlePrintClick(event: React.MouseEvent) {
    event.stopPropagation();
    setAnchorEl(null);
    onPrint(smartDocId);
  }

  function handleDownloadClick(event: React.MouseEvent) {
    event.stopPropagation();
    setAnchorEl(null);
    onDownload(smartDocId);
  }

  return (
    <Box className={`table-panel-row--context-menu ${anchorEl && "active"}`}>
      <IconButton size="small" onClick={handleMenuOpen}>
        <EllipsesIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "top",
        }}
      >
        <MenuItem key="print-doc" onClick={handlePrintClick}>
          <ListItemIcon>
            <PrintIcon />
          </ListItemIcon>
          <ListItemText>Print</ListItemText>
        </MenuItem>
        <MenuItem key="download-doc" onClick={handleDownloadClick}>
          <ListItemIcon>
            <DownloadIcon />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>
        {showSmartDoc && (
          <span>
            <Divider light sx={{ my: 1 }} />
            <SmartDocContextMenu packageId={packageId} smartDocId={smartDocId} />
          </span>
        )}
      </Menu>
    </Box>
  );
}

export default DocumentContextMenu;
