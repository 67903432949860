import React from "react";

import ArchivedLoansFilterBar from "./ArchivedLoansFilterBar";
import ArchivedSearchResultsTable from "./ArchivedSearchResultsTable";

interface ISearchResultsTabProps {
  keyRoot: string;
}

type Props = ISearchResultsTabProps;

function ArchivedSearchResults(props: Props): JSX.Element {
  const { keyRoot } = props;

  return (
    <>
      <ArchivedLoansFilterBar />
      <ArchivedSearchResultsTable keyRoot={keyRoot} />
    </>
  );
}

export default ArchivedSearchResults;
