/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import * as React from "react";
import { useState } from "react";

import { css } from "@emotion/react";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { CloudUpload } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { IMersBatch } from "../BatchOperationsInterfaces";
import { goToBatchOperationsDetailsTab } from "../batchOperationsService";
import BatchUploadModal from "./BatchUploadModal";

const connector = connect();

type TypesFromRedux = ConnectedProps<typeof connector>;
type Types = TypesFromRedux;

enum UploadType {
  Transfer,
  Update,
}

/**
 * This component is responsible for rendering a button that when clicked, controls batch upload functionality.
 */
function BatchTransactionUpload(props: Types) {
  const { dispatch } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [showUploadType, setShowUploadType] = useState<UploadType | undefined>();
  /**
   * This event is triggered by the menu option to open the transfer and delivery upload component.
   */
  function handleTransferAndDeliveryClicked() {
    setShowUploadType(UploadType.Transfer);
    setMenuAnchorEl(null);
  }

  /**
   * This event is triggered by the menu option to open the registration upload component.
   */
  function handleStatusUpdateClicked() {
    setShowUploadType(UploadType.Update);
    setMenuAnchorEl(null);
  }

  /**
   * Handles event when the upload button event is clicked to open the menu
   */
  function handleUploadMenuClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchorEl(event.currentTarget);
  }

  /**
   * Handler for batch completion.
   */
  function handleBatchComplete(batch: IMersBatch) {
    setMenuAnchorEl(null);
    setShowUploadType(undefined);
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(goToBatchOperationsDetailsTab(batch));
  }

  /**
   * Handler for canceling the batch action.
   */
  function handleCancel() {
    setShowUploadType(undefined);
  }

  /**
   * Handler for canceling close action
   */
  function handleClose(_?: unknown, reason?: string) {
    if (reason !== "backdropClick") {
      setShowUploadType(undefined);
    }
  }

  let title = "Confirm batch transfers and deliveries";
  let url = "/api/v1/mers/batch/transfers-and-deliveries";
  let templateUrl = "/bulkOperations/transferAndDeliveryTemplate.xlsx";

  if (showUploadType === UploadType.Update) {
    title = "Confirm batch update";
    url = "/api/v1/mers/batch-update";
    templateUrl = "/bulkOperations/updateTemplate.xlsx";
  }

  return (
    <>
      <Button
        color="secondary"
        css={css`
          margin-left: 8px;
          min-width: 100px;
        `}
        id="batch-upload-menu-button"
        aria-controls={!!menuAnchorEl ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={!!menuAnchorEl ? "true" : undefined}
        onClick={handleUploadMenuClick}
        startIcon={<CloudUpload />}
        type="button"
        variant="contained"
      >
        UPLOAD
      </Button>
      <Menu
        open={!!menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorEl={menuAnchorEl}
        MenuListProps={{
          "aria-labelledby": "batch-upload-menu-button",
        }}
      >
        <MenuItem aria-label="Upload list, opens modal" onClick={handleTransferAndDeliveryClicked}>
          Transfer and Delivery
        </MenuItem>
        <MenuItem aria-label="Upload list, opens modal" onClick={handleStatusUpdateClicked}>
          Status Update
        </MenuItem>
      </Menu>

      <BatchUploadModal
        onBatchComplete={handleBatchComplete}
        onCancel={handleCancel}
        open={showUploadType !== undefined}
        onClose={handleClose}
        title={title}
        url={url}
        templateUrl={templateUrl}
      />
    </>
  );
}

export default connector(BatchTransactionUpload);
