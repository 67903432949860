import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "App/Store";

import { EMPTY_MERS_RECIPIENT_DETAILS } from "Common/EVaultAppConstants";
import { apiError } from "Common/error";

import { IHttpErrorState, IResultPages, ISortByParams } from "Types/EVaultAppTypes";
import * as types from "Types/EVaultAppTypes";

import { getMersPackagesByUrl } from "Adapters/Mers/mersPackageAdapter";

import { IVaultClosedSearchBarParams } from "Features/Vault/ClosedLoans/ClosedLoansSlice";
import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import { IMersOriginator, IMersRecipientDetails, IMersRegisterPackage } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../../Constants";

const initialRegistrations: IMersRegisterPackage[] = [];

export interface registrationSearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortByParams?: ISortByParams;
  start: Date;
  stop: Date;
}

const initialRegistrationApiCallParams: registrationSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialRegistrationSearchBarParams: IVaultClosedSearchBarParams = {
  data: initialRegistrationApiCallParams,
};

export interface IMersRegisterSliceState {
  error?: IHttpErrorState;
  isLoading: boolean;
  initialized: boolean;
  links?: IResultPages;
  registrations: IMersRegisterPackage[];
  resourceUrl?: string;
  searchBarParams: IVaultClosedSearchBarParams;
  sortByParams: ISortByParams;
}

const initialState: IMersRegisterSliceState = {
  initialized: false,
  isLoading: false,
  registrations: initialRegistrations,
  searchBarParams: initialRegistrationSearchBarParams,
  sortByParams: initialSortByParams(),
};

export interface IRegistrationRecipientUpdate {
  packageId: string;
  recipient: IMersRecipientDetails;
  type: RegistrationRecipientAccessors;
}

export interface IClosedLoansFetch {
  url: string;
  mersOrgs: IMersOriginator[];
}

export interface IMersPackageResponse {
  links: types.IResultPages;
  results: IMersRegisterPackage[];
}

export enum RegistrationRecipientAccessors {
  servicingAgentRecipient = "servicingAgentRecipient",
  delegateeForTransferRecipient = "delegateeForTransferRecipient",
  securedPartyRecipient = "securedPartyRecipient",
  securedPartyDelegateeRecipient = "securedPartyDelegateeRecipient",
}

export const fetchPackages = createAsyncThunk<
  IMersPackageResponse,
  IClosedLoansFetch,
  {
    rejectValue: IHttpErrorState;
  }
>("mersRegistrations/fetch", async (payload: IClosedLoansFetch, thunkApi) => {
  try {
    const response = await getMersPackagesByUrl(payload.url);
    const packages: IMersRegisterPackage[] = response.results.map((loan) => {
      const defaultRecipient: IMersRecipientDetails = EMPTY_MERS_RECIPIENT_DETAILS;
      return {
        ...loan,
        dataPointOnly: false,
        delegateeForTransferRecipient: defaultRecipient,
        securedPartyDelegateeRecipient: defaultRecipient,
        securedPartyRecipient: defaultRecipient,
        selected: false,
      };
    });
    return {
      ...response,
      results: packages,
    };
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const mersRegistrationSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.registrations = [];
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.registrations = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState,
  name: "mersRegistrations",
  reducers: {
    resetLoadingState: (state) => {
      state.isLoading = false;
      state.initialized = false;
    },
    resetRegistrations: (state) => {
      state.registrations = [];
    },
    resetSearch: (state) => {
      state.searchBarParams = initialState.searchBarParams;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      if (!action.payload) {
        state.initialized = true;
      }
    },
    updateRecipient: (state, action: PayloadAction<IRegistrationRecipientUpdate>) => {
      const registration = state.registrations.find((reg) => reg.packageId === action.payload.packageId);
      if (registration) {
        registration[action.payload.type] = action.payload.recipient || EMPTY_MERS_RECIPIENT_DETAILS;
      }
    },
    updateRegistrations: (state, action: PayloadAction<IMersRegisterPackage[]>) => {
      state.registrations = action.payload;
    },
    updateSearchBarParams: (state, action: PayloadAction<registrationSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const {
  resetLoadingState,
  resetSearch,
  updateRecipient,
  resetRegistrations,
  setLoadingState,
  updateRegistrations,
  updateSearchBarParams,
  updateSortBy,
} = mersRegistrationSlice.actions;

export function getRegistrationQueue(state: RootState): IMersRegisterPackage[] {
  return state.mersRegistrations.registrations;
}

export default mersRegistrationSlice.reducer;
