/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function RegisterIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M15.4,20L13,14.2H9.4V20H6.1V4h7.5c1.4,0,2.4,0.2,3.2,0.6c0.7,0.4,1.2,1,1.4,1.7C18.5,7,18.6,8,18.6,9.1   c0,2.5-0.8,4.1-2.3,4.7l2.6,6.2H15.4z M14.3,11.2c0.4-0.1,0.7-0.3,0.8-0.6c0.1-0.3,0.2-0.8,0.2-1.4c0-0.7-0.1-1.1-0.2-1.4   C15,7.4,14.7,7.2,14.3,7c-0.4-0.1-1.1-0.2-2-0.2H9.4v4.5h2.9C13.2,11.3,13.9,11.3,14.3,11.2z" />
    </SvgIcon>
  );
}
