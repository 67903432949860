/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement } from "react";

import { css } from "@emotion/react";

import Button from "@mui/material/Button";

import { IHttpErrorState } from "Types/EVaultAppTypes";

interface ErrorStateProps {
  onRetry?: () => void;
  error?: IHttpErrorState;
  message?: string;
  onBack?: () => void;
  title?: string;
}

export default function ErrorState(props: ErrorStateProps): ReactElement {
  const { error, message, onBack, onRetry, title } = props;

  let errorMessage = "An error occurred while loading search results.";
  if (message) {
    errorMessage = message;
  }
  let header = `Error${error && error.status ? " - " + error.status : ""}`;
  if (title) {
    header = title;
  }

  return (
    <div
      css={css`
        flex: 1;
      `}
    >
      <div
        css={css`
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
        `}
      >
        <h2
          css={css`
            font-family: "Saira Light", "Saira Regular", "Saira", sans-serif;
            margin-bottom: 8px;
            margin-top: 8px;
          `}
        >
          {header}
        </h2>
        <p
          css={css`
            color: #a0a0a0;
            margin-bottom: 8px;
          `}
        >
          {errorMessage}
        </p>
        {onRetry && (
          <Button
            color="secondary"
            css={css`
              margin-bottom: 8px;
            `}
            onClick={onRetry}
            variant={onBack ? "contained" : "text"}
          >
            RETRY
          </Button>
        )}
        {onBack && (
          <Button color="secondary" onClick={onBack}>
            Back
          </Button>
        )}
      </div>
    </div>
  );
}
