import { DATE_SHORT, format } from "Common/dateUtilities";

import { ITablePanelColumn } from "Components/Panels/TablePanel/TablePanelInterfaces";

import { IFieldInterface } from "./index";

interface IDateField extends IFieldInterface {
  renderForDisplay: (value: Date | string | undefined, format?: string) => string;
  renderForTable: (value: Date | string | undefined, column?: ITablePanelColumn) => string;
}

const dateField: IDateField = {
  renderForDisplay: renderValue,
  renderForTable: renderForTable,
};

function renderForTable(value: string | Date | undefined): string {
  return format(value);
}

function renderValue(value: string | Date | undefined, timeFormat = DATE_SHORT): string {
  return format(value, timeFormat);
}

export default dateField;
