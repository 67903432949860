/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function DeliveryOutIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M 5.868,6.00001 6.535,4.99999 h 10.93 l 0.667,1.00002 z m 6.131999,10 V 14 H 7.9999997 V 11.99998 H 11.999999 V 10 L 16,13.00001 Z M 19.664,6.49596 18.594,4.89099 C 18.222,4.334 17.598,4 16.930001,4 H 7.0699997 C 6.4009999,4 5.7769998,4.33411 5.406,4.89099 L 4.336,6.49596 C 4.1170027,6.82403 4,7.21098 4,7.60499 V 18.00002 C 4,19.10402 4.8949999,20 6,20 h 12 c 1.104,0 2,-0.89598 2,-1.99998 V 7.60499 c 0,-0.39401 -0.116999,-0.781 -0.336,-1.10903" />
    </SvgIcon>
  );
}
