import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState } from "Types/EVaultAppTypes";

import { getManualTransfers } from "Adapters/Mers/mersTransferAdapter";

import { IVaultViewManualTransfersSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey } from "../../Constants";

const initialManualTransfersSearchResult: IVaultViewManualTransfersSearchResultData[] = [];

const initialManualTransfersApiCallParams: types.IVaultsSearchParams = {
  searchDateOption: "",
  searchKey: QueryStringSearchKey.MIN,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

const initialManualTransfersSearchBarParams: types.IVaultSearchBarParams = {
  data: initialManualTransfersApiCallParams,
};

const incomingTransfers: types.IManualTransfers = {
  isLoading: true,
  searchBarParams: initialManualTransfersSearchBarParams,
  searchResult: initialManualTransfersSearchResult,
};

export const fetchIncomingTransfers = createAsyncThunk<
  IVaultViewManualTransfersSearchResultData[],
  void,
  {
    rejectValue: IHttpErrorState;
  }
>("incomingTransfers/fetch", async (_, thunkApi) => {
  try {
    return await getManualTransfers();
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const incomingDeliveriesSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchIncomingTransfers.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchIncomingTransfers.fulfilled, (state, action) => {
      state.searchResult = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchIncomingTransfers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
  initialState: incomingTransfers,
  name: "incomingTransfers",
  reducers: {},
});

export default incomingDeliveriesSlice.reducer;
