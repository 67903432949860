import React, { ReactElement } from "react";

import Dialog from "@mui/material/Dialog";

import { IMersRegisterPackage } from "Features/Vault/VaultInterfaces";

import Content from "./Content";

interface IMersRegisterUploadModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (results: IMersRegisterPackage[]) => void;
}

type Props = IMersRegisterUploadModalProps;

export function UploadModal(props: Props): ReactElement {
  function handleCloseClick(): void {
    props.onClose();
  }

  return (
    <Dialog fullWidth={true} maxWidth="lg" onClose={handleCloseClick} open={props.open}>
      <Content onClose={handleCloseClick} onSubmit={props.onSubmit} />
    </Dialog>
  );
}

export default UploadModal;
