import React, { ReactElement } from "react";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { VaultMersConfirmationResultsModalPopup } from "../ModalPopups/VaultMersConfirmationResultsModalPopup";
import {
  IMersBatchConfirmationRequest,
  IMersSecuredPartyTransaction,
  IVaultViewQueueSearchResultTableData,
  MersConfirmationStatus,
  MersTransactionType,
  QueueAction,
} from "../VaultInterfaces";

export interface ISubmitSecuredPartyConfirmationModalProps {
  confirmationOpen: boolean;
  data: IVaultViewQueueSearchResultTableData[];
  onHideConfirmation: () => void;
  onUpdateCallback: () => void;
}

export default function SubmitSecuredPartyConfirmationModal(
  props: ISubmitSecuredPartyConfirmationModalProps
): ReactElement {
  const { confirmationOpen, data, onHideConfirmation, onUpdateCallback } = props;

  const [showConfirmationResultsModal, setShowConfirmationResultsModal] = React.useState<boolean>(false);
  const [confirmationTransaction, setConfirmationTransaction] = React.useState<IMersBatchConfirmationRequest>({
    transfers: [],
    type: MersTransactionType.SecuredParty,
  });

  function confirmSubmit() {
    const request: IMersBatchConfirmationRequest = {
      transfers: [],
      type: MersTransactionType.SecuredParty,
    };

    for (const item of data) {
      if (item.action !== MersConfirmationStatus.None) {
        request.transfers.push({
          confirmationStatus: item.action,
          id: item.id,
          min: item.min,
          packageId: item.packageId,
          queueAction: getAction(item.action),
          transferId: item.transferId,
        } as IMersSecuredPartyTransaction);
      }
    }

    onHideConfirmation();
    setShowConfirmationResultsModal(true);
    setConfirmationTransaction(request);
  }

  function getAction(status: MersConfirmationStatus): QueueAction {
    switch (status) {
      case MersConfirmationStatus.Approve:
        return QueueAction.Approve;
      case MersConfirmationStatus.Deny:
        return QueueAction.Deny;
      case MersConfirmationStatus.Pending:
      default:
        return QueueAction.Reset;
    }
  }

  function closeConfirmationResults() {
    setShowConfirmationResultsModal(false);
    onUpdateCallback();
  }

  return (
    <>
      <ModalPopupAlert
        title="Submit confirmations?"
        content="This will submit the selected transfers per your specified confirmations."
        confirmationButtonText="SUBMIT"
        onConfirm={confirmSubmit}
        showDeclineLink={true}
        onClose={onHideConfirmation}
        open={confirmationOpen}
        size="sm"
      />

      <VaultMersConfirmationResultsModalPopup
        onClosed={closeConfirmationResults}
        request={confirmationTransaction}
        open={showConfirmationResultsModal}
      />
    </>
  );
}
