import axios from "axios";

/**
 * Retrieves the client defined data fields for the user
 * @returns a Promise for the request
 */
export async function getClientDefinedDataFields(): Promise<any> {
  const response = await axios.get("/api/v1/client-defined-data-fields");
  return response.data;
}

/**
 * Updates the client defined data fields for the requested packages
 */
export async function updateClientDefinedDataFields(instructions: string, clientCode: string): Promise<any> {
  const response = await axios.post<any>(`/api/v1/clients/${clientCode}/packages/client-defined-data-fields`, {
    instructions,
  });
  return response.data;
}
