/* eslint-disable max-len */
import React, { ReactElement } from "react";

import { SvgIcon, SvgIconProps } from "@mui/material";

export default function InboxIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props}>
      <path d="M69.71,34.21,55.8,14.86A6.84,6.84,0,0,0,50.23,12H22.77a6.86,6.86,0,0,0-5.58,2.86L3.28,34.21a6.86,6.86,0,0,0-1.28,4v15A6.88,6.88,0,0,0,8.9,60H64.1A6.88,6.88,0,0,0,71,53.14h0V38.2A6.81,6.81,0,0,0,69.71,34.21ZM46.54,36a10.2,10.2,0,0,1-20.39,0H10.6L22.74,19h27.2L62.09,36Z" />
    </SvgIcon>
  );
}
