import React, { ReactElement } from "react";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import * as types from "Types/EVaultAppTypes";

import { VaultMersConfirmationResultsModalPopup } from "../../ModalPopups/VaultMersConfirmationResultsModalPopup";
import {
  IMersBatchConfirmationRequest,
  IVaultViewIncomingTransferSearchResultTableData,
  MersConfirmationStatus,
  MersTransactionType,
} from "../../VaultInterfaces";

export interface ITransferConfirmatinModalProps {
  data: IVaultViewIncomingTransferSearchResultTableData[];
  incomingTransferProps: types.IManualTransfers;
  showTransferConfirmation: boolean;
  onHideConfirmation: () => void;
}

export interface ITransferConfirmatinModalState {
  showBatchRequests: boolean;
  batchRequest: IMersBatchConfirmationRequest;
}

export class TransferConfirmationModal extends React.Component<
  ITransferConfirmatinModalProps,
  ITransferConfirmatinModalState
> {
  constructor(props: ITransferConfirmatinModalProps) {
    super(props);

    this.state = {
      batchRequest: {
        transfers: [],
        type: MersTransactionType.Transfer,
      },
      showBatchRequests: false,
    };
  }

  private closeTransferRequestResults = () => {
    this.props.onHideConfirmation();
    this.setState({
      showBatchRequests: false,
    });
  };

  private confirmTransferConfirmations = (): void => {
    this.props.onHideConfirmation();

    const batchRequest: IMersBatchConfirmationRequest = {
      transfers: [],
      type: MersTransactionType.Transfer,
    };

    const data = this.props.data as IVaultViewIncomingTransferSearchResultTableData[];
    for (const item of data) {
      const transfer = item;
      if (transfer.action !== MersConfirmationStatus.None) {
        const request = {
          action: transfer.action,
          min: transfer.min,
          packageId: transfer.packageId,
          transferId: transfer.transferId,
        };
        batchRequest.transfers.push(request);
      }
    }

    this.setState({
      batchRequest,
      showBatchRequests: true,
    });
  };

  public render(): ReactElement {
    return (
      <div>
        <ModalPopupAlert
          title="Submit confirmations"
          content="Are you sure you want to submit the Accept, Reject, and/or Reset confirmations for the selected incoming transfer requests?"
          confirmationButtonText="CONTINUE"
          onConfirm={this.confirmTransferConfirmations}
          showDeclineLink={true}
          onClose={this.props.onHideConfirmation}
          open={this.props.showTransferConfirmation}
          size="sm"
        />
        <VaultMersConfirmationResultsModalPopup
          onClosed={this.closeTransferRequestResults}
          request={this.state.batchRequest}
          open={this.state.showBatchRequests}
        />
      </div>
    );
  }
}
