import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { removeDelegateeForTransfer } from "../../../Adapters/Mers/mersRegistrationAdapter";
import { AppDispatch } from "../../../App/Store";
import { RemovePartyIcon } from "../../../Components/Icons";
import { batchTransactionAction } from "../Mers/MersTransaction/MersTransactionSlice";
import { IVaultViewClosedLoansSearchResultData, MersTransactionType } from "../VaultInterfaces";
import { RemoveDelegateeForTransfersModal } from "./RemoveDelegateeForTransfersModal";

interface IRemoveDelegateeForTransfersMenuItemProps {
  package: IVaultViewClosedLoansSearchResultData;
}

export function RemoveDelegateeForTransfersMenuItem(props: IRemoveDelegateeForTransfersMenuItemProps) {
  const [removeDelegateeForTransferConfirmModalOpen, setRemoveDelegateeForTransferConfirmModalOpen] =
    useState<boolean>(false);

  const dispatch = useDispatch() as AppDispatch;

  function handleMenuClick() {
    setRemoveDelegateeForTransferConfirmModalOpen(true);
  }

  function handleClose() {
    setRemoveDelegateeForTransferConfirmModalOpen(false);
  }

  function handleConfirm() {
    setRemoveDelegateeForTransferConfirmModalOpen(false);
    dispatch(
      batchTransactionAction(
        uuid(),
        props.package.loanNumber,
        () => removeDelegateeForTransfer(props.package.id, props.package.min),
        MersTransactionType.DelegateeForTransfer
      )
    );
  }

  return (
    <>
      <MenuItem onClick={handleMenuClick}>
        <ListItemIcon>
          <RemovePartyIcon />
        </ListItemIcon>
        <ListItemText>Remove Delegatee for Transfers</ListItemText>
      </MenuItem>
      <RemoveDelegateeForTransfersModal
        onClose={handleClose}
        onConfirm={handleConfirm}
        open={removeDelegateeForTransferConfirmModalOpen}
      />
    </>
  );
}
