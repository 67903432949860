import { PackageAction, PackageDeliveryStatus, PackageRegistrationStatus, PackageTransferStatus } from "Common/Enums";

export interface IVaultViewAllLoansDocumentDetailData {
  id: number;
  isInkSign: boolean;
  name: string;
  signatureStatus: string;
  formType: string;
  checkDisabled: boolean;
  mediaType: string;
  hasAssociatedSMARTDoc: boolean;
}

export interface IVaultLoanSearchResult {
  id: string;
  loanNumber?: string;
  packageId?: string;
  primarySignerLastName?: string;
  workflowType?: string;
}

export enum RecipientAccessors {
  controlRecipient = "controlRecipient",
  deliveryRecipient = "deliveryRecipient",
  locationRecipient = "locationRecipient",
  servicingAgentRecipient = "servicingAgentRecipient",
  subservicerRecipient = "subServicerRecipient",
  delegateeForTransferRecipient = "delegateeForTransferRecipient",
  delegateeForTransfersRecipient = "delegateeForTransfersRecipient",
  rightsholderRecipient = "rightsholderRecipient",
  securedPartyRecipient = "securedPartyRecipient",
  securedPartyDelegateeRecipient = "securedPartyDelegateeRecipient",
}

export enum DeliveryAccessors {
  delivery = "delivery",
}

export interface IVaultMersPackageRecord extends IVaultLoanSearchResult {
  originatorOrgId: string;
  mersData: IMersData;
}

interface IPackageClientDefinedDataField {
  clientDefinedDataFieldId: string;
  value: string;
}

export interface IVaultViewClosedLoansSearchResultData extends IVaultMersPackageRecord {
  action: PackageAction;
  clientDefinedDataFields: IPackageClientDefinedDataField[];
  closingDate: string;
  controlTransferCompleted: string;
  controlTransferInitiated: string;
  created: string;
  id: string;
  loanNumber: string;
  min: string;
  noteModificationDate: string | null;
  packageId: string;
  packageType: string;
  primarySignerFirstName: string;
  primarySignerLastName: string;
  primarySignerMiddleName: string;
  primarySignerSuffix: string;
  registeredOn: string;
  originator: string;
}

export interface IVaultViewTransferableClosedLoansSearchResultData {
  action: PackageAction;
  closingDate: string;
  created: string;
  loanNumber: string;
  mersInitiators: IMersInitiators;
  min: string;
  originatorOrgId: string;
  packageId: string;
  primarySignerFirstName: string;
  primarySignerLastName: string;
  primarySignerMiddleName: string;
  primarySignerSuffix: string;
  registeredOn: string;
}

export interface IMersData {
  changeStatusTransaction: IMersLastTransactionResult;
  deliveryTransaction: IMersLastTransactionResult;
  mersDeliveryStatus: PackageDeliveryStatus;
  mersInitiators: IMersInitiators;
  mersRegistrationStatus: PackageRegistrationStatus;
  mersTransferStatus: PackageTransferStatus;
  registrationTransaction: IMersLastTransactionResult;
  transferTransaction: IMersLastTransactionResult;
  incomingTransferTransaction: IMersLastTransactionResult;
}

export interface IMersTransactionData {
  completionDate: Date;
  controlOrg: IMersOrg | null;
  delegateeForTransfersOrg: IMersOrg | null;
  initiatedDate: Date;
  initiator: IMersOrg | null;
  loanNumber: string;
  locationOrg: IMersOrg | null;
  min: string;
  servicerOrg: IMersOrg | null;
  securedPartyOrg: IMersOrg | null;
  securedPartyDelegateeOrg: IMersOrg | null;
  subServicerOrg: IMersOrg | null;
}

export interface IDeliveryDetails {
  completionDate: Date;
  filename: string;
  initiatedDate: Date;
  initiator: IMersOrg | null;
  recipientDetails: IDeliveryRecipientDetails[];
}

export interface IDeliveryRecipientDetails {
  approvalDate: Date;
  confirmationDate: Date;
  deliveryRecipientStatus: DeliveryRecipientStatusValue;
  details: string;
  mersOrg: IMersOrg;
}

export interface IMersOrg {
  name: string;
  orgId: string;
}

export interface IMersOriginator {
  id: number;
  organizationDefault: boolean;
  name: string;
  mersOrgId: string;
}

export interface IVaultViewInactiveLoansSearchResultData extends IVaultMersPackageRecord {
  action: PackageAction;
  closingDate: string;
  controlTransferCompleted: string;
  controlTransferInitiated: string;
  created: string;
  loanNumber: string;
  min: string;
  packageId: string;
  packageType: string;
  primarySignerFirstName: string;
  primarySignerLastName: string;
  primarySignerMiddleName: string;
  primarySignerSuffix: string;
  noteStatus: string;
}

export interface IVaultViewManualTransfersSearchResultData {
  controllerOrgId: string;
  createDate: string;
  delegateeOrgId: string;
  documentId: number;
  effectiveDate: string;
  id: string;
  loanNumber: string;
  locationOrgId: string;
  min: string;
  packageId: string | null;
  request: number;
  requestorOrgId: string;
  status: number;
  transferId: string;
}

export interface IVaultViewManualDeliveriesSearchResultData extends IVaultLoanSearchResult {
  createDate: string;
  documentId: number;
  id: string;
  loanNumber: string;
  manualDeliveryStatus: ManualDeliveryStatus;
  min: string;
  packageId: string;
  request: string;
  requestorOrgId: string;
  status: number;
  transferId: string;
}

export interface IVaultViewManualDeliveriesSearchResultExtendedData extends IVaultViewManualDeliveriesSearchResultData {
  action: DeliveryRecipientStatus;
}

export interface IVaultViewQueueSearchResultData {
  action?: MersConfirmationStatus;
  effectiveDate: string;
  id: string;
  initiatedDate: string;
  min: string;
  packageId: string;
  smartDocId: number;
  request: string;
  sender: IMersRecipient;
  status: number;
  transferId: string;
  controllerOrg: IMersRecipient;
  locationOrg: IMersRecipient;
  delegateeOrg: IMersRecipient;
}

export enum VaultViewClosedLoansTabTypes {
  MERSInquiryDetails = "closedLoansMersInquiryDetails",
  MERSOrgDetails = "closedLoansMersOrgDetails",
  SearchResults = "closedLoansSearchResults",
  DeliverMERS = "closedLoansDeliverMers",
  TransferMERS = "closedLoansTransferMers",
  RegisterMERS = "closedLoansRegisterMers",
  UpdateMERS = "closedLoansUpdateMers",
  DocumentList = "closedLoansDocumentList",
}

export enum VaultViewArchivedLoansTabTypes {
  DocumentList = "archivedLoansDocumentList",
  MERSInquiryDetails = "archivedLoansMersInquiryDetails",
  SearchResults = "archivedLoansSearchResults",
}

export enum VaultViewInactiveLoansTabTypes {
  DocumentList = "inactiveLoansDocumentList",
  MERSInquiryDetails = "inactiveLoansMersInquiryDetails",
  SearchResults = "inactiveLoansSearchResults",
}

export enum VaultViewIncomingTabTypes {
  DocumentList = "incomingLoansDocumentList",
  IncomingDeliveries = "incomingDeliveryLoans",
  IncomingTransfers = "incomingTransferLoans",
  MERSInquiryDetails = "incomingMersInquiryDetails",
}

export enum VaultViewQueuedLoansTabTypes {
  DocumentList = "queueLoansDocumentList",
  MERSInquiryDetails = "queueLoansMersInquiryDetails",
  SearchResults = "queueLoansSearchResults",
}

export enum VaultViewBatchOperationsTabTypes {
  SearchResults = "batchOperationsSearchResults",
  BatchOperationsStatusDetails = "batchOperationsStatusDetails",
}

export enum VaultViewMinReconciliationTabTypes {
  SearchResults = "minReconciliationSearchResults",
  MinReconciliationStatusDetails = "minReconciliationStatusDetails",
  MinReconciliationClientMersOrgs = "minReconciliationClientMersOrgs",
}

export interface IVaultViewIncomingTransferSearchResultTableData {
  action: MersConfirmationStatus;
  actions: MersConfirmationStatus[];
  controllerOrgId: string;
  createDate: string;
  delegateeOrgId: string;
  documentId: number;
  effectiveDate: string;
  effectiveDateSortable: string;
  id: string;
  initiatedSortable: string;
  locationOrgId: string;
  mersTransferStatusValue: number;
  min: string;
  packageId: string | null;
  parentTransfer: string;
  request: number;
  requestorOrgId: string;
  transferId: string;
}

export interface IVaultViewQueueSearchResultTableData {
  action: MersConfirmationStatus;
  actions: MersConfirmationStatus[];
  controller: string;
  effective: string;
  id: string;
  initiated: string;
  location: string;
  min: string;
  packageId: string;
  request: string;
  requestTypeTooltip: string;
  sender: string;
  servicer: string;
  smartDocId: number;
  status: number;
  transferId: string;
}

export enum QueueAction {
  Approve = "Approve",
  Deny = "Deny",
  Reset = "Reset",
  Remove = "Remove",
}

export enum StatusRibbonValues {
  invalid = 0,
  valid = 1,
  unknown = 2,
}

export interface IMERSInquiryBorrower {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  nameSuffix?: string;
  nonPersonName?: string;
  ssn?: string;
  taxIdentificationNumber?: string;
}

export interface IMERSInquiry {
  results: IMERSInquiryResults[];
  tamperSealStatus: StatusRibbonValues;
}

export enum MersNoteStatus {
  Unknown = 0,
  Active = 1,
  ConvertedToPaper = 2,
  ChargedOff = 3,
  PaidOff = 4,
  RegistrationReversal = 5,
  TransferredToProprietaryRegistry = 6,
  Other = 7,
  ChargedOffReversal = 8,
  ConvertedToPaperReversal = 9,
  TransferredToProprietaryRegistryReversal = 10,
  PaidOffReversal = 11,
  Removed = 12,
  PaperReplacement = 13,
  PaperReplacementReversal = 14,
}

/*
 * The status of the MERS registration
 */
export enum MersRegistrationStatus {
  None = 0,

  /*
   * Note is not yet registered
   */
  Unregistered = 1,

  /*
   * A registration request has been submitted to MERS but not yet completed.
   */
  RegistrationPending = 2,

  /*
   * The registration request failed. Check the transaction log to see relevant errors or warnings.
   */

  RegistrationFailed = 3,

  /*
   * The note has been registered with MERS
   */
  Registered = 4,

  /*
   * A transfer request has been sent to MERS, but not yet completed.
   */
  TransferPending = 5,

  /*
   * The transfer request failed. Check the transaction log to see relevant errors or warnings.
   */
  TransferFailed = 6,

  /*
   * The note has been transferred in some form. See the registration MERS orgs to determine what has been transferred.
   */
  Transferred = 7,

  /*
   * An incoming transfer has been accepted
   */
  Accepted = 8,

  /*
   * Whether the transfer was rejected
   */
  Rejected = 9,

  /*
   * Whether the transfer was rejected
   */
  Archived = 10,

  /*
   * There are situations where notifications may have been lost for outgoing transfers.
   * In the case we had a pending transfer but MERS no longer has a notification, we have no better status.
   */
  IndeterminateTransfer = 11,
}

export interface IRegistrationHistory {
  min: string;
  originalRegistrant: IMersOrg | null;
  controller: IMersOrg | null;
  location: IMersOrg | null;
  delegatee: IMersOrg | null;
  noteStatus: MersNoteStatus;
  subServicer: IMersOrg | null;
  securedParty: IMersOrg | null;
  securedPartyDelegatee: IMersOrg | null;
  delegateeForTransfers: IMersOrg | null;
  noteAssumptionDate: string | null;
  noteModificationDate: string | null;
  registrationStatus: MersRegistrationStatus;
  validFrom: string;
  validTo: string;
}

export interface IMersTransactionDetailedData extends IMersTransactionData {
  registration: IRegistrationHistory | null;
  referenceId: string;
  status: MersTransactionStatus;
  type: MersDatabaseTransactionType;
}

export enum MersDatabaseTransactionType { // These do not all match up with the database.  This is intentional because of the nature of the transaction types.
  Unknown = 0,
  ConnectivityTest = 1,
  Registration = 2,
  Delivery = 3,
  Transfer = 4,
  DeliveryConfirmation = 5,
  DeliveryApproval = 6,
  TransferConfirmation = 7,
  Inquiry = 8,
  ChangeData = 9,
  ChangeStatus = 10, // This is actually the ChangeStatus value but we only have ReverseRegistration handled for ChangeStatus at this time
  ChangeStatusNotification = 11,
  ChangeDataNotification = 12,
  RegistrationNotification = 13,
  ReleaseSecuredParty = 14,
  PendingTransferNotification = 15,
}

export interface IRightsHolderDifference {
  key: string;
  newValue: IMersRecipient;
  oldValue: IMersRecipient;
}

export interface IItemDifference {
  description: string;
  key: string;
  newValue: IMersRecipient;
  oldValue: IMersRecipient;
}

export interface IMERSInquiryResults {
  borrowers?: IMERSInquiryBorrower[];
  controller?: IMersRecipient;
  delegatee?: IMersRecipient;
  delegateeForTransfers?: IMersRecipient;
  differences: IRightsHolderDifference[];
  inactiveStatusType?: string;
  isActive: boolean;
  isAssumed?: boolean;
  isModified?: boolean;
  lienPriorityType?: string;
  location?: IMersRecipient;
  min: string;
  modificationType?: string;
  pendingTransfer?: {
    id: string;
    effectiveDate: string;
  };
  property?: {
    address?: {
      apartmentOrUnit?: string;
      directionPrefix?: string;
      directionSuffix?: string;
      houseNumber?: string;
      streetName?: string;
      streetSuffix?: string;
    };
    city?: string;
    county?: string;
    postalCode?: string;
    state?: string;
  };
  registeringOrg?: IMersRecipient;
  registrationDate: string;
  securedParty?: IMersRecipient;
  securedPartyDelegatee?: IMersRecipient;
  smartDocVersion?: string;
  subServicer?: IMersRecipient;
  tampersealDate?: string;
  tampersealValid: StatusRibbonValues;
  updates: IItemDifference[];
  vaultIdentifier?: string;
}

export interface IMersTransactionStatusDetail {
  type: string;
  /**
   * The status code returned from MERS (if available). This is usually numeric;
   * but may also store MERS particpant defined codes so we can"t guarentee it is
   * numeric.
   */
  code: string;
  /**
   * A short description of the error code or the exception message
   */
  name: string;
  /**
   * A detailed description of the error code or the exception stack trace
   */
  description: string;
  /**
   * The name of the item that triggered the error or warning
   */
  key: string;
  /**
   * The value of the item that triggered the error or warning
   */
  value: string;
}

export interface IMersTransactionResult {
  id: string;
  type: string;
  packageId: string;
  loanNumber: string;
  status: string;
  statusDetails: IMersTransactionStatusDetail[];
}

export interface IMersLastTransactionResult {
  referenceId: string;
  status: number;
  type: number;
}

export interface IMersTransactionResponse {
  errors: string[];
  error: string;
  loanNumber: string;
  status: string;
  type: string;
  transactionResults: IMersTransactionResult[];
}

export interface IMersConfirmationResponse {
  errors: string[];
  error: string;
  min: string;
  status: string;
  transactionResults: IMersTransactionResult[];
}

export enum RegistrationType {
  Presentation = 1,
  DataPoint = 2,
}

export enum MersTransactionType { // These do not all match up with the database.  This is intentional because of the nature of the transaction types.
  Delivery = 3,
  ChangeStatus = 23,
  LastResultRegistration = 20,
  LastResultReverseRegistration = 21,
  LastResultTransfer = 22,
  LastResultDelivery = 26,
  Registration = 2,
  ReverseRegistration = 10, // This is actually the ChangeStatus value but we only have ReverseRegistration handled for ChangeStatus at this time
  // Once other change status functions are implemented we will need to handle this better.
  ChangeData = 9,
  Transfer = 4,
  TransferConfirmation = 7,
  CancelTransfer = 5,
  DeliveryApproval = 6,
  DeliveryConfirmation = 8,
  CancelDelivery = 25,
  SecuredParty = 24,
  DelegateeForTransfer = 27,
  RemoveSubservicer = 28,
  Unknown = 0,
}

export enum MersTransactionStatus {
  Unknown = 0,
  Pending = 1,
  Initiated = 2,
  Success = 3,
  Failed = 4,
  Rejected = 5,
  Timeout = 6,
  DeliveryNotApproved = 7,
  TransferNotApproved = 8,
  Canceled = 9,
}

export enum MersRecipientStatusType {
  Unknown = 0,
  Active = 1,
  Default = 2,
  Hidden = 3,
}

export enum MersRecipientAcceptStatusType {
  Unknown = 0, // Not a valid DB value. Used when the status couldn't be determined
  Reject = 1,
  Accept = 2,
  Approve = 3,
  Manual = 4,
}

export enum MersTransferTypes {
  LocationServicer = -1, // this is not a valid MERS option
  Invalid = 0,
  All = 1,
  Control = 2,
  Location = 3,
  Servicer = 4,
  ControlLocation = 5,
  ControlServicer = 6,
  ControlWithSecuredParty = 7,
  ControlAndLocationWithSecuredParty = 8,
}

export enum MersInitiatorTypes {
  None = 0,
  Controller = 1,
  Location = 2,
  Servicer = 3,
  DelegateeForTransfer = 4,
  ControllerServicer = 5,
  ControllerDelegateeForTransfer = 6,
  ServicerDelegateeForTransfer = 7,
  All = 8,
}

export interface IMersRecipient {
  orgId: string;
  name: string;
}

export interface IMersRecipientDetails extends IMersRecipient {
  id: number;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  mersOriginatorId: number;
  status: MersRecipientStatusType;
  deliveryAutoAcceptStatus: MersRecipientAcceptStatusType;
  transferAutoAcceptStatus: MersRecipientAcceptStatusType;
}

// Update queue page
export interface IMersUpdatePackage extends IVaultMersPackageRecord {
  packageId: string;
  loanNumber: string;
  changeStatus?: string;
  changeData?: string;
  rightsholder?: string;
  rightsholderType?: string;
  rightsholderRecipient?: IMersRecipientDetails;
  isInvalidState?: boolean;
  min?: string;
  selected: boolean;
}

// Register queue page
export interface IMersRegisterPackage {
  dataPointOnly: boolean;
  delegateeForTransferRecipient?: IMersRecipientDetails;
  id: string;
  loanNumber: string;
  min: string;
  originatorOrgId: string;
  packageId: string;
  securedPartyDelegateeRecipient?: IMersRecipientDetails;
  selected: boolean;
  securedPartyRecipient?: IMersRecipientDetails;
  servicingAgentRecipient?: IMersRecipientDetails;
}

// Transfer queue page
export interface IMersTransferPackage extends IVaultLoanSearchResult {
  controlRecipient?: IMersRecipientDetails;
  currentUserInitiatorType?: MersInitiatorTypes;
  delegateeForTransfersRecipient?: IMersRecipientDetails;
  effectiveDate: Date;
  id: string;
  includeDelivery?: boolean;
  isInvalidState?: boolean;
  originatorOrgId: string;
  loanNumber: string;
  locationRecipient?: IMersRecipientDetails;
  mersInitiators: IMersInitiators;
  min: string;
  packageId: string;
  selected: boolean;
  securedPartyDelegateeRecipient?: IMersRecipientDetails;
  securedPartyRecipient?: IMersRecipientDetails;
  servicingAgentRecipient?: IMersRecipientDetails;
  subServicerRecipient?: IMersRecipientDetails;
  transactionType?: MersTransferTypes;
}

export interface IMersDeliveryPackage extends IVaultMersPackageRecord {
  packageId: string;
  deliveryRecipient?: IMersRecipientDetails;
  loanNumber: string;
  min: string;
  isInvalidState?: boolean;
  documents: IDeliveryDocument[];
  selected: boolean;
}

export interface IDeliveryDocument {
  id: number;
  name: string;
  selected: boolean;
}

export interface IMersTransaction {
  packageId: string;
}

export interface IMersReverseRegistrationTransaction {
  packageId: string;
}

export interface IMersSecuredPartyTransaction {
  confirmationStatus: MersConfirmationStatus;
  id: string;
  min: string;
  packageId: string | null;
  queueAction: QueueAction;
  transferId: string;
}

export interface IMersReverseModificationTransaction {
  min: string;
  type: MersModificationTypes;
}

export interface IMersUpdateChangeDataTransaction extends IMersTransaction {
  min: string;
  noteStatus: string;
  modificationType: MersModificationTypes;
  rightsholder: string;
  mersOrgId: string;
}

export interface IMersUpdateChangeStatusTransaction extends IMersTransaction {
  noteStatus: string;
  min: string;
  packageId: string;
}

export interface IMersRegisterTransaction extends IMersTransaction {
  clientId?: string;
  min?: string;
  delegateeOrgId?: string; // servicer
  delegateeForTransferOrgId?: string;
  dataPointOnly?: boolean;
  loanNumber: string;
  originatorOrgId?: string;
  mersOriginatorId?: number;
  securedPartyOrgId?: string;
  securedPartyDelegateeOrgId?: string;
}

export interface IMersTransferTransaction extends IMersTransaction {
  transferType?: number;
  transferControlRecipient?: IMersRecipientDetails;
  transferDelegateeForTransfersRecipient?: IMersRecipientDetails;
  transferLocationRecipient?: IMersRecipientDetails;
  transferSecuredPartyDelegateeRecipient?: IMersRecipientDetails;
  transferSecuredPartyRecipient?: IMersRecipientDetails;
  transferServicerRecipient?: IMersRecipientDetails;
  transferSubServicerRecipient?: IMersRecipientDetails;
  effectiveDate?: Date | string;
  skipDeliveryFor?: IMersRecipientDetails[];
}

export interface IMersDeliveryRecipient {
  orgId: string;
  name: string;
}

export interface IMersDeliveryTransaction extends IMersTransaction {
  recipients: IMersDeliveryRecipient[];
  documents: number[];
}

export interface IMersManualTransferTransaction {
  packageId: string | null;
  transferId: string;
  confirmationStatus: MersConfirmationStatus;
}

export interface IMersManualDeliveryTransaction {
  id: string;
  transferId: string;
  deliveryRecipientStatus: DeliveryRecipientStatus;
  manualDeliveryStatus: ManualDeliveryStatus;
}

interface IMersBatchRequest {
  type: MersTransactionType;
  successCallback?: (result: IMersTransactionResponse) => void;
}

export interface IMersBatchTransactionRequest extends IMersBatchRequest {
  packages: (
    | IMersUpdateChangeDataTransaction
    | IMersUpdateChangeStatusTransaction
    | IMersRegisterTransaction
    | IMersTransferTransaction
    | IMersReverseRegistrationTransaction
    | IMersReverseModificationTransaction
    | IMersSecuredPartyTransaction
  )[];
}

export interface IMersBatchDeliveryRequest extends IMersBatchRequest {
  packages: IMersDeliveryTransaction[];
  type: MersTransactionType;
  successCallback?: (result: IMersTransactionResponse) => void;
}

export interface IMersBatchConfirmationRequest extends IMersBatchRequest {
  transfers: (IMersConfirmationRequest | IMersSecuredPartyTransaction)[];
}

export interface IMersConfirmationRequest {
  action: MersConfirmationStatus;
  min: string;
  packageId: string | null;
  transferId: string;
}

export interface IMersBatchDeliveryConfirmationRequest {
  deliveries: IMersDeliveryConfirmationRequest[];
}

export interface IMersDeliveryConfirmationRequest {
  action: DeliveryRecipientStatus;
  id: string;
  manualDeliveryStatus: ManualDeliveryStatus;
  min: string;
  transferId: string;
}

export interface IMersInitiators {
  controller?: IMersRecipientDetails;
  location?: IMersRecipientDetails;
  delegatee?: IMersRecipientDetails;
  delegateeForTransfer?: IMersRecipientDetails;
  originalRegistrant: IMersRecipientDetails;
  securedParty: IMersRecipientDetails;
  securedPartyDelegatee: IMersRecipientDetails;
  subServicer: IMersRecipientDetails;
}

export interface ILoanActivity {
  alternatePackageType: string;
  events: ILoanActivityEvent[];
  loanNumber: string;
  min: string;
  packageId: string;
  packageType: string;
  primarySignerFirstName: string;
  primarySignerLastName: string;
  primarySignerMiddleName: string;
  workflowType: string;
}

export interface ILoanActivityEvent {
  date: Date;
  id: string;
  name: string;
}

export enum MersTransferStatus {
  Accepted = 1,
  Rejected = 2,
  Pending = 3,
  Completed = 4,
}

export enum MersConfirmationStatus {
  None = "none",
  Accepted = "Accept",
  Approve = "Approve",
  Rejected = "Reject",
  Deny = "Deny",
  Pending = "Reset",
}

export enum MersConfirmationOption {
  Other = "Other",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Pending = "Pending",
}

export enum DeliveryRecipientStatus {
  None = "none",
  Accept = "Accept",
  Reject = "Reject",
  Approve = "Approve",
  Disapprove = "Disapprove",
}

export enum DeliveryRecipientOption {
  Other = "Other",
  Accepted = "Accepted",
  Rejected = "Rejected",
  Cancelled = "Cancelled",
  Expired = "Expired",
  Approved = "Approved",
  ApprovedConditionally = "ApprovedConditionally",
  Disapproved = "Disapproved",
}

export enum DeliveryRecipientStatusValue {
  Other = 0,
  Accepted = 1,
  Rejected = 2,
  Cancelled = 3,
  Expired = 4,
  Approved = 5,
  ApprovedConditionally = 6,
  Disapproved = 7,
  Pending = 8,
}

export enum ManualDeliveryStatus {
  Other = 0,
  Acceptance = 1,
  Approval = 2,
}

export enum MersInitiatorPositions {
  Controller = "controller",
  Location = "location",
  Servicer = "delegatee",
}

export enum MersNoteUpdateFunctions {
  ChangeStatus = "status",
  UpdateRightsholder = "rightsholder",
  ChangeData = "data",
  Modification = "modification",
}

export enum MersModificationTypes {
  None = 0,
  CEMA, // unused
  Electronic,
  Paper,
  PaperReversal, // update status
  ElectronicReversal, // update status,
  Reversal,
}

export enum MersChangeStatusTypes {
  ChargedOff,
  ConvertedToPaper,
  PaidOff,
  TransferredToProprietaryRegistry,
  ChargedOffReversal,
  ConvertedToPaperReversal,
  PaidOffReversal,
  RegistrationReversal,
  TransferredToProprietaryRegistryReversal,
  PaperReplacement,
  PaperReplacementReversal,
}

export type VaultDocumentModes =
  | "None"
  | "AllDocs"
  | "eSignDocs"
  | "InkSignDocs"
  | "eSignAndAddedModifiedDocs"
  | "InkSignAndAddedModifiedDocs";

export type Roles =
  | "evault.admin"
  | "evault.loan_officer"
  | "evault.mers.registrar"
  | "evault.mers.transferor"
  | "evault.mers.admin"
  | "evault.reader";
