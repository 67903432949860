/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { ReactElement, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { upperCase } from "lodash";
import { ConnectedProps, connect } from "react-redux";

import dateField from "Common/Fields/DateField";
import dateTimeField from "Common/Fields/DateTimeField";

import ErrorState from "Components/DataTable/ErrorState";
import Loading from "Components/Loading";
import StatusRibbon from "Components/Notifications/StatusRibbon";
import ScrollContainer from "Components/ScrollContainer";

import { inquireMERS } from "Adapters/Mers/mersAdapter";

import * as tabActions from "Features/Tabs/Tabs";
import { ITabIdentifier } from "Features/Tabs/Tabs";
import { IMERSInquiry, IMERSInquiryResults, IMersRecipient } from "Features/Vault/VaultInterfaces";

import InquiryUpdatesDisplay from "./InquiryUpdatesDisplay";
import localization from "./localization";

export interface IMERSInquiryDetailsProp {
  min: string;
  onClose?: () => any;
  packageId: string;
  tabIdentifier: ITabIdentifier;
}

const connector = connect();
type TypesFromRedux = ConnectedProps<typeof connector>;

export interface IMERSInquiryResultRow {
  results: IMersInquiryResult[];
}

export interface IMersInquiryResult {
  title: string;
  value: string;
}

type Props = IMERSInquiryDetailsProp & TypesFromRedux;

export function MersInquiryDetails(props: Props): ReactElement {
  const [showInquiryError, setShowInquiryError] = useState<boolean>(false);
  const [details, setDetails] = useState<IMERSInquiry>({} as IMERSInquiry);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { dispatch, min, packageId } = props;

  useEffect(() => {
    async function requestInquiry() {
      try {
        setShowInquiryError(false);
        const inquiry = await inquireMERS(min, packageId);
        setDetails(inquiry[0]);
        setIsLoading(false);
      } catch (_) {
        setIsLoading(false);
        setShowInquiryError(true);
      }
    }

    if (isLoading) {
      void requestInquiry();
    }
  }, [isLoading, min, packageId]);

  function handleRetry() {
    setIsLoading(true);
  }

  function getIndicatorStatus(indicator: boolean | undefined): string {
    if (indicator == null) {
      return "";
    } else {
      return indicator ? "Y" : "N";
    }
  }

  const sections: JSX.Element[] = [];
  if (!isLoading && details && details.results && details.results.length > 0) {
    const result = details.results[0];
    sections.push(getLoanInfo(result));
    sections.push(getEnoteInfo(result));
    sections.push(getRightsHolders(result));
    sections.push(<InquiryUpdatesDisplay inquiry={result} />);
  }

  function getLoanInfo(result: IMERSInquiryResults): JSX.Element {
    let address = "";
    if (result.property && result.property.address) {
      address = [
        result.property.address.houseNumber,
        result.property.address.directionPrefix,
        result.property.address.streetName,
        result.property.address.streetSuffix,
        result.property.address.directionSuffix,
        result.property.address.apartmentOrUnit,
      ]
        .filter((s) => s)
        .join(" ");
      const addressLineTwo = [result.property.city, result.property.state, result.property.postalCode]
        .filter((s) => s)
        .join(", ");
      if (addressLineTwo) {
        address += ", " + addressLineTwo;
      }
    }

    let partyName = "";
    let partyData = "";

    // Format the name
    if (result.borrowers && 0 < result.borrowers.length) {
      const borrower = result.borrowers[0];

      if (borrower.nonPersonName) {
        partyName = borrower.nonPersonName;
      } else if (borrower.lastName || borrower.firstName || borrower.middleName || borrower.nameSuffix) {
        if (borrower.lastName) {
          if (borrower.firstName || borrower.middleName || borrower.nameSuffix) {
            partyName =
              borrower.lastName +
              ", " +
              [borrower.firstName, borrower.middleName, borrower.nameSuffix].filter((s) => s).join(" ");
          } else {
            partyName = borrower.lastName;
          }
        }
      }

      if (borrower.ssn) {
        partyData = borrower.ssn;
      } else if (borrower.taxIdentificationNumber) {
        partyData = borrower.taxIdentificationNumber;
      }
    }

    const rows: IMERSInquiryResultRow[] = [];
    rows.push({
      results: [
        { title: "MIN", value: result.min },
        { title: "PROPERTY ADDRESS", value: address },
      ],
    });
    rows.push({
      results: [
        { title: "ACTIVE STATUS", value: getIndicatorStatus(result.isActive) },
        {
          title: "PROPERTY COUNTY",
          value: result.property && result.property.county ? result.property.county : "",
        },
      ],
    });
    rows.push({
      results: [
        { title: "BORROWER NAME", value: partyName },
        { title: "ASSUMPTION?", value: getIndicatorStatus(result.isAssumed) },
      ],
    });
    rows.push({
      results: [
        { title: "BORROWER SSN", value: partyData },
        {
          title: "MODIFICATION?",
          value: getIndicatorStatus(result.isModified),
        },
      ],
    });
    rows.push({
      results: [
        {
          title: "LIEN TYPE",
          value: result.lienPriorityType ? result.lienPriorityType : "",
        },
        { title: "", value: "" },
      ],
    });

    return getSection("LOAN INFO", rows);
  }

  function getEnoteInfo(result: IMERSInquiryResults) {
    const rows: IMERSInquiryResultRow[] = [];
    rows.push({
      results: [
        {
          title: "SMART DOC?",
          value: result.smartDocVersion ? `Y (Version ${result.smartDocVersion})` : "N",
        },
        {
          title: "TAMPERSEAL DATE/TIME",
          value: dateTimeField.renderForDisplay(result.tampersealDate),
        },
      ],
    });
    rows.push({
      results: [
        {
          title: "REGISTERING PARTY",
          value: result.registeringOrg ? `${result.registeringOrg.orgId} ${result.registeringOrg.name}` : "",
        },
        {
          title: "VAULT IDENTIFIER",
          value: result.vaultIdentifier ? result.vaultIdentifier : "",
        },
      ],
    });
    rows.push({
      results: [
        {
          title: "REGISTRATION DATE/TIME",
          value: dateTimeField.renderForDisplay(result.registrationDate),
        },
        {
          title: "TRANSFER IDENTIFIER",
          value: result.pendingTransfer ? "Y, " + dateField.renderForDisplay(result.pendingTransfer.effectiveDate) : "",
        },
      ],
    });

    return getSection("ENOTE INFO", rows);
  }

  function getRightsHolders(result: any) {
    const rows: IMERSInquiryResultRow[] = [
      {
        results: [
          {
            title: upperCase(localization.mers.rightsHolder.controller),
            value: getOrgValue(result.controller),
          },
          {
            title: upperCase(localization.mers.rightsHolder.delegateeForTransfers),
            value: getOrgValue(result.delegateeForTransfers),
          },
        ],
      },
      {
        results: [
          {
            title: upperCase(localization.mers.rightsHolder.servicer),
            value: getOrgValue(result.delegatee),
          },
          {
            title: upperCase(localization.mers.rightsHolder.securedParty),
            value: getOrgValue(result.securedParty),
          },
        ],
      },
      {
        results: [
          {
            title: upperCase(localization.mers.rightsHolder.subServicer),
            value: getOrgValue(result.subServicer),
          },
          {
            title: upperCase(localization.mers.rightsHolder.securedPartyDelegatee),
            value: getOrgValue(result.securedPartyDelegatee),
          },
        ],
      },
      {
        results: [
          {
            title: upperCase(localization.mers.rightsHolder.location),
            value: getOrgValue(result.location),
          },
          {
            title: "",
            value: "",
          },
        ],
      },
    ];

    return getSection("RIGHTSHOLDERS", rows);
  }

  function getOrgValue(org?: IMersRecipient): string {
    let name = "";
    if (org) {
      if (org.name) {
        name = `${org.orgId} (${org.name})`;
      } else {
        name = org.orgId;
      }
    }
    return name;
  }

  function getSection(title: string, rows: IMERSInquiryResultRow[]): JSX.Element {
    return (
      <div
        css={css`
          padding-top: 16px;
        `}
      >
        <h1
          css={css`
            font-family: "Saira Regular", serif;
            font-size: 20px;
            margin: 0 16px 12px;
          `}
        >
          {title}
        </h1>
        {rows.map((row: IMERSInquiryResultRow, rowIndex: number) => {
          return (
            <div
              css={css`
                border-bottom: solid 1px #f0f0f0;
                display: flex;
                flex-direction: row;
                align-items: center;

                :last-child {
                  border-bottom: 0;
                }
              `}
              key={rowIndex}
            >
              {row.results.map((result: IMersInquiryResult, columnIndex: number) => {
                return (
                  <div
                    css={css`
                      align-items: center;
                      display: flex;
                      flex-basis: 0;
                      flex-direction: row;
                      flex-grow: 1;
                      height: 48px;
                    `}
                    key={rowIndex + "-" + columnIndex}
                  >
                    <span
                      css={css`
                        font-family: "Saira Regular", "Saira", sans-serif;
                        font-size: 13px;
                        padding: 0 40px;
                        flex-basis: 0;
                        flex-grow: 1;
                      `}
                    >
                      {result.title}
                    </span>
                    <span
                      css={css`
                        font-family: "Roboto Regular", serif;
                        font-size: 13px;
                        padding: 0 40px;
                        flex-basis: 0;
                        flex-grow: 1;
                      `}
                    >
                      {result.value}
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  function handleGoBack() {
    dispatch(tabActions.removeTab(props.tabIdentifier));
  }

  return (
    <ScrollContainer>
      <div
        css={css`
          padding-bottom: 16px;
        `}
      >
        {!isLoading && !showInquiryError && (
          <StatusRibbon
            css={css`
              padding: 8px 8px;
            `}
            isLoading={isLoading}
            status={details.tamperSealStatus}
            validText="Valid eNote Tamper Seal"
            invalidText="Invalid eNote Tamper Seal"
            errorText="Unknown eNote Tamper Seal"
          />
        )}
        {!showInquiryError && sections}
      </div>
      {showInquiryError && (
        <div
          css={css`
            align-items: center;
            display: flex;
            height: 100%;
          `}
        >
          <ErrorState
            message="An error occurred while performing the inquiry"
            onBack={handleGoBack}
            onRetry={handleRetry}
            title="MERS inquiry error"
          />
        </div>
      )}
      {isLoading && <Loading />}
    </ScrollContainer>
  );
}

export default connector(MersInquiryDetails);
