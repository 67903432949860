import React, { ReactElement, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Link from "@mui/material/Link";

export default function TermsOfUse(): ReactElement {
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  function handleOpen() {
    setModalVisible(true);
  }
  function handleClose() {
    setModalVisible(false);
  }

  return (
    <>
      <Link aria-label="About us, opens modal" onClick={handleOpen} role="button" noWrap underline="none">
        About
      </Link>
      <Dialog maxWidth="xs" id="modal-about-us" onClose={handleClose} open={modalVisible}>
        <DialogTitle id="modal-title-about-us">About Us</DialogTitle>
        <DialogContent id="modal-content-about-us">
          {/* eslint-disable-next-line max-len */}
          Solex&trade; is a secure technology platform enabling you to electronically receive and sign documents
          anywhere, anytime, and from any device.
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
