import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState } from "App/Store";

import { BottomBar } from "Components/Notifications/BottomBar";
import ScrollContainer from "Components/ScrollContainer";

import { fetchQueueAction } from "../Mers/Queue/queuedLoansService";
import { IVaultViewQueueSearchResultTableData, MersConfirmationStatus, QueueAction } from "../VaultInterfaces";
import QueueFilterBar from "./QueueFilterBar";
import QueueSearchResultsTable from "./QueueSearchResultsTable";
import SecuredPartyConfirmationModal from "./SecuredPartyConfirmationModal";
import SubmitSecuredPartyConfirmationModal from "./SubmitSecuredPartyConfirmationModal";

interface ISearchResultsTabProps {
  keyRoot: string;
  onAddInquiryDetails: (min: string, packageId: string) => void;
  onUpdateCallback: () => void;
}

const mapStateToProps = (state: RootState) => ({
  error: state.queue.error,
  initialized: state.queue.initialized,
  isLoading: state.queue.isLoading,
  links: state.queue.links,
  queueLoanProps: state.queue,
  searchResults: state.queue.searchResult,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = ISearchResultsTabProps & TypesFromRedux;

export function QueueSearchResults(props: ComponentProps): ReactElement {
  const { dispatch, initialized, keyRoot, searchResults, onUpdateCallback } = props;

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState<boolean>(false);
  const [actionSecuredParty, setActionSecuredParty] = useState<IVaultViewQueueSearchResultTableData | undefined>();
  const [queueAction, setQueueAction] = useState<QueueAction | undefined>();

  useEffect(() => {
    if (!initialized) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchQueueAction());
    }
  }, [dispatch, initialized]);

  function handleSecuredPartyAction(data: IVaultViewQueueSearchResultTableData, action: QueueAction): void {
    setActionSecuredParty(data);
    setQueueAction(action);
    setShowConfirmation(true);
  }

  function hideSecuredPartyConfirmation() {
    setShowConfirmation(false);
  }

  function handleSubmitClick() {
    setShowSubmitConfirmation(true);
  }

  function hideSubmitConfirmation() {
    setShowSubmitConfirmation(false);
  }

  function passesValidation(): boolean {
    return _.some(searchResults, function (item: IVaultViewQueueSearchResultTableData) {
      return item.action !== MersConfirmationStatus.None;
    });
  }

  return (
    <>
      <ScrollContainer>
        <QueueFilterBar />
        <QueueSearchResultsTable keyRoot={keyRoot} onSecuredPartyAction={handleSecuredPartyAction} />
      </ScrollContainer>

      <BottomBar
        contents="Submit confirmations for selected transfers."
        buttons={[
          {
            closable: false,
            disabled: !passesValidation(),
            onClick: handleSubmitClick,
            text: "SUBMIT",
          },
        ]}
      />

      <SecuredPartyConfirmationModal
        confirmationOpen={showConfirmation}
        confirmItem={actionSecuredParty}
        onHideConfirmation={hideSecuredPartyConfirmation}
        onUpdateCallback={onUpdateCallback}
        queueAction={queueAction}
      />

      <SubmitSecuredPartyConfirmationModal
        confirmationOpen={showSubmitConfirmation}
        data={searchResults}
        onHideConfirmation={hideSubmitConfirmation}
        onUpdateCallback={onUpdateCallback}
      />
    </>
  );
}

export default connector(QueueSearchResults);
