import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IErrorPayload {
  autoHide: boolean;
  autoHideDuration: number | null;
  inForm: boolean;
  message?: string;
  variant: "success" | "info" | "warning" | "error";
}

export interface IErrorState {
  all: IErrorPayload[];
  current: IErrorPayload | Partial<IErrorPayload>;
  isOpen: boolean;
}

const initialState: IErrorState = {
  all: [],
  current: {},
  isOpen: false,
};

export const errorSlice = createSlice({
  initialState,
  name: "errors",
  reducers: {
    close: (state) => {
      return Object.assign({}, state, {
        ...state,
        isOpen: false,
      });
    },
    dequeue: (state) => {
      return Object.assign({}, state, {
        ...state,
        all: state.all.slice(1),
        isOpen: !!state.all.slice(1).length,
      });
    },
    enqueue: (state, action: PayloadAction<any>) => {
      const payload = action.payload;
      return Object.assign({}, state, {
        ...state,
        all: state.all.concat({
          autoHide: payload.options.autoHide,
          autoHideDuration: payload.options.autoHideDuration,
          inForm: payload.options.inForm,
          message: payload.message,
          variant: payload.options.variant,
        }),
        isOpen: true,
      });
    },
  },
});

export const { close, dequeue, enqueue } = errorSlice.actions;

export default errorSlice.reducer;
