import axios from "axios";
import querystring from "querystring";

import { SortOrderType } from "Common/Enums";
import { getAdjustedTimesFormatted } from "Common/dateUtilities";

import { IResultPages } from "Types/EVaultAppTypes";

import { QueryStringSearchKey } from "../Constants";
import { IArchivedLoansSearchApiParams, IVaultViewArchivedLoansSearchResultData } from "./Types";

export interface IPagedArchivedLoans {
  links: IResultPages;
  results: IVaultViewArchivedLoansSearchResultData[];
}

/**
 * Get the search results for the Transferred Loans Admin page
 * @param params search criteria values
 * @returns a promise for the request
 */
export function getTransferredMERSPackages(params: IArchivedLoansSearchApiParams): Promise<IPagedArchivedLoans> {
  return axios.get(getTransferredMERSPackagesUrl(params)).then((response) => response.data);
}

export function getTransferredMERSPackagesByUrl(url: string): Promise<IPagedArchivedLoans> {
  return axios.get(url).then((response) => response.data);
}

interface IArchivedLoansSearchPayload {
  searchKey: QueryStringSearchKey;
  searchTerm: string;
  sortField?: string;
  sortOrderType?: SortOrderType;
  start?: string;
  stop?: string;
  limit: number;
  offset: number;
}

export function getTransferredMERSPackagesUrl(params: IArchivedLoansSearchApiParams): string {
  return `/api/v1/packages/archived${getTransferredQuery(params)}`;
}

export function getTransferredQuery(params: IArchivedLoansSearchApiParams): string {
  const payload: IArchivedLoansSearchPayload = {
    ...getAdjustedTimesFormatted(params.start, params.stop),
    limit: params.limit,
    offset: params.offset,
    searchKey: params.searchKey,
    searchTerm: params.searchTerm,
  };

  // Sort
  if (params.sortByParams) {
    payload.sortField = params.sortByParams.sortField;
    payload.sortOrderType = params.sortByParams.sortOrderType;
  }

  // Default offset if not specified
  if (!payload.offset) {
    payload.offset = 0;
  }

  return "?" + querystring.stringify(payload as any);
}
