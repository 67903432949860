import React, { ReactElement } from "react";

import DownloadIcon from "@mui/icons-material/GetApp";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";

import { DataTableIcon, ViewIcon } from "Components/Icons";

interface ISmartDocContextMenuProps {
  packageId: string;
  smartDocId: number;
}

enum SmartDocAction {
  View,
  Data,
  Download,
}

export default function SmartDocContextMenu(props: ISmartDocContextMenuProps): ReactElement {
  const { packageId, smartDocId } = props;

  function smartDocAction(event: React.MouseEvent, type: SmartDocAction) {
    event.stopPropagation();
    let action = "";
    let prefix = "/api";
    switch (type) {
      case SmartDocAction.View:
        action = "smart-doc-content";
        break;
      case SmartDocAction.Data:
        action = "smart-doc-data";
        prefix = "/app";
        break;
      case SmartDocAction.Download:
        action = "smart-doc-download";
        break;
    }
    window.open(`${prefix}/packages/${packageId}/documents/${smartDocId}/${action}`, "_blank");
  }

  const disabled = smartDocId === 0;
  return (
    <>
      <li className="table-row-context-menu-header">SMART Doc</li>

      <MenuItem disabled={disabled} onClick={(event) => smartDocAction(event, SmartDocAction.View)}>
        <ListItemIcon>
          <ViewIcon />
        </ListItemIcon>
        <ListItemText>View</ListItemText>
      </MenuItem>

      <MenuItem disabled={disabled} onClick={(event) => smartDocAction(event, SmartDocAction.Data)}>
        <ListItemIcon>
          <DataTableIcon />
        </ListItemIcon>
        <ListItemText>Data</ListItemText>
      </MenuItem>

      <MenuItem disabled={disabled} onClick={(event) => smartDocAction(event, SmartDocAction.Download)}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <ListItemText>Download</ListItemText>
      </MenuItem>
    </>
  );
}
