import React, { ReactElement } from "react";

import { ModalPopupAlert } from "Components/Notifications/ModalPopupAlert";

import { VaultMersConfirmationResultsModalPopup } from "../ModalPopups/VaultMersConfirmationResultsModalPopup";
import { VaultMersTransactionResultsModalPopup } from "../ModalPopups/VaultMersTransactionResultsModalPopup";
import {
  IMersBatchConfirmationRequest,
  IMersBatchTransactionRequest,
  IMersSecuredPartyTransaction,
  IVaultViewQueueSearchResultTableData,
  MersConfirmationStatus,
  MersTransactionType,
  QueueAction,
} from "../VaultInterfaces";

export interface ISecuredPartyConfirmationModalProps {
  confirmationOpen: boolean;
  confirmItem?: IVaultViewQueueSearchResultTableData;
  onHideConfirmation: () => void;
  onUpdateCallback: () => void;
  queueAction?: QueueAction;
}

export default function SecuredPartyConfirmationModal(props: ISecuredPartyConfirmationModalProps): ReactElement {
  const { confirmationOpen, confirmItem, onHideConfirmation, onUpdateCallback, queueAction } = props;

  const [showResultsModal, setShowResultsModal] = React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<IMersBatchTransactionRequest>({
    packages: [],
    type: MersTransactionType.SecuredParty,
  });

  const [showConfirmationResultsModal, setShowConfirmationResultsModal] = React.useState<boolean>(false);
  const [confirmationTransaction, setConfirmationTransaction] = React.useState<IMersBatchConfirmationRequest>({
    transfers: [],
    type: MersTransactionType.SecuredParty,
  });

  function getTitle(): string {
    switch (queueAction) {
      case QueueAction.Approve:
        return "Approve transfer?";
      case QueueAction.Deny:
        return "Deny transfer?";
      case QueueAction.Remove:
        return "Remove secured party?";
      case QueueAction.Reset:
        return "Reset transfer?";
      default:
        return "";
    }
  }

  function getMessage(): string {
    if (confirmItem == null) {
      return "";
    }

    switch (queueAction) {
      case QueueAction.Approve:
        return `This will approve the transfer for MIN ${confirmItem.min}.`;
      case QueueAction.Deny:
        return `This will deny the transfer for MIN ${confirmItem.min}.`;
      case QueueAction.Remove:
        return `This will remove you as the secured party for MIN ${confirmItem.min}.`;
      case QueueAction.Reset:
        return `This will reset the transfer for MIN ${confirmItem.min}.`;
      default:
        return "";
    }
  }

  function getConfirmationButtonText(): string {
    switch (queueAction) {
      case QueueAction.Approve:
        return "APPROVE";
      case QueueAction.Deny:
        return "DENY";
      case QueueAction.Remove:
        return "REMOVE";
      case QueueAction.Reset:
        return "RESET";
      default:
        return "";
    }
  }

  function handleConfirm() {
    if (queueAction != null && confirmItem != null) {
      switch (queueAction) {
        case QueueAction.Remove:
          const request: IMersBatchTransactionRequest = {
            packages: [
              {
                id: confirmItem.id,
                min: confirmItem.min,
                packageId: confirmItem.packageId,
                queueAction,
              } as IMersSecuredPartyTransaction,
            ],
            type: MersTransactionType.SecuredParty,
          };

          onHideConfirmation();
          setShowResultsModal(true);
          setTransaction(request);
          break;

        case QueueAction.Approve:
        case QueueAction.Deny:
        case QueueAction.Reset:
          const confirmationRequest: IMersBatchConfirmationRequest = {
            transfers: [
              {
                confirmationStatus: getConfirmationStatus(queueAction),
                id: confirmItem.id,
                min: confirmItem.min,
                packageId: confirmItem.packageId,
                queueAction,
                transferId: confirmItem.transferId,
              } as IMersSecuredPartyTransaction,
            ],
            type: MersTransactionType.SecuredParty,
          };

          onHideConfirmation();
          setShowConfirmationResultsModal(true);
          setConfirmationTransaction(confirmationRequest);
          break;
      }
    }
  }

  function getConfirmationStatus(action: QueueAction): MersConfirmationStatus {
    switch (action) {
      case QueueAction.Approve:
        return MersConfirmationStatus.Accepted;
      case QueueAction.Deny:
        return MersConfirmationStatus.Rejected;
      default:
        return MersConfirmationStatus.Pending;
    }
  }

  function closeRequestResults() {
    setShowResultsModal(false);
    onUpdateCallback();
  }

  function closeConfirmationResults() {
    setShowConfirmationResultsModal(false);
    onUpdateCallback();
  }

  return (
    <>
      <ModalPopupAlert
        title={getTitle()}
        content={getMessage()}
        confirmationButtonText={getConfirmationButtonText()}
        onConfirm={handleConfirm}
        showDeclineLink={true}
        onClose={onHideConfirmation}
        open={confirmationOpen}
        size="sm"
      />

      <VaultMersTransactionResultsModalPopup
        onClosed={closeRequestResults}
        request={transaction}
        open={showResultsModal}
      />

      <VaultMersConfirmationResultsModalPopup
        onClosed={closeConfirmationResults}
        request={confirmationTransaction}
        open={showConfirmationResultsModal}
      />
    </>
  );
}
