import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "App/Store";

import * as profileClient from "Adapters/profileAdapter";
import { IAdminProfileViewModel } from "Adapters/profileAdapter";

/**
 * Slice of the profile in the store
 */

export interface IProfileStore {
  antiforgeryError: boolean;
  authorized: boolean;
  isLoading: boolean;
  profile: IAdminProfileViewModel;
}

export const initialState: IProfileStore = {
  antiforgeryError: false,
  authorized: true,
  isLoading: true,
  profile: {
    displayName: "",
    roles: [],
    settings: {
      application: {},
      mers: {},
    } as any,
  },
};

export const fetchProfile = createAsyncThunk<IAdminProfileViewModel>("profile/fetch", async () => {
  return await profileClient.getUserProfile();
});

export const profileSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchProfile.rejected, (state, action: any) => {
      state.authorized = action.error.message.indexOf("401") < 0;
    });
  },
  initialState: initialState,
  name: "profile",
  reducers: {
    invalidateAntiforgery: (state) => {
      state.antiforgeryError = true;
    },
  },
});

const profileStateSelector = (state: RootState): IProfileStore => {
  return state.profile;
};

const profileSelector = (state: RootState): IAdminProfileViewModel => {
  return state.profile.profile;
};

const clientDefinedDataFieldsSelector = (state: RootState): boolean => {
  return state.profile.profile.settings.application.clientDefinedDataFieldsEnabled;
};

export { clientDefinedDataFieldsSelector, profileSelector, profileStateSelector };

export function getProfile(state: RootState): IProfileStore {
  return state.profile;
}

export const { invalidateAntiforgery } = profileSlice.actions;

export default profileSlice.reducer;
