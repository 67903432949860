import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { apiError } from "Common/error";

import * as types from "Types/EVaultAppTypes";
import { IHttpErrorState, ISortByParams } from "Types/EVaultAppTypes";

import { IMersPackageResponse, getMersPackagesByUrl } from "Adapters/Mers/mersPackageAdapter";

import { initializeMersPositions } from "Features/Vault/Mers/mersOrgService";
import { formatSearchResultLinks } from "Features/Vault/PageResponseUtilities";
import { IMersOriginator, IVaultViewClosedLoansSearchResultData } from "Features/Vault/VaultInterfaces";

import { QueryStringSearchKey, initialSortByParams } from "../Constants";

const initialClosedLoansSearchResult: IVaultViewClosedLoansSearchResultData[] = [];

export interface closedLoansSearchParams {
  channelIds: number[];
  searchKey: QueryStringSearchKey;
  searchScope?: string;
  searchTerm: string;
  sortByParams?: ISortByParams;
  start: Date;
  stop: Date;
}

export interface IVaultClosedSearchBarParams {
  data: closedLoansSearchParams;
}

export interface IClosedLoansSearchApiParams extends closedLoansSearchParams {
  includeClientDefinedDataFields?: boolean;
  limit: number;
  offset: number;
  sortByParams: ISortByParams;
}

const initialClosedLoansApiCallParams: closedLoansSearchParams = {
  channelIds: [],
  searchKey: QueryStringSearchKey.LoanNumber,
  searchTerm: "",
  start: new Date(),
  stop: new Date(),
};

export const initialClosedLoansSearchBarParams: IVaultClosedSearchBarParams = {
  data: initialClosedLoansApiCallParams,
};

const initialClosedLoans: types.IClosedLoans = {
  initialized: false,
  isLoading: false,
  searchBarParams: initialClosedLoansSearchBarParams,
  searchResult: initialClosedLoansSearchResult,
  sortByParams: initialSortByParams(),
};

export interface IClosedLoansFetch {
  url: string;
  mersOrgs: IMersOriginator[];
}

export const fetchPackages = createAsyncThunk<
  IMersPackageResponse,
  IClosedLoansFetch,
  {
    rejectValue: IHttpErrorState;
  }
>("closedLoans/fetch", async (payload: IClosedLoansFetch, thunkApi) => {
  try {
    const response = await getMersPackagesByUrl(payload.url);
    response.results = response.results.map((loan) => {
      initializeMersPositions(payload.mersOrgs, loan);
      return {
        ...loan,
      };
    });
    return response;
  } catch (err) {
    const error = apiError(err);
    return thunkApi.rejectWithValue(error);
  }
});

export const closedLoansSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(fetchPackages.pending, (state) => {
      state.error = undefined;
      state.initialized = true;
      state.isLoading = true;
      state.searchResult = [];
    });
    builder.addCase(fetchPackages.fulfilled, (state, action) => {
      const payload = action.payload;
      state.searchResult = payload.results;
      state.links = formatSearchResultLinks(payload.links);
      state.isLoading = false;
    });
    builder.addCase(fetchPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });
  },
  initialState: initialClosedLoans,
  name: "closedLoans",
  reducers: {
    resetSearch: (state) => {
      state.searchBarParams = initialClosedLoans.searchBarParams;
    },
    updateSearchBarParams: (state, action: PayloadAction<closedLoansSearchParams>) => {
      state.searchBarParams.data = action.payload;
    },
    updateSortBy: (state, action: PayloadAction<ISortByParams>) => {
      state.sortByParams = action.payload;
    },
  },
});

export const { resetSearch, updateSearchBarParams, updateSortBy } = closedLoansSlice.actions;

export default closedLoansSlice.reducer;
