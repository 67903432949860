/** @jsxImportSource @emotion/react */
import * as React from "react";

import { Controller, ControllerRenderProps, FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { GlobalError } from "react-hook-form/dist/types/errors";

import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";

interface ISelectFieldControllerProps {
  children: React.ReactNode[];
  disabled?: boolean;
  error?: GlobalError;
  helperText?: string;
  label?: string;
  name: string;
  value?: string;
}

interface IRenderSelectFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  field: ControllerRenderProps<TFieldValues, TName>;
}

/**
 * The select field controller wraps all the props for text fields so that React context can be leveraged in forms.
 */
function SelectFieldController(props: ISelectFieldControllerProps): JSX.Element {
  const { error, helperText, label, name } = props;
  const { control } = useFormContext();

  let helperDisplay = helperText;
  if (error && error.message) {
    helperDisplay = error.message;
  }

  /**
   * This method will render the form field with the props passed from the Form context and any other custom props for the component.
   */
  function renderField({ field }: IRenderSelectFieldProps) {
    return (
      <FormControl fullWidth>
        <InputLabel id={`filter-${props.name}`}>{props.label}</InputLabel>
        <Select disabled={props.disabled} error={!!error} fullWidth label={label} variant="standard" {...field}>
          {props.children}
        </Select>
        <FormHelperText error={!!error}>{helperDisplay}</FormHelperText>
      </FormControl>
    );
  }

  return <Controller control={control} name={name} render={renderField} />;
}

export default SelectFieldController;
