import React, { ReactElement, useState } from "react";

import { ConnectedProps, connect } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { RootState } from "App/Store";

import useInterval from "Common/useInterval";

import { keepAlive, sessionTimedOut } from "Adapters/profileAdapter";

import { getTimeout, isExpired } from "Features/Security";

const mapStateToProps = (state: RootState) => ({
  invalidAntiforgery: state.profile.antiforgeryError,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function PageTimeout(props: TypesFromRedux): ReactElement | null {
  const [timeRemaining, setTimeRemaining] = useState<number>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  useInterval(() => {
    const timeOut = async () => {
      await sessionTimedOut();
    };
    if (isExpired()) {
      timeOut();
    } else {
      const timeout = getTimeout();
      if (timeout != null && timeout < 60000) {
        setTimeRemaining(Math.floor(timeout / 1000));
        setDialogVisible(true);
      } else {
        setDialogVisible(false);
      }
    }
  }, 1000);

  function handleCloseDialog() {
    setDialogVisible(false);
  }

  async function handleExtendSession() {
    await keepAlive();
    setDialogVisible(false);
  }

  if (props.invalidAntiforgery) {
    return null;
  }

  return (
    <Dialog maxWidth="xs" id="modal-timeout" onClose={handleCloseDialog} open={dialogVisible}>
      <DialogTitle id="modal-timeout-title">Continue session?</DialogTitle>
      <DialogContent id="modal-timeout-content">
        Your session is set to expire in {timeRemaining} seconds. Click continue to extend your session.
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" size="large" onClick={handleExtendSession}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default connector(PageTimeout);
