import React, { ReactElement } from "react";

import { Grid } from "@mui/material";

import { AuthoritativeCopyNoIcon, AuthoritativeCopyYesIcon } from "Components/Icons";
import Tooltip from "Components/Tooltip";

import { IMersOriginator, IVaultMersPackageRecord } from "../VaultInterfaces";
import { getIsAuthoritativeCopy } from "./mersOrgService";

interface AuthoritativeCopyProps {
  mersOrgs: IMersOriginator[];
  loan: IVaultMersPackageRecord;
}

export default function AuthoritativeCopy({ mersOrgs, loan }: AuthoritativeCopyProps): ReactElement {
  const isAuthoritativeCopy = getIsAuthoritativeCopy(mersOrgs, loan);
  if (!isAuthoritativeCopy) {
    return (
      <Tooltip title="Non-Authoritative copy">
        <Grid container item justifyContent="center" alignItems="center">
          <AuthoritativeCopyNoIcon />
        </Grid>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Authoritative copy">
      <Grid container item justifyContent="center" alignItems="center">
        <AuthoritativeCopyYesIcon />
      </Grid>
    </Tooltip>
  );
}
